import { useAppSelector } from '@/store/hooks';
import {
  selectActiveRequisitionID,
  selectOnBoardStatus
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import TextInput from './TextInput';
import ToggleDrawerButton from './ToggleDrawerButton';
import _isArray from 'lodash/isArray';
import { PurchasingRequisitionItemComment } from '@/common/types/purchasing';

type CommentsProps = {
  lockedForOffice: boolean;
  disabled?: boolean;
  isLoadedFromPastRequisition?: boolean;
  value: string | PurchasingRequisitionItemComment[];
};

const Comments: React.FC<CommentsProps> = ({
  lockedForOffice,
  disabled = false,
  isLoadedFromPastRequisition,
  value,
  ...rest
}) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const activeId = useAppSelector(selectActiveRequisitionID);
  const onBoardStatus = useAppSelector(selectOnBoardStatus);

  if (!activeId && !isLoadedFromPastRequisition) {
    return (
      <TextInput value={_isArray(value) ? value[0]?.text : value} disabled={disabled} {...rest} />
    );
  } else if (activeId && !isLoadedFromPastRequisition && (isOnBoard || lockedForOffice)) {
    return (
      <TextInput
        value={_isArray(value) ? value[0]?.text : value}
        disabled={onBoardStatus === 'submitted' || lockedForOffice}
        {...rest}
      />
    );
  }

  return <ToggleDrawerButton value={_isArray(value) ? value : []} {...rest} />;
};

export default Comments;
