import React from 'react';

import { components } from 'react-select';
import Select from 'common/components/form/inputs/Select';
import Status from 'common/components/general/Status';
import { useSelector } from 'react-redux';
import { selectJobStatuses } from 'store/lists/selectors';

import { DropdownIndicator } from 'common/components/form/inputs/Select';
import EntityLabel from 'common/components/labels/EntityLabel';

const JobStatusSelector = ({ filter, onChange, isMulti, label, placeholder, ...rest }) => {
  const jobStatuses = useSelector(selectJobStatuses);

  return (
    <Select
      label={label}
      placeholder={placeholder ? placeholder : 'Select value'}
      components={{ DropdownIndicator, Option, MultiValueLabel, SingleValue }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      autoFocus
      value={filter.value}
      options={jobStatuses}
      isMulti={isMulti}
      onChange={(_, selected) =>
        onChange({
          value: isMulti
            ? selected?.length
              ? selected.map(s => s.id)
              : ''
            : selected
            ? selected
            : null
        })
      }
      isClearable
      {...rest}
    />
  );
};

const ValueLabel = ({ ...props }) => {
  return (
    <EntityLabel type={'status'} color={props.data.color}>
      <components.MultiValueLabel {...props}>
        {props.data.id ? <Status value={props.data} className="bg-transparent" /> : null}
      </components.MultiValueLabel>
    </EntityLabel>
  );
};

const MultiValueLabel = ({ children, ...props }) => {
  return <ValueLabel {...props} />;
};

const SingleValue = ({ children, ...props }) => {
  return <ValueLabel {...props} />;
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.id ? <Status value={props.data} /> : null}
    </components.Option>
  );
};

export default JobStatusSelector;
