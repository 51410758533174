import SvgRender from 'common/components/general/SvgRender';
import UploadFiles from '@/ts-common/components/form/inputs/upload-files';
import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';
import digitalFormsIcon from 'common/assets/svg/common/forms.svg';
import { Attachment } from '@/ts-common/types/files';
import { FC } from 'react';

type FormFileIconProps = {
  formType: 'digital' | 'upload';
  id?: number | string;
  file?: Attachment;
  openNewTab?: boolean;
  className?: string;
};

const FormFileIcon: FC<FormFileIconProps> = ({
  formType,
  id,
  file,
  openNewTab,
  className = ''
}) => {
  const { history } = useRouter();

  return (
    <div className={`form-file-icon ${className}`}>
      {formType === 'digital' ? (
        <div
          className="digital-form-icon-container d-flex align-items-center justify-content-center cursor-pointer"
          onClick={() =>
            id
              ? openNewTab
                ? window.open(`${window.location.origin}${paths.forms}/${id}`, '_blank')
                : history.replace({
                    pathname: `${paths.forms}/${id}`,
                    search: history.location.search
                  })
              : null
          }
        >
          <SvgRender
            style={{ width: 12, height: 12 }}
            src={digitalFormsIcon}
            className="text-moody-blue pointer-events-none"
          />
        </div>
      ) : file ? (
        <UploadFiles
          group="forms.submissions"
          files={file}
          viewOnly={true}
          singleUpload={true}
          size="xs"
          onChange={() => null}
          className="mb-0"
        />
      ) : null}
    </div>
  );
};

export default FormFileIcon;
