import React from 'react';

import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import library from 'common/assets/svg/common/library.svg';
import setup from 'common/assets/svg/common/setup.svg';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import {
  isPurchasingItemsSelectionDrawerOpen,
  isPmsItemsSelectionDrawerOpen
} from 'common/components/purchasing/requisition/store/selectors';
import * as purchasingActions from 'common/components/purchasing/requisition/store/actions';

const AddFromListButton = ({ categoryID, isPms }) => {
  const isPurchasingDrawerOpen = useSelector(isPurchasingItemsSelectionDrawerOpen);
  const isPmsDrawerOpen = useSelector(isPmsItemsSelectionDrawerOpen);

  const [togglePurchasingItemsSelectionDrawer, togglePmsItemsSelectionDrawer, setActiveCategoryId] =
    useActions([
      purchasingActions.togglePurchasingItemsSelectionDrawer,
      purchasingActions.togglePmsItemsSelectionDrawer,
      purchasingActions.setActiveCategoryId
    ]);

  const toggle = () => {
    setActiveCategoryId(categoryID);

    if (isPms) {
      togglePmsItemsSelectionDrawer(!isPmsDrawerOpen);
    } else {
      togglePurchasingItemsSelectionDrawer(!isPurchasingDrawerOpen);
    }
  };

  return (
    <Button
      className={`d-flex align-items-center justify-content-center text-primary cpt-6 cpb-6 ${
        isPms ? 'ps-1 pe-2' : 'px-1'
      }`}
      type="button"
      color="yellow"
      onClick={toggle}
    >
      <SvgRender src={isPms ? setup : library} style={{ width: 13, height: 13 }} />
      <span className="fs-12 text-nowrap ms-1 fw-medium">{`Select from ${
        isPms ? 'PMS' : 'Items'
      } list`}</span>
    </Button>
  );
};

export default AddFromListButton;
