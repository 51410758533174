import { get } from '@/api';
import { OptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';

export const getDefaultOptions = (options?: OptionsRequestParams) => {
  if (!options) return Promise.resolve(null);

  const { path, params } = options;

  const requestParams = { ...(params || {}) };

  return get<unknown[]>(path, requestParams).then(res => res.data);
};

export const loadAsyncOptions = async (options?: OptionsRequestParams, search?: string) => {
  if (!options || !search?.length) return Promise.resolve(null);

  const { path, params } = options;

  const requestParams = { ...(params || {}), search: search || undefined };

  const response = await get<unknown[]>(path, requestParams);

  return response.data;
};
