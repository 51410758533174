import { useRef, useState, useEffect, FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import PropTypes from 'prop-types';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

const TextWithTooltip: FC<any> = ({
  children,
  placement = 'top',
  className = '',
  onClick = () => null,
  innerTooltipClassName = '',
  dataCy = undefined
}) => {
  const [parentWidth, setParentWidth] = useState(0);
  const parentElement = useRef<any>(null);
  const overflownElement = useRef<any>(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const isOverflown = (element: any) => {
    return element.scrollWidth > element.clientWidth;
  };

  const { avoidRender, tooltipID } = useTooltipID('information-tooltip');

  useEffect(() => {
    if (tooltipID) setShowTooltip(isOverflown(overflownElement.current));
  }, [children?.length, tooltipID, parentWidth]);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (entries && entries.length > 0) {
        const newWidth = entries[0].contentRect.width;
        setParentWidth(Math.round(newWidth));
      }
    });

    const currentElement = parentElement.current;

    if (currentElement) {
      observer.observe(currentElement);

      // Initial width calculation
      const initialWidth = currentElement.offsetWidth;
      setParentWidth(Math.round(initialWidth));
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <div className={`text-overflow h-100p w-100p overflow-hidden ${className}`} ref={parentElement}>
      <div
        onClick={onClick}
        className={`text-overflow__text text-truncate w-100p`}
        ref={overflownElement}
        id={tooltipID}
        data-cy={dataCy}
      >
        {children}
      </div>
      {!avoidRender && showTooltip && (
        <UncontrolledTooltip
          boundariesElement="window"
          arrowClassName="d-none"
          placement={placement}
          target={tooltipID}
          innerClassName={innerTooltipClassName}
        >
          {children}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

TextWithTooltip.propTypes = {
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end'
  ])
};

export default TextWithTooltip;
