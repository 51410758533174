import { AccountType } from '@/common/types/account';
import {
  PermisionEnvironments,
  PermisionModeType,
  PermissionType,
  PermissionsType
} from '@/common/types/permissions';
import { authorizeAccount, getPermissionSettings } from 'common/utils/permissions/helpers';

export const isAuthorized = (
  account: AccountType,
  permissions: PermissionsType = [],
  mode: PermisionModeType = 'or',
  environments: PermisionEnvironments = []
) => {
  if (account?.is_ocean_admin) return true;

  return authorizeAccount({ account, permissions, mode, environments }, false);
};

export const getAuthorizedPermissionSettings = (
  account: AccountType,
  permission: PermissionType,
  mode: PermisionModeType = 'or',
  environments: PermisionEnvironments = []
) => {
  return getPermissionSettings({ account, permission, mode, environments }, false);
};

export const entityExistsInPermissionSettings = (
  account: AccountType,
  permission: PermissionType,
  entityKey: string,
  entityId: string | number
): boolean => {
  if (!permission) return false;

  const isAllowed = isAuthorized(account, [permission]);

  if (!isAllowed || !entityKey) return false;

  const entitySettings = getAuthorizedPermissionSettings(account, permission)?.[entityKey];

  // In case that entity settings are empty array return true
  // for example, in this case office.vessels.view: { vessels: [] } we return true because it applies for all vessels
  if (Array.isArray(entitySettings) && !entitySettings?.length) {
    return true;
  } else if (!Array.isArray(entitySettings)) {
    return false;
  }

  return entitySettings.includes(entityId);
};
