const operators = {
  string: {
    value: 'text',
    operations: [
      {
        symbol: 'c',
        label: 'contains',
        value: 'contains'
      },
      {
        symbol: '=',
        label: 'is',
        value: '='
      }
    ]
  },
  percentage: {
    value: 'text',
    operations: [
      {
        symbol: '=',
        label: 'is',
        value: '='
      },
      {
        symbol: '≠',
        label: 'is not',
        value: '!='
      },
      {
        symbol: '<',
        label: 'is less than',
        value: '<'
      },
      {
        symbol: '≤',
        label: 'is less than or equal to',
        value: '<='
      },
      {
        symbol: '>',
        label: 'is greater than',
        value: '>'
      },
      {
        symbol: '≥',
        label: 'is greater than or equal to',
        value: '>='
      }
    ]
  },
  number: {
    value: 'text',
    operations: [
      {
        symbol: '=',
        label: 'is',
        value: '='
      },
      {
        symbol: '≠',
        label: 'is not',
        value: '!='
      },
      {
        symbol: '<',
        label: 'is less than',
        value: '<'
      },
      {
        symbol: '≤',
        label: 'is less than or equal to',
        value: '<='
      },
      {
        symbol: '>',
        label: 'is greater than',
        value: '>'
      },
      {
        symbol: '≥',
        label: 'is greater than or equal to',
        value: '>='
      }
    ]
  },
  price: {
    value: 'text',
    operations: [
      {
        symbol: '=',
        label: 'is',
        value: '='
      },
      {
        symbol: '≠',
        label: 'is not',
        value: '!='
      },
      {
        symbol: '<',
        label: 'is less than',
        value: '<'
      },
      {
        symbol: '≤',
        label: 'is less than or equal to',
        value: '<='
      },
      {
        symbol: '>',
        label: 'is greater than',
        value: '>'
      },
      {
        symbol: '≥',
        label: 'is greater than or equal to',
        value: '>='
      }
    ]
  },
  collection: {
    value: 'select',
    operations: [
      {
        label: 'one of',
        value: 'oneOf'
      },
      {
        label: 'not in',
        value: 'notIn'
      }
    ]
  },
  collectionMultiple: {
    value: 'select',
    operations: [
      {
        label: 'one of',
        value: 'oneOf'
      },
      {
        label: 'not in',
        value: 'notIn'
      },
      {
        label: 'at least in',
        value: 'atLeastIn'
      },
      {
        label: 'exactly in',
        value: 'exactIn'
      },
      {
        label: 'at most in',
        value: 'atMostIn'
      }
    ]
  },
  collectionOneOf: {
    value: 'select',
    operations: [
      {
        label: 'one of',
        value: 'oneOf'
      }
    ]
  },
  boolean: {
    value: 'select',
    operations: [
      {
        label: 'is',
        value: '='
      }
    ]
  },
  date: {
    value: 'date',
    operations: [
      {
        label: '=',
        value: '='
      },
      {
        label: 'after',
        value: '>'
      },
      {
        label: 'before',
        value: '<'
      },
      {
        label: 'between',
        value: 'between'
      },
      {
        label: 'last x days',
        value: 'lastDays'
      },
      {
        label: 'next x days',
        value: 'nextDays'
      },
      {
        label: 'before x days from today',
        value: 'beforeDaysFromToday'
      },
      {
        label: 'after x days from today',
        value: 'afterDaysFromToday'
      }
    ]
  },
  datetime: {
    value: 'date',
    operations: [
      {
        label: '=',
        value: '='
      },
      {
        label: 'after',
        value: '>'
      },
      {
        label: 'before',
        value: '<'
      },
      {
        label: 'between',
        value: 'between'
      },
      {
        label: 'last x days',
        value: 'lastDays'
      },
      {
        label: 'next x days',
        value: 'nextDays'
      }
    ]
  },
  date_period: {
    value: 'date',
    operations: [
      {
        label: '=',
        value: '='
      },
      {
        label: 'between',
        value: 'between'
      }
    ]
  },
  relative_direction: {
    value: 'select',
    operations: [
      { label: 'in', value: 'in' },
      { label: 'not in', value: 'not_in' }
    ]
  },
  inverse_relative_direction: {
    value: 'select',
    operations: [
      { label: 'in', value: 'in' },
      { label: 'not in', value: 'not_in' }
    ]
  },
  periodicity_interval: {
    value: 'select',
    operations: [
      { label: 'Days', value: 'days' },
      { label: 'Months', value: 'months' },
      { label: 'Years', value: 'years' }
    ]
  },
  ocimf_system_category: {
    value: 'select',
    operations: [
      {
        label: 'is',
        value: '='
      }
    ]
  },
  chat: {
    value: 'select',
    operations: [
      {
        label: 'has',
        value: '='
      }
    ]
  }
};
export default operators;
