import { strToNumber } from 'common/utils/numbers';

export const getFormExpenses = (activeMedicalCase, options) => {
  const expenses = options.map(option => {
    const activeExpense = activeMedicalCase.expenses?.find(
      expense => expense.event_medical_expense_id === option.id
    );

    return {
      event_medical_expense_id: option.id,
      title: option.type,
      values: activeExpense?.values ?? [{ value: null, sort_index: 0 }]
    };
  });
  return expenses;
};

export const calculateExpencesSummary = (expenses, showPaid = false) =>
  expenses?.reduce(
    (accumulator, item) =>
      accumulator +
      item.state.values.reduce((itemAccumulator, valueObj) => {
        const parsedValue = strToNumber(valueObj.value.value);
        const isPaid = valueObj.is_paid.value;

        if (showPaid && !isPaid) {
          return itemAccumulator;
        }

        return itemAccumulator + (parsedValue !== null ? parsedValue : 0);
      }, 0),
    0
  );
