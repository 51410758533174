import { Col, Row } from 'reactstrap';
import { FC } from 'react';
import { useAppSelector } from '@/store/hooks';
import { selectItemsTableComparisonViewEnabled } from '@/common/components/purchasing/requisition/store/selectors-ts';
import DifferenceNumberField from './DifferenceNumberField';
import SupplierIncludedInPoButton from '../components/SupplierIncludedInPoButton';

type ProposedQuantityFieldrops = {
  supplierRequisitionID: string;
  itemID: string;
  categoryID: number;
  disabled?: boolean;
};

const ProposedQuantityField: FC<ProposedQuantityFieldrops> = ({
  itemID,
  categoryID,
  supplierRequisitionID,
  disabled,
  ...inputProps
}) => {
  const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);

  return (
    <Row className="g-0 flex-wrap">
      <Col>
        <DifferenceNumberField
          itemID={itemID}
          supplierRequisitionID={supplierRequisitionID}
          {...inputProps}
          fieldMaxWidth={inputProps.fieldMaxWidth - 24}
          disabled={disabled}
        />
      </Col>
      <Col
        xs="auto"
        className={`min-w-24 max-w-24 d-flex justify-content-center ${
          comparisonViewIsEnabled ? 'cpt-4' : 'align-items-center'
        }`}
      >
        <SupplierIncludedInPoButton
          itemID={itemID}
          categoryID={categoryID}
          supplierRequisitionID={supplierRequisitionID}
          isFooter={false}
        />
      </Col>
    </Row>
  );
};

export default ProposedQuantityField;
