import { selectColumnTotals } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import { useAppSelector } from '@/store/hooks';
import { numberToStr } from 'common/utils/numbers';
import { FC, useMemo } from 'react';
import { ItemFieldType } from '@/common/components/purchasing/requisition/categories/items/config';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';

const characterSize = 5;

type ItemTotalsProps = {
  field: ItemFieldType;
  categoryID: number;
  isPms: boolean;
};

const ItemTotals: FC<ItemTotalsProps> = ({ field, categoryID, isPms }) => {
  const { supplierRequisitionID } = field;
  const totals = useAppSelector(state =>
    numberToStr(
      selectColumnTotals(state, categoryID, field.key, supplierRequisitionID, isPms),
      2,
      2
    )
  );

  const valueLength = useMemo(() => {
    const number = numberToStr(totals, 2, 2);

    return number?.length || 1;
  }, [totals]);

  const maxCharacters = useMemo(
    () => (field.maxWidth ? Math.round((field.maxWidth - 12) / characterSize) : valueLength),
    [field.maxWidth, valueLength]
  );

  const progress = useMemo(() => (100 * valueLength) / maxCharacters, [maxCharacters, valueLength]);

  return (
    <div
      className="position-relative text-end overflow-hidden fw-bold pe-1"
      style={{
        fontSize: progress >= 85 ? (progress < 100 ? '86%' : '80%') : ''
      }}
    >
      <TextWithTooltip>{totals}</TextWithTooltip>
    </div>
  );
};

export default ItemTotals;
