import { get, put, post, deleteRequest } from '@/api';
import { ItemType } from '@/common/components/purchasing/requisition/store/selectors-ts';
import {
  PurchasingRequisitionDeliveryReportData,
  PurchasingRequisitionDeliveryReportStatus,
  PurchasingRequisitionItemCommentsQueryParams,
  PurchasingRequisitionItemCommentMutationParams,
  PurchasingRequisitionItemCommentCreationParams,
  PurchasingRequisitionItemCommentDeletionParams,
  PurchasingRequisitionItemComment,
  PurchasingDeliveryReportStatus,
  PurchasingRequisitionDeliveryReportSupplierItem,
  PurchasingExternalSupplierTokens,
  ApplyToAllItemsInTheCategoryParams,
  Supplier
} from '@/common/types/purchasing';

// approved

export type GetPurchasingRequisitionDeliveryReportParams = {
  id: number | string;
};

/* Delivery Report */
export const getPurchasingRequisitionDeliveryReport = async (
  params: GetPurchasingRequisitionDeliveryReportParams
) => {
  const { id } = params;

  return (
    await get<PurchasingRequisitionDeliveryReportData>(`/purchasing-requisitions/${id}/delivery`)
  ).data;
};

export type UpdatePurchasingRequisitionDeliveryReportParams = {
  id: number | string;
  suppliers: { id: number | string; items: PurchasingRequisitionDeliveryReportSupplierItem[] }[];
  status?: PurchasingDeliveryReportStatus;
};

export const updatePurchasingRequisitionDeliveryReport = async (
  params: UpdatePurchasingRequisitionDeliveryReportParams
) => {
  const { id, ...rest } = params;

  return (
    await put<PurchasingRequisitionDeliveryReportData>(
      `/purchasing-requisitions/${id}/delivery`,
      rest
    )
  ).data;
};

export const getPurchasingDeliveryReportStatus = async (
  params: GetPurchasingRequisitionDeliveryReportParams
) => {
  const { id } = params;

  return (
    await get<PurchasingRequisitionDeliveryReportStatus>(
      `/purchasing-requisitions/${id}/delivery-status`
    )
  ).data;
};
/* -- */

/* Item Comments */
export const getPurchasingRequisitionItemComments = async (
  params: PurchasingRequisitionItemCommentsQueryParams
) => {
  const { id } = params;

  return (
    await get<PurchasingRequisitionItemComment[]>(`purchasing-requisitions/items/${id}/comments`)
  ).data;
};

export const createPurchasingRequisitionItemComment = async (
  params: PurchasingRequisitionItemCommentCreationParams
) => {
  const { id, ...rest } = params;

  return (
    await post<PurchasingRequisitionItemComment>(
      `purchasing-requisitions/items/${id}/comments`,
      rest
    )
  ).data;
};

export const updatePurchasingRequisitionItemComment = async (
  params: PurchasingRequisitionItemCommentMutationParams
) => {
  const { id, commentId, ...rest } = params;

  return (
    await put<PurchasingRequisitionItemComment>(
      `purchasing-requisitions/items/${id}/comments/${commentId}`,
      rest
    )
  ).data;
};

export const deletePurchasingRequisitionItemComment = async (
  params: PurchasingRequisitionItemCommentDeletionParams
) => {
  const { id, commentId } = params;

  return (
    await deleteRequest<PurchasingRequisitionItemComment>(
      `purchasing-requisitions/items/${id}/comments/${commentId}`
    )
  ).data;
};

export const applyToAllItemsInTheCategory = async (params: ApplyToAllItemsInTheCategoryParams) => {
  const { id, supplierID, categoryID, ...rest } = params;

  return await put<Supplier>(
    `purchasing-requisitions/suppliers/${supplierID}/items-category/${categoryID}`,
    rest
  );
};
/* -- */

/* Bulk create requisitions */
export type BulkPurchasingRequisitionsParams = {
  company_id: number;
  description: string;
  include_in_budget: boolean;
  is_for_vessel: boolean;
  priority_id: number;
  vessel_ids: number[];
};

export const bulkPurchasingRequisitions = async (params: BulkPurchasingRequisitionsParams) => {
  return (await post<number[]>(`/purchasing-requisitions/bulk`, params)).data;
};
/* -- */

/* Bulk update category items */
export type BulkUpdatePurchasingRequisitionCategoryItemsArgs = {
  [key: string]: unknown;
  supplierRequisitionID: string | number;
  categoryID: number;
};

export const bulkUpdatePurchasingRequisitionCategoryItems = async (
  params: BulkUpdatePurchasingRequisitionCategoryItemsArgs
) => {
  const { supplierRequisitionID, categoryID, ...rest } = params;

  return (
    await put<ItemType[]>(
      `/purchasing-requisitions/suppliers/${supplierRequisitionID}/items-category/${categoryID}`,
      rest
    )
  ).data;
};
/* -- */

/* Requisition email temporary url */
export type GetPurchasingRequisitionEmailTempUrlParams = {
  email_id: string;
};
export interface SentEmailTempUrl {
  url: string | null;
}

export const getPurchasingRequisitionEmailTempUrl = async (
  params: GetPurchasingRequisitionEmailTempUrlParams
) => {
  const { email_id } = params;
  return (await get<SentEmailTempUrl>(`/purchasing-requisitions/emails/${email_id}/temp-url`)).data;
};
/* -- */

/* Ext. Supplier */
export type RequestExternalSupplierTokenParams = { id: number };

export const requestExternalSupplierToken = async (params: RequestExternalSupplierTokenParams) => {
  const { id } = params;

  return (
    await get<PurchasingExternalSupplierTokens>(`/purchasing-requisitions/suppliers/${id}/token`)
  ).data;
};
/* -- */
