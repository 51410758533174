import { useRef } from 'react';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { isOverflownX } from 'common/utils/overflow';

const useHorizontalScroll = ref => {
  const isScrolling = useRef(false);
  const clientX = useRef(0);
  const scrollX = useRef(0);

  const onMouseDown = e => {
    if (isOverflownX(ref.current)) {
      isScrolling.current = true;

      const element = ref && ref.current ? ref.current : null;

      if (element) {
        clientX.current = e.clientX - element.offsetLeft;

        scrollX.current = element.scrollLeft;
      }
    }
  };

  const onMouseUp = () => {
    isScrolling.current = false;
  };

  const onMouseMove = e => {
    const element = ref && ref.current ? ref.current : null;

    if (isScrolling.current && element) {
      const x = e.clientX - element.offsetLeft;
      const scroll = x - clientX.current;
      element.scrollLeft = scrollX.current - scroll;
    }
  };

  useUpdateEffect(() => {
    if (isScrolling.current) {
      ref.current.classList.add('cursor-grabbing');
    } else {
      ref.current.classList.remove('cursor-grabbing');
    }
  }, [isScrolling]);

  return {
    isScrolling,
    onMouseDown,
    onMouseUp,
    onMouseMove
  };
};
export default useHorizontalScroll;
