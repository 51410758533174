import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import {
  selectSupplierInvoices,
  selectSupplierAttachmentsByStatus
} from 'common/components/purchasing/requisition/store/selectors';

import SvgRender from 'common/components/general/SvgRender';
import { icons, colors } from 'common/components/purchasing/requisition/components/Status';
import _flatten from 'lodash/flatten';

import DownloadAttachment from 'common/components/buttons/DownloadAttachment';

import * as requisitionActions from 'common/components/purchasing/requisition/store/actions';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';

import { useActions } from 'utils/hooks';
import { selectListDefaultOptions } from 'store/lists/selectors';

const SlimStatus = ({ icon, color, text, attachmentsComponent, status, className = '' }) => {
  return (
    <div
      className={`slim-supplier-status bg-light-gray d-flex align-items-center cps-4 cpy-4 cpe-4 status-${status} ${className}`}
    >
      <SvgRender
        className={`text-${color} me-1 cms-12`}
        src={icon}
        style={{ width: 12, height: 12 }}
      />

      <strong className={`text-${color} fs-12 me-1`}>{text} - </strong>

      {attachmentsComponent}
    </div>
  );
};

SlimStatus.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  className: PropTypes.string
};

const StatusesWithFiles = ({ id }) => {
  const requisitionStatuses = useSelector(state =>
    selectListDefaultOptions(state, 'purchasing-requisition-statuses')
  );
  const supplierInvoices = useSelector(state => selectSupplierInvoices(state, id));

  const qtnStatusId = requisitionStatuses.find(status => status.label === 'qtn')?.id;
  const poStatusId = requisitionStatuses.find(status => status.label === 'po')?.id;

  const invFiles = _flatten(
    supplierInvoices?.filter(invoice => invoice?.file)?.map(invoice => invoice.file)
  );
  const qtnFiles = useSelector(state => selectSupplierAttachmentsByStatus(state, id, qtnStatusId));
  const poFiles = useSelector(state => selectSupplierAttachmentsByStatus(state, id, poStatusId));

  const [downloadSupplierInvoiceAttachments] = useActions([
    requisitionActions.downloadSupplierInvoiceAttachments
  ]);

  return (
    <div className="d-flex align-items-center">
      <SlimStatus
        className="me-1"
        icon={icons.qtn}
        color={colors.qtn}
        text="QTN"
        status="qtn"
        attachmentsComponent={
          <DownloadAttachment
            data={{
              attachments: qtnFiles,
              attachattachments_count: qtnFiles.length
            }}
            downloadFiles={() =>
              downloadAllFilesOfEntity({
                id: id,
                type: 'purchasing_requisition_supplier'
              })
            }
            hasAttachments={true}
            canAddAttachments={false}
            popupPlacement="left"
          />
        }
      />
      <SlimStatus
        className="me-1"
        icon={icons.po}
        color={colors.po}
        text="PO"
        status="po"
        attachmentsComponent={
          <DownloadAttachment
            data={{
              attachments: poFiles,
              attachattachments_count: poFiles.length
            }}
            downloadFiles={() =>
              downloadAllFilesOfEntity({
                id: id,
                type: 'purchasing_requisition_supplier'
              })
            }
            hasAttachments={true}
            canAddAttachments={false}
            popupPlacement="left"
          />
        }
      />
      <SlimStatus
        className="me-1"
        icon={icons.inv}
        color={colors.inv}
        text="INV"
        status="inv"
        attachmentsComponent={
          <DownloadAttachment
            data={{
              attachments: invFiles,
              attachattachments_count: invFiles.length
            }}
            downloadFiles={() =>
              downloadSupplierInvoiceAttachments({
                id
              })
            }
            hasAttachments={true}
            canAddAttachments={false}
            popupPlacement="left"
          />
        }
      />
    </div>
  );
};

export default StatusesWithFiles;

StatusesWithFiles.propTypes = {
  id: PropTypes.number.isRequired
};
