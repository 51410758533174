import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Filter from './Filter';
import SvgRender from 'common/components/general/SvgRender';
import TableSearchText from 'common/components/table/TableSearchText';

import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';
import check from 'common/assets/svg/actions/check.svg';
import useRouter from 'use-react-router';
import _get from 'lodash/get';

import { parseQueryParams } from 'common/utils/urls';
import {
  selectAppliedTableFilters,
  selectShouldRefetchTableFiltersOnApply
} from 'common/components/table/store/selectors';
import { useSelector } from 'react-redux';

const Filters = ({
  label,
  columns = [],
  selected,
  onChange,
  onApply,
  onToggle,
  isOpen,
  excludedFilters = [],
  extraFilters,
  customText,
  className = '',
  hideTableSearch = false,
  handleTableSearch,
  grayBg
}) => {
  const { location } = useRouter();
  const appliedFilters = useSelector(state => selectAppliedTableFilters(state, label));
  const shouldRefetchTableFiltersOnApply = useSelector(state =>
    selectShouldRefetchTableFiltersOnApply(state, label)
  );

  const removeFilter = key => {
    const filtersToRemain = selected.filter(el => excludedFilters.includes(_get(el, 'column.key')));
    let newFilters = selected
      .filter(el => !excludedFilters.includes(_get(el, 'column.key')))
      .filter((_, index) => index !== key);

    if (filtersToRemain.length > 0) {
      newFilters = [...filtersToRemain, ...newFilters];
    }

    onChange(newFilters);
  };

  const updateFilter = (filter, key) => {
    let addEmpty = false;
    const filtersToRemain = selected.filter(el => excludedFilters.includes(_get(el, 'column.key')));

    let updatedFilters = selected
      .filter(el => !excludedFilters.includes(_get(el, 'column.key')))
      .map((f, index) => {
        if (index !== key) return f;
        else if (filter.column && !f.column) addEmpty = true;

        return {
          ...f,
          ...filter
        };
      });

    if (filtersToRemain.length > 0) {
      updatedFilters = [...filtersToRemain, ...updatedFilters];
    }

    if (addEmpty)
      updatedFilters = [...updatedFilters, { column: null, operation: null, value: '' }];

    onChange(updatedFilters);
  };

  const validFilters = selected.filter(
    f =>
      f.column &&
      f.operation &&
      f.value &&
      f.value !== '' &&
      !excludedFilters.includes(_get(f, 'column.key'))
  );

  useEffect(() => {
    if (validFilters.length <= 2 && !isOpen) {
      onToggle(!isOpen);
    }
  }, [validFilters?.length]);

  return (
    <div
      className={`app-filters d-flex ${className} ${
        !columns?.length && !hideTableSearch && !extraFilters ? 'app-filters--search-view' : ''
      } ${grayBg ? 'app-filters--gray-bg' : ''}`}
    >
      <div className="app-filters__container d-flex">
        {columns?.length ? (
          <div className="app-filters__toggle height-32 d-inline-flex align-items-center">
            <Button
              type="button"
              color="link"
              onClick={() => onToggle(!isOpen)}
              disabled={
                selected.filter(el => !excludedFilters.includes(_get(el, 'column.key'))).length <= 2
              }
              className={`app-filters__toggle-btn p-0 d-inline-flex align-items-center justify-content-center me-1${
                !isOpen ? ' is-closed' : ''
              }`}
            >
              <SvgRender src={arrowFilters} style={{ width: 8, height: 9 }} />
            </Button>
          </div>
        ) : null}
        <div className="app-filters__main flex-1">
          <div className="selected-filters d-flex flex-wrap w-100p h-100p selected-filters-container">
            <div className="d-flex align-items-center flex-wrap flex-1 filters-selectors-wrap">
              {selected
                .filter(el => !excludedFilters.includes(_get(el, 'column.key')))
                .map((filter, key) => {
                  return (
                    <Filter
                      customText={customText}
                      isHidden={!isOpen && key > 1}
                      key={key}
                      columns={columns}
                      filter={filter}
                      removeFilter={() => {
                        removeFilter(key);
                        if (onApply) onApply(false);
                      }}
                      updateFilter={value => {
                        updateFilter(value, key);
                        if (onApply) onApply(false);
                      }}
                      isCreate={
                        !selected
                          .filter(el => !excludedFilters.includes(_get(el, 'column.key')))
                          .some(f => f.column && f.operation && f.value && f.value !== '') &&
                        key === 0
                      }
                    />
                  );
                })}

              {!isOpen && validFilters.length > 2 ? (
                <strong className="fs-12 text-primary">{`+${validFilters.length - 2} more filter${
                  validFilters.length - 2 === 1 ? '' : 's'
                }`}</strong>
              ) : null}
            </div>

            {validFilters.length ||
            (!validFilters.length &&
              (parseQueryParams(location.search)?.filters || []).filter(
                el => !excludedFilters.includes(el.name)
              )?.length) ||
            (!validFilters.length &&
              shouldRefetchTableFiltersOnApply === false &&
              appliedFilters === false) ? (
              <div className="height-32 d-inline-flex align-items-center">
                <Button
                  type="button"
                  color="primary"
                  className="d-inline-flex align-items-center rounded rounded-sm py-0 cps-12 cpe-12 height-24"
                  onClick={() => onApply(true)}
                  size="sm"
                  data-cy="apply-filters"
                  disabled={
                    appliedFilters ||
                    validFilters.length !==
                      selected.filter(
                        c => !excludedFilters.includes(c?.column?.key) && c?.column?.key
                      ).length
                  }
                >
                  <SvgRender src={check} style={{ width: 14, height: 14 }} />
                  <span className="ps-1 line-heghit-1 fw-medium">Apply</span>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {extraFilters ? <div className="app-filters__extra">{extraFilters}</div> : null}
      </div>
      {!hideTableSearch ? (
        <div className="app-filters__search ms-2 ps-2">
          <TableSearchText onSearch={handleTableSearch} label={label} />
        </div>
      ) : null}
    </div>
  );
};

Filters.propTypes = {
  label: PropTypes.string.isRequired, // label
  columns: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.object),
  setFilters: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleFilters: PropTypes.func,
  excludedFilters: PropTypes.arrayOf(PropTypes.string),
  loadFiltersFromLocation: PropTypes.bool,
  extraFilters: PropTypes.node,
  grayBg: PropTypes.bool // Have a grayBg instead of white
};

export default Filters;
