import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from 'common/components/general/PageTitle';

import CreateNewSearch from './CreateNewSearch';
import EditSearch from './EditSearch';
import SavedSearchesSelector from './SavedSearchesSelector';

import useRouter from 'use-react-router';
import _isEqual from 'lodash/isEqual';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { parseQueryParams } from 'utils/urls';
import { selectTableListInvalid } from 'common/components/table/store/selectors';

import * as tablesActions from 'store/tables/actions';

const Title = ({
  pageTitle,
  savedSearchParam,
  tableSearch,
  tableIsInvalid,
  account,
  setModalOpened,
  queryChanged,
  label
}) => {
  return (
    <div className="page-table-layout--header-title d-flex align-items-center">
      {pageTitle}
      <SavedSearchesSelector label={label} />

      {savedSearchParam ? (
        tableSearch ? (
          <Fragment>
            {tableIsInvalid ? null : tableSearch.party_id === account.id ? (
              <EditSearch
                setModalOpened={setModalOpened}
                tableSearch={tableSearch}
                queryChanged={queryChanged}
                label={label}
                account={account}
              />
            ) : (
              <CreateNewSearch
                setModalOpened={setModalOpened}
                savedSearchParam={savedSearchParam}
                tableSearch={tableSearch}
                queryChanged={queryChanged}
                account={account}
              />
            )}
          </Fragment>
        ) : null
      ) : tableIsInvalid ? null : (
        <CreateNewSearch
          setModalOpened={setModalOpened}
          savedSearchParam={savedSearchParam}
          tableSearch={tableSearch}
          queryChanged={queryChanged}
          account={account}
        />
      )}
    </div>
  );
};

const TitleLayout = ({ title, setModalOpened, label }) => {
  const [queryChanged, setQueryChanged] = useState(false);
  const [savedSearchParam, setSavedSearchParam] = useState(null);
  const [getTableSearch] = useActions([tablesActions.getTableSearch]);
  const account = useSelector(state => state.account);
  const { history } = useRouter();

  const tableIsInvalid = useSelector(state => selectTableListInvalid(state, label));

  const tableSearch = useSelector(state => {
    const { searchId } = parseQueryParams(history.location.search);

    if (searchId) return state.tables.search;
    else return null;
  });

  useEffect(() => {
    const { searchId, searchText, ...rest } = parseQueryParams(history.location.search);

    if (
      history.location.search &&
      history.location.search !== '?' &&
      history.location.search !== ''
    ) {
      if (searchId) {
        if (searchId !== savedSearchParam) {
          setSavedSearchParam(searchId);
        }

        if (tableSearch) checkIfSearchHasChanged();
      } else if (Object.keys(rest).length) {
        setQueryChanged(true);
        setSavedSearchParam(null);
      }
    } else {
      setQueryChanged(false);
      setSavedSearchParam(null);
    }
  }, [history.location.search]);

  useEffect(() => {
    checkIfSearchHasChanged();
  }, [tableSearch]);

  useEffect(() => {
    if (savedSearchParam) {
      const fetchTableSearch = async () => {
        const res = await getTableSearch({ id: savedSearchParam });

        if (!res) {
          setSavedSearchParam(null);
          setQueryChanged(true);
        }
      };
      fetchTableSearch();
    }
  }, [savedSearchParam]);

  const checkIfSearchHasChanged = () => {
    if (tableSearch) {
      const { searchId, searchText, ...rest } = parseQueryParams(history.location.search);
      const searchParams = {
        ...rest,
        page_size: rest.page_size ? parseInt(rest.page_size) : undefined
      };
      const { filters, visible, sorting, page_size } = tableSearch;

      if (!searchParams.filters) searchParams.filters = null;
      if (!searchParams.sorting) searchParams.sorting = null;
      if (!searchParams.visible) searchParams.visible = null;

      if (
        searchId &&
        (!_isEqual(searchParams, {
          filters,
          visible,
          sorting,
          page_size: page_size ? parseInt(page_size) : undefined
        }) ||
          tableSearch.id != savedSearchParam)
      ) {
        setQueryChanged(true);
      } else {
        setQueryChanged(false);
      }
    }
  };

  return (
    <PageTitle
      render={
        <Title
          savedSearchParam={savedSearchParam}
          tableSearch={tableSearch}
          tableIsInvalid={tableIsInvalid}
          account={account}
          pageTitle={title}
          setModalOpened={setModalOpened}
          queryChanged={queryChanged}
          label={label}
        />
      }
    />
  );
};

export default TitleLayout;
