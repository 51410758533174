import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectEventName } from '@/events/store/events/selectors.js';

const Events = React.lazy(() => retry(() => import('@/views/events/index.jsx')));
const Event = React.lazy(() => retry(() => import('@/views/events/event/index.jsx')));

const EventStatuses = React.lazy(() =>
  retry(() => import('@/views/settings/events/statuses/index.jsx'))
);
const EventTemplates = React.lazy(() =>
  retry(() => import('@/views/settings/events/templates/index.jsx'))
);
const EventTemplateForm = React.lazy(() =>
  retry(() => import('@/views/settings/events/templates/form/index.jsx'))
);
const EventsCalendar = React.lazy(() => retry(() => import('@/views/events/calendar/index.jsx')));
const EventAccountsCategories = React.lazy(() =>
  retry(() => import('@/views/settings/events/accounting-categories/index.jsx'))
);

const EventMedicalCasesTypes = React.lazy(() =>
  retry(() => import('@/views/settings/events/medical-cases-types/index.jsx'))
);

const EventUnderwaterServicesTypes = React.lazy(() =>
  retry(() => import('@/views/settings/events/underwater-services-types/index.jsx'))
);

const EventOffHire = React.lazy(() =>
  retry(() => import('@/views/settings/events/off-hire/index.tsx'))
);

const Audits = React.lazy(() => retry(() => import('@/views/settings/events/audits/index.tsx')));

const LubOilAnalysis = React.lazy(() =>
  retry(() => import('@/views/settings/events/lub-oil-analysis/index.jsx'))
);

const events = [
  {
    path: `${paths.events}`,
    component: Events,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.events.list.view],
    documentTitle: 'Events'
  },
  {
    path: `${paths.events}/create`,
    component: Event,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.events.list.view],
    documentTitle: 'Create Event'
  },
  {
    path: `${paths.events}/:id`,
    component: Event,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.events.list.view],
    documentTitle: selectEventName
  },
  {
    path: `${paths.event_settings}/accounts-categories`,
    component: EventAccountsCategories,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.accounts_categories.view],
    documentTitle: 'Cost Categories'
  },
  {
    path: `${paths.event_settings}/audits/:tab(types|inspections|reasons|evaluations)?`,
    component: Audits,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.audit_types.view],
    documentTitle: 'Audits'
  },
  {
    path: `${paths.event_settings}/statuses`,
    component: EventStatuses,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.statuses.view],
    documentTitle: 'Event Statuses'
  },
  {
    path: `${paths.event_settings}/templates`,
    component: EventTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.templates.view],
    documentTitle: 'Event Templates'
  },
  {
    path: `${paths.event_settings}/templates/create`,
    component: EventTemplateForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.templates.view],
    documentTitle: 'Create Event Templates'
  },
  {
    path: `${paths.event_settings}/templates/:id`,
    component: EventTemplateForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.templates.view],
    documentTitle: selectEventName
  },
  {
    path: `${paths.event_settings}/underwater-services-types`,
    component: EventUnderwaterServicesTypes,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.underwater_services_types.view],
    documentTitle: 'Event Underwater Services Types'
  },
  {
    path: paths.event_off_hire,
    component: EventOffHire,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.off_hire.view],
    documentTitle: 'Event Off Hire'
  },
  {
    path: [
      `${paths.event_settings}/medical-cases`,
      `${paths.event_settings}/medical-cases/:tab(categories)`
    ],
    component: EventMedicalCasesTypes,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.medical_cases.view],
    documentTitle: 'Event Medical Cases Types'
  },
  {
    path: [`${paths.events_calendar}`, `${paths.events_calendar}/overview`],
    component: EventsCalendar,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.events.calendar.view],
    documentTitle: 'Events Calendar'
  },
  {
    path: paths.event_lub_oil_analysis,
    component: LubOilAnalysis,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.events.lub_oil_analysis.view],
    documentTitle: 'Lub Oil Anaylysis'
  }
];

export default events;
