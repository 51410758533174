import Input from 'common/components/form/inputs/Input';

const BasicTextField = ({ value, onChange, onAutoSave, disabled, fieldKey, ...rest }) => {
  return (
    <Input
      disabled={disabled}
      className="mb-0 white-bg"
      value={value}
      onChange={e => {
        onChange(e.target.value);
        if (onAutoSave) onAutoSave({ [fieldKey]: e.target.value });
      }}
      {...rest}
    />
  );
};

export default BasicTextField;
