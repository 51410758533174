import { memo, useRef, FC } from 'react';
import ItemSelector from './components/ItemSelector';

import useHorizontalScroll from 'common/utils/hooks/useHorizontalScroll';
import Items from './Items';
import {
  selectActiveRequisitionID,
  selectVesselRequisitionIsLockedForOffice
} from 'common/components/purchasing/requisition/store/selectors';
import { reviewProcessHasSomeCompletedSteps } from 'common/components/review-process/store/selectors';
import { isAuthorized } from 'utils/permissions/authorize';
import { selectAccount } from '@/store/account/selectors-ts';
import permissions from '@/common/utils/permissions/constants';
import { useAppSelector } from '@/store/hooks';

type CategoryItemsProps = {
  categoryID: number;
  isPms: boolean;
};

const CategoryItems: FC<CategoryItemsProps> = ({ categoryID, isPms }) => {
  const scollableRef = useRef(null);
  const { onMouseDown, onMouseUp, onMouseMove } = useHorizontalScroll(scollableRef);
  const activeId = useAppSelector(selectActiveRequisitionID);
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const account = useAppSelector(selectAccount);

  const hasOfficePermissions = isAuthorized(account, [
    permissions.office.purchasing.requisitions.items.create
  ]);
  const lockedForOffice =
    useAppSelector(selectVesselRequisitionIsLockedForOffice) ||
    (!isOnBoard && !hasOfficePermissions);
  const isLockedAfterReviewProcess = useAppSelector(state =>
    reviewProcessHasSomeCompletedSteps(state, activeId)
  );

  const isLocked = isLockedAfterReviewProcess || lockedForOffice;

  return (
    <div className="purchasing-requisition__items requisition-inner">
      <div className="pb-2 pe-2 ps-0 purchasing-requisition__items-container">
        <div
          className="purchasing-requisition__items-scrollable pb-1"
          ref={scollableRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <Items categoryID={categoryID} isOutOfList={false} isPms={isPms} />
          <Items categoryID={categoryID} isOutOfList={true} isPms={isPms} />
          {isLocked ? null : <ItemSelector categoryID={categoryID} isPms={isPms} />}
        </div>
      </div>
    </div>
  );
};

export default memo(CategoryItems);
