import { useState, FC } from 'react';
import NumberInput from 'common/components/form/inputs/NumberInput';
import { Row, Col } from 'reactstrap';

import { applyToAllItemsInTheCategoryAction } from 'common/components/purchasing/requisition/store/actions';
import { selectActiveRequisitionID } from 'common/components/purchasing/requisition/store/selectors';
import CircledButton from 'common/components/buttons/CircledButton';
import PageLoader from 'common/components/general/PageLoader';
import useFieldIsDisabled from '@/common/components/purchasing/requisition/hooks/useFieldIsDisabled';
import { reviewProcessHasSomeCompletedSteps } from '@/common/components/review-process/store/selectors-ts.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { PurchasingField } from '@/common/types/purchasing.ts';

type AvailabilityHeaderProps = {
  categoryID: number;
  field: PurchasingField;
};

const AvailabilityHeader: FC<AvailabilityHeaderProps> = ({ field, categoryID }) => {
  const [value, setValue] = useState<string | null>(null);
  const { supplierRequisitionID } = field;
  const [previousRequestedValue, setPreviousRequesteValue] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const activeID = useAppSelector(selectActiveRequisitionID);
  const isNotEditableAfterReviewProcessApproval = useAppSelector(state =>
    reviewProcessHasSomeCompletedSteps(state, activeID)
  );
  const fieldIsDisabled = useFieldIsDisabled(field.key);
  const dispatch = useAppDispatch();

  const handleAvailability = async () => {
    if (!value || value === previousRequestedValue) return;

    setIsLoading(true);

    try {
      await dispatch(
        applyToAllItemsInTheCategoryAction({
          id: activeID,
          supplierID: supplierRequisitionID,
          availability: value,
          categoryID: categoryID
        })
      ).unwrap();

      setPreviousRequesteValue(value);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      {fieldIsDisabled ? null : (
        <Row className="d-flex align-items-center cmt-4 flex-nowrap g-0">
          <Col xs={10} className="cps-4 cpe-4">
            <NumberInput
              className="discount-input mb-0"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
              disabled={isNotEditableAfterReviewProcessApproval}
            />
          </Col>
          <Col className="px-0" xs={2}>
            {isNotEditableAfterReviewProcessApproval ? null : (
              <CircledButton
                type={'reset'}
                style={{ width: 15, height: 15 }}
                svgStyle={{ width: 9, height: 9 }}
                className="d-inline-flex p-0"
                svgWrapperClassName="bg-primary"
                onClick={handleAvailability}
              />
            )}
          </Col>
        </Row>
      )}
      <PageLoader isLoading={isLoading} />
    </>
  );
};

export default AvailabilityHeader;
