import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import JobIsForVessel from 'common/components/jobs/_base/components/JobIsForVessel';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import SvgRender from 'common/components/general/SvgRender';
import classIcon from 'common/assets/svg/common/class-color.svg';
import safety_enabled from 'common/assets/svg/jobs/guards.svg';
import drydock_enabled from 'common/assets/svg/jobs/drydock_enabled.svg';
import PageLink from 'common/components/general/page-link';
import { getJobLocationPath } from 'common/utils/urls';

const JobLink = ({
  job,
  history,
  vesselName,
  isPms,
  target,
  onClick,
  isRegularJob = false, // This prop is temporary, we should remove it when the PageLink component replace all the Links in PMS
  customTooltipMessage,
  linkClassName = ''
}) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const Tag = isRegularJob ? PageLink : Link;

  return (
    <div className="d-flex align-items-center w-100p">
      <Tag
        customTooltipMessage={customTooltipMessage}
        to={`${getJobLocationPath(job)}${history?.location?.search || ''}`}
        onClick={onClick}
        target={target}
        className={`me-2 overflow-hidden ${linkClassName}`}
      >
        {isRegularJob ? job?.title : <TextWithTooltip>{job?.title}</TextWithTooltip>}
      </Tag>{' '}
      {job?.is_safety ? (
        <div className="d-inline-flex align-items-center cpb-2 cme-4">
          <SvgRender className="cme-4" src={safety_enabled} style={{ width: 14, height: 14 }} />
        </div>
      ) : null}
      {job?.is_drydock ? (
        <div className="d-inline-flex align-items-center cpb-2 cme-4 ">
          <SvgRender className="cme-4" src={drydock_enabled} style={{ width: 14, height: 14 }} />
        </div>
      ) : null}
      {job?.is_class ? (
        <div className="d-inline-flex align-items-center cpb-2 ">
          <SvgRender className="cme-4" src={classIcon} style={{ width: 14, height: 14 }} />
        </div>
      ) : null}
      {job?.is_class && job?.cms_reference_code ? (
        <div className="d-inline-flex align-items-center lh-1 fs-10 text-primary cme-4 cpb-2 w-auto text-truncate">
          <TextWithTooltip> {job?.cms_reference_code}</TextWithTooltip>
        </div>
      ) : null}
      {!isOnBoard && !isPms && <JobIsForVessel job={job} vesselName={vesselName} />}
    </div>
  );
};

export default JobLink;
