import { Link } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';
import type { Placement } from '@popperjs/core';
import { constructTooltipMessage } from './helpers';
import Tooltip from '@/ts-common/components/general/Tooltip';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import linkIcon from 'common/assets/svg/common/link.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import AuthCheck from 'components/permissions/AuthCheck';

type PageLinkProps = PropsWithChildren<{
  to: string | object;
  customTooltipMessage?: string;
  tooltipPlacement?: Placement;
  openOnNewTab?: boolean;
  permissions?: string[];
  hideLinkIcon?: boolean;
  className?: string;
}>;

const PageLink: FC<PageLinkProps> = ({
  customTooltipMessage = null,
  to,
  children,
  tooltipPlacement = 'auto',
  openOnNewTab = true,
  permissions,
  hideLinkIcon = false,
  ...rest
}) => {
  const { avoidRender, tooltipID } = useTooltipID('page-link-tooltip');

  if (avoidRender || !children) return null;

  const tooltipMessage = customTooltipMessage || constructTooltipMessage(to);

  return (
    <div className="d-flex align-items-center overflow-hidden text-nowrap">
      {hideLinkIcon ? null : (
        <AuthCheck permissions={permissions}>
          <SvgRender
            className="cme-4 text-royal-blue"
            src={linkIcon}
            id={tooltipID ? tooltipID : ''}
            style={{ minWidth: 11, minHeight: 11, maxWidth: 11, maxHeight: 11 }}
          />
          {tooltipMessage && tooltipID ? (
            <Tooltip
              placement={tooltipPlacement}
              innerClassName="min-width-fit max-width-fit"
              target={tooltipID}
              fade={false}
            >
              {tooltipMessage}
            </Tooltip>
          ) : null}
        </AuthCheck>
      )}

      <div className="d-flex align-items-center overflow-hidden text-nowrap">
        <TextWithTooltip>
          <AuthCheck permissions={permissions} unAuthorizedRender={children}>
            <Link target={openOnNewTab ? '_blank' : null} to={to} {...rest}>
              {children}
            </Link>
          </AuthCheck>
        </TextWithTooltip>
      </div>
    </div>
  );
};

export default PageLink;
