import { FC } from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  selectPurchasingRequisitionsHighlightCheapest,
  selectPurchasingRequisitionsHighlightMostExpensive
} from '@/store/settings/selectors';
import {
  selectIsMaxSupplierField,
  selectIsMinSupplierField
} from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import BasicNumberField from 'common/components/purchasing/requisition/categories/items/fields/BasicNumberField';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

type HighlightedSupplierFieldProps = {
  value: number | null;
  itemID: string;
  fieldKey: string;
  supplierRequisitionID: string;
  comparisonFieldKey: string;
};

const HighlightedSupplierField: FC<HighlightedSupplierFieldProps> = ({
  value,
  itemID,
  fieldKey,
  supplierRequisitionID,
  comparisonFieldKey = '',
  ...rest
}) => {
  const hasValue = value !== undefined;

  const shouldHighlightMostExpensive = useAppSelector(
    selectPurchasingRequisitionsHighlightMostExpensive
  );
  const shouldHighlightCheapest = useAppSelector(selectPurchasingRequisitionsHighlightCheapest);

  const isMaxPrice = useAppSelector(
    state =>
      shouldHighlightMostExpensive &&
      selectIsMaxSupplierField(state, itemID, fieldKey, supplierRequisitionID, comparisonFieldKey)
  );
  const isMinPrice = useAppSelector(
    state =>
      shouldHighlightCheapest &&
      selectIsMinSupplierField(state, itemID, fieldKey, supplierRequisitionID, comparisonFieldKey)
  );

  const { avoidRender, tooltipID } = useTooltipID(`requisition-max-number-supplier-field`);

  return (
    <>
      <div id={tooltipID || ''}>
        <BasicNumberField
          value={value}
          itemID={itemID}
          fieldKey={fieldKey}
          className={
            hasValue
              ? isMaxPrice
                ? 'max-supplier-item'
                : isMinPrice
                ? 'min-supplier-item'
                : ''
              : ''
          }
          {...rest}
        />
      </div>
      {(isMaxPrice || isMinPrice) && tooltipID && !avoidRender ? (
        <Tooltip target={tooltipID}>
          {isMaxPrice ? 'Most Expensive Price' : 'Cheapest Price'}
        </Tooltip>
      ) : null}
    </>
  );
};

export default HighlightedSupplierField;
