import React, { useCallback, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useAppSelector } from '@/store/hooks';
import _isNumber from 'lodash/isNumber';

import Field from './fields';
import ItemTotals from './components/ItemTotals';
import SupplierIncludedInQtnButton from './components/SupplierIncludedInQtnButton';

import {
  selectSupplierCurrency,
  isItemOutOfTheList,
  selectRequisitionStatusIsDraft
} from 'common/components/purchasing/requisition/store/selectors';
import { selectItemsTableComparisonViewEnabled } from '@/common/components/purchasing/requisition/store/selectors-ts';
import { selectSystemCurrency } from 'store/settings/selectors';
import { getItemClassNames, getInitialValueFrom } from './helpers';
import DiscountFieldHeader from './components/DiscountFieldHeader';
import AutoFillButton from './components/AutoFillButton';
import SupplierHeaderName from './components/SupplierHeaderName';
import LastDeliveredTooltip from './fields/LastDeliveredTooltip';
import SupplierIncludedInPoButton from './components/SupplierIncludedInPoButton';
import AvailabilityHeader from '@/common/components/purchasing/requisition/categories/items/components/AvailabilityHeader';

const FieldColumn = React.memo(
  ({
    field,
    children,
    isHeader,
    isFooter,
    isOnBoard,
    fieldIndex,
    fields,
    rowIndex,
    isInDeliveryReport,
    itemID,
    categoryID,
    ...rest
  }) => {
    const fieldsInfo = {
      field,
      fieldIndex,
      fields
    };
    const fieldsProperties = { isHeader, isFooter, isOnBoard, isInDeliveryReport };
    const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);

    return (
      <>
        {!isOnBoard &&
        !comparisonViewIsEnabled &&
        field.supplierRequisitionID &&
        field.supplierRequisitionID !== fields?.[fieldIndex - 1]?.supplierRequisitionID ? (
          <Col
            xs="auto"
            className="requisition-item supplier-qtn d-flex align-items-center justify-content-center py-0 pe-0"
            style={{ maxWidth: 20, minWidth: 20 }}
          >
            {isHeader || !field.supplierRequisitionID ? null : (
              <SupplierIncludedInQtnButton
                isFooter={isFooter}
                field={field}
                itemID={itemID}
                categoryID={categoryID}
              />
            )}
          </Col>
        ) : null}

        <Col
          xs={field.width}
          className={getItemClassNames(fieldsInfo, fieldsProperties)}
          style={{ maxWidth: field.maxWidth, minWidth: field.maxWidth }}
          data-index={
            (field.key === 'code' || field.key === 'unlisted_description') && _isNumber(rowIndex)
              ? rowIndex + 1
              : undefined
          }
          {...rest}
        >
          {children}
        </Col>
      </>
    );
  }
);
FieldColumn.displayName = 'FieldColumn';

const Currency = ({ supplierRequisitionID, equivalentCurrency }) => {
  const supplierCurrency = useSelector(state =>
    selectSupplierCurrency(state, supplierRequisitionID)
  );
  const systemCurrency = useSelector(selectSystemCurrency);

  return supplierCurrency ? (
    <span className="text-violet">
      {equivalentCurrency ? systemCurrency?.label : supplierCurrency}
    </span>
  ) : (
    ''
  );
};

const Item = ({
  categoryID,
  id,
  index,
  isOutOfTheList,
  hasSuppliers,
  fields,
  isPms,
  status,
  isInDeliveryReport
}) => {
  const getColumnKey = useCallback(field => {
    return `${field.key}${field.supplierRequisitionID ? `_${field.supplierRequisitionID}` : ''}`;
  }, []);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isDraft = useSelector(selectRequisitionStatusIsDraft);
  const categoryFields = useMemo(
    () => fields.filter(field => (!isPms && !field?.isPmsOnly) || (isPms && !field?.isItemOnly)),
    [fields, isPms]
  );

  const calculatedWidth = useMemo(() => {
    let width = 0;

    categoryFields.forEach(f => (width += f.maxWidth));

    return width;
  }, [categoryFields]);

  return (
    <>
      {index === 0 ? (
        <>
          <Row
            className={`purchasing-requisition__items-header ${
              isOutOfTheList ? 'purchasing-requisition__items-header--items-out-of-list' : ''
            } ${
              !hasSuppliers && !isOnBoard
                ? 'purchasing-requisition__items-header--no-suppliers'
                : ''
            }`}
            style={{ minWidth: calculatedWidth }}
            noGutters
          >
            {categoryFields.map((field, fieldIndex) => (
              <FieldColumn
                key={getColumnKey(field)}
                field={field}
                isHeader
                isOnBoard={isOnBoard}
                fieldIndex={fieldIndex}
                fields={categoryFields}
                itemID={id}
                categoryID={categoryID}
              >
                <SupplierHeaderName
                  fieldsInfo={{
                    field,
                    fieldIndex,
                    fields: categoryFields
                  }}
                  isDiscount={field.key === 'discount_percentage'}
                />

                <div
                  className={`purchasing-requisition__items-header-inner ${
                    field.equivalentCurrency ? 'text-moody-blue' : ''
                  }`}
                >
                  {field.label}{' '}
                  {field.currency ? (
                    <Currency
                      supplierRequisitionID={field.supplierRequisitionID}
                      equivalentCurrency={field.equivalentCurrency}
                    />
                  ) : null}
                  {field.key === 'discount_percentage' ? (
                    <DiscountFieldHeader field={field} categoryID={categoryID} isPms={isPms} />
                  ) : null}
                  {field.key === 'availability' ? (
                    <AvailabilityHeader field={field} categoryID={categoryID} />
                  ) : null}
                </div>
              </FieldColumn>
            ))}
          </Row>

          {status && !isDraft ? (
            <Row
              className="purchasing-requisition__items-footer"
              style={{ minWidth: calculatedWidth }}
              noGutters
            >
              {categoryFields.map((field, fieldIndex) => (
                <FieldColumn
                  key={getColumnKey(field)}
                  field={field}
                  isFooter
                  isOnBoard={isOnBoard}
                  fieldIndex={fieldIndex}
                  fields={categoryFields}
                  itemID={id}
                  categoryID={categoryID}
                >
                  {field.footer?.totals ? (
                    <ItemTotals itemID={id} categoryID={categoryID} field={field} isPms={isPms} />
                  ) : field.hasFillButton ? (
                    <AutoFillButton
                      categoryID={categoryID}
                      field={field}
                      isPms={isPms}
                      isOnBoard={isOnBoard}
                    />
                  ) : null}
                  {field.key === 'proposed_quantity' && field.supplierRequisitionID ? (
                    <SupplierIncludedInPoButton
                      isFooter={true}
                      categoryID={categoryID}
                      itemID={id}
                      supplierRequisitionID={field.supplierRequisitionID}
                    />
                  ) : null}
                </FieldColumn>
              ))}
            </Row>
          ) : null}
        </>
      ) : null}
      <Row
        className={`purchasing-requisition__items-single min-h-24 ${
          isOutOfTheList ? 'purchasing-requisition__items-out-of-list' : ''
        }`}
        style={{ minWidth: calculatedWidth }}
        noGutters
      >
        {categoryFields.map((field, fieldIndex) => (
          <FieldColumn
            key={getColumnKey(field)}
            field={field}
            isOnBoard={isOnBoard}
            fieldIndex={fieldIndex}
            rowIndex={index}
            fields={categoryFields}
            isInDeliveryReport={isInDeliveryReport}
            data-id={`${categoryID}-${index}-${fieldIndex}`}
            itemID={id}
            categoryID={categoryID}
          >
            {fieldIndex === 0 ? <LastDeliveredTooltip itemID={id} /> : null}
            <Field
              categoryID={categoryID}
              itemID={id}
              field={field}
              initialValueFromField={getInitialValueFrom(field.key)}
            />
          </FieldColumn>
        ))}
      </Row>
    </>
  );
};

const ItemWrapper = ({ id, isOutOfTheList, ...rest }) => {
  const isItemOutOfList = useSelector(state => isItemOutOfTheList(state, id));

  return (isOutOfTheList && !isItemOutOfList) || (!isOutOfTheList && isItemOutOfList) ? null : (
    <Item id={id} isOutOfTheList={isOutOfTheList} {...rest} />
  );
};

export default ItemWrapper;
