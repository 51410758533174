import { FC } from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  selectItemSupplierComparisonDetails,
  selectItemsTableComparisonViewEnabled,
  selectSupplierHasEquivalentCurrency
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import { SupplierItemComparisionDetails } from '@/common/types/purchasing';
import { SUPPLIER_FIELDS } from '@/common/components/purchasing/requisition/categories/items/config';
import { numberToStr, strToNumber } from '@/ts-common/utils/numbers';
import Information from '@/ts-common/components/general/Information';
import warning from '@/ts-common/assets/svg/common/warning-icon.svg';

type FieldValue = number | string | null;

type SupplierFieldComparisonDetailsProps = {
  itemID: string;
  fieldKey: string;
  supplierRequisitionID: string;
  value?: FieldValue;
};

type ComparisonField = {
  comparisionDetails: SupplierItemComparisionDetails;
  value?: FieldValue;
  supplierHasEquivalentCurrency?: boolean;
};

const getComparedQuantitesColor = (comparisionDetails: SupplierItemComparisionDetails) => {
  const {
    has_less_requested_than_quoted_quantities,
    has_equal_requested_and_quoted_quantities,
    has_more_requested_than_quoted_quantities
  } = comparisionDetails;

  return has_less_requested_than_quoted_quantities
    ? 'orange'
    : has_more_requested_than_quoted_quantities
    ? 'royal-blue'
    : has_equal_requested_and_quoted_quantities
    ? 'violet'
    : 'primary';
};

const ComparedQuantities = ({ comparisionDetails }: ComparisonField) => {
  const { requested_quantity, quoted_quantity } = comparisionDetails;

  return (
    <div className={`cps-2 text-${getComparedQuantitesColor(comparisionDetails)}`}>
      Asked: {numberToStr(requested_quantity || 0)} vs Q: {numberToStr(quoted_quantity || 0)} Units
    </div>
  );
};

const UnitPrice = ({
  comparisionDetails,
  value,
  supplierHasEquivalentCurrency
}: ComparisonField) => {
  const { has_lowest_price_per_unit, quoted_price_per_unit } = comparisionDetails;

  return value && strToNumber(quoted_price_per_unit) ? (
    <div
      className={`${supplierHasEquivalentCurrency ? 'cpe-6' : 'cpe-2'} w-100p text-end text-${
        has_lowest_price_per_unit ? 'green' : 'violet'
      }`}
    >
      {numberToStr(quoted_price_per_unit, 2, 2)}/Unit
    </div>
  ) : null;
};

const ProposedQuantity = ({ comparisionDetails, value }: ComparisonField) => {
  const { proposed_quantity } = comparisionDetails;
  const qty = strToNumber(proposed_quantity);

  return value || value === 0 ? (
    <div className={`cpe-2 w-100p text-end text-${getComparedQuantitesColor(comparisionDetails)}`}>
      {numberToStr(proposed_quantity)} Unit
      {`${qty && qty > 1 ? 's' : ''}`}
    </div>
  ) : null;
};

const SupplierFieldComparisonDetails: FC<SupplierFieldComparisonDetailsProps> = ({
  itemID,
  fieldKey,
  supplierRequisitionID,
  value
}) => {
  const comparisonViewIsEnabled = useAppSelector(selectItemsTableComparisonViewEnabled);
  const comparisionDetails = useAppSelector(state =>
    selectItemSupplierComparisonDetails(state, itemID, supplierRequisitionID)
  );

  const supplierHasEquivalentCurrency = useAppSelector(state =>
    selectSupplierHasEquivalentCurrency(state, supplierRequisitionID)
  );

  if (!comparisonViewIsEnabled) return null;

  return (
    <div className="cpt-2 fs-9 lh-12 fw-bold d-flex align-items-center text-wrap">
      {comparisionDetails?.is_successful ? (
        <>
          {fieldKey === SUPPLIER_FIELDS.available_packaging_id.key ||
          fieldKey === SUPPLIER_FIELDS.quality_id.key ? (
            <ComparedQuantities comparisionDetails={comparisionDetails} />
          ) : (!supplierHasEquivalentCurrency && fieldKey === SUPPLIER_FIELDS.unit_price.key) ||
            (supplierHasEquivalentCurrency &&
              fieldKey === SUPPLIER_FIELDS.unit_price_base_currency_equivalent.key) ? (
            <UnitPrice
              comparisionDetails={comparisionDetails}
              value={value}
              supplierHasEquivalentCurrency={supplierHasEquivalentCurrency}
            />
          ) : fieldKey === SUPPLIER_FIELDS.proposed_quantity.key ? (
            <ProposedQuantity comparisionDetails={comparisionDetails} value={value} />
          ) : null}
        </>
      ) : comparisionDetails?.failure_reason &&
        (fieldKey === SUPPLIER_FIELDS.available_packaging_id.key ||
          fieldKey === SUPPLIER_FIELDS.quality_id.key) ? (
        <div className="d-flex align-items-center text-red">
          <Information
            message={comparisionDetails?.failure_reason}
            svgIcon={warning}
            svgStyle={{ width: 10, height: 10 }}
            className="cursor-pointer"
          />
          <span className="cps-4 cpt-2">Comparison Failed</span>
        </div>
      ) : null}
    </div>
  );
};

export default SupplierFieldComparisonDetails;
