import { useSelector } from 'react-redux';
import {
  selectItemBasicField,
  selectRequisitionStatusLabel,
  selectOnBoardStatus
} from 'common/components/purchasing/requisition/store/selectors';
import _get from 'lodash/get';

import chapter from 'common/assets/svg/common/chapter.svg';
import subChapter from 'common/assets/svg/common/ribbon-line.svg';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ColorViewer from 'common/components/general/ColorViewer';

import { Col, Row } from 'reactstrap';

import ItemActions from '@/common/components/purchasing/requisition/categories/items/fields/ItemActions.tsx';
import ItemActionsInfo from '@/common/components/purchasing/requisition/categories/items/fields/ItemActionsInfo.tsx';
import PropTypes from 'prop-types';

const CodeDescription = ({ itemID, value, hideColor = false, categoryID }) => {
  const part_no = useSelector(state => selectItemBasicField(state, itemID, 'part_no'));
  const description = useSelector(state => selectItemBasicField(state, itemID, 'description'));
  const systems = useSelector(state => selectItemBasicField(state, itemID, 'vessel_systems'))?.[0];
  const drawing_no = useSelector(state => selectItemBasicField(state, itemID, 'drawing_no'));
  const colorCode = useSelector(state => selectItemBasicField(state, itemID, 'color_hex_code'));
  const colorName = useSelector(state => selectItemBasicField(state, itemID, 'color_name'));

  if (!systems) {
    return (
      <MainDescription
        value={value || part_no}
        description={description}
        color={!hideColor ? { code: colorCode, name: colorName } : {}}
        itemID={itemID}
        categoryID={categoryID}
      />
    );
  } else {
    const group = systems?.parent_id
      ? _get(systems, 'parent.description')
      : _get(systems, 'description');

    const subGroup = systems?.parent_id ? _get(systems, 'description') : null;

    return (
      <div className="d-flex flex-column justify-content-center cpy-4">
        <MainDescription
          value={value || part_no}
          description={description}
          drawingNumber={drawing_no}
          color={!hideColor ? { code: colorCode, name: colorName } : {}}
          itemID={itemID}
          categoryID={categoryID}
        />
        <Row noGutters xs={11} className="fs-10 text-violet fw-medium d-flex align-items-center">
          <Col className="d-flex align-items-center" xs="auto">
            <SvgRender className="cme-6" src={chapter} style={{ width: 8, height: 8 }} />
            <TextWithTooltip>
              {group}{' '}
              {subGroup ? (
                <>
                  &nbsp;|&nbsp;
                  <SvgRender className="cme-6" src={subChapter} style={{ width: 9, height: 9 }} />
                  {subGroup}
                </>
              ) : null}
            </TextWithTooltip>
          </Col>
        </Row>
      </div>
    );
  }
};

CodeDescription.propTypes = {
  itemID: PropTypes.number,
  value: PropTypes.string,
  hideColor: PropTypes.bool,
  categoryID: PropTypes.number
};

const MainDescription = ({ value, description, drawingNumber, color, itemID, categoryID }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const status = useSelector(selectRequisitionStatusLabel);
  const onBoardStatus = useSelector(selectOnBoardStatus);

  const showActions = !isOnBoard
    ? onBoardStatus !== 'for_correction' && (status === 'rqn' || status === 'rfq')
    : false;

  return (
    <div className="d-flex align-items-center h-100p lh-1 w-100p">
      {drawingNumber ? <div className="text-violet">&nbsp;- {drawingNumber}</div> : null}
      <div className="flex-1 d-flex align-items-center overflow-hidden">
        <strong className="text-nowrap pe-1">
          {drawingNumber && value ? <>&nbsp;-&nbsp;</> : ''}
          {value}
        </strong>
        <div className=" overflow-hidden">
          <TextWithTooltip className="d-inline-flex">{description}</TextWithTooltip>
        </div>
        {color?.name ? (
          <div className="text-violet fs-10 text-nowrap">&nbsp;- {color.name}</div>
        ) : null}
      </div>

      <div className="d-flex flex-nowrap align-items-center ms-auto pe-2 position-relative">
        {showActions ? (
          <>
            <div className="mx-1">
              <ItemActions itemID={itemID} categoryID={categoryID} />
            </div>
            <ItemActionsInfo itemID={itemID} />
          </>
        ) : null}
        <div className="min-w-16 d-flex justify-content-end cms-4">
          {color?.code ? <ColorViewer color={`#${color.code}`} hideLabel /> : null}
        </div>
      </div>
    </div>
  );
};

export default CodeDescription;

MainDescription.propTypes = {
  itemID: PropTypes.number,
  drawingNumber: PropTypes.number,
  value: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  categoryID: PropTypes.number
};
