import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';

import { get } from 'utils/api';

export const getFormPlannings = params => dispatch => {
  const { table, ...rest } = params;
  dispatch({ type: TYPES.GET_FORM_PLANNINGS.START, payload: { params } });
  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

  return get('/form-plannings', rest)
    .then(response => {
      dispatch({ type: TYPES.GET_FORM_PLANNINGS.SUCCESS, payload: response.data });
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FORM_PLANNINGS.ERROR, payload: error });
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
    });
};

export const setFormPlanningFrom = params => dispatch => {
  dispatch({ type: TYPES.SET_FORM_PLANNING_FROM, payload: params });
};

export const setFormPlanningTo = params => dispatch => {
  dispatch({ type: TYPES.SET_FORM_PLANNING_TO, payload: params });
};

export const setFormPlanningInAlarm = params => dispatch => {
  dispatch({ type: TYPES.SET_FORM_PLANNING_IN_ALARM, payload: params });
};

export const setFormPlanningStatuses = params => dispatch => {
  dispatch({ type: TYPES.SET_FORM_PLANNING_STATUSES, payload: params });
};

export const updateFormPlanningSubmission = data => dispatch => {
  dispatch({ type: TYPES.UPDATE_FORM_PLANNING_SUBMISSION, payload: data });
};
