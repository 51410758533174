import Information from 'common/components/general/Information';
import EventAuditReasonsSelector from 'common/components/selectors/EventAuditReasonsSelector';
import FindingTagsSelector from '@/common/components/selectors/FindingTagsSelector';
import EventFindingsSelector from 'common/components/selectors/EventFindingsSelector';
import EventSelector from 'common/components/selectors/EventSelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import EventRelatedAuditSelector from 'common/components/selectors/EventAuditsSelector';
import FindingCategoriesSelector from '@/common/components/selectors/FindingCategoriesSelector';
import FormsSelector from 'common/components/selectors/FormsSelector';
import PrioritySelector from 'common/components/selectors/PrioritySelector';
import JobStatusSelector from 'common/components/selectors/JobStatusSelector';
import EventStatusSelector from 'common/components/selectors/EventStatusSelector';
import FormStatusSelector from 'common/components/selectors/FormStatusSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';

import paths from 'routing/routes/_paths';
import Evaluations from 'common/components/findings/report/components/Evaluations';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import CommentsPopover from 'common/components/popovers/CommentsPopover';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import EvaluationPopover from 'common/components/popovers/EvaluationPopover';
import { getJobLocationPath } from 'common/utils/urls';
import PageLink from 'common/components/general/page-link';
import Priority from 'common/components/general/Priority';
import Departments from 'common/components/general/Departments';
import EntityLabel from 'common/components/labels/EntityLabel';
import { dateToLocal } from 'common/utils/dates';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';
import Status from 'common/components/general/Status';
import LinkedFindingsDescription from 'common/components/findings/report/components/LinkedFindingsDescription';
import LinkedOriginIcon from 'common/components/findings/report/components/LinkedOriginIcon';
import LinkedStatus from 'common/components/findings/report/components/LinkedStatus';
import LinkedDate from 'common/components/findings/report/components/LinkedDate';

import star from 'common/assets/svg/common/star.svg';

import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from '@/api/files/api';
import PurchasingRequisitionStatusesSelector from 'common/components/selectors/PurchasingRequisitionStatusSelector';
import PurchasingRequisitionSelector from 'common/components/selectors/PurchasingRequisitionSelector';
import { displayDate } from '@/ts-common/utils/dates';

export const getColumns = (isEvent = false) => [
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance_id',
    sortingKey: 'importance',
    type: 'collection',
    sort: true,
    component: PrioritySelector,
    componentRest: { isMulti: true },
    sticky: true,
    left: 0,
    headerClassName: 'z-index-3',
    bodyClassName: 'd-flex justify-content-start cpt-6  z-index-5',
    minWidth: 45,
    maxWidth: 45
  },
  {
    header: 'ID',
    key: 'id',
    data_key: 'orca_id',
    sort: true,
    sticky: true,
    truncate: true,
    left: 45,
    headerClassName: 'z-index-3',
    bodyClassName: 'd-flex justify-content-start cpt-4  z-index-5',
    minWidth: 80,
    maxWidth: 80
  },
  {
    header: 'Finding',
    key: 'finding',
    sort: true,
    sticky: true,
    truncate: false,
    left: 125,
    headerClassName: 'z-index-3',
    bodyClassName: 'd-flex justify-content-start cpt-4  border-end z-index-5',
    minWidth: 322,
    maxWidth: 322
  },
  {
    header: 'Finding',
    key: 'finding_id',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'collection',
    component: EventFindingsSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Category',
    key: 'category',
    data_key: 'category.name',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'string',
    sort: true,
    canFilter: false,
    truncate: true,
    minWidth: 145,
    maxWidth: 145
  },
  {
    header: 'Category',
    key: 'finding_category_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: FindingCategoriesSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Department',
    key: 'department_id',
    sortingKey: 'department',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'collection',
    component: DepartmentSelector,
    componentRest: { isMulti: true },
    sort: true,
    canFilter: true,
    minWidth: 132,
    maxWidth: 132
  },
  {
    header: 'Vessel',
    key: 'vessel',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    minWidth: 139,
    maxWidth: 139,
    hideOnBoard: true
  },
  {
    header: 'Status',
    key: 'finding_status',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    minWidth: 125,
    maxWidth: 125
  },
  {
    header: '',
    key: 'comments',
    className: 'ps-0 d-flex justify-content-start cpt-4 ',
    sort: false,
    minWidth: 36,
    maxWidth: 36
  },
  {
    header: '',
    key: 'attachments',
    sort: false,
    className: 'me-1 d-flex justify-content-start cpt-4 ',
    minWidth: 36,
    maxWidth: 36
  },
  {
    header: 'Linked With',
    key: 'linked_with',
    columns: [
      {
        header: '',
        key: 'origin_icon',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        sort: false,
        minWidth: 32,
        maxWidth: 32,
        className: 'pe-0'
      },
      {
        header: 'Description',
        key: 'description',
        bodyClassName: 'd-flex justify-content-start cpt-4',
        sort: false,
        minWidth: 265,
        maxWidth: 265
      },
      {
        header: 'Status',
        key: 'status',
        sort: false,
        minWidth: 138,
        maxWidth: 138
      },
      {
        header: (
          <div className="d-flex align-items-center flex-nowrap">
            <div className="me-1">Date</div>
            {isEvent ? null : (
              <Information
                message={
                  <div>
                    The following Dates represent:
                    <ul className="ps-2 pb-0 mb-0">
                      <li>The Job Due Date.</li>
                      <li>The Event Starting Date</li>
                      <li>The Case Submitted Date</li>
                      <li>The Form Date</li>
                    </ul>
                  </div>
                }
                tooltipClassname="text-start min-width-fit max-width-412"
                target={`linked-with-date`}
                svgStyle={{ height: 12, width: 12 }}
              />
            )}
          </div>
        ),
        bodyClassName: 'd-flex justify-content-start cpt-4',
        key: 'linked_with_date',
        sort: false,
        minWidth: 120,
        maxWidth: 120
      },
      ...(isEvent
        ? []
        : [
            {
              header: 'Audit/Inspection',
              key: 'audit',
              bodyClassName: 'd-flex justify-content-start cpt-4 ',
              data_key: 'origin_audit.audit.name',
              type: 'string',
              sort: true,
              canFilter: false,
              truncate: true,
              minWidth: 223,
              maxWidth: 223
            },
            {
              header: 'Reason',
              sortingKey: 'reason',
              bodyClassName: 'd-flex justify-content-start cpt-4 ',
              key: 'event_reason_id',
              data_key: 'origin_audit.reason.name',
              sort: true,
              truncate: true,
              canFilter: false,
              minWidth: 146,
              maxWidth: 146
            },
            {
              header: 'Auditing / Inspecting Company',
              key: 'company_name',
              bodyClassName: 'd-flex justify-content-start cpt-4 ',
              sort: true,
              truncate: true,
              type: 'string',
              canFilter: false,
              minWidth: 226,
              maxWidth: 226
            },
            {
              header: 'Auditor / Inspector',
              key: 'auditor_name',
              bodyClassName: 'd-flex justify-content-start cpt-4 ',
              sort: true,
              truncate: true,
              type: 'string',
              canFilter: false,
              minWidth: 192,
              maxWidth: 192
            }
          ])
    ]
  },
  {
    header: 'Subcategory',
    key: 'subcategory',
    data_key: 'subcategory.name',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    canFilter: false,
    truncate: true,
    minWidth: 220,
    maxWidth: 220
  },
  {
    header: 'Tags',
    key: 'tag_id',
    sortingKey: 'tags',
    bodyClassName: 'd-flex justify-content-start cpt-4',
    type: 'collection',
    component: FindingTagsSelector,
    componentRest: { isMulti: true },
    sort: false,
    minWidth: 161,
    maxWidth: 161
  },
  {
    header: 'Date',
    key: 'created_at',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    type: 'date',
    canFilter: false,
    minWidth: 105,
    maxWidth: 105
  },
  {
    header: (
      <div className="d-flex align-items-center flex-nowrap">
        <div>Days In</div>
        <Information
          message={
            <div>
              Here we count:
              <ul className="ps-2 pb-0 mb-0">
                <li>Days since Reported date for unresolved findings.</li>
                <li>Days elapsed between Reported and resolution dates.</li>
              </ul>
            </div>
          }
          tooltipClassname="text-start min-width-fit max-width-412"
          className="ms-1"
          target={`total-hours-header-info`}
          svgStyle={{ height: 12, width: 12 }}
        />
      </div>
    ),
    key: 'days_unresolved_column',
    sortingKey: 'days_unresolved',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    minWidth: 120,
    maxWidth: 120
  },
  {
    header: 'Due Date',
    key: 'finding_due_date',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'date',
    sort: true,
    minWidth: 100,
    maxWidth: 100
  },
  {
    header: 'Resolution Date',
    key: 'resolved_at',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'date',
    sort: true,
    minWidth: 140,
    maxWidth: 140
  },
  {
    header: '',
    key: 'evaluations',
    className: 'd-flex justify-content-start cpt-4',
    minWidth: 60,
    maxWidth: 60
  },
  {
    header: 'Reported in Events',
    key: 'event_id',
    type: 'collection',
    component: EventSelector,
    componentRest: { isMulti: true, listParams: { modules: ['audits'] } },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Created in form',
    key: 'form_uid',
    type: 'collection',
    component: FormsSelector,
    componentRest: { isMulti: true },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Reported by',
    key: 'auditor_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Linked with Event',
    key: 'with_origin_audit',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Event Starting Date',
    key: 'event_starting_date',
    type: 'date',
    hidden: true
  },
  {
    header: 'Form Date',
    key: 'form_date',
    type: 'date',
    hidden: true
  },
  {
    header: 'Jobs Due Date',
    key: 'job_due_date',
    type: 'date',
    hidden: true
  },
  {
    header: 'Event status',
    key: 'event_status_id',
    type: 'collection',
    component: EventStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Job status',
    key: 'job_status_id',
    type: 'collection',
    component: JobStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Form status',
    key: 'form_status_id',
    type: 'collection',
    component: FormStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Linked with Form',
    key: 'with_form_submissions',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Reason',
    key: 'event_reason_id',
    type: 'collection',
    component: EventAuditReasonsSelector,
    componentRest: { isMulti: true },
    sort: false,
    hidden: true,
    truncate: true,
    minWidth: 146,
    maxWidth: 146
  },
  {
    header: 'Days Unresolved',
    key: 'days_unresolved',
    canFilter: true,
    type: 'number',
    hidden: true
  },
  {
    header: 'Audit/Inspector - Audit module',
    key: 'audit_inspector_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Audit/Inspector Company - Audit module',
    key: 'audit_company_inspector_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company' } },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Audit/Inspection',
    key: 'event_audit_id',
    type: 'collection',
    component: EventRelatedAuditSelector,
    componentRest: { isMulti: true },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Linked with Purchase Case',
    key: 'with_purchasing_cases',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Purchase Case Status',
    key: 'purchasing_status_id',
    type: 'collection',
    canFilter: true,
    hidden: true,
    component: PurchasingRequisitionStatusesSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Purchase Case Submitted Date',
    key: 'purchasing_submitted_at',
    type: 'date',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Purchase Case',
    key: 'purchasing_requisition_id',
    type: 'collection',
    canFilter: true,
    hidden: true,
    component: PurchasingRequisitionSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Linked with jobs',
    key: 'with_jobs',
    type: 'boolean',
    hidden: true,
    canFilter: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const constructColumns = (cols, isOnBoard) => {
  return isOnBoard ? cols?.filter(c => !c?.hideOnBoard) : cols;
};

export const LinkedEntityContainer = ({ children, className = '' }) => (
  <div
    className={`height-24 d-flex flex-column justify-content-center w-100p overflow-hidden ${className}`}
  >
    {children}
  </div>
);

const selectAuditorName = data => {
  const name =
    data.origin_audit?.auditor?.type === 'person'
      ? data.origin_audit?.auditor?.full_name
      : data.origin_audit?.auditor?.type === 'company'
      ? data.origin_audit?.company_auditor_party?.full_name
      : null;

  return <TextWithTooltip className="h-auto">{name || ''}</TextWithTooltip>;
};

const selectCompanyAuditorName = data =>
  data.origin_audit?.auditor?.type === 'company' ? (
    <TextWithTooltip className="h-auto">
      {data.origin_audit.auditor?.full_name || ''}
    </TextWithTooltip>
  ) : null;

export const rows = {
  importance_id: data => <Priority type={null} value={data.importance_id} />,
  department_id: data => <Departments values={[data.department]} />,
  finding_status: data => (
    <EntityLabel
      color={data?.resolved_at ? 'color_5' : 'color_4'}
      className="fw-bold"
      innerClassName="mx-auto fs-10"
      type="status"
    >
      {data?.resolved_at ? 'RESOLVED' : 'UNRESOLVED'}
    </EntityLabel>
  ),
  comments: data => <CommentsPopover popoverTitle="Remarks" comments={data.notes} />,
  vessel: data => (
    <div className="d-flex align-items-center justify-content-between">
      {data?.vessel?.name ? data.vessel.name : ''}
    </div>
  ),
  attachments: data => (
    <DownloadAttachment
      data={data}
      downloadFiles={() =>
        downloadAllFilesOfEntity({
          id: data.id,
          type: 'finding'
        })
      }
      hasAttachments={data.attachments?.length > 0}
    />
  ),
  origin_icon: data => <LinkedOriginIcon data={data} />,
  status: data => <LinkedStatus data={data} />,
  linked_with_date: data => <LinkedDate data={data} />,
  finding: data => (
    <PageLink openOnNewTab={false} to={`${paths.findings}/${data?.id}`}>
      {data?.name}
    </PageLink>
  ),
  description: data => <LinkedFindingsDescription data={data} />,
  tag_id: data => (
    <div className="d-flex align-items-center justify-content-between">
      <NamesInTooltip
        names={
          data?.tags?.length
            ? data?.tags?.map((tag, index) => (
                <div className="d-inline-flex align-items-center" key={index}>
                  {tag.name}
                </div>
              ))
            : []
        }
      />
    </div>
  ),
  job: data =>
    data.jobs.map(job => {
      const path = getJobLocationPath(job);

      return (
        <div className="height-24 d-flex align-items-center">
          <PageLink to={path} target="_blank">
            <TextWithTooltip className="d-flex align-items-center w-100p h-auto">
              {job.title}
            </TextWithTooltip>
          </PageLink>
        </div>
      );
    }),
  finding_due_date: data => displayDate(data?.due_date, { time: true }),
  job_status: data => data.jobs.map(job => <Status className="lh-14" value={job.status} />),
  linked_form_submission: data =>
    data.form_submissions?.length
      ? data.form_submissions.map(f => (
          <div className="height-24 d-flex align-items-center">
            <PageLink to={`${paths.forms}/${f?.id}`} target="_blank">
              <TextWithTooltip className="d-flex align-items-center h-auto">
                {f?.form?.name}
              </TextWithTooltip>
            </PageLink>
          </div>
        ))
      : null,
  form_submission_status: data =>
    data?.form_submissions?.length
      ? data?.form_submissions?.map(s => (s?.status ? <Status value={s.status} /> : null))
      : null,
  days_unresolved_column: data => data.days_unresolved,
  auditor_name: data => selectAuditorName(data),
  company_name: data => selectCompanyAuditorName(data),
  evaluations: data => {
    if (!data.audit?.evaluation) return;

    return (
      <EvaluationPopover
        popoverTitle={
          <div className="d-flex align-items-center fs-12 fw-medium text-violet">
            <SvgRender src={star} style={{ height: 14, width: 14 }} />
            <span className="ms-1">
              Audit / Inspection Evaluation{' '}
              <span className="text-turquoise">
                | Submitted on {dateToLocal(data?.created_at).format('DD/MM/YYYY, HH:mm')}
              </span>
            </span>
          </div>
        }
        popoverBody={<Evaluations values={data?.audit?.evaluation.evaluation_values} />}
      />
    );
  }
};
