import { Row, Col } from 'reactstrap';

import { useFormState } from 'utils/hooks';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import DateInput from 'common/components/form/inputs/date';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import NumberInput from 'common/components/form/inputs/NumberInput';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { Option } from 'common/components/purchasing/requisition/categories/items/components/ItemSelector';
import SvgRender from 'common/components/general/SvgRender';

import binIcon from 'common/assets/svg/common/bin.svg';
import { useEffect } from 'react';

const Item = ({ formState, accountId, onRemove, startedAt }) => {
  const { fields, changeField, selectField } = useFormState(formState);

  useEffect(() => {
    selectField('total')(
      strToNumber(fields.price_per_unit.value) * strToNumber(fields.quantity.value)
    );
  }, [fields.price_per_unit.value, fields.quantity.value, selectField]);

  return (
    <Row className="fs-12 text-primary mb-1 align-items-center" noGutters>
      <Col xs={3} className="cme-12">
        <AsyncSelector
          onChange={selectField('store')}
          type={'stores'}
          label={' '}
          placeholder="Select item"
          className="cmb-2"
          getOptionValue={option => option.id}
          getOptionLabel={option => `${option.code}. ${option.description}`}
          loadOptions={search =>
            getAsyncOptions(search, 'stores', {
              mga_account_id: accountId,
              active: true
            })
          }
          defaultOptions={() =>
            getInitialAsyncValues('stores', null, null, {
              mga_account_id: accountId,
              active: true
            })
          }
          components={{ Option }}
          noOptionsMessage={({ inputValue }) =>
            !inputValue.length ? `Search for items` : `No items found`
          }
          {...fields.store}
          error={!fields.store.value ? 'Field is required' : ''}
        />
      </Col>
      <Col xs={2} className="me-1">
        <DateInput
          label=""
          onChange={selectField('date')}
          className="mb-0"
          {...fields.date}
          error={
            !fields.date.value
              ? 'Field is required'
              : fields.date.value && startedAt && !fields.date.value.isBefore(startedAt)
              ? 'Invalid date'
              : ''
          }
        />
      </Col>
      <Col xs={2} className="cme-12">
        <NumberInput
          className="mb-0"
          placeholder="Add amount"
          onChange={changeField('price_per_unit')}
          fixedDecimalScale={2}
          {...fields.price_per_unit}
          error={!fields.price_per_unit.value ? 'Field is required' : ''}
        />
      </Col>
      <Col xs={2} className="cme-12">
        <NumberInput
          className="mb-0"
          placeholder="Add rob"
          onChange={changeField('quantity')}
          {...fields.quantity}
          error={!fields.quantity.value ? 'Field is required' : ''}
        />
      </Col>
      <Col className="cme-12">{numberToStr(fields.total.value, 2, 2)}</Col>
      <Col xs="auto" className="cme-12">
        <SvgRender
          src={binIcon}
          style={{ width: 16, height: 16 }}
          className="text-coral cursor-pointer"
          onClick={onRemove}
        />
      </Col>
    </Row>
  );
};

export default Item;
