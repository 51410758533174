import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleApiErrorNotification } from '@/common/utils/notifications';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => handleApiErrorNotification(error)
  }),
  mutationCache: new MutationCache({
    onError: error => handleApiErrorNotification(error)
  })
});

export default queryClient;
