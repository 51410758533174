import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import spreadsheets from 'common/assets/svg/files/spreadsheets.svg';

const ExportExcel = ({
  onClick,
  className = '',
  textClassname = '',
  svgStyle = { width: 14, height: 18 },
  disabled,
  showLabel,
  customLabel,
  tooltipMessage = ''
}) => {
  const { tooltipID, avoidRender } = useTooltipID(`download-excel-file`);

  return (
    <>
      <div
        id={tooltipID}
        onClick={!disabled ? onClick : null}
        className={`base-transition cursor-pointer d-inline-flex align-items-center${
          disabled ? ` opacity-4 ${tooltipMessage ? ' ' : 'pointer-events-none'}` : ''
        } ${className}`}
      >
        <div className="d-flex align-items-center justify-content-center bg-white rounded h-24 cpx-6">
          <SvgRender src={spreadsheets} style={svgStyle || { width: 14, height: 18 }} />
        </div>
        {showLabel ? (
          <span className={`cms-12 lh-12 text-nowrap fw-normal fs-12 text-violet ${textClassname}`}>
            Export Excel
          </span>
        ) : customLabel ? (
          customLabel
        ) : null}
      </div>

      {tooltipMessage && !avoidRender ? (
        <Tooltip target={tooltipID} innerClassName="text-start max-width-280">
          {tooltipMessage}
        </Tooltip>
      ) : null}
    </>
  );
};

export default ExportExcel;
