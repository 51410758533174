import Items from 'common/components/purchasing/requisition/categories/items';

import { selectCategory } from 'common/components/purchasing/requisition/store/selectors';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

const PmsItems = ({ id, render }) => {
  const category = useSelector(state => selectCategory(state, id));

  if (category?.item_types === 'store') return null;

  return render ? render(id) : <Items categoryID={id} isPms={true} />;
};

PmsItems.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  render: PropTypes.func // custom render method
};
export default PmsItems;
