import Items from 'common/components/purchasing/requisition/categories/items';

import { selectCategory } from 'common/components/purchasing/requisition/store/selectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PurchasingItems = ({ id, render }) => {
  const category = useSelector(state => selectCategory(state, id));

  if (category?.item_types === 'spare_part') return null;

  return render ? render(id) : <Items categoryID={id} isPms={false} />;
};

PurchasingItems.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  render: PropTypes.func // custom render method
};

export default PurchasingItems;
