import CoordinateInput from 'captain-reports/templates/components/custom-fields/CoordinateInput';
import StoppageAssociatedElement from 'captain-reports/templates/components/custom-fields/StoppageAssociatedElement';
import StoppageDuration from 'captain-reports/templates/components/custom-fields/StoppageDuration';
import StoppageEndTimestampField from 'captain-reports/templates/components/custom-fields/StoppageEndTimestampField';
import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';
import LocalTimestampField from 'captain-reports/templates/components/custom-fields/LocalTimestampField';
import Select from 'captain-reports/templates/inputs/Select';
import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';
import UtcField from 'captain-reports/templates/components/custom-fields/UtcField';
import RadioInput from 'captain-reports/templates/inputs/RadioInput';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import TextAreaField from 'captain-reports/templates/inputs/TextAreaField';
import CheckboxInput from 'captain-reports/templates/inputs/CheckboxInput';
import CheckboxInputWithTooltip from '../inputs/CheckboxInputWithTooltip';

import InstructedEta from 'captain-reports/templates/components/custom-fields/InstructedEta';
import SpeedOG from 'captain-reports/templates/components/custom-fields/SpeedOG';
import EcaFields from 'captain-reports/templates/components/custom-fields/EcaFields';
import CpValuesHeader from 'captain-reports/templates/components/custom-fields/CpValuesHeader';
import DeliveredAtField from 'captain-reports/templates/components/custom-fields/DeliveredAtField';
import DeliveredToCharterField from 'captain-reports/templates/components/custom-fields/DeliveredToCharterField';
import SteamingTimeField from 'captain-reports/templates/components/custom-fields/SteamingTimeField';
import AllowanceUnit from 'captain-reports/templates/components/custom-fields/AllowanceUnit';
import SpeedField from 'captain-reports/templates/components/custom-fields/SpeedField';
import FoConsField from 'captain-reports/templates/components/custom-fields/FoConsField';
import GoConsField from 'captain-reports/templates/components/custom-fields/GoConsField';
import AllowanceField from 'captain-reports/templates/components/custom-fields/AllowanceField';
import PortOfArrivalField from 'captain-reports/templates/components/custom-fields/PortOfArrivalField';

import ballast from 'captain-reports/assets/svg/ballast.svg';
import laden from 'captain-reports/assets/svg/laden.svg';
import pollution from 'common/assets/svg/common/pollution.svg';

import { timeZoneOptions } from 'common/utils/fixed';
import { berthOptions } from 'captain-reports/templates/config/_helpers';
import {
  autocompleteTrim,
  autocompleteStoppageDuration
} from 'captain-reports/templates/config/_autocompleteHelpers';

import {
  validateTimeZone,
  validateTimeStamp,
  validateTimeStampIsNull,
  validateDeliveryCurrentPort,
  validateCharterParty
} from './validations';

const noon = () => [
  {
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        width: { xs: 3 }
      },
      {
        label: 'Date & Time',
        key: 'local_timestamp',
        validation: validateTimeStamp,
        render: LocalTimestampField,
        width: { xs: 'auto' },
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        type: 'string',
        width: { xs: '' },
        sendOnlyValue: true,
        validation: validateTimeZone,
        render: Select,
        previousReportInitializationKey: 'timezone',
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0 gmt-dropdown',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Loading Condition',
        key: 'loading_condition',
        render: RadioInput,
        width: { xs: 2 },
        previousReportInitializationKey: 'loading_condition',
        options: [
          { label: 'Ballast', value: 'ballast', icon: ballast },
          { label: 'Laden', value: 'laden', icon: laden }
        ]
      },
      {
        label: 'Displacement used',
        subLabel: '%',
        key: 'displacement_used',
        previousReportInitializationKey: 'displacement_used',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Aft',
        subLabel: 'm',
        previousReportInitializationKey: 'draft_aft',
        key: 'draft_aft',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Mid',
        subLabel: 'm',
        key: 'draft_mid',
        previousReportInitializationKey: 'draft_mid',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Fwd',
        subLabel: 'm',
        key: 'draft_fwd',
        previousReportInitializationKey: 'draft_fwd',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Trim',
        subLabel: 'm',
        key: 'trim',
        width: { xs: '' },
        autocompleteValues: [{ key: 'draft_aft' }, { key: 'draft_fwd' }],
        autocomplete: autocompleteTrim,
        className: 'pointer-events-none noborder-input',
        disabled: true,
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    headerComponent: <CpValuesHeader />,
    groupClassName: 'align-items-start',
    fields: [
      {
        label: 'Distance run',
        key: 'distance_run',
        width: { xs: '' },
        subLabel: 'nm',
        render: NumberField,
        templateValidation: { required: true },
        type: 'number',
        className: 'mt-1'
      },
      {
        label: 'Steaming Time',
        key: 'steaming_time',
        width: { xs: '' },
        templateValidation: { required: true },
        render: SteamingTimeField,
        className: 'mt-1'
      },
      {
        label: 'SPEED OG',
        subLabel: 'knots',
        key: 'speed_og',
        width: { xs: 5 },
        render: SpeedOG,
        type: 'number'
      },
      {
        label: 'Speed TW',
        subLabel: 'knots',
        key: 'speed_tw',
        width: { xs: '' },
        render: NumberField,
        type: 'number',
        className: 'mt-1'
      },

      {
        label: 'Engine RPM',
        key: 'engine_rpm',
        className: 'pointer-events-none noborder-input mt-1',
        disabled: true,
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Engine Miles',
        key: 'engine_miles',
        className: 'pointer-events-none noborder-input mt-1',
        disabled: true,
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Slip',
        width: { xs: 0 },
        className: 'm-0 p-0 d-none',
        key: 'apparent_slip',
        type: 'number',
        render: () => null,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Next Port',
        key: 'next_port_id',
        sendOnlyValue: true,
        previousReportInitializationKey: 'next_port',
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'next_port' }
      },
      {
        label: 'ETA',
        key: 'eta',
        previousReportInitializationKey: 'eta',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETB',
        key: 'etb',
        previousReportInitializationKey: 'etb',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETD',
        key: 'etd',
        previousReportInitializationKey: 'etd',
        render: DateTimeInput,
        width: { xs: 'auto' }
      }
    ]
  },
  {
    customComponent: EcaFields,
    fields: [
      {
        label: 'Miles to Go',
        subLabel: 'nm',
        key: 'miles_to_go',
        width: { xs: 12 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ECA',
        key: 'eca_intersection',
        width: { xs: 12 },
        inputProps: {
          keysToResetWhenFalse: ['eca_entered_at', 'eca_exited_at', 'eca_miles']
        },
        render: CheckboxInput
      },
      {
        label: 'Entry Date & Time',
        key: 'eca_entered_at',
        width: { xs: 12, md: 6, lg: 4 },
        render: DateTimeInput
      },
      {
        label: 'Exit Date & Time',
        key: 'eca_exited_at',
        render: DateTimeInput,
        width: { xs: 12, md: 6, lg: 4 }
      },
      {
        label: 'Miles in ECA',
        width: { xs: 12, md: 6, lg: 4 },
        key: 'eca_miles',
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const arrival = () => [
  {
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        width: { xs: 3 }
      },
      {
        label: 'EOSP DATE & TIME',
        key: 'local_timestamp',
        render: DateTimeInput,
        validation: validateTimeStamp,
        width: { xs: 'auto' },
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        width: { xs: '' },
        type: 'string',
        sendOnlyValue: true,
        previousReportInitializationKey: 'timezone',
        validation: validateTimeZone,
        render: Select,
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Loading Condition',
        key: 'loading_condition',
        previousReportInitializationKey: 'loading_condition',
        render: RadioInput,
        width: { xs: 2 },
        options: [
          { label: 'Ballast', value: 'ballast', icon: ballast },
          { label: 'Laden', value: 'laden', icon: laden }
        ]
      },
      {
        label: 'Displacement used',
        subLabel: '%',
        previousReportInitializationKey: 'displacement_used',
        key: 'displacement_used',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: (
          <span>
            Stability-G<span className="text-lowercase">o</span>m
          </span>
        ),
        subLabel: 'm',
        key: 'metacentric_height_gom',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Aft',
        subLabel: 'm',
        key: 'draft_aft',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Mid',
        key: 'draft_mid',
        width: { xs: '' },
        subLabel: 'm',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Fwd',
        subLabel: 'm',
        key: 'draft_fwd',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Trim',
        subLabel: 'm',
        key: 'trim',
        width: { xs: '' },
        autocompleteValues: [{ key: 'draft_aft' }, { key: 'draft_fwd' }],
        autocomplete: autocompleteTrim,
        className: 'pointer-events-none noborder-input',
        disabled: true,
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Port of Arrival',
        key: 'current_port_id',
        sendOnlyValue: true,
        width: { xs: 3 },
        render: PortOfArrivalField,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'current_port' }
      },
      {
        label: 'This was an STS voyage',
        key: 'cii_reduction_sts_voyage',
        className: 'mt-1',
        width: { xs: 4 },
        render: CheckboxInputWithTooltip,
        icon: pollution,
        activeColor: 'red'
      }
    ]
  },
  {
    fields: [
      {
        label: 'NOR',
        key: 'nor',
        width: { xs: 'auto' },
        render: DateTimeInput
      },
      {
        label: 'ETB',
        key: 'current_port_etb',
        width: { xs: 'auto' },
        render: DateTimeInput
      },
      {
        label: 'ETD',
        key: 'current_port_etd',
        width: { xs: 'auto' },
        render: DateTimeInput
      }
    ]
  },

  {
    headerComponent: <CpValuesHeader />,
    groupClassName: 'align-items-start',
    fields: [
      {
        label: 'SPEED OG',
        subLabel: 'knots',
        key: 'speed_og',
        width: { xs: 5 },
        render: SpeedOG,
        type: 'number'
      },
      {
        label: 'Speed TW',
        subLabel: 'knots',
        key: 'speed_tw',
        width: { xs: '' },
        render: NumberField,
        type: 'number',
        className: 'mt-1'
      },
      {
        label: 'Steaming Time',
        key: 'steaming_time',
        width: { xs: '' },
        render: SteamingTimeField,
        className: 'mt-1'
      },
      {
        label: 'Distance run',
        key: 'distance_run',
        width: { xs: '' },
        render: NumberField,
        type: 'number',
        className: 'mt-1'
      },
      {
        label: 'Engine RPM',
        key: 'engine_rpm',
        className: 'pointer-events-none noborder-input mt-1',
        disabled: true,
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Engine Miles',
        key: 'engine_miles',
        className: 'pointer-events-none noborder-input mt-1',
        disabled: true,
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Slip',
        width: { xs: 0 },
        className: 'm-0 p-0 d-none',
        key: 'apparent_slip',
        type: 'number',
        render: () => null,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Next Port',
        key: 'next_port_id',
        sendOnlyValue: true,
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'next_port' }
      },
      {
        label: 'ETA',
        key: 'eta',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETB',
        key: 'etb',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETD',
        key: 'etd',
        render: DateTimeInput,
        width: { xs: 'auto' }
      }
    ]
  },
  {
    customComponent: EcaFields,
    fields: [
      {
        label: 'Miles to Go',
        key: 'miles_to_go',
        subLabel: 'nm',
        width: { xs: 12 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ECA',
        key: 'eca_intersection',
        width: { xs: 12 },
        inputProps: {
          keysToResetWhenFalse: ['eca_entered_at', 'eca_exited_at', 'eca_miles']
        },
        render: CheckboxInput
      },
      {
        label: 'Entry Date & Time',
        key: 'eca_entered_at',
        width: { xs: 12, md: 6, lg: 4 },
        render: DateTimeInput
      },
      {
        label: 'Exit Date & Time',
        key: 'eca_exited_at',
        render: DateTimeInput,
        width: { xs: 12, md: 6, lg: 4 }
      },
      {
        label: 'Miles in ECA',
        width: { xs: 12, md: 6, lg: 4 },
        key: 'eca_miles',
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const departure = () => [
  {
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        width: { xs: 3 }
      },
      {
        label: 'COSP DATE & TIME',
        key: 'local_timestamp',
        render: DateTimeInput,
        validation: validateTimeStamp,
        width: { xs: 'auto' },
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        width: { xs: '' },
        validation: validateTimeZone,
        render: Select,
        sendOnlyValue: true,
        previousReportInitializationKey: 'timezone',
        type: 'string',
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Loading Condition',
        key: 'loading_condition',
        previousReportInitializationKey: 'loading_condition',
        render: RadioInput,
        width: { xs: 2 },
        options: [
          { label: 'Ballast', value: 'ballast', icon: ballast },
          { label: 'Laden', value: 'laden', icon: laden }
        ]
      },
      {
        label: 'Cargo Onboard',
        subLabel: 'mt',
        key: 'cargo_onboard',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Displacement used',
        subLabel: '%',
        previousReportInitializationKey: 'displacement_used',
        key: 'displacement_used',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: (
          <span>
            Stability-G<span className="text-lowercase">o</span>m
          </span>
        ),
        subLabel: 'm',
        key: 'metacentric_height_gom',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Aft',
        subLabel: 'm',
        key: 'draft_aft',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Mid',
        key: 'draft_mid',
        width: { xs: '' },
        subLabel: 'm',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Fwd',
        subLabel: 'm',
        key: 'draft_fwd',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Trim',
        subLabel: 'm',
        key: 'trim',
        width: { xs: '' },
        autocompleteValues: [{ key: 'draft_aft' }, { key: 'draft_fwd' }],
        autocomplete: autocompleteTrim,
        className: 'pointer-events-none noborder-input',
        disabled: true,
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Cargo Onboard',
        subLabel: 'units',
        key: 'cargo_onboard_units',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'EV Onboard',
        subLabel: 'units',
        key: 'cargo_onboard_units_ev',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Departure Port',
        key: 'current_port_id',
        sendOnlyValue: true,
        width: { xs: 3 },
        previousNavigationalInitializationKey: 'current_port',
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'current_port' }
      },
      {
        label: 'Departure',
        key: 'current_port_etd',
        previousNavigationalInitializationKey: 'current_port_etd',
        width: { xs: 3 },
        render: DateTimeInput,
        templateValidation: { required: false }
      },
      {
        label: 'COSP',
        key: 'cosp',
        width: { xs: 3 },
        render: DateTimeInput,
        templateValidation: { required: false }
      },

      {
        label: 'STS Operations outside port limits',
        className: 'd-flex justify-content-end mt-1',
        key: 'sts_operations_outside_port_limits',
        width: { xs: '' },
        render: CheckboxInput
      }
    ]
  },
  {
    fields: [
      {
        label: 'Next Port',
        key: 'next_port_id',
        sendOnlyValue: true,
        previousNavigationalInitializationKey: 'next_port',
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'next_port' }
      },
      {
        label: 'ETA',
        key: 'eta',
        previousNavigationalInitializationKey: 'eta',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETB',
        previousNavigationalInitializationKey: 'etb',
        key: 'etb',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETD',
        key: 'etd',
        previousNavigationalInitializationKey: 'etd',
        render: DateTimeInput,
        width: { xs: 'auto' }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Miles to go',
        width: { xs: 12, md: 6, lg: 3 },
        key: 'miles_to_go',
        previousNavigationalInitializationKey: 'miles_to_go',
        subLabel: 'nm',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'INSTRUCTED BY',
        key: 'instructed_speed_consumption_by',
        width: { xs: 2 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-instructed-speed-consumption-by',
          isAsync: false
        }
      },
      {
        label: 'INSTRUCTIONS FOR',
        key: 'instructed_speed_consumption_target',
        width: { xs: 3 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-instructed-speed-consumption-target',
          isAsync: false
        }
      },
      {
        label: 'SPEED',
        key: 'instructed_speed',
        subLabel: 'kt',
        width: { xs: 1 },
        render: SpeedField,
        type: 'number'
      },
      {
        label: 'ALLOWANCE',
        key: 'instructed_speed_allowance',
        width: { xs: 1 },
        render: AllowanceField,
        type: 'number'
      },
      {
        label: 'Speed Unit',
        key: 'instructed_speed_allowance_unit',
        width: { xs: 'auto' },
        render: AllowanceUnit
      }
    ]
  },
  {
    fields: [
      {
        label: 'FO CONS',
        key: 'instructed_fo_consumption',
        subLabel: 'mt/d',
        width: { xs: 'auto' },
        className: 'max-width-112',
        render: FoConsField,
        type: 'number'
      },
      {
        label: 'GO CONS',
        key: 'instructed_go_consumption',
        className: 'max-width-112',
        subLabel: 'mt/d',
        width: { xs: 'auto' },
        render: GoConsField,
        type: 'number'
      },
      {
        label: 'ALLOWANCE',
        key: 'instructed_consumption_allowance',
        width: { xs: 'auto' },
        className: ' max-width-112',
        render: AllowanceField,
        type: 'number'
      },
      {
        label: '',
        key: 'instructed_consumption_allowance_unit',
        width: { xs: 'auto' },
        className: ' max-width-112',
        render: AllowanceUnit
      },
      {
        label: 'TARGET ETA',
        key: 'instructed_eta',
        render: InstructedEta,
        width: { xs: 3 }
      }
    ]
  }
];

const delivery = () => [
  {
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput,
        templateValidation: { required: true }
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        templateValidation: { required: true }
      },
      {
        label: 'Date & Time',
        key: 'local_timestamp',
        width: { xs: 'auto' },
        render: DateTimeInput,
        validation: validateTimeStampIsNull,
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        type: 'string',
        width: { xs: '' },
        sendOnlyValue: true,
        validation: validateTimeZone,
        render: Select,
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'DELIVERED AT',
        key: 'delivered_at',
        width: { xs: 3 },
        render: DeliveredAtField,
        selectRest: {
          list: 'captain-report-enums-delivered-at',
          isAsync: false
        },
        templateValidation: { required: true }
      },
      {
        label: 'Port',
        key: 'current_port_id',
        sendOnlyValue: true,
        width: { xs: 3 },
        templateValidation: { required: true },
        validation: validateDeliveryCurrentPort,
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'current_port' }
      },
      {
        label: 'HOLDS ON DELIVERY',
        key: 'holds_on_delivery',
        width: { xs: 3 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-holds-on-delivery',
          isAsync: false
        }
      }
    ]
  },
  {
    fields: [
      {
        label: 'DELIVERED TO CHARTERER',
        key: 'charter_party_id',
        width: { xs: 12 },
        render: DeliveredToCharterField,
        sendOnlyValue: true,
        validation: validateCharterParty,
        templateValidation: { required: true },
        selectRest: { list: 'charter-parties', isAsync: true, selectKey: 'charter_party' }
      }
    ]
  }
];

const redelivery = () => [
  {
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput,
        templateValidation: { required: true }
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        templateValidation: { required: true }
      },
      {
        label: 'Date & Time',
        key: 'local_timestamp',
        validation: validateTimeStampIsNull,
        width: { xs: 'auto' },
        render: DateTimeInput,
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        type: 'string',
        sendOnlyValue: true,
        validation: validateTimeZone,
        width: { xs: '' },
        render: Select,
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'REDELIVERED AT',
        key: 'delivered_at',
        width: { xs: 3 },
        render: DeliveredAtField,
        selectRest: {
          list: 'captain-report-enums-delivered-at',
          isAsync: false
        },
        templateValidation: { required: true }
      },
      {
        label: 'Port',
        key: 'current_port_id',
        sendOnlyValue: true,
        width: { xs: 3 },
        render: DynamicSelect,
        validation: validateDeliveryCurrentPort,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'current_port' },
        templateValidation: { required: true }
      },
      {
        label: 'HOLDS ON REDELIVERY',
        key: 'holds_on_delivery',
        width: { xs: 3 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-holds-on-delivery',
          isAsync: false
        }
      }
    ]
  },
  {
    fields: [
      {
        label: 'REDELIVERED FROM CHARTERER',
        key: 'charter_party_id',
        width: { xs: 12 },
        render: DeliveredToCharterField,
        sendOnlyValue: true,
        validation: validateCharterParty,
        templateValidation: { required: true },
        selectRest: { list: 'charter-parties', isAsync: true, selectKey: 'charter_party' }
      }
    ]
  }
];

const stoppage = () => [
  {
    header: 'COMMENCEMENT OF STOPPAGE',
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput,
        templateValidation: { required: true }
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        templateValidation: { required: true }
      },
      {
        label: 'Date & Time',
        key: 'local_timestamp',
        validation: validateTimeStampIsNull,
        width: { xs: 'auto' },
        render: DateTimeInput,
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        type: 'string',
        sendOnlyValue: true,
        width: { xs: '' },
        render: Select,
        validation: validateTimeZone,
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    header: 'END OF STOPPAGE',
    fields: [
      {
        label: 'Latitude',
        key: 'stoppage_end_latitude',
        width: { xs: 3 },
        render: CoordinateInput
      },
      {
        label: 'Longitude',
        key: 'stoppage_end_longitude',
        render: CoordinateInput
      },
      {
        label: 'Date & Time',
        key: 'stoppage_end_local_timestamp',
        width: { xs: 'auto' },
        render: DateTimeInput
      },
      {
        label: 'UTC',
        key: 'stoppage_end_timestamp',
        className: 'mb-0',
        width: { xs: 3 },
        render: StoppageEndTimestampField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Stoppage Duration',
        key: 'stoppage_duration',
        autocompleteValues: [{ key: 'local_timestamp' }, { key: 'stoppage_end_local_timestamp' }],
        autocomplete: autocompleteStoppageDuration,
        width: { xs: 2 },
        render: StoppageDuration
      },
      {
        label: 'Miles Drifted',
        key: 'distance_run',
        width: { xs: 2 },
        render: NumberField
      },
      {
        label: 'Stoppage Reason',
        key: 'stoppage_reason',
        width: { xs: 5 },
        render: DynamicSelect,
        selectRest: {
          list: 'stoppage-reason',
          isAsync: false
        }
      },
      {
        label: 'Associated Element',
        key: 'stoppage_associated_element',
        render: StoppageAssociatedElement,
        selectRest: {
          list: 'stoppage-associated-element',
          isAsync: false
        }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Stoppage Description',
        key: 'stoppage_description',
        width: { xs: 6 },
        render: TextAreaField
      },
      {
        label: 'Immediate actions taken',
        key: 'stoppage_actions_taken',
        width: { xs: 6 },
        render: TextAreaField
      }
    ]
  }
];

const port_noon = () => [
  {
    fields: [
      {
        label: 'Latitude',
        key: 'lat',
        width: { xs: 3 },
        render: CoordinateInput
      },
      {
        label: 'Longitude',
        key: 'lon',
        render: CoordinateInput,
        width: { xs: 3 }
      },
      {
        label: 'Date & Time',
        key: 'local_timestamp',
        validation: validateTimeStamp,
        render: LocalTimestampField,
        width: { xs: 'auto' },
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        type: 'string',
        sendOnlyValue: true,
        validation: validateTimeZone,
        width: { xs: '' },
        previousReportInitializationKey: 'timezone',
        render: Select,
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Current Port',
        key: 'current_port_id',
        sendOnlyValue: true,
        width: { xs: 3 },
        previousNavigationalInitializationKey: 'current_port',
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'current_port' }
      },
      {
        label: 'ETD',
        key: 'current_port_etd',
        previousNavigationalInitializationKey: 'current_port_etd',
        width: { xs: 3 },
        render: DateTimeInput
      },
      {
        label: 'STATUS',
        key: 'status_at_port',
        width: { xs: 2 },
        render: DynamicSelect,
        selectRest: { list: 'status-at-port', isAsync: false }
      },
      {
        label: 'STS Operations outside port limits',
        className: 'd-flex justify-content-end mt-1',
        key: 'sts_operations_outside_port_limits',
        width: { xs: 'auto' },
        render: CheckboxInput
      }
    ]
  },
  {
    fields: [
      {
        label: 'Type of berth',
        key: 'type_berth',
        width: { xs: 3 },
        previousNavigationalInitializationKey: 'type_berth',
        options: berthOptions,
        render: Select
      }
    ]
  },
  {
    fields: [
      {
        label: 'Draft Aft',
        subLabel: 'm',
        previousReportInitializationKey: 'draft_aft',
        key: 'draft_aft',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Mid',
        subLabel: 'm',
        key: 'draft_mid',
        previousReportInitializationKey: 'draft_mid',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Draft Fwd',
        subLabel: 'm',
        key: 'draft_fwd',
        previousReportInitializationKey: 'draft_fwd',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Trim',
        subLabel: 'm',
        key: 'trim',
        width: { xs: 3 },
        autocompleteValues: [{ key: 'draft_aft' }, { key: 'draft_fwd' }],
        autocomplete: autocompleteTrim,
        className: 'pointer-events-none noborder-input',
        disabled: true,
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Next Port',
        key: 'next_port_id',
        sendOnlyValue: true,
        previousNavigationalInitializationKey: 'next_port',
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'next_port' }
      },
      {
        label: 'ETA',
        key: 'eta',
        previousNavigationalInitializationKey: 'eta',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETB',
        key: 'etb',
        previousNavigationalInitializationKey: 'etb',
        render: DateTimeInput,
        width: { xs: 'auto' }
      },
      {
        label: 'ETD',
        previousNavigationalInitializationKey: 'etd',
        key: 'etd',
        render: DateTimeInput,
        width: { xs: 'auto' }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Miles to go',
        subLabel: 'nm',
        previousNavigationalInitializationKey: 'miles_to_go',
        width: { xs: 12, md: 6, lg: 3 },
        key: 'miles_to_go',
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const navigationalConfig = {
  noon: () => noon(),
  arrival: () => arrival(),
  ballast: () => [],
  delivery: () => delivery(),
  redelivery: () => redelivery(),
  departure: () => departure(),
  port_noon: () => port_noon(),
  stoppage: () => stoppage(),
  instructed_speed: () => [],
  passage_plan: () => []
};

export default navigationalConfig;
