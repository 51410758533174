import React, { useState } from 'react';

import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import SharedByLabel from 'common/components/general/SharedByLabel';
import Form from './form';

import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { useDrawer } from 'common/components/drawer';
import { parseQueryParams } from 'utils/urls';

import { goToSavedTable } from './helpers';

import * as tablesActions from 'store/tables/actions';
import {
  selectSearchShares,
  selectSearchSaving,
  selectTableDefaultRequestParams
} from 'store/tables/selectors';

import arrowBtn from 'assets/svg/common/arrow.svg';

const EditSearch = ({ setModalOpened, tableSearch, queryChanged, label, account }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { history } = useRouter();
  const drawer = useDrawer();
  const searchSaving = useSelector(selectSearchSaving);
  const [updateTableSearch] = useActions([tablesActions.updateTableSearch]);

  const searchShares = useSelector(selectSearchShares);

  const defaultTableParams = useSelector(selectTableDefaultRequestParams);

  const shares = {
    parties: searchShares?.parties?.map(e => e.id) || [],
    departments: searchShares?.departments?.map(e => e.id) || []
  };

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        {queryChanged ? <div className="ms-1 fs-14 text-gray-400">- Edited</div> : null}
        <SharedByLabel
          shouldShowName={tableSearch.party_id !== account.id}
          name={tableSearch.owner?.full_name}
          sharedWithNumber={tableSearch.shares?.length}
          wrapperClassName="ms-3 ps-3 border-start height-32"
        />
      </div>
      <div className="d-flex align-items-center ms-1">
        {queryChanged ? (
          <>
            <Button
              className="ms-2"
              color="grey"
              type="button"
              disabled={searchSaving}
              onClick={() => {
                const { searchId, filters, sorting, ...rest } = parseQueryParams(
                  history.location.search
                );
                updateTableSearch({
                  ...rest,
                  sorting: !sorting ? null : sorting,
                  filters: !filters ? null : filters,
                  id: searchId,
                  shares: shares,
                  table_label: label
                });
              }}
            >
              Save
            </Button>
            <Dropdown
              direction="down"
              isOpen={dropdownOpened}
              toggle={() => setDropdownOpened(!dropdownOpened)}
              disabled={searchSaving}
            >
              <DropdownToggle color="dropdown-toggler">
                <SvgRender src={arrowBtn} style={{ width: 10, height: 10 }} />
              </DropdownToggle>
              <DropdownMenu className="ms-2" end={true} strategy="fixed">
                <DropdownItem className="pointer" onClick={() => setModalOpened(true)}>
                  Save as
                </DropdownItem>
                <DropdownItem
                  className="pointer"
                  onClick={() => goToSavedTable(history, defaultTableParams, tableSearch)}
                >
                  Discard changes
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            &nbsp;
          </>
        ) : null}
        <Button color="grey" type="button" onClick={() => drawer.toggle()}>
          Manage
        </Button>
      </div>

      <Form drawer={drawer} tableSearch={tableSearch} />
    </div>
  );
};

export default EditSearch;
