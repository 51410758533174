import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_MGA_PERIOD_FORM: 'SET_MGA_PERIOD_FORM',
  SET_MGA_ACTION_FORM: 'SET_MGA_ACTION_FORM',
  SET_WARNING_CREW_CONTRACT_IDS: 'SET_WARNING_CREW_CONTRACT_IDS',
  SET_WARNING_CREW_CLOSING_BALANCE: 'SET_WARNING_CREW_CLOSING_BALANCE',
  SET_WARNING_CREW_OVERVIEW: 'SET_WARNING_CREW_OVERVIEW',

  ...asyncTypes('GET_MGA_PERIODS'),
  ...asyncTypes('CREATE_MGA_PERIOD'),
  ...asyncTypes('EDIT_MGA_PERIOD'),

  ...asyncTypes('CREATE_MGA_ACTION'),
  ...asyncTypes('EDIT_MGA_ACTION'),
  ...asyncTypes('DELETE_MGA_ACTION'),
  ...asyncTypes('GET_MGA_ACTION'),
  ...asyncTypes('GET_MGA_ACTION_ATTACHMENTS'),
  ...asyncTypes('DOWNLOAD_MGA_ACTION_ATTACHMENTS'),
  ...asyncTypes('CREATE_MGA_ACTION_DIGITAL_SIGNATURE'),
  ...asyncTypes('DELETE_MGA_ACTION_DIGITAL_SIGNATURE'),
  ...asyncTypes('GET_MGA_ACTION_DIGITAL_SIGNATURES'),
  ...asyncTypes('CREATE_MGA_ACCOUNT_DIGITAL_SIGNATURE'),
  ...asyncTypes('DELETE_MGA_ACCOUNT_DIGITAL_SIGNATURE'),
  ...asyncTypes('GET_MGA_ACCOUNT_DIGITAL_SIGNATURES'),

  ...asyncTypes('GET_ACCOUNT_BALANCES'),

  ...asyncTypes('GET_MGA_WARNINGS'),
  ...asyncTypes('GET_MGA_INFO'),
  ...asyncTypes('GET_MGA_BALANCE_SHEET_REPORT'),
  ...asyncTypes('GET_MGA_SETTING'),

  ...asyncTypes('GET_MGA_CHAT_MESSAGES'),
  ...asyncTypes('POST_MGA_CHAT_MESSAGE'),
  ...asyncTypes('GET_MGA_UNREAD_MESSAGES'),
  ...asyncTypes('MGA_CHAT_MESSAGE_MARK_AS_READ')
};

export default TYPES;
