import { FC, ReactNode } from 'react';
import BudgetPercentage from './BudgetPercentage';

import { numberToStr } from 'common/utils/numbers';
import _get from 'lodash/get';
import { useAppSelector } from '@/store/hooks';
import { selectCategoryAccountBudgets } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import { Budget } from '@/common/types/budget.ts';

type ValueProps = {
  asPrice?: boolean;
  asPercentage?: boolean;
  category: Budget;
  valueKey: string;
};

const Value: FC<ValueProps> = ({ asPrice, asPercentage, category, valueKey }) => (
  <strong className="cms-4">
    {asPrice ? `$ ` : ''}
    {numberToStr(_get(category, valueKey), 2, asPercentage ? 0 : 2)}
    {asPercentage ? ` %` : ''}
  </strong>
);

type FieldProps = {
  label: string;
  valueKey: string;
  data: Budget;
  budgetPercentageKey?: string;
  asPrice?: boolean;
  asPercentage?: boolean;
  showBudgetPercentage?: boolean;
};

const Field: FC<FieldProps> = ({
  label,
  valueKey,
  data,
  budgetPercentageKey,
  asPrice = false,
  asPercentage = false,
  showBudgetPercentage = false
}) => {
  return (
    <div className="text-primary fs-12 d-inline-flex align-items-center">
      <span>{label}:</span>
      {budgetPercentageKey ? (
        <BudgetPercentage value={_get(data, budgetPercentageKey)}>
          <Value
            asPrice={asPrice}
            asPercentage={asPercentage}
            category={data}
            valueKey={valueKey}
          />
          {showBudgetPercentage ? (
            <>
              <span className="lh-16 fs-16 ps-1 cpe-4">|</span>
              <Value asPercentage category={data} valueKey={budgetPercentageKey} />
            </>
          ) : null}
        </BudgetPercentage>
      ) : (
        <Value asPrice={asPrice} asPercentage={asPercentage} category={data} valueKey={valueKey} />
      )}
    </div>
  );
};

type LayoutProps = {
  children: ReactNode;
  inline: boolean;
};

const Layout: FC<LayoutProps> = ({ children, inline }) => {
  if (inline) return children;

  return (
    <div className="w-100p d-flex flex-wrap align-items-center rounded bg-light-gray px-1 cpy-4">
      {children}
    </div>
  );
};

type AccountBudgetsProps = {
  categoryID: number;
  inline: boolean;
};

const AccountBudgets: FC<AccountBudgetsProps> = ({ categoryID, inline = true }) => {
  const accountBudgets = useAppSelector(state => selectCategoryAccountBudgets(state, categoryID));

  return accountBudgets.map((data, index) => (
    <div
      key={data.id}
      className={`d-flex cmt-4 flex-wrap align-items-center lh-12 ${
        inline ? 'rounded bg-shades-2 px-1 cpy-2' : 'ms-1 cmt-4'
      } ${index === accountBudgets.length - 1 && inline ? 'mb-2' : ''}`}
    >
      <Layout inline={inline}>
        <div className="fs-12 text-primary d-flex align-items-center">
          {data.budgetable.code ? `${data.budgetable.code}. ` : ''}
          {data.budgetable.name}

          <div className="bg-primary small-separator mx-2 cpb-14"></div>
        </div>

        <div className="pe-2 me-2 border-end border-primary">
          <Field data={data} label="Not Delivered" valueKey="calculations.not_delivered" asPrice />
        </div>

        <div className="pe-2 me-2 border-end border-primary">
          <Field data={data} label="Delivered" valueKey="calculations.delivered" asPrice />
        </div>

        <div className="pe-2 me-2 border-end border-primary">
          <Field data={data} label="Total Ordered" valueKey="calculations.ordered_total" asPrice />
        </div>

        <div className="me-2 pe-2 border-end border-primary">
          <Field data={data} label="Budget" valueKey="amount_budgeted" asPrice />
        </div>

        <div>
          <Field
            data={data}
            label="Remaining"
            valueKey="calculations.remaining"
            budgetPercentageKey="calculations.budget_used_percentage"
            showBudgetPercentage
            asPrice
          />
        </div>
      </Layout>
    </div>
  ));
};

export default AccountBudgets;
