import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_SAVED_SEARCHES'),
  ...asyncTypes('GET_TABLE_SEARCH'),
  ...asyncTypes('CREATE_TABLE_SEARCH'),
  ...asyncTypes('UPDATE_TABLE_SEARCH'),
  RESET_CURRENT_PAGE: 'RESET_CURRENT_PAGE',
  RESET_TABLE_DATA: 'RESET_TABLE_DATA',
  RESET_TABLE_COLUMNS: 'RESET_TABLE_COLUMNS',
  RESET_TABLE_SEARCH: 'RESET_TABLE_SEARCH',
  SET_TABLE_FILTERS: 'SET_TABLE_FILTERS',
  SET_TABLE_DEFAULT_REQUEST_PARAMS: 'SET_TABLE_DEFAULT_REQUEST_PARAMS',
  TRIGGER_PROFILE_ACTION: 'TRIGGER_PROFILE_ACTION',
  SET_HAS_TABLE_SAVED_FILTERS: 'SET_HAS_TABLE_SAVED_FILTERS'
};

export default TYPES;
