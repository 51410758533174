import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getCrewClosingBalanceWarnings } from 'common/components/mga/store/parser';
import paths from 'routing/routes/_paths';
import _sortBy from 'lodash/sortBy';

const INITIAL_STATE = {
  isPastMga: false,
  periodForm: {
    isOpen: false,
    isModalOpen: false,
    active: null
  },
  periods: [],

  vesselFilter: null,
  periodFilter: null,

  account: {
    balances: null
  },

  actionForm: {
    isOpen: false,
    isModalOpen: false,
    active: null,
    cell: null
  },

  inventoryDepletionMethod: null,

  shouldRefetchTable: false,

  wageAccountAmounts: {}, // { crew_member_id: [] }

  warningCrewContractIds: [],
  warningCrewClosingBalance: [],
  warningCrewOverview: {},

  currencies: [],
  pastRates: []
};

const wageAccountDefaults = {
  recurring: { order: 1, label: 'Recurring 30-days' },
  hourly: { order: 2, label: 'Hourly' },
  'ad-hoc': { order: 3, label: 'Ad hoc' }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_MGA_PERIODS.SUCCESS:
      return { ...state, periods: payload };

    case TYPES.EDIT_MGA_PERIOD.SUCCESS:
      return {
        ...state,
        periods: state.periods.map(p => (p.id === payload.id ? { ...p, ...payload } : p))
      };

    case TYPES.SET_MGA_PERIOD_FORM:
      return { ...state, periodForm: { ...payload } };

    case TYPES.SET_MGA_ACTION_FORM:
      return {
        ...state,
        actionForm: { ...payload },
        shouldRefetchTable: payload.isOpen || payload.isModalOpen ? false : state.shouldRefetchTable
      };

    case TYPES.CREATE_MGA_ACTION.SUCCESS:
    case TYPES.EDIT_MGA_ACTION.SUCCESS:
    case TYPES.DELETE_MGA_ACTION.SUCCESS:
      return { ...state, shouldRefetchTable: true };

    case TYPES.GET_ACCOUNT_BALANCES.SUCCESS:
      return {
        ...state,
        account: { ...state.account, balances: payload }
      };

    case TYPES.GET_MGA_INFO.START:
      return {
        ...state,
        wageAccountAmounts: {}
      };

    case TYPES.GET_MGA_INFO.SUCCESS:
      return {
        ...state,
        wageAccountAmounts: payload
          .filter(p => p.wage_account_amounts?.length)
          .reduce((acc, cur) => {
            acc[`${cur.crew_member_id}_${cur.crew_contract_id}`] = _sortBy(
              cur.wage_account_amounts.map(o => ({
                ...o,
                order: wageAccountDefaults[o.account.contract_amount_payment_type]?.order,
                label: wageAccountDefaults[o.account.contract_amount_payment_type]?.label
              })),
              [o => o.order, o => o.account.code]
            );

            return acc;
          }, {})
      };

    case TYPES.GET_MGA_SETTING.SUCCESS:
      return {
        ...state,
        inventoryDepletionMethod: payload?.data?.mga_inventory_depletion_method
      };

    case TYPES.SET_WARNING_CREW_CONTRACT_IDS:
      return {
        ...state,
        warningCrewContractIds: payload
      };

    case TYPES.SET_WARNING_CREW_CLOSING_BALANCE:
      return {
        ...state,
        warningCrewClosingBalance: getCrewClosingBalanceWarnings(payload)
      };

    case TYPES.SET_WARNING_CREW_OVERVIEW:
      return {
        ...state,
        warningCrewOverview: payload
      };

    case 'GET_MGA_CURRENCIES/fulfilled':
    case 'UPDATE_MGA_CURRENCIES/fulfilled': {
      const baseCurrency = { ...(payload?.base || {}), isBase: true };
      const extraCurrencies = payload?.extra || [];

      return { ...state, currencies: [baseCurrency, ...extraCurrencies] };
    }

    case 'GET_MGA_FOREX_RATE/fulfilled':
      return {
        ...state,
        pastRates: payload
      };

    case LOCATION_CHANGE:
      if (payload.location.pathname.startsWith(paths.mga)) {
        const parts = payload.location.pathname.split('/');
        const isPastMga = payload.location.pathname?.includes('past');

        if (parts?.[isPastMga ? 4 : 3]) {
          const vessel_id = parseInt(parts?.[isPastMga ? 4 : 3]);
          const period_id = parts?.[isPastMga ? 5 : 4] || null;

          return {
            ...state,
            isPastMga: isPastMga,
            vesselFilter: vessel_id,
            periodFilter: period_id,
            periods: vessel_id !== state.vesselFilter && !isPastMga ? [] : state.periods
          };
        } else {
          return {
            ...state,
            isPastMga: isPastMga,
            vesselFilter: null,
            periodFilter: null
          };
        }
      } else {
        return {
          ...state,
          periods: [],
          vesselFilter: null,
          periodFilter: null
        };
      }

    default:
      return state;
  }
};
export default reducer;
