const actions = {
  purchasingRequisitionApproval: { label: 'purchasing_requisition_approval', name: 'Requisition' },
  purchasingForwardingApproval: { label: 'purchasing_forwarding_approval', name: 'Forwarding' },
  formsApproval: { label: 'form_submission_creation', name: 'Form' },
  manualApproval: { label: 'manual_version', name: 'Manual' },
  contractsApproval: { label: 'crew_contract', name: 'Contracts' },
  pmsJobsApproval: { label: 'pms_jobs', name: 'Pms Jobs' },
  invoice: { label: 'invoices', name: 'Invoices' },
  crewContract: { label: 'crew_contract', name: 'Crew Contract' },
  manualVersion: { label: 'manual_version', name: 'Manual Version' }
};

export default actions;
