import { FC, MouseEvent, ReactElement } from 'react';

import styled from '@emotion/styled';
import variables from '@/common/assets/scss/abstracts/_exports.module.scss';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type ActivationCirlceProps = {
  className?: string;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  isActive: boolean;
  disabled?: boolean;
  info?: string | ReactElement;
};

const ActivationCirlce: FC<ActivationCirlceProps> = ({
  className = '',
  onClick,
  isActive,
  disabled,
  info
}) => {
  const { tooltipID, avoidRender } = useTooltipID('activation-circle');

  if (avoidRender) return null;

  return (
    <>
      <CircleContainer
        id={tooltipID}
        isActive={isActive}
        className={`d-flex align-items-center justify-content-center cursor-pointer ${className} ${
          disabled ? 'disabled' : ''
        }`}
        onClick={onClick}
      >
        <span className={`rounded bg-${isActive ? 'green' : 'inactive'}`} />
      </CircleContainer>

      {!!info && <Tooltip target={tooltipID}>{info}</Tooltip>}
    </>
  );
};

export default ActivationCirlce;

const CircleContainer = styled.div(
  ({ isActive }: { isActive: boolean }) => `
  border: 2px solid transparent;
  border-radius: 50%;
  padding: ${variables.size2};

  span {
    width: ${variables.size8};
    height: ${variables.size8};
    opacity: 1;
  }

  &:hover {
    border: 2px solid ${isActive ? variables.turquoise : variables.pastelGray} !important;
  }
`
);
