import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getInvoicesTotals } from './actions';

export interface Totals {
  total_count: number;
  total_price: string;
}

export interface TotalsPerCurrency {
  becoming_overdue: boolean;
  currency_id: number;
  is_overdue: boolean;
  label: string;
  name: string;
  total_amount: string;
  total_count: number;
}

export interface Invoices {
  totals: Totals | null;
  totals_per_currency: TotalsPerCurrency[];
}

const INITIAL_STATE: Invoices = {
  totals: null,
  totals_per_currency: []
};

const slice = createSlice({
  name: 'invoices',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInvoicesTotals.fulfilled, (state, action: PayloadAction<Invoices>) => {
      state.totals = action.payload.totals;
      state.totals_per_currency = action.payload.totals_per_currency;

      return state;
    });
  }
});

export default slice.reducer;
