import { put, get, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateTableRow } from 'store/tables/lists/actions';
import { getColumns, constructColumns } from '../report/tableConfig';
import TABLE_TYPES from 'common/components/table/store/types';
import { successHandler } from 'common/utils/notifications';

export const getFindings = createAsyncThunk(
  'GET_FINDINGS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, isOnBoard } = params;
      const columns = getColumns();

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          table,
          columns: constructColumns(columns, isOnBoard)
        }
      });

      const res = await get('/findings', params);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: { ...res?.data, columns: constructColumns(columns, isOnBoard) },
          table
        }
      });

      return { data: res?.data, report_type: res.report_type };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateFinding = createAsyncThunk(
  'UPDATE_FINDING',
  async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const { finding_id, ...rest } = params;

    try {
      const res = await put(`/findings/${finding_id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return fulfillWithValue({ data: res.data, params: rest });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resolveFinding = createAsyncThunk(
  'RESOLVE_FINDING',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, finding_id } = params;

      const res = await put(`/findings/${finding_id}/resolve`, params);

      dispatch(updateTableRow({ data: res.data, table: table }));

      return { data: res?.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resolveBulkFinding = createAsyncThunk(
  'RESOLVE_BULK_FINDING',
  async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const res = await put(`/findings/resolve/bulk`, params);

      return fulfillWithValue({ data: res.data });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getFinding = createAsyncThunk('GET_FINDING', async (params, { rejectWithValue }) => {
  try {
    const { id } = params;

    const res = await get(`/findings/${id}`);

    return res?.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const deleteFinding = createAsyncThunk(
  'DELETE_FINDING',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { id } = params;

      const res = await deleteRequest(`/findings/${id}`);
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createReportFinding = createAsyncThunk(
  'CREATE_REPORT_FINDING',
  async (params, { rejectWithValue }) => {
    try {
      const res = await post(`/findings`, params);

      return { data: res?.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
