import FleetTce from './types/fleet-tce';
import CashBalance from './types/cash-balance';

import Jobs from './types/jobs';
import JobsCardWidget from './types/jobs-card';
import PendingEvaluations from './types/pending-evaluations';
import PendingEvaluationsCardWidget from './types/pending-evaluations-card';
import PmsJobs from './types/pms-jobs';
import PmsJobsCardWidget from './types/pms-jobs-card';
import Birthday, { BirthdayPreferences } from './types/birthday';
import VesselsConnectivity from './types/vessels-connectivity';
import VesselPerfomance from './types/vessel-perfomance-widget';
import Forms from './types/forms';
import FormsCardWidget from './types/forms-card';
import UnreadMessages, { UnreadMessagesPreferences } from './types/unread-messages';
import CaptainReportsCardWidget from './types/captain-reports-card';
import WidgetSavedTableSettings from '../components/WidgetSavedTableSettings';
import CaptainReports from './types/captain-reports';
import BookmarksCardWidget, { BookmarksPreferences } from './types/bookmarks-card';
import BookmarksTable from 'views/dashboard/widgets/types/bookmarks-table';
import Requisitions from './types/requisitions';
import RequisitionsCardWidget from './types/requisitions-card';
import Seamen from './types/seamen';
import SeamenCardWidget from './types/seamen-card';
import VesselTceWidget, { VesselTcePreferences } from './types/vessel-tce';
import RunningHoursReports from './types/running-hours';
import RunningHoursReportsPreferences from './types/running-hours/Preferences';
import Events from './types/events';
import EventsCardWidget from './types/events-card';
import VesselDocumentsCardWidget from './types/vessel-documents-card';
import VesselDocuments from './types/vessel-documents-table';
import CrewDocuments from './types/crew-documents';
import CrewDocumentsCardWidget from './types/crew-documents-card';
import FindingsCardWidget from './types/findings-card';
import Findings from './types/findings';
import PurchasingApprovalsCardWidget from './types/purchasing-approvals-card';
import PurchasingApprovals from './types/purchasing-approvals';
import BudgetPreferences from './types/budget/Preferences';
import Budget from 'views/dashboard/widgets/types/budget';
import FlagDispenations from 'views/dashboard/widgets/types/flag-dispensations';
import FlagDispensationsCardWidget from 'views/dashboard/widgets/types/flag-dispensations-card';
import Companies from './types/companies';
import CompaniesCardWidget from './types/companies-card';
import Persons from './types/persons';
import PersonsCardWidget from './types/persons-card';
import PersonsPreferences from './types/persons/Preferences';
import InvoicesCardWidget from './types/invoices-card';
import Invoices from './types/invoices';
import InventorySpareParts from './types/inventory-spare-parts';
import InventorySparePartsCardWidget from './types/inventory-spare-parts-card';
import OverdueJobs from 'views/dashboard/widgets/types/ovedue-jobs';
import SparePartsInventoryDueDates from 'views/dashboard/widgets/types/spare-parts-inventory-survey-due-dates';

import moment from 'moment';

import vessels from 'assets/svg/sidebar/vessels.svg';
import runningHours from 'common/assets/svg/common/reminder.svg';
import jobs from 'common/assets/svg/common/jobs.svg';
import pms from 'common/assets/svg/common/setup.svg';
import forms from 'common/assets/svg/common/forms.svg';
import birthdays from 'common/assets/svg/common/birthday-icon.svg';
import message from 'common/assets/svg/common/messages-icon.svg';
import chat_bubble from 'common/assets/svg/common/chat-bubble.svg';
import evaluation from 'common/assets/svg/common/star.svg';
import captainReports from 'common/assets/svg/common/crew.svg';
import bookmarks from 'common/assets/svg/common/ribbon-line.svg';
import requisitions from 'common/assets/svg/common/purchasing.svg';
import seamen from 'common/assets/svg/common/crew_planning.svg';
import events from 'common/assets/svg/common/events.svg';
import vesselDocument from 'assets/svg/sidebar/document_manager.svg';
import findings from 'common/assets/svg/common/findings.svg';
import purchasingApproval from 'common/assets/svg/common/purchasing-approval.svg';
import formsObligations from 'common/assets/svg/common/forms-obligations.svg';
import documentManagerIcon from 'assets/svg/sidebar/document_manager.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import personIcon from 'common/assets/svg/common/person_profile.svg';
import inventoryIcon from 'common/assets/svg/accounting/inventory.svg';
import budget from 'common/assets/svg/accounting/budget.svg';
import flag from 'common/assets/svg/common/flag.svg';
import clock from 'common/assets/svg/common/clock-line.svg';
import invoices from 'common/assets/svg/common/invoices.svg';

const defaultSettings = { custom_title: '' };
const cardSizes = { sm: 'sm', md: 'md', lg: 'lg' };

const widgetsDetails = {
  tce_actual_vs_estimated: {
    Component: null
  },
  tce_actual_vs_market: {
    Component: null
  },
  loans_outstanding: {
    Component: null
  },
  cash_balance: {
    Component: CashBalance,
    defaults: {
      preferences: {
        ...defaultSettings,
        group_by: 'account',
        only_finalized: true,
        week_day: 'mon',
        min_book_value: 5000
      }
    }
  },
  birthday_table: {
    Component: Birthday,
    icon: birthdays,
    PreferencesComponent: BirthdayPreferences,
    defaults: {
      preferences: {
        ...defaultSettings,
        type: 'person'
      }
    }
  },
  unread_messages_sidebar: {
    Component: UnreadMessages,
    PreferencesComponent: UnreadMessagesPreferences,
    icon: message,
    iconColored: chat_bubble,
    defaults: {
      preferences: {
        ...defaultSettings,
        entity_types: []
      }
    }
  },
  fleet_routes_table: {
    Component: null
  },
  fleet_tce_table: {
    Component: FleetTce,
    defaults: {
      preferences: {
        ...defaultSettings,
        selectedVessels: [],
        average: true,
        from: moment().subtract(5, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      }
    }
  },
  jobs_table: {
    Component: Jobs,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['jobs_list'],
      allOptionsText: 'All jobs'
    },
    icon: jobs,
    preferencesInfoText: `You haven’t selected any job filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All jobs' },
        update: false
      }
    }
  },
  jobs_card: {
    Component: JobsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['jobs_list'],
      allOptionsText: 'All jobs'
    },
    icon: jobs,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All jobs' },
        update: false
      }
    }
  },
  pms_jobs_card: {
    Component: PmsJobsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['pms_jobs_list', 'pms_jobs_per_system'],
      allOptionsText: 'All pms jobs'
    },
    icon: pms,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All pms jobs' },
        update: false
      }
    }
  },
  pms_jobs_table: {
    Component: PmsJobs,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['pms_jobs_list', 'pms_jobs_per_system'],
      allOptionsText: 'All pms jobs'
    },
    icon: pms,
    preferencesInfoText: `You haven't selected any PMS job filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All pms jobs' },
        update: false
      }
    }
  },
  pending_evaluations_card: {
    Component: PendingEvaluationsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['crew_evaluation_obligations'],
      allOptionsText: 'All pending evaluations'
    },
    icon: evaluation,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All evaluations' },
        update: false
      }
    }
  },
  pending_evaluations_table: {
    Component: PendingEvaluations,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['crew_evaluation_obligations'],
      allOptionsText: 'All pending evaluations'
    },
    icon: evaluation,
    preferencesInfoText: `You haven’t selected any evaluations filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All evaluations' },
        update: false
      }
    }
  },
  forms_table: {
    Component: Forms,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['form_submissions'],
      allOptionsText: 'All forms'
    },
    icon: forms,
    preferencesInfoText: `You haven’t selected any form filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All forms' },
        update: false
      }
    }
  },
  forms_card: {
    Component: FormsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['form_submissions'],
      allOptionsText: 'All forms'
    },
    icon: forms,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All forms' },
        update: false
      }
    }
  },
  vessel_connectivity_table: {
    Component: VesselsConnectivity,
    defaults: {
      preferences: {
        ...defaultSettings,
        type: 'all'
      }
    }
  },
  performance_report_table: {
    Component: VesselPerfomance
  },
  captain_reports_card: {
    Component: CaptainReportsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['captain_reports'],
      allOptionsText: 'All captain reports'
    },
    icon: captainReports,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All captain reports' },
        update: false
      }
    }
  },
  captain_reports_table: {
    Component: CaptainReports,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['captain_reports'],
      allOptionsText: 'All captain reports'
    },
    preferencesInfoText: `You haven’t selected any captain report filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: captainReports,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All captain reports' },
        update: false
      }
    }
  },
  bookmarks_card: {
    Component: BookmarksCardWidget,
    PreferencesComponent: BookmarksPreferences,
    preferencesComponentProps: {
      tableLabels: ['bookmarks'],
      allOptionsText: 'All bookmarks'
    },
    icon: bookmarks,
    size: cardSizes.lg,
    defaults: {
      preferences: {
        ...defaultSettings,
        bookmarks: []
      }
    }
  },
  bookmarks_table: {
    Component: BookmarksTable,
    PreferencesComponent: BookmarksPreferences,
    preferencesComponentProps: {
      tableLabels: ['bookmarks'],
      allOptionsText: 'All bookmarks'
    },
    preferencesInfoText: `You haven’t added any Orca links yet.<br/>Click on the button below & add the links that suits you.`,
    icon: bookmarks,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        bookmarks: []
      }
    }
  },
  purchasing_table: {
    Component: Requisitions,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['purchasing_requisitions'],
      allOptionsText: 'All Requisitions'
    },
    icon: requisitions,
    preferencesInfoText: `You haven’t selected any Requisition filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Requisitions' },
        update: false
      }
    }
  },
  purchasing_card: {
    Component: RequisitionsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['purchasing_requisitions'],
      allOptionsText: 'All Requisitions'
    },
    icon: requisitions,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Requisitions' },
        update: false
      }
    }
  },
  seamen_table: {
    Component: Seamen,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['crew'],
      allOptionsText: 'All Seamen'
    },
    preferencesInfoText: `You haven’t selected any Seamen filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: seamen,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Seamen' },
        update: false
      }
    }
  },
  seamen_card: {
    Component: SeamenCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['crew'],
      allOptionsText: 'All Seamen'
    },
    icon: seamen,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Seamen' },
        update: false
      }
    }
  },
  vessel_tce_results_table: {
    Component: VesselTceWidget,
    PreferencesComponent: VesselTcePreferences,
    preferencesInfoText: `You haven’t selected any Vessel TCE filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: vessels,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        vessel_id: null
      }
    }
  },
  running_hours_reports_table: {
    Component: RunningHoursReports,
    PreferencesComponent: RunningHoursReportsPreferences,
    preferencesInfoText: `You haven’t selected any Vessel or Vessel Group yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: runningHours,
    defaults: {
      preferences: {
        ...defaultSettings,
        vessel_id: null
      }
    }
  },
  events_table: {
    Component: Events,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['events'],
      allOptionsText: 'All Events'
    },
    preferencesInfoText: `You haven’t selected any event filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: events,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Events' },
        update: false
      }
    }
  },
  events_card: {
    Component: EventsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['events'],
      allOptionsText: 'All Events'
    },
    icon: events,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Events' },
        update: false
      }
    }
  },
  vessel_documents_table: {
    Component: VesselDocuments,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['document_manager_vessels_list'],
      allOptionsText: 'All Vessel Documents'
    },
    preferencesInfoText: `You haven’t selected any vessel document filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: vesselDocument,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Vessel Documents' }
        // update: false
      }
    }
  },
  vessel_documents_card: {
    Component: VesselDocumentsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['document_manager_vessels_list'],
      allOptionsText: 'All Vessel Documents'
    },
    icon: vesselDocument,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Vessel Documents' },
        update: false
      }
    }
  },
  crew_documents_table: {
    Component: CrewDocuments,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['document_manager_crew_documents'],
      allOptionsText: 'All Crew Documents',
      update: false
    },
    preferencesInfoText: `You haven’t selected any crew documents filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: documentManagerIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Crew Documents' },
        update: false
      }
    }
  },
  crew_documents_card: {
    Component: CrewDocumentsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['document_manager_crew_documents'],
      allOptionsText: 'All Crew Documents'
    },
    icon: documentManagerIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Crew Documents' },
        update: false
      }
    }
  },
  findings_table: {
    Component: Findings,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['reports_findings'],
      allOptionsText: 'All Findings'
    },
    preferencesInfoText: `You haven’t selected any findings filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: findings,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Findings' },
        update: false
      }
    }
  },
  findings_card: {
    Component: FindingsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['reports_findings'],
      allOptionsText: 'All Findings'
    },
    icon: findings,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Findings' },
        update: false
      }
    }
  },
  purchasing_approvals_table: {
    Component: PurchasingApprovals,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['purchasing_requisitions_approvals'],
      allOptionsText: 'All approvals',
      update: false
    },
    preferencesInfoText: `You haven’t selected any purchasing approval filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: purchasingApproval,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All approvals' },
        update: false
      }
    }
  },
  purchasing_approvals_card: {
    Component: PurchasingApprovalsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['purchasing_requisitions_approvals'],
      allOptionsText: 'All approvals'
    },
    icon: purchasingApproval,
    previewCardIcon: formsObligations,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All approvals' },
        update: false
      }
    }
  },
  flag_dispensation_table: {
    Component: FlagDispenations,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['jobs_flag_extensions_dispensations_list'],
      allOptionsText: 'All Flag Dispensations'
    },
    preferencesInfoText: `You haven’t selected any flag dispensation filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: flag,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Flag dispensations' },
        update: false
      }
    }
  },
  flag_dispensation_card: {
    Component: FlagDispensationsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['jobs_flag_extensions_dispensations_list'],
      allOptionsText: 'All Flag Dispensations'
    },
    icon: flag,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Flag Dispensation' },
        update: false
      }
    }
  },
  budget: {
    Component: Budget,
    PreferencesComponent: BudgetPreferences,
    preferencesComponentProps: {
      tableLabels: ['accounting_budget'],
      allOptionsText: 'All budgets'
    },
    preferencesInfoText: `You haven’t selected any budget filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: budget,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All budgets' },
        category: ''
      }
    }
  },
  companies_table: {
    Component: Companies,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['companies'],
      allOptionsText: 'All Companies'
    },
    preferencesInfoText: `You haven’t selected any company filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: companyIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Companies' },
        update: false
      }
    }
  },
  companies_card: {
    Component: CompaniesCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['companies'],
      allOptionsText: 'All Companies'
    },
    icon: companyIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Companies' },
        update: false
      }
    }
  },
  persons_table: {
    Component: Persons,
    PreferencesComponent: PersonsPreferences,
    preferencesComponentProps: {
      tableLabels: ['persons'],
      allOptionsText: 'All Persons'
    },
    preferencesInfoText: `You haven’t selected any person filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: personIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Persons' },
        update: false
      }
    }
  },
  persons_card: {
    Component: PersonsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['persons'],
      allOptionsText: 'All Persons'
    },
    icon: personIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Persons' },
        update: false
      }
    }
  },
  spare_parts_inventory_table: {
    Component: InventorySpareParts,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['inventory_spare_parts'],
      allOptionsText: 'All Inventory Spare Parts'
    },
    preferencesInfoText: `You haven’t selected any inventory spare parts inventory filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: inventoryIcon,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Inventory Spare Parts' },
        update: false
      }
    }
  },
  spare_parts_inventory_survey_due_dates_table: {
    Component: SparePartsInventoryDueDates,
    PreferencesComponent: RunningHoursReportsPreferences,
    preferencesInfoText: `You haven’t selected any Vessel or Vessel Group yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: inventoryIcon,
    defaults: {
      preferences: {
        ...defaultSettings,
        vessel_id: null
      }
    }
  },
  spare_parts_inventory_card: {
    Component: InventorySparePartsCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['inventory_spare_parts'],
      allOptionsText: 'All Inventory Spare Parts'
    },
    icon: inventoryIcon,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Inventory Spare Parts' },
        update: false
      }
    }
  },
  invoices_table: {
    Component: Invoices,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['accounting_invoices'],
      allOptionsText: 'All invoices'
    },
    preferencesInfoText: `You haven’t selected any invoice filter yet.<br/>Click on the button below & choose the filter that suits you.`,
    icon: invoices,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Invoices' },
        update: false
      }
    }
  },
  invoices_card: {
    Component: InvoicesCardWidget,
    PreferencesComponent: WidgetSavedTableSettings,
    preferencesComponentProps: {
      tableLabels: ['accounting_invoices'],
      allOptionsText: 'All Invoices'
    },
    icon: invoices,
    size: cardSizes.sm,
    defaults: {
      preferences: {
        ...defaultSettings,
        filter: { id: 'all', label: 'All Invoices' },
        update: false
      }
    }
  },
  overdue_jobs_table: {
    Component: OverdueJobs,
    icon: clock
  }
};

export default widgetsDetails;
