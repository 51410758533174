import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createInvoice, editInvoice, getInvoice } from '@/api/invoices/api';

export const createAccountingInvoice = params => dispatch => {
  dispatch({ type: TYPES.CREATE_ACCOUNTING_INVOICE.START, payload: { params } });

  return createInvoice(params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_ACCOUNTING_INVOICE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_ACCOUNTING_INVOICE.ERROR, payload: error });

      throw error;
    });
};

export const getAccountingInvoice = params => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNTING_INVOICE.START, payload: { params } });

  return getInvoice(params)
    .then(response => {
      dispatch({ type: TYPES.GET_ACCOUNTING_INVOICE.SUCCESS, payload: response });
      return response;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_ACCOUNTING_INVOICE.ERROR, payload: error });

      throw error;
    });
};

export const updateAccountingInvoice = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_ACCOUNTING_INVOICE.START, payload: { params } });

  return editInvoice(params)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_ACCOUNTING_INVOICE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_ACCOUNTING_INVOICE.ERROR, payload: error });

      throw error;
    });
};

export const deleteAccountingInvoice = params => dispatch => {
  dispatch({ type: TYPES.DELETE_ACCOUNTING_INVOICE.START, payload: { params } });

  return deleteRequest(`/invoices/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.DELETE_ACCOUNTING_INVOICE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_ACCOUNTING_INVOICE.ERROR, payload: error });

      throw error;
    });
};

export const updateAccountingInvoiceReviewedStatus = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_ACCOUNTING_INVOICE_REVIEWED_STATUS.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`invoices/${id}/review`, rest)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_ACCOUNTING_INVOICE_REVIEWED_STATUS.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Status updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_ACCOUNTING_INVOICE_REVIEWED_STATUS.ERROR, payload: error });

      throw error;
    });
};

export const sendInvoiceToAccounting = createAsyncThunk(
  'SEND_INVOICE_TO_ACCOUNTING',
  async ({ id, ...rest }, { rejectWithValue }) => {
    try {
      const res = await post(`/integrations/accounting/invoices/${id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const shouldResetInvoiceReviewProcess = createAsyncThunk(
  'SHOULD_RESET_INVOICE_REVIEW_PROCESS',
  async ({ id, ...rest }, { rejectWithValue }) => {
    try {
      const res = await put(`/invoices/${id}/review-process-reset-check`, rest);

      return res.data?.review_process_reset;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getInvoicesTotals = createAsyncThunk(
  'GET_INVOICES_TOTALS',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/invoices-totals`, params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
