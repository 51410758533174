import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '@/store/hooks';

import { useForm } from 'common/utils/hooks/useForm';

import ShadowBox from 'common/components/general/ShadowBox';
import {
  selectActiveRequisitionCode,
  selectActiveRequisitionID,
  isPurchasingCommunicationDrawerOpen
} from 'common/components/purchasing/requisition/store/selectors';
import config from './config';
import Drawer, { DrawerHeader, FormBody, FormDrawer } from 'common/components/drawer';
import SentEmails from './SentEmails';
import Actions from './Actions';
import EmailForm from './EmailForm';

import { fetchListOptions } from 'store/lists/actions';
import {
  getPurchasingRequisitionEmails,
  getPurchasingRequisitionSuppliers
} from 'common/components/purchasing/requisition/store/actions';
import { togglePurchasingCommunicationsDrawer } from 'common/components/purchasing/requisition/store/actions';
import LinkEmails from './link-emails';

interface CommunicationsDrawerProps {
  hideEmailForm: boolean;
}

const CommunicationsDrawer: React.FC<CommunicationsDrawerProps> = ({ hideEmailForm }) => {
  const { formState, collectValues, resetForm, hasErrors } = useForm(config);

  const dispatch = useAppDispatch();

  const activeID = useAppSelector(selectActiveRequisitionID);
  const activeCode = useAppSelector(selectActiveRequisitionCode);

  const isOpen = useAppSelector(isPurchasingCommunicationDrawerOpen);

  const onInit = useCallback(async () => {
    const params = {
      filters: [
        {
          name: 'requisition_id',
          operation: 'oneOf',
          value: [activeID]
        }
      ]
    };
    dispatch(fetchListOptions('email-templates'));
    await dispatch(getPurchasingRequisitionSuppliers({ id: activeID }));
    await dispatch(getPurchasingRequisitionEmails(params));
    resetForm();
  }, [activeID, dispatch, resetForm]);

  useEffect(() => {
    if (isOpen) {
      onInit();
    }
  }, [isOpen, onInit]);

  return (
    <Drawer
      className="purchasing-communications"
      isOpen={isOpen}
      close={() => dispatch(togglePurchasingCommunicationsDrawer(false))}
      size="md"
    >
      <DrawerHeader>Communication for {activeCode}</DrawerHeader>

      {isOpen ? (
        <FormDrawer>
          <FormBody className={`d-flex flex-column px-0 overflow-hidden`}>
            <div className="overflow-auto gray-scrollbar pt-1 position-relative flex-1">
              <LinkEmails />
              <div className="flex-1 px-8 overflow-hidden mb-5">
                <SentEmails />
              </div>
              <div className="purchasing-communications__overflow-gradient mt-4"></div>
            </div>

            {hideEmailForm ? null : (
              <ShadowBox
                className="purchasing-communications__auto-email-container mt-auto px-8 py-2"
                color="light-1"
                flat
              >
                <EmailForm formState={formState} />
                <Actions
                  formState={formState}
                  collectValues={collectValues}
                  hasErrors={hasErrors}
                  resetForm={resetForm}
                />
              </ShadowBox>
            )}
          </FormBody>
        </FormDrawer>
      ) : null}
    </Drawer>
  );
};

export default CommunicationsDrawer;
