import TYPES from './types';
import * as REDUX_PERSIST_TYPES from 'redux-persist/lib/constants';
import _sortBy from 'lodash/sortBy';
import _mapValues from 'lodash/mapValues';
import paths from '@/routing/routes/_paths';
import { constructSelectedAssignment } from '../helpers';
import { getSystemSubSystems } from './actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getTreeOfSearchedItems, getSubItems } from 'common/components/tree-layout/utils/helpers';
import { Vessel } from '@/common/types/vessel';
import { TablePaging } from '@/common/types/front-entities/table';
import {
  VesselSystem,
  VesselSystemAssignment,
  VesselSystemBase,
  VesselSystemGroup
} from '@/common/types/vessel-systems';
import { MaintenanceJobPrototype, SparePartBase } from '@/common/types/pms';

type GroupId = number;
type SystemId = number;

type OpenSystemsType = { [systemId: SystemId]: boolean };
type OpenGroupsType = { [groupId: GroupId]: boolean };

type GroupsType = { [groupId: GroupId]: VesselSystemGroup };

type GroupsInSearchType = {
  [groupId: GroupId]: boolean;
};

type SubSystemsType = { [systemId: SystemId]: SystemId[] };

type SystemsType = { [systemId: SystemId]: VesselSystemBase | undefined };

type InitialState = {
  selectedVesselFilter: Vessel | null;
  groupsLoading: boolean;
  singleGroupIsLoading: boolean;
  groups: GroupsType;
  rootGroups: GroupId[];
  subGroups: { [groupId: GroupId]: VesselSystemGroup | undefined };
  normalizedSubGroups: { [groupId: GroupId]: GroupId[] };
  activeGroup: 'all' | number;
  openGroups: OpenGroupsType;
  openGroupsButtonIsInExpandedState: boolean;
  groupsSearch: string;
  systemFormType: string | null;
  systemFormParentId: SystemId | null;
  selectedSystem: number | null;
  currentSystem: VesselSystem | null;
  currentSystemLoading: false;
  systemsLoading: boolean;
  systemsSearch: string;
  systems: SystemsType;
  rootSystems: SystemId[];
  subSystems: SubSystemsType;
  openSystems: OpenSystemsType;
  openSystemsButtonIsInExpandedState: boolean;
  systemsPaging: TablePaging;
  activeSystemGroupState: { type: string | null; data: VesselSystemGroup | null };
  currentFormSystem: SystemId | null;
  sparePartsLoading: boolean;
  sparePartsList: SparePartBase[];
  selectedSparePart: SparePartBase | null;
  selectedTab: string | null;
  currentAssignments: VesselSystemAssignment[];
  currentSubAssignments: VesselSystemAssignment[];
  selectedAssignment: VesselSystemAssignment | null;
  selectedSubAssignment: VesselSystemAssignment | null;
  assignmentsLoading: boolean;
  subAssignmentsLoading: boolean;
  jobs: MaintenanceJobPrototype[];
  jobsLoading: boolean;
  currentJob: { activeTabs: any };
  pmsLibraryIsUsedIn: 'categories' | 'requisition' | 'setup';
  isCreatingSubSystem: boolean;
  groupsCollapsed: boolean;
  systemCollapsed: boolean;
  groupsInSearch: GroupsInSearchType;
};

export const INITIAL_STATE: InitialState = {
  selectedVesselFilter: null,
  groupsLoading: false,
  singleGroupIsLoading: false,
  groups: {},
  rootGroups: [],
  subGroups: {},
  normalizedSubGroups: {},
  activeGroup: 'all',
  openGroups: {},
  openGroupsButtonIsInExpandedState: false,
  groupsSearch: '',
  systemFormType: null,
  systemFormParentId: null,
  selectedSystem: null,
  currentSystem: null,
  currentSystemLoading: false,
  systemsLoading: false,
  systemsSearch: '',
  systems: {},
  rootSystems: [],
  subSystems: {},
  openSystems: {},
  systemsPaging: {
    per_page: 100,
    current_page: 1,
    last_page: 1,
    total: 1
  },
  activeSystemGroupState: { type: null, data: null },
  currentFormSystem: null,
  sparePartsLoading: false,
  sparePartsList: [],
  selectedSparePart: null,
  selectedTab: null,
  currentAssignments: [],
  currentSubAssignments: [],
  selectedAssignment: null,
  selectedSubAssignment: null,
  assignmentsLoading: false,
  subAssignmentsLoading: false,
  jobs: [],
  jobsLoading: false,
  currentJob: { activeTabs: {} },
  pmsLibraryIsUsedIn: 'setup',
  openSystemsButtonIsInExpandedState: false,
  isCreatingSubSystem: false,
  groupsCollapsed: false,
  systemCollapsed: false,
  groupsInSearch: {}
};

const selectedSubSystems = (subAssignments, id) => {
  return subAssignments.filter(e => e.parent_id === id);
};

const updateGroupedEntriesCollapseState = (group, value) => {
  return _mapValues(group, () => value);
};

const filterGroups = (groups: GroupsType, groupsInSearch: GroupsInSearchType) => {
  return Object.keys(groups)
    .filter((key: string) => groupsInSearch[key])
    .reduce((res: GroupsType, key: string) => {
      res[key] = groups[key];
      return res;
    }, {});
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload, key, meta }) => {
  const reducerKey = key;

  switch (type) {
    case REDUX_PERSIST_TYPES.REHYDRATE:
      if (reducerKey === 'pms.setup' && payload) {
        return {
          ...state,
          groups: payload?.groups || {},
          rootGroups: payload?.rootGroups || [],
          subGroups: payload?.subGroups || {}
        };
      }

      return state;

    case TYPES.GET_MAINTENANCE_JOBS.START:
      return {
        ...state,
        jobsLoading: true
      };

    case TYPES.GET_MAINTENANCE_JOBS.SUCCESS:
      return {
        ...state,
        jobsLoading: false,
        jobs: payload
      };

    case TYPES.GET_MAINTENANCE_JOBS.ERROR:
      return {
        ...state,
        jobsLoading: false
      };

    case TYPES.SET_MAINTENANCE_JOB_ACTIVE_TABS: {
      const key = payload.key;
      const isActive = payload.isActive;

      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          activeTabs: { ...state.currentJob.activeTabs, [key]: isActive }
        }
      };
    }

    case TYPES.RESET_MAINTENANCE_JOB_ACTIVE_TABS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          activeTabs: {}
        }
      };

    case TYPES.UPDATE_ASSIGNMENT.SUCCESS:
      return {
        ...state,
        selectedAssignment: payload?.parent_id ? state.selectedAssignment : payload,
        selectedSubAssignment: payload?.parent_id ? payload : null
      };

    case `${TYPES.CREATE_SUBSYSTEM}/fulfilled`:
    case `${TYPES.CREATE_SYSTEM}/fulfilled`:
      return {
        ...state,
        selectedSystem: payload?.id,
        currentSystem: payload,
        selectedTab: null,
        openSystems: payload.parent_id
          ? { ...state.openSystems, [payload.parent_id]: true }
          : state.openSystems
      };

    case TYPES.SET_SELECTED_SUB_ASSIGNMENT:
      return {
        ...state,
        selectedSubAssignment: payload
      };

    case TYPES.SET_SELECTED_ASSIGNMENT: {
      const filterSubSystems =
        state.currentSubAssignments?.length && payload
          ? selectedSubSystems(state.currentSubAssignments, payload?.id)
          : null;

      return {
        ...state,
        selectedSubAssignment: filterSubSystems?.length ? filterSubSystems[0] : null,
        selectedAssignment: payload
      };
    }

    case TYPES.ADD_VESSEL_ASSIGNMENT.SUCCESS:
      return {
        ...state
      };

    case TYPES.GET_VESSEL_ASSIGNMENTS.SUCCESS: {
      const shouldLeaveAssignment = payload.leaveSelectedAssignment;
      const responseHasAssignments = payload.data && payload.data[0];
      const selectedAssignment = constructSelectedAssignment(
        shouldLeaveAssignment,
        responseHasAssignments,
        state.selectedAssignment,
        payload
      );

      return {
        ...state,
        currentAssignments: payload.data,
        assignmentsLoading: false,
        selectedAssignment: selectedAssignment
      };
    }

    case TYPES.GET_VESSEL_ASSIGNMENTS.START:
      return {
        ...state,
        assignmentsLoading: true
      };

    case TYPES.GET_VESSEL_SUB_ASSIGNMENTS.START:
      return {
        ...state,
        subAssignmentsLoading: true
      };

    case TYPES.GET_VESSEL_ASSIGNMENTS.ERROR:
    case TYPES.GET_VESSEL_SUB_ASSIGNMENTS.ERROR:
      return {
        ...state,
        assignmentsLoading: false,
        subAssignmentsLoading: false
      };

    case TYPES.GET_VESSEL_SUB_ASSIGNMENTS.SUCCESS: {
      const shouldLeaveSubAssignment = payload.leaveSelectedAssignment;
      const responseHasSubAssignments = payload.data && payload.data[0];
      const selectedSubAssignment = constructSelectedAssignment(
        shouldLeaveSubAssignment,
        responseHasSubAssignments,
        state.selectedSubAssignment,
        payload
      );

      return {
        ...state,
        currentSubAssignments: payload.data,
        subAssignmentsLoading: false,
        selectedSubAssignment: selectedSubAssignment
      };
    }

    case TYPES.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload,
        selectedAssignment: null,
        currentAssignments: [],
        selectedSubAssignment: null,
        currentSubAssignments: []
      };

    case TYPES.GET_SINGLE_SYSTEM.START:
      return {
        ...state,
        currentSystemLoading: true
      };

    case TYPES.GET_SINGLE_SYSTEM.SUCCESS:
      return {
        ...state,
        currentSystem: payload,
        currentSystemLoading: false
      };

    case TYPES.GET_SINGLE_SYSTEM.ERROR:
      return {
        ...state,
        currentSystemLoading: false
      };

    case TYPES.CREATE_SPARE_PART.SUCCESS: {
      return {
        ...state,
        systems: {
          ...state.systems,
          [payload.systemId]: {
            ...state.systems[payload.systemId],
            spare_parts_count: (state.systems[payload.systemId]?.spare_parts_count || 0) + 1
          }
        }
      };
    }

    case TYPES.DELETE_SPARE_PART.SUCCESS: {
      return {
        ...state,
        systems: {
          ...state.systems,
          [payload.systemId]: {
            ...state.systems[payload.systemId],
            spare_parts_count: (state.systems[payload.systemId]?.spare_parts_count || 1) - 1
          }
        }
      };
    }

    case TYPES.CREATE_MAINTENANCE_JOB.SUCCESS: {
      return {
        ...state,
        systems: {
          ...state.systems,
          [payload.systemId]: {
            ...state.systems[payload.systemId],
            maintenance_job_prototypes_count:
              (state.systems[payload.systemId]?.maintenance_job_prototypes_count || 0) + 1
          }
        }
      };
    }

    case TYPES.DELETE_MAINTENANCE_JOB.SUCCESS: {
      return {
        ...state,
        systems: {
          ...state.systems,
          [payload.systemId]: {
            ...state.systems[payload.systemId],
            maintenance_job_prototypes_count:
              (state.systems[payload.systemId]?.maintenance_job_prototypes_count || 1) - 1
          }
        }
      };
    }

    case TYPES.GET_SPARE_PARTS.START:
      return {
        ...state,
        sparePartsLoading: true
      };

    case TYPES.GET_SPARE_PARTS.SUCCESS:
      return {
        ...state,
        sparePartsLoading: false,
        sparePartsList: payload
      };

    case TYPES.GET_SPARE_PARTS.ERROR:
      return {
        ...state,
        sparePartsLoading: false
      };

    case TYPES.SET_SELECTED_SPARE_PART:
      return {
        ...state,
        selectedSparePart: payload
      };

    case `${TYPES.UPDATE_SYSTEM}/fulfilled`:
      return {
        ...state,
        systems: {
          ...state.systems,
          [payload.id]: { ...state.systems[payload.id], ...payload }
        }
      };

    case TYPES.SET_CURRENT_FORM_SYSTEM:
      return {
        ...state,
        currentFormSystem: payload
      };

    case TYPES.TOGGLE_ALL_SYSTEMS: {
      const updatedOpenSystems = updateGroupedEntriesCollapseState(state.subSystems, payload);

      return {
        ...state,
        openSystems: updatedOpenSystems,
        openSystemsButtonIsInExpandedState: payload
      };
    }

    case TYPES.TOGGLE_SYSTEM:
      return {
        ...state,
        openSystems: {
          ...state.openSystems,
          [payload]: state.openSystems[payload] ? false : true
        }
      };

    case `${TYPES.GET_PMS_LIBRARY_VESSEL_SYSTEMS}/pending`:
    case `${TYPES.GET_PURCHASING_REQUISITION_VESSEL_SYSTEMS}/pending`:
      return {
        ...state,
        systemsPaging: { ...state.systemsPaging, ...meta.arg.paging },
        systemsLoading: meta.arg?.hideLoading ? false : true
      };

    case `${TYPES.GET_PMS_LIBRARY_VESSEL_SYSTEMS}/fulfilled`:
    case `${TYPES.GET_PURCHASING_REQUISITION_VESSEL_SYSTEMS}/fulfilled`: {
      const { data, meta } = payload.response;
      const { current_page, last_page, total } = meta;

      let rootSystems: SystemId[] = [];
      let subSystems: SubSystemsType = {};
      let systems: SystemsType = {};
      const openSystems = { ...state.openSystems };

      if (current_page !== 1) {
        rootSystems = [...state.rootSystems];
        subSystems = { ...state.subSystems };
        systems = { ...state.systems };
      }

      data.forEach((el: VesselSystem) => {
        if (!el.parent_id) {
          rootSystems.push(el.id);
        } else {
          if (!subSystems[el.parent_id]) subSystems[el.parent_id] = [];

          subSystems[el.parent_id].push(el.id);
        }

        systems[el.id] = el;

        if (payload.hasSearch || state.openSystemsButtonIsInExpandedState)
          openSystems[el.id] = true;
      });

      data
        .filter((el: VesselSystem) => el.parent_id)
        .forEach((el: VesselSystem) => {
          subSystems[el.parent_id] = _sortBy(subSystems[el.parent_id], [
            id => systems[id].description
          ]);
        });

      return {
        ...state,
        systemsLoading: false,
        systems,
        rootSystems,
        subSystems,
        systemsPaging: { ...state.systemsPaging, current_page, last_page, total },
        openSystems: openSystems,
        systemsSearch: payload.search
      };
    }

    case `${TYPES.GET_PMS_LIBRARY_VESSEL_SYSTEMS}/rejected`:
    case `${TYPES.GET_PURCHASING_REQUISITION_VESSEL_SYSTEMS}/rejected`:
      return {
        ...state,
        systemsLoading: false
      };

    case `${getSystemSubSystems.fulfilled}`:
      return {
        ...state,
        subSystems: {
          ...state.subSystems,
          [payload.systemId]: payload.data.map((subSystem: VesselSystem) => subSystem.id)
        }
      };

    case TYPES.SET_SELECTED_SYSTEM: {
      const sys = state.systems[payload];
      const newOpened =
        sys?.parent_id && !state.openSystems[sys.parent_id]
          ? {
              ...state.openSystems,
              [sys.parent_id]: true
            }
          : state.openSystems;
      return {
        ...state,
        selectedSystem: payload,
        openSystems: newOpened
      };
    }

    case TYPES.SET_SYSTEM_FORM_PARENT_ID:
      return {
        ...state,
        systemFormParentId: payload
      };

    case TYPES.SET_SYSTEM_FORM_TYPE:
      return {
        ...state,
        systemFormType: payload
      };

    case TYPES.SET_IS_CREATING_SUBSYSTEM:
      return {
        ...state,
        isCreatingSubSystem: payload
      };

    case TYPES.TOGGLE_ALL_GROUPS: {
      const updatedOpenGroups = updateGroupedEntriesCollapseState(state.groups, payload);

      return {
        ...state,
        openGroups: updatedOpenGroups,
        openGroupsButtonIsInExpandedState: payload
      };
    }

    case TYPES.TOGGLE_GROUP: {
      const newOpenGroups = {
        ...state.openGroups,
        [payload]: state.openGroups[payload] ? false : true
      };
      return {
        ...state,
        openGroups: newOpenGroups
      };
    }

    case TYPES.SET_ACTIVE_GROUP:
      return {
        ...state,
        activeGroup: payload
      };

    case TYPES.GET_SYSTEM_GROUPS.START:
      return {
        ...state,
        groupsLoading: true
      };

    case TYPES.GET_SYSTEM_GROUPS.SUCCESS: {
      const rootGroups = [];
      const subGroups = {};
      const groups = {};
      const openGroups = { ...state.openGroups };
      const normalizedSubGroups = getSubItems(payload.data);

      if (payload.hasSearch) {
        const groupsInSearch = getTreeOfSearchedItems(
          state.groups,
          normalizedSubGroups,
          payload.data
        );

        return {
          ...state,
          groupsLoading: false,
          groups: filterGroups(state.groups, groupsInSearch),
          subGroups: filterGroups(state.subGroups, groupsInSearch),
          groupsSearch: payload.search
        };
      }

      payload.data.forEach(el => {
        if (!el.parent_id) {
          rootGroups.push(el.id);
        } else {
          subGroups[el.id] = el;
        }
        groups[el.id] = el;

        if (payload.hasSearch || state.openGroupsButtonIsInExpandedState) openGroups[el.id] = true;
      });

      return {
        ...state,
        groupsLoading: false,
        groupsInSearch: {},
        groupsSearch: '',
        groups,
        normalizedSubGroups,
        rootGroups,
        subGroups,
        openGroups
      };
    }

    case TYPES.GET_SYSTEM_GROUPS.ERROR:
      return {
        ...state,
        groupsLoading: false
      };

    case TYPES.UPDATE_SYSTEM_GROUP.SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.id]: { ...state.groups[payload.id], ...payload }
        }
      };

    case TYPES.GET_SYSTEM_GROUP.START:
      return {
        ...state,
        singleGroupIsLoading: true
      };

    case TYPES.GET_SYSTEM_GROUP.SUCCESS:
      if (payload?.data?.id)
        return {
          ...state,
          singleGroupIsLoading: false,
          groups: {
            ...state.groups,
            [payload.data.id]: { ...state.groups[payload.data.id], ...payload.data }
          }
        };
      else
        return {
          ...state,
          singleGroupIsLoading: false
        };

    case TYPES.GET_SYSTEM_GROUP.ERROR:
      return {
        ...state,
        singleGroupIsLoading: false
      };

    case TYPES.SET_ACTIVE_SYSTEM_GROUP_STATE:
      return {
        ...state,
        activeSystemGroupState: payload
      };

    case TYPES.SET_GROUPS_COLLAPSED:
      return {
        ...state,
        groupsCollapsed: payload
      };

    case TYPES.SET_SYSTEM_COLLAPSED:
      return {
        ...state,
        systemCollapsed: payload
      };

    case TYPES.SET_SELECTED_VESSEL_FILTER:
      return {
        ...state,
        selectedVesselFilter: payload
      };

    case TYPES.SET_PMS_LIBRARY_IS_USED_IN:
      return {
        ...INITIAL_STATE,
        pmsLibraryIsUsedIn: payload || 'setup'
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.includes(`${paths.pms_library}`)) {
        return INITIAL_STATE;
      }
      return state;

    default:
      return state;
  }
};

export default reducer;
