import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';

import rolodex from 'assets/svg/sidebar/rolodex.svg';
import department from 'common/assets/svg/common/department.svg';
import access from 'assets/svg/sidebar/permissions.svg';
import jobsConfiguration from 'assets/svg/sidebar/jobs_configuration.svg';
import templates from 'common/assets/svg/common/templates.svg';
import jobsTriggers from 'common/assets/svg/jobs/jobs_triggers.svg';
import jobsStatus from 'common/assets/svg/jobs/jobs_status.svg';
import fieldsSetup from 'assets/svg/sidebar/fields_setup.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import vessels from 'assets/svg/sidebar/vessels.svg';
import hsqe from 'common/assets/svg/common/hsqe.svg';
import documents from 'common/assets/svg/common/documents.svg';
import captainReportTemplates from 'common/assets/svg/common/templates.svg';
import drills from 'assets/svg/sidebar/drills.svg';
import trainings from 'common/assets/svg/crew/training.svg';
import digitalForms from 'assets/svg/sidebar/digital-forms.svg';
import formStatus from 'common/assets/svg/common/forms_status.svg';
import formPlanning from 'common/assets/svg/forms/forms-planning.svg';
import eventStatus from 'assets/svg/sidebar/event_status.svg';
import riskAssessment from 'common/assets/svg/common/risk_assessment.svg';
import eventTemplate from 'assets/svg/sidebar/event_templates.svg';
import payroll from 'assets/svg/sidebar/payroll.svg';
import rank from 'common/assets/svg/crew/rank.svg';
import categories from 'common/assets/svg/common/categories.svg';
import company from 'common/assets/svg/common/company.svg';
import syncs from 'assets/svg/common/syncs.svg';
import formFields from 'assets/svg/sidebar/digital-form-fields.svg';
import forms from 'common/assets/svg/common/forms.svg';
import star from 'common/assets/svg/common/star.svg';
import role from 'common/assets/svg/common/roles.svg';
import add from 'common/assets/svg/actions/add-action.svg';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import crew from 'common/assets/svg/common/crew.svg';
import rootCauseAnalysis from 'assets/svg/sidebar/market_indexes.svg';
import up from 'common/assets/svg/crew/airplane-up.svg';
import events from 'common/assets/svg/common/events.svg';
import vetting from 'common/assets/svg/common/vetting.svg';
import clock from 'common/assets/svg/common/reminder.svg';
import claimExpensesIcon from 'common/assets/svg/common/claims_expenses.svg';
import matrix from 'common/assets/svg/common/matrix.svg';
import newsAnnouncementsIcon from 'common/assets/svg/common/news-announcements.svg';
import underwaterServiceTypesIcon from 'assets/svg/sidebar/underwater-service-types.svg';
import medicalCasesIcon from 'common/assets/svg/common/medical-cases.svg';
import lubOilAnalysis from 'common/assets/svg/events/lub-oil-analysis.svg';
import search from 'common/assets/svg/common/search.svg';
import skillsExperience from 'common/assets/svg/crew/skills-experience.svg';
import termination from 'common/assets/svg/crew/termination.svg';
import holidays from 'assets/svg/sidebar/holidays.svg';
import policies from 'common/assets/svg/common/policies.svg';
import formObligations from 'common/assets/svg/common/forms-obligations.svg';
import reviewProcesses from 'common/assets/svg/common/review-process.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import notificationPrefs from 'assets/svg/common/notifications-empty.svg';
import findings from 'common/assets/svg/common/findings.svg';
import budget from 'common/assets/svg/accounting/budget.svg';
import budgetScenarios from 'common/assets/svg/accounting/budget-scenarios.svg';
import reports from 'common/assets/svg/common/reports.svg';
import voyages from '@/common/assets/svg/common/voyages.svg';
import daItems from '@/common/assets/svg/voyages/da-items.svg';
import offHire from '@/assets/svg/common/dollar.svg';
import accountingContracts from '@/common/assets/svg/common/accounting-contracts.svg';

const items = [
  {
    icon: accounting,
    name: 'Accounting',
    label: 'accounting',
    submenu: [
      {
        label: 'accounting_ledger_cards_settings',
        icon: accounting,
        path: paths.accounting_ledger_cards_settings,
        name: 'Ledger Cards',
        permissions: [permissions.office.settings.accounting.accounting_coa.view]
      },
      {
        path: `${paths.accounting_budget_settings}`,
        label: 'accounting_budget',
        icon: budget,
        name: 'Budget',
        permissions: [permissions.office.settings.accounting.budget.view]
      },
      {
        label: 'accounting_budget_scenarios_settings',
        icon: budgetScenarios,
        path: paths.accounting_budget_scenarios_settings,
        name: 'Budget Scenarios',
        permissions: [permissions.office.settings.accounting.budget_scenarios.view]
      },
      {
        label: 'accounting_coa_groups_settings',
        icon: categories,
        path: paths.accounting_coa_groups_settings,
        name: 'CoA Groups',
        permissions: [permissions.office.settings.accounting.accounting_coa_groups.view]
      },
      {
        label: 'accounting_companies_settings',
        icon: company,
        path: paths.accounting_companies_settings,
        name: 'Companies',
        permissions: [permissions.office.settings.accounting.companies.view]
      },
      {
        label: 'accounting_contract_types_settings',
        icon: accountingContracts,
        path: paths.accounting_contract_types_settings,
        name: 'Contract Types',
        permissions: [permissions.office.settings.accounting.contract_types.view]
      },
      {
        label: 'mga_items_settings',
        icon: categories,
        path: paths.mga_items_settings,
        name: 'Groups & Items for MGA',
        permissions: [permissions.office.settings.accounting.groups_and_items_for_mga.view]
      },
      {
        label: 'mga_action_types_settings',
        icon: add,
        path: paths.mga_action_types_settings,
        name: 'MGA Action Types',
        permissions: [permissions.office.settings.accounting.mga_action_types.view]
      },
      {
        label: 'mga_coa_settings',
        icon: crew,
        path: paths.mga_coa_settings,
        name: 'MGA CoA',
        permissions: [permissions.office.settings.accounting.mga_coa.view]
      },
      {
        label: 'budgeting_report_setup',
        icon: reports,
        path: paths.budgeting_report_setup,
        name: 'Report Setup',
        permissions: [permissions.office.settings.accounting.budgeting_reports.view]
      },
      {
        icon: claimExpensesIcon,
        path: paths.accounting_medical_expenses_settings,
        name: 'Medical Expenses',
        label: 'medical_expenses',
        permissions: [permissions.office.settings.accounting.medical_expenses.view]
      }
    ]
  },
  {
    icon: purchasing,
    path: paths.case_tags,
    name: 'Case Tags',
    label: 'case_tags',
    permissions: [permissions.office.settings.case_tags.view]
  },
  {
    icon: company,
    path: paths.company_types,
    name: 'Company Types',
    label: 'company_types',
    permissions: [permissions.office.settings.company_types.view]
  },
  {
    label: 'captain_report_templates',
    icon: captainReportTemplates,
    path: `${paths.captain_report_templates}`,
    name: 'Cpt Report Templates',
    permissions: [permissions.office.settings.captain_report_templates.view]
  },
  {
    icon: crewPlanning,
    name: 'Crew',
    label: 'crew',
    submenu: [
      {
        icon: matrix,
        path: paths.crew_matrices,
        name: 'Crew Matrix',
        label: 'crew_matrices',
        permissions: [permissions.office.settings.crew.crew_matrix.view]
      },
      {
        icon: documents,
        path: paths.crew_document_types,
        name: 'Documents',
        label: 'crew_document_types',
        permissions: [permissions.office.settings.crew.documents.view]
      },
      {
        icon: categories,
        path: paths.crew_evaluation_criteria,
        name: 'Evaluation Criteria',
        label: 'crew_evaluation_criteria',
        permissions: [permissions.office.settings.crew.evaluation_criteria.view]
      },
      {
        icon: star,
        path: paths.crew_evaluation_obligation_templates,
        name: 'Evaluation Obligations',
        label: 'crew_evaluation_obligation_templates',
        permissions: [permissions.office.settings.crew.evaluation_obligation_templates.view]
      },
      {
        icon: templates,
        path: paths.crew_evaluation_templates,
        name: 'Evaluation Templates',
        label: 'crew_evaluation_template',
        permissions: [permissions.office.settings.crew.evaluation_templates.view]
      },
      {
        icon: holidays,
        path: paths.crew_holidays,
        name: 'Holidays',
        label: 'crew_holidays',
        permissions: [permissions.office.settings.crew.holidays.view]
      },
      {
        icon: rank,
        path: paths.crew_ranks,
        name: 'Ranks',
        label: 'crew_ranks',
        permissions: [permissions.office.settings.crew.ranks.view]
      },
      {
        icon: skillsExperience,
        path: paths.crew_skill_types,
        name: 'Skills',
        label: 'crew_skill_types',
        permissions: [permissions.office.settings.crew.skills.view]
      },
      {
        icon: termination,
        path: paths.crew_termination_reasons,
        name: 'Termination Reasons',
        label: 'crew_termination_reasons',
        permissions: [permissions.office.settings.crew.termination_reasons.view]
      },
      {
        icon: up,
        path: paths.crew_ticketing_types,
        name: 'Ticket Types',
        label: 'ticketing_types',
        permissions: [permissions.office.settings.crew.ticket_types.view]
      },
      {
        icon: trainings,
        path: paths.crew_trainings,
        name: 'Trainings',
        label: 'crew_trainings',
        permissions: [permissions.office.settings.crew.trainings.view]
      },
      {
        icon: payroll,
        path: paths.crew_wages,
        name: 'Wages',
        label: 'wages',
        permissions: [permissions.office.settings.crew.wages.view]
      }
    ]
  },
  {
    icon: events,
    name: 'Events',
    label: 'events',
    submenu: [
      {
        label: 'event_accounts_categories',
        icon: categories,
        path: `${paths.event_settings}/accounts-categories`,
        name: 'Accounts Categories',
        permissions: [permissions.office.settings.events.accounts_categories.view]
      },
      {
        label: 'event_audit_types',
        icon: search,
        path: `${paths.event_settings}/audits`,
        name: 'Audits / Inspections',
        permissions: [permissions.office.settings.events.audit_types.view]
      },
      {
        label: 'event_statuses',
        icon: eventStatus,
        path: `${paths.event_settings}/statuses`,
        name: 'Event Statuses',
        permissions: [permissions.office.settings.events.statuses.view]
      },
      {
        label: 'event_templates',
        icon: eventTemplate,
        path: `${paths.event_settings}/templates`,
        name: 'Event Templates',
        permissions: [permissions.office.settings.events.templates.view]
      },
      {
        label: 'event_lub_oil_analysis',
        icon: lubOilAnalysis,
        path: `${paths.event_lub_oil_analysis}`,
        name: 'Lub Oil Analysis',
        permissions: [permissions.office.settings.events.lub_oil_analysis.view]
      },
      {
        label: 'event_underwater_services_types',
        icon: underwaterServiceTypesIcon,
        path: `${paths.event_settings}/underwater-services-types`,
        name: 'Underwater Svc. Types',
        permissions: [permissions.office.settings.events.underwater_services_types.view]
      },
      {
        label: 'event_off_hire',
        icon: offHire,
        path: paths.event_off_hire,
        name: 'Off Hire',
        permissions: [permissions.office.settings.events.off_hire.view]
      },
      {
        label: 'event_medical_case_settings',
        icon: medicalCasesIcon,
        path: `${paths.event_medical_cases}`,
        name: 'Crew Medical Cases',
        permissions: [permissions.office.settings.events.medical_cases.view]
      }
    ]
  },
  {
    icon: forms,
    name: 'Forms',
    label: 'forms',
    submenu: [
      {
        label: 'form_fields',
        icon: formFields,
        path: `${paths.form_settings}/form-fields`,
        name: 'Digital Form Fields',
        permissions: [permissions.office.settings.forms.form_fields.view]
      },
      {
        label: 'form_obligations',
        icon: formObligations,
        path: `${paths.form_settings}/form-obligations`,
        name: 'Form Obligations',
        permissions: [permissions.office.settings.forms.form_obligations.view]
      },
      {
        label: 'form_planning',
        icon: formPlanning,
        path: `${paths.form_settings}/planning`,
        name: 'Form Planning',
        permissions: [permissions.office.settings.forms.planning.view]
      },
      {
        label: 'digital_forms',
        icon: digitalForms,
        path: `${paths.form_settings}/digital`,
        name: 'Form Setup',
        permissions: [permissions.office.settings.forms.forms_setup.view]
      },
      {
        label: 'form_statuses',
        icon: formStatus,
        path: `${paths.form_settings}/statuses`,
        name: 'Form Statuses',
        permissions: [permissions.office.settings.forms.statuses.view]
      }
    ]
  },
  {
    icon: hsqe,
    name: 'HSQE',
    label: 'hsqe',
    submenu: [
      {
        icon: drills,
        path: paths.drills,
        name: 'Drills',
        label: 'crew_drills',
        permissions: [permissions.office.settings.hsqe.drills.view]
      },
      {
        label: 'risk_assessment',
        icon: riskAssessment,
        path: `${paths.risk_assessment}`,
        name: 'Risk Assessment',
        permissions: [permissions.office.settings.hsqe.risk_assessment.view]
      },
      {
        label: 'root_cause_analysis',
        icon: rootCauseAnalysis,
        path: `${paths.root_cause_analysis}/categories`,
        name: 'Root Cause Analysis',
        permissions: [permissions.office.settings.hsqe.root_cause_analysis.view]
      },
      {
        icon: trainings,
        path: paths.hsqe_trainings,
        name: 'Trainings',
        label: 'crew_trainings',
        permissions: [permissions.office.settings.hsqe.trainings.view]
      },
      {
        icon: vetting,
        path: paths.vetting_setup,
        name: 'Vetting Setup',
        label: 'vetting_setup',
        permissions: [permissions.office.settings.hsqe.vetting_setup.view]
      }
    ]
  },
  {
    icon: jobsConfiguration,
    name: 'Jobs & Findings',
    label: 'jobs_and_findings',
    submenu: [
      {
        path: paths.jobs_restricted,
        icon: fieldsSetup,
        name: 'Fields Setup',
        permissions: [permissions.office.settings.jobs_configuration.fields_setup.view]
      },
      {
        path: paths.jobs_status,
        icon: jobsStatus,
        name: 'Job Statuses',
        label: 'jobs_statuses',
        permissions: [permissions.office.settings.jobs_configuration.statuses.view]
      },
      {
        path: paths.jobs_templates,
        icon: templates,
        name: 'Templates',
        permissions: [permissions.office.settings.jobs_configuration.templates.view]
      },
      {
        path: paths.jobs_triggers,
        icon: jobsTriggers,
        name: 'Triggers',
        permissions: [permissions.office.settings.jobs_configuration.triggers.view]
      },
      {
        icon: findings,
        path: paths.jobs_findings,
        name: 'Findings',
        permissions: [permissions.office.settings.jobs_configuration.findings.view]
      }
    ]
  },
  {
    label: 'news_announcements',
    icon: newsAnnouncementsIcon,
    path: `${paths.news_announcements}`,
    name: 'News/Announcements',
    permissions: [permissions.office.settings.news_and_announcements.view]
  },
  {
    label: 'onboard_syncs',
    icon: syncs,
    path: `${paths.onboard_syncs}`,
    name: 'Onboard Syncs',
    environments: ['development', 'staging', 'preproduction']
  },
  {
    icon: access,
    name: 'Application Access',
    label: 'application_access',
    submenu: [
      {
        icon: rolodex,
        path: paths.persons_permissions,
        name: 'Users',
        label: 'users',
        permissions: [permissions.office.settings.application_access.users.view]
      },
      {
        path: paths.departments,
        icon: department,
        name: 'Departments',
        label: 'departments',
        permissions: [permissions.office.settings.application_access.departments.view]
      },
      {
        path: paths.department_roles,
        icon: role,
        name: 'Roles',
        label: 'roles',
        permissions: [permissions.office.settings.application_access.roles.view]
      },
      {
        path: `${paths.policies}/core`,
        icon: policies,
        name: 'Policies',
        label: 'policies'
      },
      {
        path: paths.notification_preferences_administration,
        icon: notificationPrefs,
        name: 'Notification Prefs',
        label: 'notification_preferences_administration'
      }
    ]
  },
  {
    icon: vessels,
    name: 'Vessels',
    label: 'vessels',
    submenu: [
      {
        icon: vessels,
        path: paths.vessel_groups,
        name: 'Vessel Groups',
        label: 'vessel_groups',
        permissions: [permissions.office.settings.vessels.vessel_groups.view]
      },
      {
        icon: forms,
        path: paths.vessel_document_types,
        name: 'Documents',
        label: 'vessel_document_types',
        permissions: [permissions.office.settings.vessels.document_types.view]
      },
      {
        icon: clock,
        path: paths.vessel_document_reminders,
        name: 'Doc Reminders',
        label: 'vessels_documents',
        permissions: [permissions.office.settings.vessels.reminders_vessels_documents.view]
      }
    ]
  },
  {
    name: 'Review Process Setup',
    label: 'review_process',
    icon: reviewProcesses,
    path: `${paths.review_process}`,
    permissions: [permissions.office.settings.review_process_setup.view]
  },
  {
    icon: voyages,
    name: 'Voyages',
    label: 'voyages',
    permissions: [permissions.office.settings.voyages.view],
    submenu: [
      {
        icon: daItems,
        path: paths.port_da_items,
        name: 'DA Items',
        label: 'port_da_items',
        permissions: [permissions.office.settings.voyages.da_items.view]
      },
      {
        icon: claimExpensesIcon,
        path: paths.freight_hire_collection_items,
        name: 'Freight & Hire Items',
        label: 'freight_hire_collection_items',
        permissions: [permissions.office.settings.voyages.freight_collection_items.view]
      }
    ]
  }
];

export default items;
