import React, { useState, useEffect, useCallback } from 'react';
import OccuredDate from 'events/event/modules/module-components/crew-medical-case/form/compoments/DateOccured';
import DateReported from 'events/event/modules/module-components/crew-medical-case/form/compoments/DateReported';
import ArrivedAt from 'events/event/modules/module-components/crew-medical-case/form/compoments/ArrivedAt';
import ClosedAt from 'events/event/modules/module-components/crew-medical-case/form/compoments/ClosedAt';
import PreExistingRadios from 'events/event/modules/module-components/crew-medical-case/form/compoments/PreExistingRadios';
import Hospitalized from 'events/event/modules/module-components/crew-medical-case/form/compoments/Hospitalized';
import Telemedico from 'events/event/modules/module-components/crew-medical-case/form/compoments/Telemedico';
import Duty from 'events/event/modules/module-components/crew-medical-case/form/compoments/Duty';
import ClaimAmounts from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts';

import Checkbox from 'common/components/form/inputs/Checkbox';
import Textarea from 'common/components/form/inputs/Textarea';
import Input from 'common/components/form/inputs/Input';
import UploadFiles from 'common/components/form/inputs/upload-files';
import config from 'events/event/modules/module-components/crew-medical-case/form/config';
import { upload, download } from 'utils/api';
import { Row, Col } from 'reactstrap';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { useForm, useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectEventId } from 'events/store/events/selectors';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import { fetchListOptions } from 'store/lists/actions';
import PropTypes from 'prop-types';
import { getFormExpenses } from './helpers';
import PartyCompanySpecificTypeSelector from 'common/components/selectors/PartyCompanySpecificTypeSelector';
import { useDispatch } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import {
  selectCrewActiveMedicalCase,
  selectIsMedicalCaseLocked
} from 'events/store/event-modules/crew-medical-cases/selectors';
import {
  createCrewMedicalCase,
  getCrewMedicalCases,
  updateCrewMedicalCase
} from 'events/store/event-modules/crew-medical-cases/actions';
import { setActiveMedicalCase } from 'events/store/event-modules/crew-medical-cases/slice';
import CrewMedicalTypesSelector from 'events/event/modules/module-components/crew-medical-case/form/compoments/CrewMedicalTypeSelector';
import CrewMedicalCategoriesSelector from 'events/event/modules/module-components/crew-medical-case/form/compoments/CrewMedicalCategoriesSelector';
import Information from 'common/components/general/Information';

const Form = ({ isLoading, closeForm }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { formState, loadValues, collectValues } = useForm(config, {});
  const { fields, selectField, changeField } = useFormState(formState);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const pniStatusesOptions = useSelector(state =>
    selectListOptionsFromStore(state, 'crew-medical-case-pni-statuses')
  );
  const companyStatusesOptions = useSelector(state =>
    selectListOptionsFromStore(state, 'crew-medical-case-company-statuses')
  );
  const claimExpensesOptions = useSelector(state =>
    selectListOptionsFromStore(state, 'event-medical-expenses')
  );
  const activeMedicalCase = useSelector(selectCrewActiveMedicalCase);
  const eventId = useSelector(selectEventId);
  const isLocked = useSelector(selectIsMedicalCaseLocked);

  const handleSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      const {
        crew_member,
        medical_type,
        medical_category,
        attachments,
        location,
        local_pni_correspondent,
        company_status,
        pni_status,
        expenses,
        ocimf_system_categorization,
        ...rest
      } = values;

      setIsSubmitting(true);

      const params = {
        ...rest,
        attachments: attachments?.map(a => a.id) || [],
        crew_medical_case_type_id: medical_type.id,
        crew_medical_case_category_id: medical_category?.[0] || null,
        crew_member_id: crew_member.id,
        location: location || '',
        local_pni_correspondent_id: local_pni_correspondent.id || local_pni_correspondent || null,
        company_status_id: rest.paid_amount
          ? company_status?.id || companyStatusesOptions?.[0]?.id
          : null,
        pni_status_id: rest.covered_by_pni ? pni_status?.id || pniStatusesOptions?.[0]?.id : null,
        expenses: expenses.map(item => ({
          ...item,
          values: item.values.filter(valueItem => valueItem.value !== '')
        })),
        ocimf_system_categorization_id: ocimf_system_categorization
          ? ocimf_system_categorization?.id
          : null
      };

      if (activeMedicalCase?.id) {
        await dispatch(
          updateCrewMedicalCase({
            ...params,
            eventId: eventId,
            medicalCaseId: activeMedicalCase.id
          })
        ).unwrap();
      } else {
        await dispatch(createCrewMedicalCase({ ...params, eventId: eventId })).unwrap();
      }

      setIsSubmitting(false);
      await dispatch(getCrewMedicalCases({ eventId: eventId })).unwrap();
      closeForm();
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const load = useCallback(
    async activeMedicalCase => {
      loadValues({
        ...activeMedicalCase,
        medical_type: activeMedicalCase?.type,
        medical_category: activeMedicalCase?.category?.id ? [activeMedicalCase.category.id] : null,
        crew_member: activeMedicalCase?.crew_member || null,
        expenses: getFormExpenses(activeMedicalCase, claimExpensesOptions),
        local_pni_correspondent: activeMedicalCase.local_pni_correspondent || ''
      });
    },
    [loadValues, claimExpensesOptions]
  );

  const onCancelForm = () => {
    closeForm();
    dispatch(setActiveMedicalCase(null));
  };

  useEffect(() => {
    dispatch(fetchListOptions('event-medical-expenses'));
    dispatch(fetchListOptions('crew-medical-case-pni-statuses'));
    dispatch(fetchListOptions('crew-medical-case-company-statuses'));
    dispatch(fetchListOptions('crew-medical-case-ocimf-system-categorization'));
  }, [dispatch]);

  useEffect(() => {
    load(activeMedicalCase ?? {});
  }, [activeMedicalCase, load]);

  return (
    <div className="event-medical-crew-cases__content edit">
      <div className="event-medical-crew-cases__content-top  d-flex justify-content-between edit">
        <div className={`event-medical-crew-cases__content-left edit `}>
          <Row className="d-flex">
            <Col xs={3} className="d-flex pb-2">
              <Checkbox
                onChange={e => selectField('is_work_related')(e.target.checked)}
                className="mb-0 pb-0"
                {...fields.is_work_related}
              />
              <div className="fs-12 text-primary fw-medium d-flex align-items-center">
                This Case is Work Related
              </div>
            </Col>
            <Col xs={3} className="d-flex pb-2">
              <Checkbox
                onChange={e => selectField('is_crew_claim')(e.target.checked)}
                className="mb-0 pb-0"
                {...fields.is_crew_claim}
              />
              <div className="fs-12 text-primary fw-medium d-flex align-items-center">
                This Case is Crew Claim
              </div>
            </Col>
          </Row>
          <Row className="d-flex">
            <Col xs={5} className="d-flex pb-0">
              <AsyncSelector
                label="Seaman"
                onChange={e => selectField('crew_member')(e)}
                type="crew-members"
                placeholder="Select seaman"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.full_name}
                isClearable={true}
                className="w-100p mb-0"
                {...fields.crew_member}
              />
            </Col>
            <Col>
              <Input
                label="Medical case description"
                placeholder="Add description"
                onChange={e => changeField('description')(e)}
                {...fields.description}
              />
            </Col>
          </Row>

          <Row className="d-flex">
            <Col xs={5}>
              <CrewMedicalTypesSelector
                label="Type"
                placeholder="Select type"
                isMulti={false}
                filter={{ value: fields.medical_type.value }}
                error={fields.medical_type.error}
                onChange={({ value }) => selectField('medical_type')(value)}
              />
            </Col>
            <Col xs={3} className="ps-1">
              <CrewMedicalCategoriesSelector
                label="Category"
                placeholder="Select category"
                isMulti={false}
                filter={{ value: fields.medical_category.value }}
                error={fields.medical_category.error}
                onChange={({ value }) => selectField('medical_category')(value)}
              />
            </Col>
            <Col xs={'auto'}>
              <PreExistingRadios fields={fields} selectField={selectField} />
            </Col>
          </Row>

          <Row className="d-flex mt-2">
            <Col xs={5}>
              <Input
                label="Location"
                placeholder="Add location"
                onChange={e => changeField('location')(e)}
                {...fields.location}
              />
            </Col>
            <Col xs={3}>
              <PartyCompanySpecificTypeSelector
                placeholder="Select company"
                label="LOCAL P&I CORRESPONDENT"
                isMulti={false}
                filter={{ value: fields.local_pni_correspondent.value }}
                onChange={({ value }) => selectField('local_pni_correspondent')(value)}
                type={'p&i_correspondent'}
              />
            </Col>
            <Col>
              <AsyncSelector
                label={
                  <div className="d-flex">
                    <div className="me-1">OCIMF SYSTEM CATEGORY</div>
                    <Information
                      message={'This field is going to affect results in LTIF & TRCF Crew Report.'}
                      svgStyle={{ width: 12, height: 12 }}
                      tooltipClassname="min-width-fit max-width-fit"
                      className="text-primary d-flex align-items-center"
                    />
                  </div>
                }
                type="crew-medical-case-ocimf-system-categorization"
                onChange={e => selectField('ocimf_system_categorization')(e)}
                placeholder="Select categorization"
                isClearable={true}
                isMulti={false}
                className="w-100p mb-0"
                {...fields.ocimf_system_categorization}
              />
            </Col>
            {/*  */}
          </Row>

          <Row className="d-flex">
            <Col xs={5}>
              <OccuredDate fields={fields} selectField={selectField} />
            </Col>
            <Col>
              <DateReported fields={fields} selectField={selectField} changeField={changeField} />
            </Col>
          </Row>

          <Row className="d-flex mb-2">
            <Col xs={7}>
              <Duty fields={fields} selectField={selectField} />
            </Col>
          </Row>

          <Row className="d-flex align-items-end">
            <Col xs={5} className="cmb-18">
              <Checkbox
                onChange={e => selectField('telemedico')(e.target.checked)}
                label={<span className="text-primary fs-12 fw-medium">Telemedico</span>}
                className="mb-0"
                {...fields.telemedico}
              />
            </Col>
            <Col xs={7}>
              {fields.telemedico.value ? (
                <Telemedico fields={fields} selectField={selectField} changeField={changeField} />
              ) : null}
            </Col>
          </Row>

          <Row className="d-flex align-items-end">
            <Col xs={5} className={`cmb-18 ${fields?.hospitalized?.value ? 'cpb-2' : ''}`}>
              <Checkbox
                onChange={e => selectField('hospitalized')(e.target.checked)}
                className={'mb-0'}
                label={<span className="text-primary fs-12 fw-medium">Hospitalized</span>}
                {...fields.hospitalized}
              />
            </Col>
            {fields?.hospitalized?.value ? (
              <Hospitalized fields={fields} selectField={selectField} />
            ) : null}
          </Row>

          <Row className="d-flex align-items-end">
            <Col xs={5} className={`cmb-18 ${fields?.repatriated?.value ? 'cpb-2' : ''}`}>
              <Checkbox
                onChange={e => selectField('repatriated')(e.target.checked)}
                label={<span className="text-primary fs-12 fw-medium">Repatriated</span>}
                className={'mb-0'}
                {...fields.repatriated}
              />
            </Col>
            <Col xs={7}>
              {fields?.repatriated?.value ? (
                <ArrivedAt fields={fields} selectField={selectField} changeField={changeField} />
              ) : null}
            </Col>
          </Row>

          <Row className="d-flex">
            <Col xs={5}></Col>
            <Col>
              <ClosedAt fields={fields} selectField={selectField} />
            </Col>
          </Row>
        </div>

        <div className="event-medical-crew-cases__content-right d-flex flex-column edit">
          <Textarea
            placeholder={'Add some remarks'}
            label="Remarks"
            onChange={changeField('remarks')}
            className="flex-1 min-height-350"
            {...fields.remarks}
          />

          <div className="cpt-2 mt-auto d-flex align-items-end">
            <UploadFiles
              upload={upload}
              download={download}
              group="events.crew-medical-cases"
              files={fields.attachments.value}
              onChange={files => selectField('attachments')(files)}
              uploadText="Upload files"
              size="lg"
              className="mb-0"
            />
          </div>
        </div>
      </div>

      {!isOnBoard && (
        <div className={`event-medical-crew-cases__content-bottom edit`}>
          <ClaimAmounts
            claimExpensesOptions={claimExpensesOptions}
            selectField={selectField}
            formState={formState}
            fields={fields}
            activeMedicalCase={activeMedicalCase}
          />
        </div>
      )}

      {isLocked ? null : (
        <div className="event-medical-crew-cases__content-actions edit">
          <InputSaveActions
            disabled={isLoading || isSubmitting}
            onCancel={onCancelForm}
            onSave={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  closeForm: PropTypes.func
};

export default Form;
