import _last from 'lodash/last';
import _isNumber from 'lodash/isNumber';
import paths from 'routing/routes/_paths';
import { jobEnums } from './fixed';

import qs from 'qs';

export const urlArrayLimit = 100;

export const stringifyObj = obj => qs.stringify(obj, { indices: true, parameterLimit: 10000 });

export const parseQueryParams = search => {
  if (search.includes('searchText=')) {
    // when searchText has symbols like '&' it creates bugs on our parsing

    // take the searchText correct value so we capture all the cases
    const regex = /searchText=([^&]+).*?(?=&sorting|&filters|&searchId|&visible|$)/;

    // deconstuct and take the searchText value
    const match = search.match(regex);

    const searchTextRaw = match ? match[0] : null;

    // clear the value from its tag and we keep it
    const searchTextValue = decodeURIComponent(searchTextRaw)
      ?.replace('&searchText=', '')
      .replace('searchText=', '');

    // modify the whole search url by deleting all the searchText part
    const modifiedSearch = search.replace(`${searchTextRaw}`, '');

    // parse the new modified search url
    const parsed = qs.parse(
      modifiedSearch.startsWith('?') ? modifiedSearch.substring(1) : modifiedSearch,
      { arrayLimit: urlArrayLimit }
    );

    return {
      ...parsed,
      // we add the searchText passing our new cleared text version
      searchText: searchTextValue
    };
  }
  return {
    ...qs.parse(search.startsWith('?') ? search.substring(1) : search, {
      arrayLimit: urlArrayLimit
    })
  };
};

export const trimLocationPath = (path, level) => {
  if (!path) return '';

  const trimmedPathSplit = path.split('/');

  const trimmedPath = trimmedPathSplit.reduce((result, item, i) => {
    if (i === 0) {
      return '';
    } else if (i <= level) {
      return result + `/${item}`;
    } else return result;
  }, '');

  return trimmedPath;
};

export const getBeaconInternalUrlPostId = event => {
  if (
    event &&
    event?.target?.tagName?.toLowerCase() === 'a' &&
    event?.target?.href?.includes('/beacon/posts/')
  ) {
    const parts = event.target.href.split('/');
    const postId = parseInt(_last(parts));

    if (postId && _isNumber(postId)) {
      return postId;
    }
  }

  return null;
};

export const getJobLocationPath = job => {
  const { id, type } = job;
  const suffix = id ? `/${id}` : '';

  switch (type) {
    case jobEnums.regular:
      return `${paths.regular_jobs}${suffix}`;
    case jobEnums.pms:
      return `${paths.pms_jobs}${suffix}`;
    case jobEnums.unscheduled:
      const filters = [
        {
          name: 'type',
          operation: 'oneOf',
          value: [jobEnums.unscheduled]
        }
      ];

      return `${paths.pms_jobs}${suffix}?${stringifyObj({ filters })}`;
    case jobEnums.flag_dispensation:
      return `${paths.flag_extensions_dispensations_jobs}${suffix}`;
    case jobEnums.orca_jobs:
      return `${paths.orca_jobs}${suffix}`;
    default:
      return '';
  }
};

export const getPartyUrl = (type, id) => {
  const partyId = `${id ? `/${id}` : ''}`;

  switch (type) {
    case 'crew':
      return `${paths.crew}${partyId}`;
    case 'person':
      return `${paths.persons}${partyId}`;
    case 'company':
      return `${paths.companies}${partyId}`;
    default:
      return null;
  }
};
