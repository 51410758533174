import React, { FC, ReactNode, useState } from 'react';
import SaveSearchModal from './SaveSearchModal';
import PageActions from 'components/layouts/page/table/PageActions';
import TitleLayout from './TitleLayout';

import { Row, Col } from 'reactstrap';
import { PageAction } from '../types';

type SaveTableLayoutProps = {
  pageTitle?: string | ReactNode;
  pageActions?: PageAction[];
  label: string;
  className?: string;
  headerClassName?: string;
};

const SaveTableLayout: FC<SaveTableLayoutProps> = React.memo(function SaveTableLayout({
  pageTitle,
  pageActions,
  label,
  className = '',
  headerClassName = ''
}) {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div className={`page-layout page-layout--table ${className}`}>
      <Row className={`align-items-center page-table-layout--header ${headerClassName}`}>
        <Col className="d-flex align-items-center" xs="auto">
          <TitleLayout title={pageTitle} setModalOpened={setModalOpened} label={label} />
        </Col>

        <PageActions actions={pageActions} label={label} />
      </Row>

      <SaveSearchModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        label={label}
      ></SaveSearchModal>
    </div>
  );
});

export default SaveTableLayout;
