import { createSelector } from 'reselect';

export const selectState = state => state;

export const selectSocketTasksReducer = state => state.tasks;

export const selectTasks = state => selectSocketTasksReducer(state).data;

export const selectTaskByUuid = (state, uuid) => selectTasks(state)?.[uuid];

export const selectTaskOuputByUuid = (state, uuid) => selectTaskByUuid(state, uuid)?.output;

const selectTaskUuids = state => selectSocketTasksReducer(state).uuids;

export const hasTaskSucceded = (state, uuid) =>
  selectTaskByUuid(state, uuid)?.event === 'task.completed';

export const hasTaskFailed = (state, uuid) =>
  selectTaskByUuid(state, uuid)?.event === 'task.failed';

export const isTaskNotHandled = createSelector(
  selectTaskByUuid,
  task => (task?.event === 'task.completed' && !task?.isHandled) || (task && !task?.isHandled)
);

export const selectNotHandledSucceededTaskUuids = createSelector(
  [selectTaskUuids, selectState],
  (taskUuids, state) =>
    taskUuids.filter(
      uuid => !selectTaskByUuid(state, uuid).isHandled && hasTaskSucceded(state, uuid)
    )
);

export const selectNotHandledSucceededTasks = createSelector(
  [selectNotHandledSucceededTaskUuids, selectState],
  (notHandledTaskUuids, state) => notHandledTaskUuids.map(uuid => selectTaskByUuid(state, uuid))
);
