import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import SearchFormBody from './SearchFormBody';

import { useForm, useActions } from 'utils/hooks';
import config from './config';

import * as tablesActions from 'store/tables/actions';

const TableForm = ({ drawer, tableSearch }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, collectValues, loadValues, isDirty, hasErrors, handleSubmitError } =
    useForm(config);
  const [updateTableSearch] = useActions([tablesActions.updateTableSearch]);

  const onSubmit = async () => {
    const values = collectValues();

    if (!values) return;
    const { shared_parties, shared_departments, ...rest } = values;

    const shares = {
      parties: shared_parties.map(s => s.id),
      departments: shared_departments
    };

    try {
      setIsSubmitting(true);
      await updateTableSearch({ id: tableSearch.id, shares, ...rest });
      setIsSubmitting(false);
      drawer.close();
    } catch (error) {
      handleSubmitError(error);
    }
  };

  useEffect(() => {
    loadValues({
      name: tableSearch.name,
      description: tableSearch.description,
      shared_parties: tableSearch.shares.parties,
      shared_departments: tableSearch.shares.departments.map(el => el.id)
    });
  }, []);

  return (
    <Drawer {...drawer}>
      <DrawerHeader>Manage</DrawerHeader>

      <FormDrawer>
        <SearchFormBody isDirty={isDirty} formState={formState} />
      </FormDrawer>
      <FormFooter>
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={drawer.close}
          disabled={isSubmitting}
        >
          CANCEL
        </Button>
        <Button
          onClick={onSubmit}
          disabled={isSubmitting || hasErrors}
          color="primary"
          className="px-4"
        >
          SAVE
        </Button>
      </FormFooter>
    </Drawer>
  );
};

export default TableForm;
