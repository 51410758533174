import React from 'react';
import Priority from 'common/components/general/Priority';
import { Department } from 'common/components/general/Departments';

import { components } from 'react-select';
import { Row, Col } from 'reactstrap';
import TextWithTooltip from '../general/TextWithTooltip';
import Status from 'common/components/general/Status';
import calendarIcon from 'common/assets/svg/common/calendar.svg';
import SvgRender from 'common/components/general/SvgRender';
import { displayDate } from '@/ts-common/utils/dates';

const OptionPreview = ({ data, font }) => {
  return (
    <Row className="d-flex align-items-center w-100p flex-nowrap" noGutters>
      <Col xs={'auto'} className="me-1">
        <div className=" d-flex justify-content-center width-24"></div>
        <Priority
          className="d-flex justify-content-center"
          value={data?.form?.form?.importance?.label}
          withText={false}
          size="small"
        />
      </Col>
      <Col xs={'auto'} className="me-1">
        <div className=" d-flex justify-content-center width-32 fs-10">
          <TextWithTooltip>{data?.id}</TextWithTooltip>
        </div>
      </Col>

      <Col className={`fs-${font} overflow-hidden`}>
        <div className="text-nowrap overflow-x-hidden text-truncate pe-1">
          {data?.form?.form?.name}
          {data?.form?.revision && <strong>&nbsp; - {data?.form?.revision}</strong>}
          {data?.created_at ? (
            <div className="d-flex align-items-center fs-10 text-violet">
              <SvgRender src={calendarIcon} style={{ width: 12, height: 12 }} className="me-1" />
              <div className="fs-10">{displayDate(data.created_at, { time: true })}</div>
            </div>
          ) : null}
        </div>
      </Col>
      <Col xs={'auto'} className="ms-auto d-flex align-items-center me-1">
        {data?.vessel?.name ? (
          <div className="d-flex align-items-center justify-content-center height-20 bg-white fs-10 text-primary fw-bold cps-4 cpe-4 border-radius-3">
            {data?.vessel?.name}
          </div>
        ) : null}
        <Status value={data?.status} className={`${data?.vessel?.name ? 'cms-4' : ''}`} />
      </Col>
    </Row>
  );
};

const SingleValuePreview = ({ data, font }) => {
  return (
    <div className="d-flex align-items-center w-100p">
      <Priority
        className="cpe-12"
        value={data?.form?.form?.importance?.label}
        withText={false}
        size="small"
      />
      <div className={`text-truncate fs-${font}`}>
        {data?.form?.form?.name}
        {data?.form?.revision && <strong>&nbsp; - {data?.form?.revision}</strong>}
      </div>
      <div className="ms-auto me-1">
        <Department value={data?.form?.form?.department} />
      </div>
    </div>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="w-100p">
      {props.data ? <OptionPreview data={props.data} font={14} /> : null}
    </components.Option>
  );
};

export const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue className="w-100p" {...props}>
      {props.data ? <SingleValuePreview data={props.data} font={12} /> : null}
    </components.SingleValue>
  );
};
