export const getDefaultSorting = key => {
  switch (key) {
    case 'captain_reports':
      return { timestamp: 'desc' };

    case 'ticketing_crew':
      return { date_of_change: 'asc' };
    case 'ticketing_events':
      return { started_at: 'asc' };
    case 'ticketing_tickets':
    case 'accounting_invoices_tickets':
      return { departure: 'desc' };
    case 'crew-training-types':
      return { name: 'asc' };
    case 'crew_list':
      return { rank_id: 'asc' };
    case 'crew_seaman_profile_contracts':
      return { contract_from_date: 'desc' };
    case 'reports_evaluations':
      return { date: 'desc' };
    case 'reports_trainings':
      return { expires_at: 'desc' };
    case 'reports_past_promotions':
      return { contract_from_date: 'desc' };
    case 'reports_not_for_reemployment':
      return { full_name: 'asc' };
    case 'reports_attendance_events':
      return { started_at: 'desc' };
    case 'crew_evaluation_obligations':
      return { days_left: 'asc' };
    case 'accounting_invoices':
      return { date: 'desc' };
    case 'mga_action_types':
      return { name: 'asc' };
    case 'mga_accounts':
      return { timestamp: 'asc' };
    case 'mga_items':
      return { 'store-group': 'asc' };
    case 'mga_inventory_items_additions':
      return { timestamp: 'asc' };
    case 'mga_inventory_items_overview':
      return { date: 'asc' };
    case 'mga_inventory_items_removals':
      return { timestamp: 'asc' };
    case 'accounting_budget':
      return { budgetable: 'asc' };
    case 'company_connected_companies':
      return { company_name: 'asc' };
    case 'company_bank_accounts':
      return { bank_name: 'asc' };
    case 'accounting_companies_settings':
      return { code: 'asc' };
    case 'pms_jobs_list':
      return { due_date: 'asc' };
    case 'events':
      return { started_at: 'desc' };
    case 'reports_running_cps_redelivery_dates':
      return { vessel: 'asc' };
    case 'reports_fixtures':
      return { vessel: 'asc', delivery_date: 'desc' };
    case 'purchasing_budgeted_quantity':
      return { code: 'asc', name: 'asc' };
    case 'events_accounts':
      return { date: 'desc', category: 'desc' };
    case 'event_off_hire':
      return { name: 'asc' };
    case 'event_accounts_categories':
      return { name: 'asc' };
    case 'reports_findings':
      return { reported_at: 'desc' };
    case 'reports_vetting_overview':
      return { question_number: 'asc' };
    case 'vetting_setup':
      return { number: 'asc', description: 'asc' };
    case 'pms_job_prototypes':
      return { vessel_system: 'asc', title: 'asc' };
    case 'pms_spare_parts':
      return { vessel_system: 'asc', drawing_no: 'asc', part_no: 'asc' };
    case 'pms_job_types':
      return { type: 'asc' };
    case 'pms_vessel_system_assignments':
      return { description: 'asc' };
    case 'system_tags':
      return { label: 'asc', params: 'asc' };
    case 'generated_tags':
      return { label: 'asc', params: 'asc' };
    case 'default_sources':
      return { name: 'asc' };
    case 'document_manager_vessels_list':
      return { expiration_date: 'asc' };
    case 'purchasing_pricelist_items':
      return { code: 'asc' };
    case 'accounting_budget_scenarios':
      return { code: 'asc' };
    case 'accounting_ledger_cards':
      return { code: 'asc', parent_code: 'asc' };
    case 'purchasing_forwarding_items':
      return { forwarding_case_status: 'asc', date: 'asc', item: 'asc' };
    case 'purchasing_forwarding_cases':
      return { id: 'desc' };
    case 'purchasing_forwarding_orders':
      return { requisition_code: 'asc', supplier: 'asc', date: 'asc' };
    case 'port_da_cases':
      return { arrival: 'desc' };
    case 'events_audits_types':
      return { name: 'asc' };
    case 'events_audits_inspections':
      return { type: 'asc', name: 'asc' };
    case 'events_audits_reasons':
      return { name: 'asc' };
    case 'events_audits_evaluations':
      return { audit_evaluation_type: 'asc', name: 'asc' };
    case 'freight_hire_collection_items':
      return { name: 'asc' };
    case 'reports_port_documents':
      return { timestamp: 'desc' };
    case 'charter_parties':
      return { opening_date: 'desc' };
    default:
      return {};
  }
};

const perPageDefaults = {
  document_manager_crew_documents: 50,
  crew_planning: 50,
  crew_planning_history: 50,
  crew_document_types: 50,
  crew_seaman_profile_contracts: 100,
  reports_change_logs: 50,
  pms_jobs_list: 100,
  pms_jobs_per_system: 50,
  company_evaluations: 20,
  form_submissions: 100,
  purchasing_pricelist_items: 100,
  vessel_formula_logs: 15,
  inventory_spare_parts: 200,
  reports_crew_promotions_contracts_seamen: 25
};

export const getDefaultPageSize = key => {
  if (!key) return;

  if (key.includes('reports_lubricants')) {
    return 80;
  } else {
    return perPageDefaults?.[key];
  }
};

export const getExportDefaultRequestParamsExcluded = key => {
  switch (key) {
    case 'pms_jobs_list':
      return ['paging'];

    default:
      return [];
  }
};
