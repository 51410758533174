import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import useTooltipID from 'common/utils/hooks/useTooltipID';

export const truncateText = (string, length) => {
  if (!string) return null;
  if (string.length <= length) return string;
  return `${string.substring(0, length - 3)}...`;
};

const TruncatedText = ({ text, length, placement, tooltipProps, tooltipClassName }) => {
  const [truncatedText, setTruncatedText] = useState('');
  const [isTruncated, setIsTruncated] = useState('');

  useEffect(() => {
    if (length) {
      const newText = truncateText(text, length);
      setTruncatedText(newText);
      setIsTruncated(newText.substring(newText.length - 3, newText.length) === '...');
    }
  }, [text, length]);

  const { avoidRender, tooltipID } = useTooltipID('truncated-text');

  if (avoidRender) return null;

  return (
    <>
      <div id={tooltipID} className={`${!length && 'text-truncate'}`}>
        {length ? truncatedText : text}
      </div>
      {isTruncated || (!length && text) ? (
        <UncontrolledTooltip
          placement={placement || 'top-end'}
          boundariesElement="window"
          target={tooltipID}
          innerClassName={`show-more-tooltip${tooltipClassName ? ` ${tooltipClassName}` : ''}`}
          {...(tooltipProps || {})}
        >
          {text}
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number
};

export default TruncatedText;
