export const capitalizeFirst = field => {
  if (field) {
    let str = field.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return '-';
  }
};

export const truncateText = (string, length) => {
  if (!string) return null;
  if (string.length <= length) return string;
  return `${string.substring(0, length - 3)}...`;
};

export const isTextTruncated = (e, func) => {
  const parentComputedStyle = window.getComputedStyle(e.target.parentElement, null);
  const parentElemWidth = parentComputedStyle
    ? parseFloat(parentComputedStyle.width) -
      (parseFloat(parentComputedStyle.paddingLeft) + parseFloat(parentComputedStyle.paddingRight))
    : 0;

  const elemWidth = e.target ? e.target.getBoundingClientRect().width : 0;

  if (Math.round(elemWidth) > Math.round(parentElemWidth)) {
    func(true);
  }
};

export const copyTextToClipboard = async text => {
  try {
    if (navigator.clipboard) {
      if (typeof navigator?.clipboard?.writeText === 'function') {
        await navigator.clipboard.writeText(text);
        return text;
      } else {
        console.error('Reading from clipboard is not supported in this browser.');
      }
      return text;
    } else {
      console.error(
        'Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
      );
    }
  } catch (err) {
    console.error('Failed to copy: ', err);
    throw err;
  }
};

export const getClipboardContents = async () => {
  try {
    if (navigator.clipboard) {
      const text = await navigator.clipboard.readText();

      return text;
    } else {
      console.error(
        'Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
      );
    }
  } catch (err) {
    console.error('Failed to read clipboard contents: ', err);
    throw err;
  }
};

export const getImgTagsFromHtml = htmlContent => {
  const imgTagsRegex = /<img\s+[^>]*>/gi;
  const imgTags = htmlContent.match(imgTagsRegex);

  if (imgTags?.length) return imgTags;

  return null;
};

export const getLetterFromNumber = number => {
  const num = parseInt(number, 10);

  if (isNaN(num) || number < 1 || number > 26) return null;

  // Convert the number to the corresponding character code and add 96 to get the letter code.
  // The letter code for 'a' is 97, so we need to subtract 1 to get the correct offset.
  return String.fromCharCode(num + 96);
};

export const copyClipBoardDetails = copiedTextRef => {
  var range = document.createRange();
  range.selectNode(copiedTextRef.current);
  window.getSelection().removeAllRanges(range);
  window.getSelection().addRange(range);
  document.execCommand('copy');
};

export const hyphenToCamelCase = name => {
  // Convert royal-blue to royalBlue
  return name.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};
