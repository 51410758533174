import { FC } from 'react';
import _get from 'lodash/get';
import styled from '@emotion/styled';

import { TicketingStatusType } from '@/common/types/ticketing.ts';

type TicketingBulletStatusProps = {
  status: TicketingStatusType;
  className?: string;
  enableShadow?: boolean;
  id: string;
};

const TicketingBulletStatus: FC<TicketingBulletStatusProps> = ({
  status,
  className,
  enableShadow = false,
  id
}) => {
  const color = _get(status, 'color');
  const Container = enableShadow ? BulletWithShadow : BulletWithoutShadow;

  return (
    <Container
      id={id}
      className={`min-w-9 max-w-9 min-h-9 max-h-9 rounded-circle status-single--${color}-no-opacity ms-1 me-3 ${
        className || ''
      }`}
    ></Container>
  );
};

export default TicketingBulletStatus;

const BulletWithShadow = styled.div`
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

const BulletWithoutShadow = styled.div``;
