import { ReactNode } from 'react';
import { RequistionOnBoardStatus, RequistionStatusLabel } from '@/common/types/purchasing';
import _get from 'lodash/get';

export const styles = {
  gutter: 4,
  alignment: { right: 'text-end' },
  separated: { left: 'separated-left-cell', right: 'separated-right-cell' },
  indexes: {
    first: 'first-index',
    last: 'last-index',
    highlighted: 'highlighted-index',
    transparent: 'transparent-index',
    noBorder: 'no-border-index'
  }
};

export const ITEMS_CONFIGURATION_KEYS = {
  all: 'all_columns',
  usd: 'usd',
  packing: 'packing',
  quality: 'quality',
  discount: 'discount'
} as const;

export type ItemConfigurationKey =
  (typeof ITEMS_CONFIGURATION_KEYS)[keyof typeof ITEMS_CONFIGURATION_KEYS];

export type ItemFieldType = {
  key: string;
  type?: string;
  maxWidth?: number;
  width?: number | string;
  label?: ReactNode;
  className?: string;
  supplierRequisitionID?: string;
  headerClassName?: string;
  footerClassName?: string;
  itemsConfigurationKeys?: ItemConfigurationKey[];
  isItemOnly?: boolean;
  isPmsOnly?: boolean;
  isNew?: boolean;
  equivalentCurrency?: boolean;
  currency?: boolean;
  hasFillButton?: boolean;
  showInComparisonView?: boolean;
  footer?: { totals: boolean };
};

// FIELDS AND SUPPLIER_FIELDS MUST CONTAIN the maxWidth PROPERTY
type FieldsType = {
  [key: string]: ItemFieldType;
};

export const FIELDS: FieldsType = {
  code: { key: 'code', maxWidth: 430, label: 'ITEMS' },
  rob: {
    key: 'rob',
    type: 'number',
    maxWidth: 42 + styles.gutter,
    label: 'ROB',
    className: styles.alignment.right
  },
  requested_packaging_id: {
    key: 'requested_packaging_id',
    maxWidth: 120 + styles.gutter,
    label: 'PACKING',
    isItemOnly: true
  },
  requested_quantity: {
    key: 'requested_quantity',
    type: 'number',
    maxWidth: 42 + styles.gutter,
    label: 'REQ QTY',
    className: styles.alignment.right
  },
  unlisted_description: {
    key: 'unlisted_description',
    maxWidth: 430,
    label: 'OUT OF THE LIST ITEMS'
  },
  comments: {
    key: 'comments',
    maxWidth: 24 + styles.gutter
  },
  file: {
    key: 'file',
    width: 'auto',
    maxWidth: 20 + styles.gutter,
    className: 'item-file d-flex justify-content-start cpt-6'
  },
  delete: {
    key: 'delete',
    width: 'auto',
    maxWidth: 24,
    className: 'd-flex align-items-center justify-content-center ps-0'
  },
  revised_quantity: {
    key: 'revised_quantity',
    type: 'number',
    maxWidth: 42 + styles.gutter,
    label: 'REV QTY',
    className: styles.alignment.right,
    hasFillButton: true
  },
  total_proposed_quantity: {
    key: 'total_proposed_quantity',
    type: 'number',
    maxWidth: 70 + styles.gutter,
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;PROPOSED
        <br />
        &nbsp;QTY
      </span>
    ),
    className: styles.alignment.right
  },
  total_approved_quantity: {
    key: 'total_approved_quantity',
    type: 'number',
    maxWidth: 70 + styles.gutter,
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;APPROVED
        <br />
        &nbsp;QTY
      </span>
    ),
    className: styles.alignment.right
  },
  total_delivered_quantity: {
    key: 'total_delivered_quantity',
    type: 'number',
    maxWidth: 70 + styles.gutter,
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;DELIVERED
        <br />
        &nbsp;QTY
      </span>
    ),
    className: styles.alignment.right
  }
};

export const SUPPLIER_FIELDS: FieldsType = {
  quality_id: {
    key: 'quality_id',
    label: 'QUALITY',
    maxWidth: 120 + styles.gutter,
    isPmsOnly: true,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.quality],
    showInComparisonView: true
  },
  available_packaging_id: {
    key: 'available_packaging_id',
    label: 'AVAILABLE PACKING',
    maxWidth: 120 + styles.gutter,
    isItemOnly: true,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.packing],
    showInComparisonView: true
  },
  available_quantity: {
    key: 'available_quantity',
    label: (
      <span>
        AVBL <br /> QTY
      </span>
    ),
    maxWidth: 54 + styles.gutter,
    className: styles.alignment.right,
    hasFillButton: true
  },
  unit_price: {
    key: 'unit_price',
    label: (
      <span>
        QUOTED <br /> UNIT
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    showInComparisonView: true
  },
  unit_price_base_currency_equivalent: {
    key: 'unit_price_base_currency_equivalent',
    label: (
      <span>
        QUOTED <br /> UNIT
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    equivalentCurrency: true,
    currency: true,
    showInComparisonView: true
  },
  total_original_price: {
    key: 'total_original_price',
    label: (
      <span>
        TOTAL BEFORE DISCOUNT
        <br />
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    footer: { totals: true },
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.discount]
  },
  total_original_price_base_currency_equivalent: {
    key: 'total_original_price_base_currency_equivalent',
    label: (
      <span>
        TOTAL BEFORE DISCOUNT
        <br />
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    equivalentCurrency: true,
    currency: true,
    footer: { totals: true },
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.discount]
  },
  discount_percentage: {
    key: 'discount_percentage',
    label: <span className="cpe-12">DISC %</span>,
    maxWidth: 58 + styles.gutter,
    className: styles.alignment.right,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.discount]
  },
  total_discounted_price: {
    key: 'total_discounted_price',
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;QUOTED
        <br />
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    footer: { totals: true }
  },
  total_discounted_price_base_currency_equivalent: {
    key: 'total_discounted_price_base_currency_equivalent',
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;QUOTED
        <br />
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    equivalentCurrency: true,
    footer: { totals: true }
  },
  availability: {
    key: 'availability',
    label: (
      <div className="d-flex align-items-center">
        AVBL
        <br />
        <span className="text-violet">&nbsp;DAYS</span>
      </div>
    ),
    maxWidth: 60 + styles.gutter,
    className: styles.alignment.right
  },
  proposed_quantity: {
    key: 'proposed_quantity',
    label: (
      <span>
        PROPOSED
        <br />
        &nbsp;QTY
      </span>
    ),
    maxWidth: 84 + styles.gutter,
    className: styles.alignment.right,
    headerClassName: 'pe-4',
    footerClassName: 'd-flex flex-nowrap flex-row',
    showInComparisonView: true
  },
  total_proposed_price: {
    key: 'total_proposed_price',
    label: 'TOTAL PROPOSED',
    maxWidth: 84 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    footer: { totals: true }
  },
  total_proposed_price_base_currency_equivalent: {
    key: 'total_proposed_price_base_currency_equivalent',
    label: 'TOTAL PROPOSED',
    maxWidth: 84 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    equivalentCurrency: true,
    footer: { totals: true }
  },
  approved_quantity: {
    key: 'approved_quantity',
    label: (
      <span>
        APPROVED
        <br />
        &nbsp;/ORDERED
        <br />
        &nbsp;QTY
      </span>
    ),
    maxWidth: 84 + styles.gutter,
    className: styles.alignment.right,
    hasFillButton: true
  },
  total_approved_price: {
    key: 'total_approved_price',
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;APPROVED
        <br />
        &nbsp;/ORDERED
      </span>
    ),
    maxWidth: 84 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    footer: { totals: true }
  },
  total_approved_price_base_currency_equivalent: {
    key: 'total_approved_price_base_currency_equivalent',
    label: (
      <span>
        TOTAL
        <br />
        &nbsp;APPROVED
        <br />
        &nbsp;/ORDERED
      </span>
    ),
    maxWidth: 84 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    equivalentCurrency: true,
    footer: { totals: true }
  },
  delivered_quantity: {
    key: 'delivered_quantity',
    label: (
      <span>
        DELIVERED
        <br />
        &nbsp;QTY
      </span>
    ),
    maxWidth: 80 + styles.gutter,
    className: styles.alignment.right,
    hasFillButton: true
  },
  total_delivered_price: {
    key: 'total_delivered_price',
    label: 'TOTAL DELIVERED',
    maxWidth: 55 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    footer: { totals: true }
  },
  total_delivered_price_base_currency_equivalent: {
    key: 'total_delivered_price_base_currency_equivalent',
    label: 'TOTAL DELIVERED',
    maxWidth: 55 + styles.gutter,
    className: styles.alignment.right,
    currency: true,
    equivalentCurrency: true,
    footer: { totals: true }
  }
};

const approvedSupplierFields = [
  FIELDS.code,
  FIELDS.rob,
  FIELDS.requested_packaging_id,
  FIELDS.requested_quantity,
  FIELDS.comments,
  FIELDS.file,
  FIELDS.revised_quantity,
  SUPPLIER_FIELDS.quality_id,
  SUPPLIER_FIELDS.available_packaging_id,
  SUPPLIER_FIELDS.available_quantity,
  SUPPLIER_FIELDS.unit_price,
  SUPPLIER_FIELDS.unit_price_base_currency_equivalent,
  SUPPLIER_FIELDS.total_original_price,
  SUPPLIER_FIELDS.total_original_price_base_currency_equivalent,
  SUPPLIER_FIELDS.discount_percentage,
  {
    ...SUPPLIER_FIELDS.total_discounted_price,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  {
    ...SUPPLIER_FIELDS.total_discounted_price_base_currency_equivalent,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  SUPPLIER_FIELDS.availability,
  { ...SUPPLIER_FIELDS.proposed_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] },
  {
    ...SUPPLIER_FIELDS.total_proposed_price,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  {
    ...SUPPLIER_FIELDS.total_proposed_price_base_currency_equivalent,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  {
    ...SUPPLIER_FIELDS.approved_quantity,
    isNew: true,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  {
    ...SUPPLIER_FIELDS.total_approved_price,
    isNew: true,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  {
    ...SUPPLIER_FIELDS.total_approved_price_base_currency_equivalent,
    className: styles.alignment.right,
    itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
  },
  { ...FIELDS.total_proposed_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] },
  { ...FIELDS.total_approved_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] }
];

const lastStepFields = {
  office: [
    FIELDS.code,
    FIELDS.rob,
    FIELDS.requested_packaging_id,
    FIELDS.requested_quantity,
    FIELDS.comments,
    FIELDS.file,
    FIELDS.revised_quantity,
    SUPPLIER_FIELDS.quality_id,
    SUPPLIER_FIELDS.available_packaging_id,
    SUPPLIER_FIELDS.available_quantity,
    SUPPLIER_FIELDS.unit_price,
    SUPPLIER_FIELDS.unit_price_base_currency_equivalent,
    SUPPLIER_FIELDS.total_original_price,
    SUPPLIER_FIELDS.total_original_price_base_currency_equivalent,
    SUPPLIER_FIELDS.discount_percentage,
    SUPPLIER_FIELDS.total_discounted_price,
    SUPPLIER_FIELDS.total_discounted_price_base_currency_equivalent,
    SUPPLIER_FIELDS.proposed_quantity,
    SUPPLIER_FIELDS.total_proposed_price,
    SUPPLIER_FIELDS.total_proposed_price_base_currency_equivalent,
    {
      ...SUPPLIER_FIELDS.approved_quantity,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_approved_price,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_approved_price_base_currency_equivalent,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.delivered_quantity,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_delivered_price,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_delivered_price_base_currency_equivalent,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    { ...FIELDS.total_proposed_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] },
    { ...FIELDS.total_approved_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] }
  ],
  onboard: [
    FIELDS.code,
    FIELDS.rob,
    FIELDS.requested_packaging_id,
    FIELDS.requested_quantity,
    {
      ...SUPPLIER_FIELDS.approved_quantity,
      label: 'ORDERED QTY'
    },
    FIELDS.total_delivered_quantity,
    { ...FIELDS.comments, width: '' },
    FIELDS.file
  ]
};

type OutOfTheListItemsLayout = {
  items?: ItemFieldType[];
  out_of_the_list_items?: ItemFieldType[];
  onboard?: { items: ItemFieldType[]; out_of_the_list_items: ItemFieldType[] };
  office?: { items: ItemFieldType[]; out_of_the_list_items: ItemFieldType[] };
};
type SeperatedLayout = { onboard: ItemFieldType[]; office: ItemFieldType[] };
type BasicLayout = ItemFieldType[];

type ItemsLayoutType = {
  draft: OutOfTheListItemsLayout;
  for_correction: OutOfTheListItemsLayout;
  submitted: OutOfTheListItemsLayout;
  rqn: OutOfTheListItemsLayout;
  rfq: BasicLayout;
  qtn: BasicLayout;
  pr: BasicLayout;
  po: BasicLayout;
  dlv: SeperatedLayout;
  ev: SeperatedLayout;
  comp: SeperatedLayout;
};

const layout: ItemsLayoutType = {
  draft: {
    items: [
      FIELDS.code,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      { ...FIELDS.comments, width: '', maxWidth: 430, label: 'COMMENTS' },
      FIELDS.file,
      FIELDS.delete
    ],
    out_of_the_list_items: [
      FIELDS.unlisted_description,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      { ...FIELDS.comments, width: '', maxWidth: 430, label: 'COMMENTS' },
      FIELDS.file,
      FIELDS.delete
    ]
  },
  for_correction: {
    items: [
      FIELDS.code,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      { ...FIELDS.comments, width: '', maxWidth: 430, label: 'COMMENTS' },
      FIELDS.file,
      FIELDS.delete
    ],
    out_of_the_list_items: [
      FIELDS.unlisted_description,
      FIELDS.rob,
      FIELDS.requested_quantity,
      { ...FIELDS.comments, width: '', maxWidth: 430, label: 'COMMENTS' },
      FIELDS.file,
      FIELDS.delete
    ]
  },
  submitted: {
    office: {
      items: [
        FIELDS.code,
        FIELDS.rob,
        FIELDS.requested_packaging_id,
        FIELDS.requested_quantity,
        FIELDS.comments,
        FIELDS.file
      ],
      out_of_the_list_items: [
        FIELDS.unlisted_description,
        FIELDS.rob,
        FIELDS.requested_quantity,
        FIELDS.comments,
        FIELDS.file
      ]
    },
    onboard: {
      items: [
        FIELDS.code,
        FIELDS.rob,
        FIELDS.requested_packaging_id,
        FIELDS.requested_quantity,
        { ...FIELDS.comments, width: '', maxWidth: 430, label: 'COMMENTS' },
        FIELDS.file
      ],
      out_of_the_list_items: [
        FIELDS.unlisted_description,
        FIELDS.rob,
        FIELDS.requested_quantity,
        { ...FIELDS.comments, width: '', maxWidth: 430, label: 'COMMENTS' },
        FIELDS.file
      ]
    }
  },
  rqn: {
    items: [
      FIELDS.code,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      FIELDS.comments,
      FIELDS.file,
      FIELDS.revised_quantity
    ],
    out_of_the_list_items: [
      FIELDS.unlisted_description,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      FIELDS.comments,
      FIELDS.file,
      FIELDS.revised_quantity,
      FIELDS.delete
    ]
  },
  rfq: [
    FIELDS.code,
    FIELDS.rob,
    FIELDS.requested_packaging_id,
    FIELDS.requested_quantity,
    FIELDS.comments,
    FIELDS.file,
    FIELDS.revised_quantity
  ],
  qtn: [
    FIELDS.code,
    FIELDS.rob,
    FIELDS.requested_packaging_id,
    FIELDS.requested_quantity,
    FIELDS.comments,
    FIELDS.file,
    FIELDS.revised_quantity,
    { ...SUPPLIER_FIELDS.quality_id, isNew: true },
    { ...SUPPLIER_FIELDS.available_packaging_id, isNew: true },
    {
      ...SUPPLIER_FIELDS.available_quantity,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.unit_price_base_currency_equivalent,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.unit_price,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_original_price_base_currency_equivalent,
      isNew: true
    },
    {
      ...SUPPLIER_FIELDS.total_original_price,
      isNew: true
    },
    { ...SUPPLIER_FIELDS.discount_percentage, isNew: true },
    {
      ...SUPPLIER_FIELDS.total_discounted_price_base_currency_equivalent,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_discounted_price,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    { ...SUPPLIER_FIELDS.availability, isNew: true }
  ],
  pr: [
    FIELDS.code,
    FIELDS.rob,
    FIELDS.requested_packaging_id,
    FIELDS.requested_quantity,
    FIELDS.comments,
    FIELDS.file,
    FIELDS.revised_quantity,
    SUPPLIER_FIELDS.quality_id,
    SUPPLIER_FIELDS.available_packaging_id,
    {
      ...SUPPLIER_FIELDS.available_quantity,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    { ...SUPPLIER_FIELDS.unit_price, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] },
    {
      ...SUPPLIER_FIELDS.unit_price_base_currency_equivalent,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    SUPPLIER_FIELDS.total_original_price,
    SUPPLIER_FIELDS.total_original_price_base_currency_equivalent,
    SUPPLIER_FIELDS.discount_percentage,
    {
      ...SUPPLIER_FIELDS.total_discounted_price,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_discounted_price_base_currency_equivalent,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    SUPPLIER_FIELDS.availability,
    {
      ...SUPPLIER_FIELDS.proposed_quantity,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_proposed_price,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    {
      ...SUPPLIER_FIELDS.total_proposed_price_base_currency_equivalent,
      isNew: true,
      itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
    },
    { ...FIELDS.total_proposed_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] }
  ],
  po: approvedSupplierFields,
  dlv: {
    onboard: [
      FIELDS.code,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      {
        ...SUPPLIER_FIELDS.approved_quantity,
        label: 'ORDERED QTY'
      },
      SUPPLIER_FIELDS.delivered_quantity,
      FIELDS.comments,
      FIELDS.file
    ],
    office: [
      FIELDS.code,
      FIELDS.rob,
      FIELDS.requested_packaging_id,
      FIELDS.requested_quantity,
      FIELDS.comments,
      FIELDS.file,
      FIELDS.revised_quantity,
      SUPPLIER_FIELDS.quality_id,
      SUPPLIER_FIELDS.available_packaging_id,
      SUPPLIER_FIELDS.available_quantity,
      SUPPLIER_FIELDS.unit_price,
      SUPPLIER_FIELDS.unit_price_base_currency_equivalent,
      SUPPLIER_FIELDS.total_original_price,
      SUPPLIER_FIELDS.total_original_price_base_currency_equivalent,
      SUPPLIER_FIELDS.discount_percentage,
      {
        ...SUPPLIER_FIELDS.total_discounted_price,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      {
        ...SUPPLIER_FIELDS.total_discounted_price_base_currency_equivalent,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      SUPPLIER_FIELDS.availability,
      SUPPLIER_FIELDS.proposed_quantity,
      {
        ...SUPPLIER_FIELDS.total_proposed_price,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      {
        ...SUPPLIER_FIELDS.total_proposed_price_base_currency_equivalent,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      {
        ...SUPPLIER_FIELDS.approved_quantity,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      {
        ...SUPPLIER_FIELDS.total_approved_price,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      {
        ...SUPPLIER_FIELDS.total_approved_price_base_currency_equivalent,
        itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd]
      },
      SUPPLIER_FIELDS.delivered_quantity,
      SUPPLIER_FIELDS.total_delivered_price,
      SUPPLIER_FIELDS.total_delivered_price_base_currency_equivalent,
      { ...FIELDS.total_proposed_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] },
      { ...FIELDS.total_approved_quantity, itemsConfigurationKeys: [ITEMS_CONFIGURATION_KEYS.usd] }
    ]
  },
  ev: lastStepFields,
  comp: lastStepFields
} as const;

export const getSpecialCasesModifiedFields = (
  fieldsConfig: ItemFieldType[],
  {
    status,
    isForVessel,
    isOnBoard
  }: { status: RequistionStatusLabel; isForVessel: boolean; isOnBoard: boolean }
) => {
  if (
    status === 'dlv' &&
    !isForVessel &&
    !isOnBoard &&
    !fieldsConfig.find(field => field.key === SUPPLIER_FIELDS.delivered_quantity.key)
  ) {
    const indexToInsert = fieldsConfig.findIndex(
      field => field.key === FIELDS.total_proposed_quantity.key
    );
    const arrayBeforeIndex = fieldsConfig.slice(0, indexToInsert);
    const arrayAfterIndex = fieldsConfig.slice(indexToInsert);

    return [...arrayBeforeIndex, SUPPLIER_FIELDS.delivered_quantity, ...arrayAfterIndex];
  }

  return fieldsConfig;
};

type ConfigParams = {
  onBoardStatus: RequistionOnBoardStatus;
  status: RequistionStatusLabel;
  isOnBoard: boolean;
  isForVessel: boolean;
};
const config = ({
  onBoardStatus,
  status,
  isOnBoard,
  isForVessel
}: ConfigParams): ItemFieldType[] => {
  const fieldsConfig = layout[status || onBoardStatus] || [FIELDS.code];

  return getSpecialCasesModifiedFields(
    _get(fieldsConfig, isOnBoard ? 'onboard' : 'office', fieldsConfig) as ItemFieldType[],
    { status, isForVessel, isOnBoard }
  );
};

export default config;
