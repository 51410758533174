import React, { useState } from 'react';

import { Button } from 'reactstrap';

import { useActions } from 'utils/hooks';

import { isItemOutOfTheList } from 'common/components/purchasing/requisition/store/selectors';

import binIcon from 'common/assets/svg/actions/delete.svg';
import SvgRender from 'common/components/general/SvgRender';

import * as requisitionActions from 'common/components/purchasing/requisition/store/actions';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { useSelector } from 'react-redux';

const DeleteItem = ({ itemID, categoryID }) => {
  const [removeCategoryItem] = useActions([requisitionActions.removeCategoryItem]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isOutOfTheList = useSelector(state => isItemOutOfTheList(state, itemID));

  const deleteItem = async () => {
    setIsLoading(true);

    try {
      await removeCategoryItem(itemID, categoryID);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Button
        type="button"
        color="link"
        className="p-0 text-red d-inline-flex align-items-center"
        disabled={isLoading}
        onClick={() => setIsModalOpen(true)}
      >
        <SvgRender
          className="pointer-events-none"
          src={binIcon}
          style={{ width: 14, height: 14 }}
        />
      </Button>

      <DangerousActionModal
        transparent
        onAccept={deleteItem}
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        actionText={'DELETE'}
        header={`Delete`}
        body={
          <div className="text-center">{`Are you sure you want to delete this${
            isOutOfTheList ? ' out of the list' : ''
          } item?`}</div>
        }
      />
    </>
  );
};

export default DeleteItem;
