import { combineReducers } from '@reduxjs/toolkit';
import { reducer as alerts } from 'react-notification-system-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import account from 'store/account/reducer';
import auth from 'store/auth/slice';
import asyncFilters from 'store/async-filters/reducer';
import lists from 'store/lists/reducer';
import sidebar from 'store/sidebar/reducer';
import dashboards from 'store/dashboards/reducer';
import tables from 'store/tables/reducer';
import persons from 'store/persons/reducer';
import companies from 'store/companies/reducer';
import crew from 'store/crew';
import estimations from 'store/estimations/reducer';
import estimator from 'views/estimations/estimator/state/reducer';
import favorites from 'store/favorites/reducer';
import vessels from 'store/vessels/reducer';
import vesselsAnalytics from 'store/vessels-analytics/reducer';
import vesselDocumentManager from 'store/vessel-document-manager/reducer';
import marketIndexes from 'store/market-indexes/reducer';
import bunkeringPrices from 'store/bunkering-prices/reducer';
import tools from 'store/tools/reducer';
import jobs from 'common/components/jobs/_base/store/reducer';
import jobsTemplates from 'store/jobs-templates/reducer';
import jobsRestricted from 'store/jobs-fields/reducer';
import jobsTriggers from 'store/jobs-triggers/reducer';
import findings from 'common/components/findings/store/slice';
import voyages from 'store/voyages';
import notificationModal from 'store/notification-modal/reducer';
import jobsStatuses from 'store/jobs-statuses/reducer';
import captainReports from 'captain-reports/store/reducer/reportReducer';
import captainReportsForm from 'captain-reports/store/reducer/formReducer';
import portStatement from 'captain-reports/store/port-statement/reducer';
import systemRunningHours from 'captain-reports/store/system-running-hours/reducer';
import portDocumentOverview from 'captain-reports/store/port-document-overview/reducer';
import captainReportsTemplates from 'store/captain-reports-templates/reducer';
import captainReportsAlarms from 'captain-reports/store/alarms/reducer';
import organization from 'store/organization/reducer';
import ports from 'store/ports/reducer';
import sockets from 'store/sockets/reducer';
import notifications from 'store/notifications/reducer';
import projects from 'store/projects/reducer';
import comparison from 'store/comparison/reducer';
import reminders from 'store/reminders';
import manuals from 'store/manuals';
import manualInner from 'manuals/store/reducer';
import shifts from 'crew/store/shifts/reducer';
import theFleet from 'store/the-fleet';
import beacon from 'store/beacon';
import ticketing from 'store/ticketing/reducer';
import workingSchedule from 'store/working-schedule/reducer';
import reportComparisonReducer from 'store/vessel-report-comparison/reducer';
import events from 'events/store/events/reducer';
import eventModules from 'events/store/event-modules';
import eventOverview from 'store/event-overview/reducer';
import reports from 'store/reports';
import drills from 'store/crew/drills/reducer';
import trainingTypes from 'store/crew/training-types/reducer';
import reviewProcess from 'common/components/review-process/store/reducer';
import reviewProcessTemplates from 'store/review-process-template/reducer';
import formPlanningSettings from 'store/form-planning-settings/reducer';
import formPlanning from 'common/components/forms/planning-table/store/reducer';
import pms from 'store/pms';
import onboardReducer from 'store/on-board/reducer';
import purchasing from 'store/purchasing';
import chatbox from 'common/components/chatbox/store/reducer';
import historyLogs from 'common/components/history-logs/store/reducer';
import gallery from 'common/components/gallery/store/reducer';
import formSettings from 'store/form-settings/reducer';
import filesystem from 'common/components/filesystem/store/reducer';
import mgaSettings from 'store/mga-settings';
import accountingSettings from 'store/accounting-settings';
import rootCauseAnalysis from 'store/root-cause-analysis/reducer';
import mga from 'common/components/mga/store/reducer';
import settings from 'store/settings/slice';
import inventory from 'common/components/inventory/store/slice';
import vesselCrewMatrices from 'common/components/crew/vessel-matrices/store/slice';
import newsAnnouncementsSettings from 'store/news-announcements/slice';
import news from 'common/components/news-and-announcements/store/slice';
import charterParty from 'common/components/charter-party/store';
import eventTemplates from 'events/event-templates/store/slice';
import policies from 'store/policies/slice';
import departments from 'store/departments/slice';
import roles from 'store/roles/slice';
import similarities from 'store/similarities/slice';
import tasks from 'store/tasks/slice';
import reportSetup from 'store/report-setup/slice';
import invoices from 'store/invoices/slice';

import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import _stateHistory from 'utils/state-history/reducer';

const settingsPersistConfig = {
  key: 'settings',
  storage
};

const accountPersistConfig = {
  key: 'account',
  storage,
  blacklist: ['notification_preferences']
};

const sidebarPersistConfig = {
  key: 'sidebar',
  storage,
  whitelist: ['isOtherMenuCollapse', 'savedItems', 'recents']
};

const favoritesPersistConfig = {
  key: 'favorites',
  storage
};

const dashboardsPersistConfig = {
  key: 'dashboards',
  storage,
  whitelist: ['list']
};

const toolsPersistConfig = {
  key: 'tools',
  storage
};

const jobsPersistConfig = {
  key: 'jobs',
  storage,
  whitelist: ['isA4Layout']
};

const jobsTemplatesPersistConfig = {
  key: 'jobsTemplates',
  storage,
  whitelist: ['relationTypes']
};

const jobsStatusesPersistConfig = {
  key: 'jobsStatuses',
  storage
};

const organizationConfig = {
  key: 'organization',
  storage
};

const jobRestrictedFieldsConfig = {
  key: 'jobsRestricted',
  storage
};

const comparisonPersistConfig = {
  key: 'comparison',
  storage,
  whitelist: ['fields']
};

const beaconPersistConfig = {
  key: 'beacon',
  storage,
  whitelist: ['menu']
};

const theFleetPersistConfig = {
  key: 'theFleet',
  storage,
  whitelist: ['map']
};

const listsPersistConfig = {
  key: 'lists',
  storage,
  whitelist: ['units']
};

export const history = createBrowserHistory();

export const reducers = {
  auth,
  alerts: import.meta.env.MODE === `test` ? (state = {}) => state : alerts,
  router: connectRouter(history),
  estimator,
  tables,
  persons,
  companies,
  crew,
  estimations,
  voyages,
  sockets,
  vessels,
  vesselsAnalytics,
  vesselDocumentManager,
  marketIndexes,
  notificationModal,
  asyncFilters,
  captainReports,
  captainReportsForm,
  captainReportsTemplates,
  captainReportsAlarms,
  chatbox,
  gallery,
  systemRunningHours,
  bunkeringPrices,
  _stateHistory,
  jobsTriggers,
  notifications,
  ports,
  projects,
  reminders,
  manuals,
  manualInner,
  shifts,
  portStatement,
  portDocumentOverview,
  ticketing,
  workingSchedule,
  events,
  eventModules,
  eventOverview,
  similarities,
  tasks,
  invoices,
  eventTemplates,
  reports,
  drills,
  trainingTypes,
  reviewProcess,
  reviewProcessTemplates,
  pms,
  purchasing,
  formPlanningSettings,
  formPlanning,
  formSettings,
  filesystem,
  mgaSettings,
  accountingSettings,
  rootCauseAnalysis,
  mga,
  historyLogs,
  inventory,
  vesselCrewMatrices,
  newsAnnouncementsSettings,
  news,
  charterParty,
  policies,
  departments,
  roles,
  findings,
  reportSetup,
  isOnBoard: onboardReducer,
  settings: persistReducer(settingsPersistConfig, settings),
  vesselReportComparison: reportComparisonReducer,
  lists: persistReducer(listsPersistConfig, lists),
  theFleet: persistReducer(theFleetPersistConfig, theFleet),
  account: persistReducer(accountPersistConfig, account),
  sidebar: persistReducer(sidebarPersistConfig, sidebar),
  favorites: persistReducer(favoritesPersistConfig, favorites),
  dashboards: persistReducer(dashboardsPersistConfig, dashboards),
  tools: persistReducer(toolsPersistConfig, tools),
  jobs: persistReducer(jobsPersistConfig, jobs),
  jobsStatuses: persistReducer(jobsStatusesPersistConfig, jobsStatuses),
  jobsTemplates: persistReducer(jobsTemplatesPersistConfig, jobsTemplates),
  jobsRestricted: persistReducer(jobRestrictedFieldsConfig, jobsRestricted),
  organization: persistReducer(organizationConfig, organization),
  comparison: persistReducer(comparisonPersistConfig, comparison),
  beacon: persistReducer(beaconPersistConfig, beacon)
};

function rootReducer(state, action) {
  const combinedReducer = combineReducers(reducers);

  switch (action.type) {
    default:
      return combinedReducer(state, action);
  }
}

export default rootReducer;
