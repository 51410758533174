import BudgetPercentage from './BudgetPercentage';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { selectCategory } from 'common/components/purchasing/requisition/store/selectors';
import { numberToStr } from 'common/utils/numbers';
import _get from 'lodash/get';

const Value = ({ asPrice, asPercentage, category, valueKey }) => (
  <strong className="cms-4">
    {asPrice ? `$ ` : ''}
    {numberToStr(_get(category, valueKey), 2, asPercentage ? 0 : 2)}
    {asPercentage ? ` %` : ''}
  </strong>
);

const Field = ({
  categoryID,
  label,
  valueKey,
  budgetPercentageKey,
  asPrice = false,
  asPercentage = false,
  showBudgetPercentage = false
}) => {
  const category = useSelector(state => selectCategory(state, categoryID));

  return (
    <div className="text-primary fs-12 d-inline-flex align-items-center">
      <span>{label}:</span>
      {budgetPercentageKey ? (
        <BudgetPercentage value={_get(category, budgetPercentageKey)}>
          <Value
            asPrice={asPrice}
            asPercentage={asPercentage}
            category={category}
            valueKey={valueKey}
          />
          {showBudgetPercentage ? (
            <>
              <span className="lh-16 fs-16 ps-1 cpe-4">|</span>
              <Value asPercentage category={category} valueKey={budgetPercentageKey} />
            </>
          ) : null}
        </BudgetPercentage>
      ) : (
        <Value
          asPrice={asPrice}
          asPercentage={asPercentage}
          category={category}
          valueKey={valueKey}
        />
      )}
    </div>
  );
};

Field.propTypes = {
  categoryID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  budgetPercentageKey: PropTypes.string,
  asPrice: PropTypes.bool,
  asPercentage: PropTypes.bool
};

const Layout = ({ children, inline }) => {
  if (inline) return children;

  return (
    <div className="w-100p d-flex flex-wrap align-items-center rounded bg-light-gray px-1 cpy-4">
      {children}
    </div>
  );
};

const BudgetedCategory = ({ categoryID, inline = true }) => {
  return (
    <div>
      <div
        className={`d-flex flex-wrap align-items-center lh-12 ${
          inline ? 'rounded bg-shades-2 px-1 cpy-2' : 'cmt-4'
        }`}
      >
        <Layout inline={inline}>
          <div className="pe-2 me-2 border-end border-primary">
            <Field
              categoryID={categoryID}
              label="Not Delivered"
              valueKey="budget.calculations.not_delivered"
              asPrice
            />
          </div>

          <div className="pe-2 me-2 border-end border-primary">
            <Field
              categoryID={categoryID}
              label="Delivered"
              valueKey="budget.calculations.delivered"
              asPrice
            />
          </div>

          <div className="pe-2 me-2 border-end border-primary">
            <Field
              categoryID={categoryID}
              label="Total Ordered"
              valueKey="budget.calculations.ordered_total"
              // budgetPercentageKey="budget.calculations.budget_used_percentage"
              asPrice
            />
          </div>

          <div className="me-2 pe-2 border-end border-primary">
            <Field
              categoryID={categoryID}
              label="Budget"
              valueKey="budget.amount_budgeted"
              asPrice
            />
          </div>

          <div>
            <Field
              categoryID={categoryID}
              label="Remaining"
              valueKey="budget.calculations.remaining"
              budgetPercentageKey="budget.calculations.budget_used_percentage"
              showBudgetPercentage
              asPrice
            />
          </div>
        </Layout>
      </div>
    </div>
  );
};

BudgetedCategory.propTypes = {
  categoryID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool
};

export default BudgetedCategory;
