import { FC, MouseEvent, ReactNode, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalProps } from 'reactstrap';
import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import SvgRender from '../general/SvgRender';
import useSvgIcon from '@/ts-common/utils/hooks/useSvgIcon';

interface DangerousActionModalProps extends ModalProps {
  type?: string;
  header?: ReactNode;
  icon?: string;
  children: ReactNode;
  svgColor?: string;
  svgStyle?: {
    width: number;
    height: number;
  };
  onAccept: (event: MouseEvent<HTMLDivElement>) => void;
  onCancel: (event: MouseEvent<HTMLDivElement>) => void;
  acceptButtonText?: string;
  cancelButtonText?: string;
  acceptButtonBackground?: string;
  cancelButtonBackground?: string;
  disabled?: boolean;
}

const DangerousActionModal: FC<DangerousActionModalProps> = ({
  type = 'delete',
  icon,
  header,
  children,
  svgColor = 'red',
  svgStyle = { width: 80, height: 80 },
  onAccept,
  onCancel,
  acceptButtonText = 'DELETE',
  cancelButtonText = 'NO',
  acceptButtonBackground = 'red',
  cancelButtonBackground = 'timberWolf',
  disabled,
  ...rest
}) => {
  const svgUrl = useSvgIcon(type, icon);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onAcceptHandler = async (event: MouseEvent<HTMLDivElement>) => {
    try {
      setIsSubmitting(true);
      await onAccept(event);
      onCancel(event);

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      backdrop={false}
      centered
      {...rest}
      toggle={() => null}
      contentClassName="align-items-center bg-transparent"
    >
      <ModalBody className="p-0 d-flex flex-column align-items-center">
        <ModalContainer className="d-flex align-items-center justify-content-center flex-column max-w-416 min-w-416">
          {svgUrl ? (
            <div className="mb-4">
              <SvgRender src={svgUrl} style={svgStyle} className={`text-${svgColor}`} />
            </div>
          ) : null}
          <div className="fs-28 mb-2">{header}</div>
          <div className="fs-18 fw-light text-center px-3">{children}</div>
        </ModalContainer>
      </ModalBody>
      <ModalFooter className="pt-0 pb-0 px-4 bg-transparent justify-content-center max-w-416 w-100p">
        <ModalActionsConteiner>
          <ModalAction
            onClick={onCancel}
            disabled={disabled || isSubmitting}
            color={cancelButtonBackground}
          >
            {cancelButtonText}
          </ModalAction>
          <ModalAction
            onClick={onAcceptHandler}
            disabled={disabled || isSubmitting}
            color={acceptButtonBackground}
          >
            {acceptButtonText}
          </ModalAction>
        </ModalActionsConteiner>
      </ModalFooter>
    </Modal>
  );
};

const ModalActionsConteiner = styled.div`
  box-shadow: 3px 8px 20px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
`;

const ModalAction = styled.div`
  flex: 1;
  height: ${variables.size64};
  font-size: ${variables.size16};
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: ${({ disabled }: { disabled?: boolean }) => (disabled ? 'none' : 'pointer')};
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.5 : 1)};
  transition: all 100ms ease-in-out;

  &:first-child {
    &:hover {
      color: white;
      background: ${({ color }: { color?: string }) => (color ? variables?.[color] : '')};
    }
  }

  &:last-child {
    border-left: 1px solid ${variables.platinum};

    &:hover {
      color: white;
      background: ${({ color }: { color?: string }) => (color ? variables?.[color] : '')};
    }
  }
`;

const ModalContainer = styled.div`
  color: white;
  background: rgba(128, 128, 128, 0.7);
  border-radius: 5px 5px 3px 3px;
  box-shadow: 3px 8px 20px 0 rgba(0, 0, 0, 0.25);
  min-height: 25rem;
  backdrop-filter: blur(10px);
`;

export default DangerousActionModal;
