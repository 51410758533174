import { FC, ReactNode, useEffect, useState } from 'react';
import { Size, BlobFile, Attachment } from '@/ts-common/types/files';
import { upload } from '@/api';

import _isArray from 'lodash/isArray';
import Gallery from '@/ts-common/components/gallery';
import useGallery from '@/ts-common/components/gallery/useGallery';
import Container from './Container';
import UploadedButton from './UploadButton';
import UploadedFiles from './UploadedFiles';

type UploadFilesProps = {
  size?: Size;
  color?: string;
  label?: string | React.ReactNode;
  files?: Attachment[] | Attachment; // When singleUpload = true, the `files` could be File object instead of an array
  error?: string | null; // The error message to display
  viewOnly?: boolean; // Prevent removing or uploading files
  singleUpload?: boolean; // Multiple or single file upload
  isPublic?: boolean; // Set as true if the uploaded files have to be publicly accessible
  initGalleryFiles?: boolean; // Init the gallery files on file click
  group?: string; // The s3 storage group to store the uploaded files (provided by API)
  onChange: (files: Attachment[]) => void;
  onRemoveFile?: (file: Attachment) => void; // Optional function to handle a file removal
  imageSizeLabel?: string; // Use it to select the right image size, as used in 'imageUrlSelector' from image-size.js
  accept?: string;
  uploadText?: ReactNode; // Change the upload button text
  uploadPath?: string; // Change the upload API path
  className?: string;
};

const UploadFiles: FC<UploadFilesProps> = ({
  size = 'sm',
  color = 'primary',
  label,
  files,
  error,
  viewOnly = false,
  singleUpload = false,
  isPublic = false,
  initGalleryFiles = true,
  group,
  onChange,
  onRemoveFile,
  imageSizeLabel,
  accept,
  uploadText,
  uploadPath = '/files',
  className = ''
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const uploadedFiles = (
    singleUpload && !!files && !_isArray(files) ? [files] : files
  ) as Attachment[];

  const {
    setGalleryFiles,
    toggleGallery,
    setGalleryCurrentFile,
    setGalleryRotateDirection,
    ...galleryState
  } = useGallery();

  const onUploadFiles = async (acceptedFiles: BlobFile[], restFiles: Attachment[]) => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      setUploadError(null);

      const data = new FormData();

      if (group) data.append('group', group);
      if (isPublic) data.append('public', 'true');

      acceptedFiles.forEach(file => {
        data.append('files[]', file, file.name);
      });

      upload<Attachment[]>(uploadPath, data)
        .then(res => {
          onChange([...(restFiles || []), ...res.data]);
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error);
          setUploadError('An error occurred');
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      setUploadError('An error occurred');
    }
  };

  const onRemoveFileHandler = async (file: Attachment, restFiles: Attachment[]) => {
    if (onRemoveFile) {
      await onRemoveFile(file);
    } else {
      onChange(restFiles.filter(f => f.id !== file.id));
    }
  };

  useEffect(() => {
    if (error) setUploadError(error);
    else if (!error && uploadError) setUploadError(null);
  }, [error, uploadError]);

  return (
    <Container label={label}>
      <div className={`d-flex align-items-start flex-wrap ${className}`}>
        <UploadedFiles
          size={size}
          files={uploadedFiles}
          viewOnly={viewOnly || isLoading}
          singleUpload={singleUpload}
          imageSizeLabel={imageSizeLabel}
          onRemoveFile={(file: Attachment) => onRemoveFileHandler(file, uploadedFiles)}
          initGalleryFiles={initGalleryFiles}
          setGalleryFiles={setGalleryFiles}
          setGalleryCurrentFile={setGalleryCurrentFile}
          toggleGallery={toggleGallery}
        />
        {viewOnly || (singleUpload && uploadedFiles?.length === 1) ? null : (
          <UploadedButton
            size={size}
            onUpload={newFiles => onUploadFiles(newFiles, uploadedFiles)}
            isLoading={isLoading}
            uploadError={uploadError}
            uploadText={uploadText}
            singleUpload={singleUpload}
            color={color}
            accept={accept}
          />
        )}
      </div>

      <Gallery
        setRotateDirection={setGalleryRotateDirection}
        setSelectedFile={setGalleryCurrentFile}
        toggleGallery={toggleGallery}
        {...galleryState}
      />
    </Container>
  );
};

export default UploadFiles;
