import { useCallback, useEffect } from 'react';
import { setWarning, setValue } from 'captain-reports/store/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectItineraryEditScheduledPortsOnboard } from '@/store/settings/selectors';
import { getItineraryFirstScheduledPort } from '@/api/itinerary-ports/api';
import { selectCaptainReportIsOnBoard } from '@/captain-reports/store/selectors';
import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';
import useValue from 'captain-reports/utils/useValue';
import useInitializeFromPreviousReport from '@/captain-reports/utils/useInitializeFromPreviousReport';
import _get from 'lodash/get';

const PortOfArrivalField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const dispatch = useAppDispatch();
  const enabledEditScheduledPorts = useAppSelector(selectItineraryEditScheduledPortsOnboard);
  const isOnBoard = useAppSelector(selectCaptainReportIsOnBoard);

  const currentValue = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  useInitializeFromPreviousReport({
    field: {
      ...field,
      previousReportInitializationKey: !enabledEditScheduledPorts || !isOnBoard ? 'next_port' : null
    },
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const initPort = useCallback(async () => {
    if (enabledEditScheduledPorts && isOnBoard) {
      try {
        const res = await getItineraryFirstScheduledPort();

        if (!currentValue && res?.port) {
          dispatch(
            setValue({
              key: field.key,
              value: res?.port,
              sectionLabel,
              sectionActiveTab,
              subGroup,
              subGroupIndex,
              selectKey: _get(field, 'selectRest.selectKey')
            })
          );
        } else {
          dispatch(
            setWarning({
              key: field.key,
              isRequired: true,
              error: !currentValue
                ? 'Required'
                : res?.port_id && currentValue?.id !== res?.port_id
                ? 'Unmatched scheduled port'
                : '',
              sectionLabel,
              sectionActiveTab,
              subGroup,
              subGroupIndex
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      dispatch(
        setWarning({
          key: field.key,
          isRequired: true,
          error: !currentValue ? 'Required' : '',
          sectionLabel,
          sectionActiveTab,
          subGroup,
          subGroupIndex
        })
      );
    }
  }, [
    dispatch,
    currentValue,
    enabledEditScheduledPorts,
    isOnBoard,
    field,
    sectionActiveTab,
    sectionLabel,
    subGroup,
    subGroupIndex
  ]);

  useEffect(() => {
    initPort();
  }, [initPort]);

  return (
    <DynamicSelect
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      {...field}
    />
  );
};

export default PortOfArrivalField;
