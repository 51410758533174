import React from 'react';
import PropTypes from 'prop-types';
import { useAppSelector } from '@/store/hooks';

import {
  isFirstSupplierCell,
  findWidthPerSupplier
} from 'common/components/purchasing/requisition/categories/items/helpers';
import {
  selectSupplierFullName,
  selectSupplier
} from 'common/components/purchasing/requisition/store/selectors';
import { isTheSameSupplierUsedMultipleTimes } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Field as FieldType } from 'common/entities/purchasing/PurchasingItemTypes';
import { displaySupplierDeliveryPlace } from '@/common/components/purchasing/requisition/store/helpers-ts.ts';

const SupplierHeaderName = React.memo(({ fieldsInfo }) => {
  const { fields, fieldIndex, field } = fieldsInfo;
  const isFirstCellPerSupplier = isFirstSupplierCell(fields, fieldIndex, field);
  const { supplier_sort_index, supplierRequisitionID } = field;
  const supplierName = useAppSelector(state =>
    selectSupplierFullName(state, supplierRequisitionID)
  );
  const supplier = useAppSelector(state => selectSupplier(state, supplierRequisitionID));

  const width = findWidthPerSupplier(fields, supplier_sort_index);
  const supplierUsedMultipleTimes = useAppSelector(state =>
    isTheSameSupplierUsedMultipleTimes(state, supplier?.supplier_id)
  );

  return isFirstCellPerSupplier ? (
    <div
      className={`height-16 cmb-4 px-1 border-radius-5 requisition-supplier-item supplier-index-${
        (supplier_sort_index + 1) % 10 || 10
      }  supplier-header-name d-flex align-items-center text-center`}
      style={{ width }}
    >
      <TextWithTooltip className="h-auto fw-bold fs-10 lh-1 text-white">
        {supplierName} {supplierUsedMultipleTimes ? displaySupplierDeliveryPlace(supplier) : ''}
      </TextWithTooltip>
    </div>
  ) : (
    <div className="height-16 cmb-4"></div>
  );
});

export default SupplierHeaderName;

SupplierHeaderName.displayName = 'SupplierHeaderName';
SupplierHeaderName.propTypes = {
  fieldsInfo: PropTypes.shape({
    fieldIndex: PropTypes.number.isRequired,
    field: FieldType,
    fields: PropTypes.arrayOf(FieldType)
  }),
  isDiscount: PropTypes.bool
};
