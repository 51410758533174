import { put } from '@/api';

export type ReplaceReviewProcessParams = {
  id: number;
};

export const replaceReviewProcess = async (params: ReplaceReviewProcessParams) => {
  const { id } = params;
  return (await put(`/review-processes/${id}/replace`)).data;
};
