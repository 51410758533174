import moment from 'moment';
import { mode } from 'common/components/form/inputs/date/_constants';
import { displayDate } from 'common/utils/dates';
import { hasValue } from 'common/utils/numbers';

export const displayFormFieldValue = (value, type) => {
  switch (type) {
    case `${mode.single.date}-with-time`:
    case mode.single.date:
      const hideTime = !type.endsWith('with-time');

      return (
        displayDate(value instanceof moment ? value.toString() : value, { time: hideTime }) || '-'
      );
    default:
      return value ?? '-';
  }
};

export const displayFormAddressFields = ({
  city,
  street_name,
  street_no,
  postal_code,
  country
}) => {
  return [
    city || '',
    `${street_name || ''}${street_name && street_no ? ' ' : ''}${street_no || ''}`,
    postal_code || '',
    country?.name || ''
  ]
    .filter(a => a)
    .join(', ');
};

export const displaySparePart = sparePart => {
  return `${sparePart?.drawing_no ? `${sparePart?.drawing_no}, ` : ''}${
    sparePart?.part_no ? `${sparePart?.part_no}. ` : ''
  }${sparePart?.description || '-'}`;
};

export const showSparePartsNumber = value => {
  return hasValue(value) && value !== '-';
};
