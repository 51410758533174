import { useState, useEffect, useRef, useMemo } from 'react';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import PropTypes from 'prop-types';

import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { default as ReactSelect, components } from 'react-select';
import CreateOption from 'common/components/general/CreateOption';
import useCancelablePromise from 'common/utils/hooks/useCancelablePromise';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';

import {
  basePlaceholderStyles,
  baseOptionStyles,
  baseMenuStyles
} from 'common/components/form/_selectStyles';
import { selectListDefaultOptions } from 'store/lists/selectors';

import SelectMultipleMenu from '../helpers/SelectMultipleMenu';
import SelectMultipleMultiValueContainer from '../helpers/SelectMultipleMultiValueContainer';
import SelectMultipleIndicatorsContainer from '../helpers/SelectMultipleIndicatorsContainer';
import { useSelector } from 'react-redux';
import FormFieldPreviewer from '@/ts-common/components/form/helpers/FormFieldPreviewer';

const getOption = ({ value, options = [], isMulti }, getOptionValue = null) => {
  // !! This is == on purpose, do not change this

  if (isMulti) {
    if (value && value.length) {
      return value.map(v => {
        return (options || []).find(option => {
          return findOptionKeyValue({
            option: option,
            getOptionValue: getOptionValue,
            value: v
          });
        });
      });
    } else {
      return null;
    }
  } else {
    return (options || [])?.find(option => {
      return findOptionKeyValue({ option: option, getOptionValue: getOptionValue, value: value });
    });
  }
};

const findOptionKeyValue = ({ option, getOptionValue, value }) => {
  // If we have set a key in getOptionValue we display this
  if (getOptionValue) {
    return getOptionValue(option) == value;
  }

  if (option.value) {
    return option.value == value;
  }

  if (option.id) {
    return option.id == value;
  }
};

const getSelectedValue = ({ isAsync, isMulti, options, value }, getOptionValue) => {
  if (isAsync) {
    if (value && Object.keys(value).length) {
      if (value) return value;
      else return null;
    } else {
      return null;
    }
  } else if (value !== null && value !== undefined) {
    return getOption({ value, options, isMulti }, getOptionValue);
  } else {
    return null;
  }
};

const SelectComponent = ({
  dataCy,
  width,
  size = 'sm',
  value,
  options,
  onChange,
  style,
  placeholder,
  isMulti,
  className,
  label,
  labelSmall,
  selectClassName,
  classNamePrefix,
  onCreateOption,
  loadOptions,
  disabled,
  getOptionLabel,
  getOptionValue,
  isAsync,
  isCreatable,
  isClearable,
  error,
  invisible,
  defaultOptions,
  defaultOptionsTriggerChange,
  components,
  menuPortalTarget = document.body,
  extraPortalStyle = {},
  iconLabel,
  dynamicWidth = false, // The select will have a dynamic width based on its content width
  canSelectAllOptions = true, // Use it along with isMulti to prevent selecting all menu options at once
  viewOnly = false,
  createOptionText = null,
  ...rest
}) => {
  const cancelablePromise = useCancelablePromise();
  const selectRef = useRef(null);
  const selected = getSelectedValue({ isAsync, isMulti, options, value }, getOptionValue);

  const Tag =
    isAsync && isCreatable
      ? AsyncCreatableSelect
      : isAsync
      ? AsyncSelect
      : isCreatable
      ? CreatableSelect
      : ReactSelect;

  const [defaultListKey, setDefaultListKey] = useState(null);
  const [initialAsyncOptions, setInitialAsyncOptions] = useState([]);
  const cachedListOptions = useSelector(state =>
    defaultListKey ? selectListDefaultOptions(state, defaultListKey) : []
  );

  const debounceLoadOptions =
    isAsync && loadOptions
      ? _debounce((params, callback) => {
          // loadOptions must return a promise, otherwise this will throw an error
          loadOptions(params).then(data => {
            callback(data);
          });
        }, 500)
      : false;

  const initAsyncOptions = async () => {
    if (typeof defaultOptions === 'function') {
      const res = await cancelablePromise(defaultOptions());

      if (res?.defaultListKey) {
        setDefaultListKey(res?.defaultListKey);
      } else {
        setInitialAsyncOptions(res);
      }
    }
  };

  useEffect(() => {
    if (isAsync && Array.isArray(defaultOptions) && defaultOptions.length) {
      setInitialAsyncOptions(defaultOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (isAsync && defaultOptions) {
      initAsyncOptions();
    }
  }, [defaultOptionsTriggerChange]);

  useEffect(() => {
    if (defaultListKey && cachedListOptions?.length) {
      setInitialAsyncOptions(cachedListOptions);
    }
  }, [defaultListKey, cachedListOptions?.length]);

  useSelectInputWrapper(selectRef);

  const selectStyles = useMemo(() => {
    let result = styles({ size, error });

    if (menuPortalTarget) {
      result = {
        ...result,
        menuPortal: base => ({ ...base, zIndex: 9999, ...extraPortalStyle })
      };
    }

    return result;
  }, [menuPortalTarget, size, error]);

  const fixedSelectProps = useMemo(
    () =>
      isMulti
        ? {
            isSearchable: false,
            hideSelectedOptions: false,
            closeMenuOnSelect: false,
            closeMenuOnScroll: true,
            backspaceRemovesValue: false,
            blurInputOnSelect: false
          }
        : {},
    [isMulti]
  );

  const selectValue = ({ option }) => {
    if (isAsync) {
      return option;
    } else {
      if (getOptionValue) {
        return getOptionValue(option);
      }

      if (option.value) {
        return option.value;
      }

      if (option.id) {
        return option.id;
      }
    }
  };

  if (viewOnly)
    return (
      <FormFieldPreviewer
        className={className}
        label={label}
        value={getOptionLabel && value ? getOptionLabel(value) : null}
        type="select"
      />
    );

  return (
    <FormGroupWrap
      className={`${invisible ? 'invisible-select' : ''} ${className || ''}`}
      label={label}
      error={error}
      iconLabel={iconLabel}
      dataCy={dataCy}
    >
      <Tag
        ref={selectRef}
        size={size}
        value={selected}
        options={options}
        onChange={(opt, actionTypes) => {
          if ((opt && !opt.__isNew__) || !opt) {
            const getValue = opt => (opt ? selectValue({ option: opt }) : null);
            const value = isMulti ? (opt ? opt.map(getValue) : []) : getValue(opt);

            onChange(value, opt, actionTypes?.action);
          }
        }}
        styles={selectStyles}
        style={style}
        placeholder={placeholder}
        className={`form-field react-select position-relative ${
          selectClassName ? selectClassName : ''
        } ${disabled ? 'disabled' : ''}${dynamicWidth ? ' dynamic-width' : ''}`}
        classNamePrefix={`react-select`}
        isMulti={isMulti ? isMulti : false}
        loadOptions={isAsync ? debounceLoadOptions : null}
        defaultOptions={isAsync ? initialAsyncOptions : []}
        getOptionLabel={getOptionLabel ? getOptionLabel : option => option.label}
        getOptionValue={getOptionValue ? getOptionValue : option => option.value || option.id}
        isClearable={isClearable}
        isDisabled={disabled ? disabled : false}
        isAsync={isAsync}
        onCreateOption={isCreatable && onCreateOption ? onCreateOption : null}
        components={{
          DropdownIndicator,
          Option,
          ...components,
          LoadingIndicator,
          Menu,
          ValueContainer: isMulti ? ValueContainer : components?.ValueContainer || ValueContainer,
          MultiValueContainer: SelectMultipleMultiValueContainer,
          IndicatorsContainer
        }}
        noOptionsMessage={({ inputValue }) =>
          isAsync && label
            ? !inputValue.length
              ? `Search for ${_isString(label) ? label.toLowerCase() : 'options'}`
              : `No ${_isString(label) ? label.toLowerCase() : 'options'} found`
            : 'No options'
        }
        // menuIsOpen={true}
        isValidNewOption={(inp, value, options) => {
          if (options.length > 0) return false;
          return true;
        }}
        menuPortalTarget={menuPortalTarget}
        menuPlacement="auto"
        dynamicWidth={dynamicWidth}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={false}
        canSelectAllOptions={canSelectAllOptions}
        createOptionText={createOptionText}
        {...rest}
        {...fixedSelectProps}
      />
    </FormGroupWrap>
  );
};
export default SelectComponent;

SelectComponent.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg'])
};

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        className="dropdown-arrow"
        width="10"
        height="10"
        viewBox="0 0 10 6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.8333333 0L5 3.76 1.1666667 0 0 1.12 5 6l5-4.88z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const Option = props => {
  if (props.data.__isNew__) {
    return (
      <components.Option {...props}>
        <div onClick={props.selectProps.onCreateOption}>
          <CreateOption
            label={
              props.selectProps.createOptionText ? props.selectProps.createOptionText : 'Create new'
            }
          />
        </div>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};

const Menu = ({ children, ...props }) => {
  if (props.isMulti) return <SelectMultipleMenu {...props}>{children}</SelectMultipleMenu>;

  return (
    <components.Menu className={props.selectProps?.menuClassName} {...props}>
      {children}
    </components.Menu>
  );
};

export const IndicatorsContainer = ({ children, ...props }) => {
  if (props.isMulti)
    return (
      <SelectMultipleIndicatorsContainer {...props}>{children}</SelectMultipleIndicatorsContainer>
    );

  return <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>;
};

const ValueContainer = ({ children, ...props }) => {
  // Show always the Placeholder indicator when isMulti && searching && !hasValue
  return (
    <components.ValueContainer {...props}>
      {props.isMulti && !props.hasValue && props.selectProps?.inputValue?.length ? (
        <span className="hidden-placeholder">{props.selectProps.placeholder}</span>
      ) : null}
      {children}
    </components.ValueContainer>
  );
};

const LoadingIndicator = ({ ...props }) => {
  // Show always the Clear indicator when isMulti && hasValue
  if (props.isMulti) return props.hasValue ? <components.ClearIndicator {...props} /> : null;

  return <components.LoadingIndicator {...props} />;
};

const isEntityLabeled = state => {
  if (state.hasValue && state?.selectProps?.isEntityLabeled) {
    return true;
  }

  return false;
};

export const styles = props => {
  const size = props?.size || 'sm';
  const error = props?.error || '';

  return {
    container: base => ({
      ...base,
      width: '100%'
    }),
    control: (_, state) => ({
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: variables.inputBorderRadius,
      minHeight: `${size === 'sm' ? variables.inputHeightSm : variables.inputHeightLg}`,
      maxHeight: `${size === 'sm' ? variables.inputHeightSm : variables.inputHeightLg}`,
      border: `1px solid ${
        error
          ? variables.red
          : state.isDisabled
          ? `${variables.inputDisabledBorderColor} !important`
          : state.isFocused
          ? variables.inputFocusBorderColor
          : variables.inputBorderColor
      }`,

      ['&.react-select__control--menu-is-open']: {
        ['.react-select__dropdown-indicator']: {
          transform: 'rotate(180deg)'
        }
      },

      ['&:hover']: {
        border: `1px solid ${
          state.isFocused ? variables.inputFocusBorderColor : variables.inputHoverBorderColor
        }`
      }
    }),
    indicatorsContainer: (provided, state) => {
      const styles = { ...provided };

      if (
        !state.isMulti &&
        !state.selectProps.dynamicWidth &&
        isEntityLabeled(state) &&
        !state.isDisabled
      ) {
        styles.flex = 1;
        styles.maxWidth = 'max-content';
      }

      return styles;
    },
    dropdownIndicator: (provided, state) => {
      const styles = {
        ...provided,
        color: variables.bodyColor,
        padding: 0,
        margin: `0 0.5rem 0 0`,
        display: state.isDisabled ? 'none' : 'inline-flex',
        alignItems: 'center'
      };

      if (!state.isMulti && isEntityLabeled(state)) {
        styles.marginLeft = state.selectProps.dynamicWidth ? variables.size8 : 'auto';
      }

      return styles;
    },
    indicatorSeparator: () => ({
      display: 'none'
    }),
    clearIndicator: (provided, state) => {
      const styles = {
        ...provided,
        padding: 0,
        width: '14px',
        height: '14px',
        alignItems: 'center',
        marginRight: '0.25rem',
        cursor: 'pointer',

        ['&:hover']: {
          color: variables.red
        }
      };

      if (!state.isMulti && isEntityLabeled(state)) {
        styles.zIndex = 1;
        styles.color = variables.primary;
      }

      return styles;
    },
    placeholder: basePlaceholderStyles,
    valueContainer: (provided, state) => {
      const styles = {
        ...provided,
        display: 'flex',
        flexWrap: 'wrap',
        height: 20,
        justifyContent: 'flex-start !important',
        flexDirection: 'row',
        flex: 1,
        padding: `0 0.5rem 0 ${variables.inputPaddingX}`
      };

      if (!state.isMulti && !state.isDisabled && isEntityLabeled(state)) {
        styles.flex = 1;
        styles.width = 'auto';
        styles.maxWidth = 'calc(100% - 18px)';
        styles.paddingRight = '0';

        if (state.selectProps.dynamicWidth) {
          styles.width = 'min-content';
          styles.maxWidth = 'min-content';
          styles.flex = 1;
          styles.flexWrap = 'no-wrap';
        }
      }

      return styles;
    },
    singleValue: (provided, state) => {
      const { position, top, transform, ...rest } = provided;

      const styles = {
        ...rest,
        fontWeight: variables.inputsFontWeight,
        fontSize: variables.inputsFontSize,
        lineHeight: 1.1,
        color: variables.primary,
        marginLeft: 0,
        maxWidth: '100%'
      };

      if (!state.isMulti && !state.isDisabled && isEntityLabeled(state)) {
        styles.marginRight = 0;
      }

      return styles;
    },
    multiValue: (provided, state) => {
      const styles = {
        ...provided,
        backgroundColor: 'rgba(112,132,211,0.1)',
        borderRadius: 3,
        margin: 0,
        padding: `0 ${variables.size2}`,
        minHeight: variables.size20,
        fontSize: variables.inputsFontSize,
        alignItems: 'center',
        display: 'flex',
        position: 'relative'
      };

      if (state.isMulti && isEntityLabeled(state)) {
        styles.backgroundColor = 'transparent';
      }

      return styles;
    },
    multiValueLabel: provided => ({
      ...provided,
      color: variables.primary,
      padding: 0,
      paddingLeft: 0,
      fontWeight: variables.inputsFontWeight,
      fontSize: variables.inputsFontSize,
      lineHeight: 1.1
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      paddingRight: 0,
      background: 'none !important',
      display: state.isDisabled ? 'none' : 'flex',
      color: variables.primary,

      ['&:hover']: {
        color: variables.red
      }
    }),
    menu: baseMenuStyles,
    menuList: provided => ({
      ...provided,
      overflowX: 'hidden'
    }),
    option: baseOptionStyles,
    input: provided => ({
      ...provided,
      color: variables.primary,
      marginTop: 0,
      marginBottom: 0,
      fontSize: variables.inputsFontSize
    })
  };
};

export const useSelectInputWrapper = selectRef => {
  useEffect(() => {
    // Fix the bug on react-select__input parent with class: "[class$='-Input']", that's missing on production

    if (selectRef && selectRef.current) {
      const controlRef =
        _get(selectRef.current, 'select.controlRef', null) ||
        _get(selectRef.current, 'select.select.controlRef', null);

      if (controlRef) {
        const input = controlRef.querySelector('.react-select__input');
        const inputWrapper = input ? input.parentElement : null;

        if (inputWrapper) {
          inputWrapper.classList.add('react-select__input-wrapper');
        }
      }
    }
  }, []);
};
