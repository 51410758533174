import { get, post, put } from '@/api';
import {
  InstallmentPeriodicityValue,
  Invoice,
  InvoiceDocumentTypeLabel,
  InvoiceLinkedAccountingCompanyType
} from '@/common/types/invoices.ts';

type loadInvoiceAmountsParams = {
  id: number;
};

export const loadInvoiceAmounts = async (params: loadInvoiceAmountsParams) => {
  const { id } = params;

  return (await get<InvoiceLinkedAccountingCompanyType[]>(`/invoices/${id}/load-amounts`)).data;
};

export interface AccountingCompanyParamsType {
  accounting_company_id: number;
  account_id: number | null;
  account_group_id: number | null;
  local_amount: string;
  debit_or_credit: 'debit' | 'credit';
}

export type BaseInvoiceRequestParamsType = {
  address?: string;
  invoice_no?: string;
  date?: string;
  due_date?: string;
  remarks?: string;
  amount?: string;
  is_domestic?: boolean;
  vat_percentage?: string | null;
  custom_rate?: string | null;
  is_paid?: boolean;
  company_id?: number | null;
  vendor_id?: number;
  currency_id?: number;
  country_id?: number;
  attachments?: number[] | string[];
  file_id?: number | string | null;
  pay_party_id?: number | null;
  department_ids?: number[];
  vat_no?: string | null;
  has_installments?: boolean;
  number_of_installments?: number | null;
  installments_periodicity_value?: number | null;
  installments_periodicity_type?: InstallmentPeriodicityValue | null;
  has_downpayments?: boolean;
  downpayments_amount?: number | null;
  should_send?: boolean;
  document_type?: InvoiceDocumentTypeLabel | null;
  accounting_companies?: AccountingCompanyParamsType[];
  invoice_type_id?: number;
  invoice_relation_ids?: number[];
};

export const createInvoice = async (params: BaseInvoiceRequestParamsType) => {
  return (await post<Invoice>(`/invoices`, params)).data;
};

type EditInvoiceRequestParams = BaseInvoiceRequestParamsType & { id: number };

export const editInvoice = async (params: EditInvoiceRequestParams) => {
  const { id, ...rest } = params;

  return (await put<Invoice>(`/invoices/${id}`, rest)).data;
};

export const getInvoice = async (params: { id: number | string }) => {
  const { id } = params;

  return (await get<Invoice>(`/invoices/${id}`)).data;
};
