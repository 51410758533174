import paths from 'routing/routes/_paths';
import flagExtensionDispensationColumns from './flag-extension-dispensation/columns';
import flagExtensionDispensationRows from './flag-extension-dispensation/rows';

import regularColumns from './regular/columns';
import regularRows from './regular/rows';
import { jobEnums } from 'common/utils/fixed';

const vesselExcludedColumns = [
  'vessels',
  'relates',
  'job_trigger_prototype_id',
  'template_id',
  'project_id',
  'blocked_by',
  'blocks',
  'in_project',
  'is_linked_to_findings'
];

const config = (type, isOnBoard) => {
  switch (type) {
    case jobEnums.regular:
      return {
        label: 'jobs_list',
        title: 'Regular',
        path: paths.regular_jobs,
        columns: isOnBoard
          ? regularColumns.filter(col => !vesselExcludedColumns?.includes(col.key))
          : regularColumns,
        rows: regularRows
      };

    case jobEnums.orca_jobs:
      return {
        label: 'jobs_orca_jobs_list',
        title: 'Orca Jobs',
        path: paths.orca_jobs,
        columns: isOnBoard
          ? regularColumns.filter(col => !vesselExcludedColumns?.includes(col.key))
          : regularColumns,
        rows: regularRows
      };

    case jobEnums.flag_dispensation:
      return {
        path: paths.flag_extensions_dispensations_jobs,
        title: 'Flag Extensions-Dispensations',
        label: 'jobs_flag_extensions_dispensations_list',
        columns: isOnBoard
          ? flagExtensionDispensationColumns.filter(
              col => !vesselExcludedColumns?.includes(col.key)
            )
          : flagExtensionDispensationColumns,
        rows: flagExtensionDispensationRows
      };

    default:
      return {};
  }
};

export default config;
