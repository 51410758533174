import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const FormPreviewActions = ({ onEdit, onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditHovering, setIsEditHovering] = useState(false);
  const [isDeleteHovering, setIsDeleteHovering] = useState(false);

  return (
    <>
      {onEdit && onDelete ? (
        <div className={`text-violet d-flex align-items-center fs-12 ms-2`}>
          <div
            onMouseEnter={() => setIsEditHovering(true)}
            onMouseLeave={() => setIsEditHovering(false)}
            onClick={onEdit}
            className={`${isEditHovering ? 'text-primary' : ''} me-1 cursor-pointer`}
          >
            Edit
          </div>
          |
          <div
            onMouseEnter={() => setIsDeleteHovering(true)}
            onMouseLeave={() => setIsDeleteHovering(false)}
            onClick={() => setShowDeleteModal(true)}
            className={`${isDeleteHovering ? 'text-red' : ''} ms-1 cursor-pointer`}
          >
            Remove
          </div>
        </div>
      ) : null}

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDelete}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText={'DELETE'}
        header={'Delete'}
        body={`Are you sure you want to delete this note ?`}
      />
    </>
  );
};

FormPreviewActions.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default FormPreviewActions;
