import { createSlice } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';

const INITIAL_STATE = {
  data: {},
  uuids: []
};

const slice = createSlice({
  name: 'tasks',
  initialState: INITIAL_STATE,
  reducers: {
    initializeSocketTask: (state, { payload }) => {
      state.data = { [payload?.job_uuid]: { isHandled: false }, ...state.data };
      state.uuids = [...state.uuids, payload?.job_uuid];

      return state;
    },
    receiveSocketTask: (state, { payload }) => {
      state.data = { ...state.data, [payload?.data?.job_uuid]: { ...payload, isHandled: false } };
      state.uuids = _uniq([...state.uuids, payload?.data?.job_uuid]);

      return state;
    },
    handlePendingSocketTasks: (state, { payload }) => {
      state.data = {
        ...state.data,
        [payload]: { ...state.data?.[payload], isHandled: true }
      };

      return state;
    },
    setTheTaskOuput: (state, { payload }) => {
      state.data = {
        ...state.data,
        [payload?.uuid]: {
          ...state.data?.[payload?.uuid],
          isHandled: true,
          output: payload?.output
        }
      };

      return state;
    }
  }
});

export const {
  receiveSocketTask,
  handlePendingSocketTasks,
  setTheTaskOuput,
  initializeSocketTask
} = slice.actions;

export default slice.reducer;
