import React from 'react';
import { useSelector } from 'react-redux';
import { selectCategories } from 'common/components/purchasing/requisition/store/selectors';
import Category from 'common/components/purchasing/requisition/categories/items/components/Category';
import PurchasingItems from 'common/components/purchasing/requisition/categories/PurchasingItems';
import PmsItems from 'common/components/purchasing/requisition/categories/PmsItems';

import Items from './items';

const Categories = () => {
  const categories = useSelector(selectCategories);

  return categories.map(id => (
    <div key={id}>
      <Category categoryID={id} inline={false} />
      <PurchasingItems id={id} render={() => <Items categoryID={id} />} />
      <PmsItems id={id} render={() => <Items categoryID={id} isPms={true} />} />
    </div>
  ));
};

export default Categories;
