import { useSelector } from 'react-redux';
import { selectRowSupplierTotals } from 'common/components/purchasing/requisition/store/selectors';

import BasicNumberField from './BasicNumberField';

const TotalDeliveredQuantityField = ({ itemID }) => {
  const totals = useSelector(state => selectRowSupplierTotals(state, itemID, 'delivered_quantity'));

  return <BasicNumberField value={totals} disabled={true} />;
};

export default TotalDeliveredQuantityField;
