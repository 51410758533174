import {
  selectCategoryStoreItems,
  selectCategoryPmsItems
} from 'common/components/purchasing/requisition/store/selectors';
import { useSelector } from 'react-redux';
import Item from './Item';
import Header from './Header';
import PropTypes from 'prop-types';

const Items = ({ categoryID, isPms }) => {
  const allCategoryItems = useSelector(state =>
    isPms ? selectCategoryPmsItems(state, categoryID) : selectCategoryStoreItems(state, categoryID)
  );

  return (
    <div className="mt-2 mb-3">
      <Header isPms={isPms} />
      {allCategoryItems.map((id, index) => (
        <Item itemID={id} index={index} categoryID={categoryID} />
      ))}
    </div>
  );
};

Items.propTypes = {
  categoryID: PropTypes.number,
  isPms: PropTypes.bool
};

export default Items;
