import React from 'react';
import { components } from 'react-select';
import { getPartyDisplayName } from 'common/utils/labels';
import { Link } from 'react-router-dom';

import CapitalizeText from 'common/components/general/CapitalizeText';
import EntityLabel from 'common/components/labels/EntityLabel';

import paths from 'routing/routes/_paths';

export const getDepartmentColor = data =>
  data && data.departments && data.departments[0] ? (
    <EntityLabel type="department" color={data.departments[0].color} bullet></EntityLabel>
  ) : (
    ''
  );

export const getDepartmentName = data => {
  const department = data?.departments?.[0];

  return department ? (
    <EntityLabel type="department" color={department?.color}>
      {department?.name}
    </EntityLabel>
  ) : null;
};

export const PersonWithDepartment = ({ person, link, displayClassname, className = '' }) => {
  const Name = () => (
    <span
      className={`${
        displayClassname ? displayClassname : 'text-primary d-flex align-items-center'
      }`}
    >
      {getPartyDisplayName(person)}
    </span>
  );

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {getDepartmentColor(person)}
      {link ? (
        <Link to={`${paths.persons}/${person.id}`}>
          <Name />
        </Link>
      ) : (
        <Name />
      )}
    </div>
  );
};

export const PersonWithDepartmentOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <PersonWithDepartment person={props.data} />
    </components.Option>
  );
};

export const getReportPathname = (currentPathName, report, newReport) => {
  const updatedLocation = currentPathName.split('/');
  const reportIndex = updatedLocation.findIndex(part => part === report);

  updatedLocation[reportIndex] = newReport;

  return updatedLocation.slice(0, reportIndex + 1).join('/');
};
