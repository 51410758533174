import React, { useEffect } from 'react';

import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';
import Priority from 'common/components/general/Priority';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { fetchListOptions } from 'store/lists/actions';

import { DropdownIndicator } from 'common/components/form/inputs/Select';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from 'common/entities/selectors/SelectorTypes';
import { selectListOptionsFromStore } from 'store/lists/selectors';

const ImportanceSelector = ({
  filter,
  onChange,
  isMulti,
  label,
  placeholder,
  isClearable = true,
  styled,
  withText = true,
  defaultValue = false,
  isJob = false,
  isPurchasing = false,
  ...rest
}) => {
  const SelectTag = styled ? StyledSelect : Select;

  const dispatch = useDispatch();

  const listing = isJob
    ? 'job-importances'
    : isPurchasing
    ? 'purchasing-requisition-priorities'
    : 'importances';

  const options = useSelector(state => selectListOptionsFromStore(state, listing));

  useEffect(() => {
    dispatch(fetchListOptions(listing));
  }, [dispatch, listing]);

  useEffect(() => {
    if (defaultValue && !filter?.value) {
      const defaultNormalImportance = options?.find(option => option.label === 'normal');
      onChange({ value: defaultNormalImportance?.id });
    }
  }, [defaultValue, filter?.value, onChange, options]);

  return (
    <SelectTag
      autoFocus
      label={label}
      placeholder={placeholder ? placeholder : 'Select value'}
      components={{ DropdownIndicator, Option, MultiValueLabel, SingleValue }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter?.value}
      options={options}
      isMulti={isMulti}
      onChange={selected => onChange({ value: selected })}
      isClearable={isClearable}
      withText={withText}
      {...rest}
    />
  );
};

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.label ? (
        <div className="d-flex align-items-center justify-content-center h-100p">
          <Priority
            value={props.data.label}
            name={props.data.name}
            withText={props.selectProps.withText}
          />
        </div>
      ) : null}
    </components.MultiValueLabel>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.label ? (
        <div className="d-flex align-items-center justify-content-center h-100p">
          <Priority
            value={props.data.label}
            name={props.data.name}
            withText={props.selectProps.withText}
          />
        </div>
      ) : null}
    </components.SingleValue>
  );
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.label ? (
        <Priority value={props.data.label} name={props.data.name} withText />
      ) : null}
    </components.Option>
  );
};

ImportanceSelector.propTypes = Selectors;

export default ImportanceSelector;
