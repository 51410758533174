import { useDispatch } from 'react-redux';

import CircledButton from '@/common/components/buttons/CircledButton';
import {
  selectActiveRequisitionID,
  selectRequisitionIsForVessel
} from '@/common/components/purchasing/requisition/store/selectors';
import { getPurchasingRequisitionSuppliers } from '@/common/components/purchasing/requisition/store/actions';
import Tooltip from '@/ts-common/components/general/Tooltip.tsx';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID.ts';

import { useAppSelector } from '@/store/hooks';

import {
  fillQuantityFromExistings,
  fillRevQuantityFromReQ
} from '@/common/components/purchasing/requisition/store/actions';
import useFieldIsDisabled from '@/common/components/purchasing/requisition/hooks/useFieldIsDisabled.ts';

const getTooltipMessage = fieldKey => {
  switch (fieldKey) {
    case 'available_quantity':
      return 'Fill in quantities from REV QTY';

    case 'proposed_quantity':
      return 'Fill in quantities from AVBL QTY';

    case 'approved_quantity':
      return 'Fill in quantities from PROPOSED QTY';
    case 'delivered_quantity':
      return 'Fill in quantities from ORDERED QTY';
    case 'revised_quantity':
      return 'Fill in quantities from REQ QTY';
    default:
      return '';
  }
};

const AutoFillButton = ({ categoryID, field, isPms, isOnBoard }) => {
  const dispatch = useDispatch();
  const { tooltipID, avoidRender } = useTooltipID('autofill-button-tooltip');

  const activeRequisitionId = useAppSelector(selectActiveRequisitionID);
  const isForVessel = useAppSelector(selectRequisitionIsForVessel);
  const toolTipMessage = getTooltipMessage(field.key);

  const handleFill = async () => {
    try {
      const params = {
        id: activeRequisitionId,
        field: field.key,
        item_type: isPms ? ['spare_part'] : ['store'],
        purchasing_category_id: categoryID
      };

      if (field.key === 'revised_quantity') {
        await dispatch(fillRevQuantityFromReQ(params));
      } else {
        const res = await dispatch(
          fillQuantityFromExistings({
            ...params,
            supplierID: field.supplierRequisitionID
          })
        );

        if (field.key === 'delivered_quantity' && !isForVessel && res) {
          await dispatch(getPurchasingRequisitionSuppliers({ id: activeRequisitionId }));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fieldIsDisabled = useFieldIsDisabled(field.key, {
    supplierRequisitionID: field.supplierRequisitionID
  });

  if (avoidRender || fieldIsDisabled) return null;

  return (
    <>
      <div
        id={tooltipID}
        className="d-flex align-itmes-center justify-content-center flex-1"
        data-cy="field-autofill-button"
      >
        <CircledButton
          type="reset"
          svgStyle={{ width: 10, height: 10 }}
          style={{ width: 14, height: 14 }}
          svgWrapperClassName="bg-white"
          svgClassName="text-primary"
          className="position-relative"
          onClick={handleFill}
        />
      </div>
      <Tooltip target={tooltipID}>{toolTipMessage}</Tooltip>
    </>
  );
};

export default AutoFillButton;
