import BasicNumberField from 'common/components/purchasing/requisition/categories/items/fields/BasicNumberField';
import { selectIsDifferentField } from 'common/components/purchasing/requisition/store/selectors';
import { useSelector } from 'react-redux';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

const DifferenceNumberField = ({
  value,
  itemID,
  fieldKey,
  fieldKeyToCompare,
  supplierRequisitionID,
  disabled,
  ...rest
}) => {
  const hasValue = value !== undefined;

  const isDifferent = useSelector(state =>
    selectIsDifferentField(state, itemID, fieldKey, fieldKeyToCompare, supplierRequisitionID)
  );

  const { avoidRender, tooltipID } = useTooltipID(`differnce-number-field`);

  if (avoidRender) return null;

  const hasDifference = hasValue && isDifferent;

  return (
    <>
      <div id={tooltipID}>
        <BasicNumberField
          value={value}
          itemID={itemID}
          fieldKey={fieldKey}
          supplierRequisitionID={supplierRequisitionID}
          className={hasDifference ? 'deferring-field' : ''}
          disabled={disabled}
          {...rest}
        />
      </div>
      {hasDifference ? <Tooltip target={tooltipID}>{'Changed Quantity'}</Tooltip> : null}
    </>
  );
};

export default DifferenceNumberField;
