import UploadFiles from 'common/components/form/inputs/upload-files';
import { upload, download, getApiBaseUrl } from 'utils/api';
import { useSelector } from 'react-redux';

import add from 'common/assets/svg/actions/add.svg';

const File = ({ disabled, value, onChange }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const filePreviewURL = isOnBoard ? getApiBaseUrl() : '';

  return (
    <UploadFiles
      upload={upload}
      download={download}
      showFileSize={false}
      previewImageBaseUrl={filePreviewURL}
      group="stores"
      className="square-upload mb-0 purchasing-requisition-file-field"
      uploadIcon={add}
      files={value ? [value] : []}
      onChange={files => onChange(files ? files[0] : null)}
      uploadText=" "
      singleUpload
      showFileName={false}
      showDelete
      isOnBoard={isOnBoard}
      onFileClick={() => {}}
      viewOnly={disabled}
      size="xs"
    />
  );
};

export default File;
