import TYPES from './types';
import { getItineraryPortActions } from 'common/utils/voyages/helpers';
import _cloneDeep from 'lodash/cloneDeep';

export const INITIAL_STATE = {
  port: {},
  summary: {},
  timeline: {
    itineraries: [], // [ITINERARY_ID]
    itinerary: {}, // { ITINERARY_ID: {} }
    itineraryActions: {}, // { ITINERARY_ID: [] } //
    itineraryTrip: {}, // { ITINERARY_ID: {} }
    itinerariesPaging: { current_page: 1, per_page: 20 },

    portStatement: {}, // { PORT_STATEMENT_ID: { ... port_statement } }
    deliveryReport: {}, // { DELIVERY_REPORT_ID: { ... delivery_report } }
    redeliveryReport: {}, // { REDELIVERY_REPORT_ID: { ... redelivery_report } }
    isFetching: true
  },
  weather: {
    current: {},
    daily: [],
    isFetching: true
  },
  bunker_prices: {
    data: [],
    isFetching: true
  },
  agents: {
    data: [],
    isFetching: true
  },
  actions: {
    data: {
      bunkering: { count: null, data: [] },
      discharging: { count: null, data: [] },
      loading: { count: null, data: [] }
    },
    isFetching: true
  },
  data: [],
  selectedVessels: [],
  profileLoading: false,
  portId: null
};

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case TYPES.GET_PORT.START:
      if (state.portId === payload.params.id) {
        return {
          ...state,
          portId: payload.params.id,
          profileLoading: true
        };
      } else {
        return {
          ...state,
          ..._cloneDeep(INITIAL_STATE),
          portId: payload.params.id,
          profileLoading: true
        };
      }

    case TYPES.GET_BUNKERING_PRICES.START:
      return {
        ...state,
        bunker_prices: {
          ...state.bunker_prices,
          data: [],
          isFetching: true
        }
      };

    case TYPES.GET_BUNKERING_PRICES.SUCCESS:
      return {
        ...state,
        bunker_prices: {
          ...state.bunker_prices,
          data: payload,
          isFetching: false
        }
      };

    case TYPES.GET_BUNKERING_PRICES.ERROR:
      return {
        ...state,
        bunker_prices: {
          ...state.bunker_prices,
          isFetching: false
        }
      };

    case TYPES.GET_PORT.SUCCESS:
      return {
        ...state,
        ...payload,
        profileLoading: false
      };

    case TYPES.GET_PORT_SUMMARY.START:
      return {
        ...state,
        profileLoading: true
      };

    case TYPES.GET_PORT_SUMMARY.SUCCESS:
      return {
        ...state,
        summary: payload,
        profileLoading: false
      };

    case TYPES.GET_PORT_WEATHER.START:
      return {
        ...state,
        weather: {
          ...state.weather,
          isFetching: true
        }
      };

    case TYPES.GET_PORT_WEATHER.ERROR:
      return {
        ...state,
        weather: {
          ...state.weather,
          isFetching: false
        }
      };

    case TYPES.GET_PORT_WEATHER.SUCCESS:
      return {
        ...state,
        weather: { ...payload, isFetching: false }
      };

    case TYPES.GET_PORT_TIMELINE.START:
      return {
        ...state,
        timeline: {
          ..._cloneDeep(INITIAL_STATE)?.timeline,
          isFetching: true
        }
      };

    case TYPES.GET_PORT_TIMELINE.SUCCESS:
      const itinerariesState = payload.reduce(
        (acc, cur) => {
          const { actions, port_statement, delivery_report, redelivery_report, trip, id, ...rest } =
            cur;

          if (!acc.itineraries.includes(id)) acc.itineraries.push(id);
          acc.itinerary[id] = { ...rest, port_statement_id: port_statement?.id };

          if (actions?.length || port_statement)
            acc.itineraryActions[id] = getItineraryPortActions(actions, {
              ...rest,
              port_statement,
              delivery_report,
              redelivery_report
            });

          if (port_statement) acc.portStatement[port_statement?.id] = port_statement;
          if (delivery_report) acc.deliveryReport[rest?.delivery_report_id] = delivery_report;
          if (redelivery_report)
            acc.redeliveryReport[rest?.redelivery_report_id] = redelivery_report;
          if (trip) acc.itineraryTrip[id] = trip;

          return acc;
        },
        {
          itineraries: state.timeline.itineraries,
          itinerary: state.timeline.itinerary,
          itineraryActions: state.timeline.itineraryActions,
          portStatement: state.timeline.portStatement,
          deliveryReport: state.timeline.deliveryReport,
          redeliveryReport: state.timeline.redeliveryReport,
          itineraryTrip: state.timeline.itineraryTrip
        }
      );

      return {
        ...state,
        timeline: { ...itinerariesState, isFetching: false }
      };

    case TYPES.GET_PORT.ERROR:
      return {
        ...state,
        profileLoading: false
      };

    case TYPES.SET_PORT_NAME:
      return {
        ...state,
        port: {
          ...state.port,
          ...payload
        }
      };

    case TYPES.GET_PORT_AGENTS.START:
      return {
        ...state,
        agents: {
          ...state.agents,
          data: [],
          isFetching: true
        }
      };

    case TYPES.GET_PORT_AGENTS.SUCCESS:
      return {
        ...state,
        agents: {
          ...state.agents,
          data: payload,
          isFetching: false
        }
      };

    case TYPES.GET_PORT_ACTIONS.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          isFetching: true
        }
      };

    case TYPES.GET_PORT_ACTIONS.SUCCESS:
      const category =
        payload.type === 'bunkering'
          ? {
              bunkering: {
                count: payload.data.action_count,
                data: payload.data.bunker_details
              }
            }
          : payload.type === 'loading'
          ? {
              loading: {
                count: payload.data.action_count,
                data: payload.data.cargo_details
              }
            }
          : payload.type === 'discharging'
          ? {
              discharging: {
                count: payload.data.action_count,
                data: payload.data.cargo_details
              }
            }
          : null;

      return {
        ...state,
        actions: {
          ...state.actions,
          data: {
            ...state.actions.data,
            ...category
          },
          isFetching: false
        }
      };

    case TYPES.GET_PORT_ITINERARY.SUCCESS:
      if (payload) {
        const { port_statement, delivery_report, redelivery_report, trip, id, actions, ...rest } =
          payload;

        const updatedItinerary = {
          [id]: { ...state.timeline.itinerary[id], ...rest, loaded: true }
        };
        const updatedTrip = trip ? { [id]: { ...state.timeline.itineraryTrip[id], ...trip } } : {};
        const updatedItineraryActions = {
          [id]: getItineraryPortActions(actions, {
            ...rest,
            port_statement,
            delivery_report,
            redelivery_report
          })
        };
        const updatedPortStatement = port_statement
          ? {
              [port_statement?.id]: {
                ...state.timeline.portStatement[port_statement?.id],
                ...port_statement
              }
            }
          : {};
        const updatedDelivery = delivery_report
          ? {
              [rest?.delivery_report_id]: {
                ...state.timeline.deliveryReport[rest?.delivery_report_id],
                ...delivery_report
              }
            }
          : {};
        const updatedRedelivery = redelivery_report
          ? {
              [rest?.redelivery_report_id]: {
                ...state.timeline.redeliveryReport[rest?.delivery_report_id],
                ...redelivery_report
              }
            }
          : {};

        return {
          ...state,
          timeline: {
            ...state.timeline,
            itineraries: !state.timeline.itineraries.includes(id)
              ? [id, ...state.timeline.itineraries]
              : state.timeline.itineraries,
            itinerary: { ...state.timeline.itinerary, ...updatedItinerary },
            itineraryTrip: { ...state.timeline.itineraryTrip, ...updatedTrip },
            itineraryActions: { ...state.timeline.itineraryActions, ...updatedItineraryActions },
            portStatement: { ...state.timeline.portStatement, ...updatedPortStatement },
            deliveryReport: { ...state.timeline.deliveryReport, ...updatedDelivery },
            redeliveryReport: { ...state.timeline.redeliveryReport, ...updatedRedelivery }
          }
        };
      }

      return state;

    default:
      return state;
  }
};

export default reducer;
