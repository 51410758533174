import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Col, Row } from 'reactstrap';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import Modal from 'common/components/modals/BasicModal';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Switch from '@/ts-common/components/form/inputs/Switch';

import SvgRender from 'common/components/general/SvgRender';
import { selectSystemCurrency } from 'store/settings/selectors';
import {
  selectRequisitionVessel,
  selectLastDeliveredTooltip,
  selectItemLastDeliveredData,
  selectItemBasicField
} from 'common/components/purchasing/requisition/store/selectors';

import { selectItemLastDeliveredTotals } from '@/common/components/purchasing/requisition/store/selectors-ts';
import info from 'common/assets/svg/common/info.svg';
import { toggleLastDeliveredTooltip } from '@/common/components/purchasing/requisition/store/actions';
import PageLink from 'common/components/general/page-link';

import paths from 'routing/routes/_paths';
import { strToNumber, numberToStr } from '@/ts-common/utils/numbers.ts';

import _isNumber from 'lodash/isNumber';
import { LastDeliverdItem } from '@/common/types/purchasing.ts';

const HEIGHT_OF_EACH_ROW_VESSEL = 22;

type DisplayTotalProps = {
  title?: string;
  total?: number | string | null;
  unit?: string;
  className?: string;
  innerClassName?: string;
};

type LastDeliveredTooltipMessageProps = {
  id: number;
  lastDelivered: LastDeliverdItem[];
};

type LastDeliveredTooltipProps = {
  itemID: number;
};

const DisplayTotal: React.FC<DisplayTotalProps> = ({
  title,
  total,
  unit,
  className,
  innerClassName
}) => {
  return (
    <span className={className}>
      {title}:&nbsp;
      {_isNumber(total) ? (
        <span className={innerClassName}>
          {strToNumber(total)}&nbsp;
          {unit?.toLowerCase()}
          {total !== 0 && total !== 1 && total !== -1 ? 's' : ''}
        </span>
      ) : (
        '-'
      )}
    </span>
  );
};

const LastDeliveredTooltipMessage: React.FC<LastDeliveredTooltipMessageProps> = ({
  lastDelivered = [],
  id
}) => {
  const systemCurrency = useAppSelector(selectSystemCurrency);
  const totals = useAppSelector(state => selectItemLastDeliveredTotals(state, id));
  const systemCurrencyLabel = _get(systemCurrency, 'label', '');

  const allVesselsHeight = lastDelivered.length * HEIGHT_OF_EACH_ROW_VESSEL;

  const totalQuantity = totals?.budgeted_amount ?? null;
  const totalOrdered = totals?.delivered ?? null;
  const totalRemaining = totals?.remaining ?? null;
  const totalsUnit = totals?.purchasing_unit?.name ?? '';

  return (
    <div className="d-flex flex-column w-100p text-white">
      <div className="text-white mb-2">
        <DisplayTotal
          className={'me-4'}
          title="Budgeted Quantity"
          total={strToNumber(totalQuantity)}
          unit={totalsUnit}
        />
        <DisplayTotal
          className={'me-4'}
          title="Total Ordered"
          total={totalOrdered}
          unit={totalsUnit}
        />
        <DisplayTotal
          className={'me-4'}
          title="Remaining"
          total={totalRemaining}
          unit={totalsUnit}
          innerClassName={totalRemaining !== null && totalRemaining < 0 ? 'text-red' : ''}
        />
      </div>
      {!lastDelivered.length ? (
        <div className="fs-12 d-flex justify-content-center text-white">
          No previous purchases for this item.
        </div>
      ) : (
        <div style={{ minHeight: allVesselsHeight }}>
          <Row className="w-100p fw-bold fs-10 lh-1 cmb-12">
            <Col xs={2} className="ps-3">
              REQ.NO.
            </Col>
            <Col xs={2}>SUPPLIER</Col>
            <Col xs={2}>VESSEL/COMPANY</Col>
            <Col xs={2}>PORT</Col>
            <Col xs={2}>DLV. DATE</Col>
            <Col className="pe-0" xs={1}>
              QTY
            </Col>
            <Col xs={1} className="d-flex align-items-center justify-content-start pe-0">
              UNIT PRICE
            </Col>
          </Row>

          {lastDelivered.map((item, index) => {
            const requisition_code = _get(item, 'requisition_code');
            const requisition_id = _get(item, 'requisition_id');
            const supplier_detail = _get(item, 'supplier_detail');
            const requisition_supplier = _get(supplier_detail, 'requisition_supplier');
            const unit_price = _get(supplier_detail, 'unit_price_base_currency_equivalent');
            const vesselName = _get(item, 'vessel.name', '');
            const companyName = _get(item, 'company.name', '');
            const unit = _get(item, 'item.purchasing_unit.notation', 'pc');
            return (
              <Row
                key={index}
                className={`align-items-center text-start w-100p ${
                  index < lastDelivered.length - 1 ? 'cmb-6' : ''
                }`}
              >
                <Col xs={2}>
                  <PageLink
                    to={`${paths.purchasing_requisitions}/${requisition_id}`}
                    className="text-violet"
                  >
                    <TextWithTooltip className="text-white">{requisition_code}</TextWithTooltip>
                  </PageLink>
                </Col>
                <Col xs={2}>
                  <TextWithTooltip>
                    {requisition_supplier?.supplier.full_name || '-'}
                  </TextWithTooltip>
                </Col>
                <Col xs={2}>
                  <TextWithTooltip>
                    {vesselName ? vesselName : companyName ? companyName : '-'}
                  </TextWithTooltip>
                </Col>
                <Col xs={2}>
                  <TextWithTooltip>
                    {requisition_supplier?.delivery_port?.name || '-'}
                  </TextWithTooltip>
                </Col>
                <Col xs={2}>
                  {requisition_supplier?.delivery_date
                    ? moment(requisition_supplier.delivery_date).format('DD MMM YYYY')
                    : '-'}
                </Col>
                <Col className="pe-0" xs={1}>
                  {supplier_detail.delivered_quantity
                    ? `${supplier_detail.delivered_quantity} ${unit}`
                    : '-'}
                </Col>
                <Col xs={1} className="d-flex align-items-center justify-content-start pe-0">
                  {_isEmpty(unit_price) ? (
                    '-'
                  ) : (
                    <>
                      <TextWithTooltip>
                        {numberToStr(unit_price, 2, 2)}&nbsp;{systemCurrencyLabel}/{unit}
                      </TextWithTooltip>
                    </>
                  )}
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

const LastDeliveredTooltip: React.FC<LastDeliveredTooltipProps> = ({ itemID }) => {
  const lastDelivered = useAppSelector(state => selectItemLastDeliveredData(state, itemID));
  const id = useAppSelector(state => selectItemBasicField(state, itemID, 'id'));

  const totals = useAppSelector(state => selectItemLastDeliveredTotals(state, id));

  const requisitionVessel = useAppSelector(selectRequisitionVessel);

  const [isAllVessels, setIsAllVessels] = useState(requisitionVessel?.id ? true : false);

  const dispatch = useAppDispatch();

  const lastDeliveredTooltip = useAppSelector(selectLastDeliveredTooltip);

  const data = !isAllVessels
    ? lastDelivered?.filter(
        (e: LastDeliverdItem) => e.vessel && e.vessel.id === requisitionVessel?.id
      )
    : lastDelivered;

  const hasTotalRemainingItems = _isNumber(totals?.remaining) && totals.remaining < 0;

  const onToggle = useCallback(() => {
    if (itemID === lastDeliveredTooltip.itemID) {
      dispatch(toggleLastDeliveredTooltip(false, null));
    } else {
      dispatch(toggleLastDeliveredTooltip(true, itemID));
    }
  }, [itemID, lastDeliveredTooltip.itemID, dispatch]);

  if (!data?.length) return null;

  return (
    <div className="d-flex align-items-center justify-content-end item-last-deliverd-button">
      <SvgRender
        data-cy="last-delivered-tooltip-info-icon"
        src={info}
        style={{ height: 14, width: 14 }}
        onClick={onToggle}
        className={`cursor-pointer ${hasTotalRemainingItems ? 'text-red' : ''}`}
      />

      <Modal
        size="xl"
        className="modal-dark"
        isOpen={lastDeliveredTooltip.itemID === itemID && lastDeliveredTooltip.isOpen}
        toggle={onToggle}
        header={
          <div className="d-flex align-items-center w-100p justify-content-between w-100p border-1 text-white">
            <div className="fw-bold fs-12 flex-1 d-flex justify-content-start">
              Last 12 months delivered:
            </div>

            <div className="d-flex align-items-center">
              <div className="fw-medium fs-12 me-1">
                {isAllVessels ? 'All Vessels' : requisitionVessel?.name}
              </div>
              <Switch
                className="mb-0"
                size="sm"
                onChange={() => setIsAllVessels(cur => !cur)}
                value={isAllVessels}
                disabled={!requisitionVessel?.id}
              />
            </div>
          </div>
        }
        body={<LastDeliveredTooltipMessage lastDelivered={data} id={id} />}
      />
    </div>
  );
};

export default LastDeliveredTooltip;
