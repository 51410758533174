import TYPES from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { replaceReviewProcess, ReplaceReviewProcessParams } from '@/api/review-processes/api.ts';
import { AppDispatch } from '@/store';

export const replaceReviewProcessAction = createAsyncThunk(
  'UPDATE_DELIVERY_REPORT',
  async (params: ReplaceReviewProcessParams, { rejectWithValue }) => {
    try {
      const res = await replaceReviewProcess(params);

      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetActiveEntityReviewProcess = (activeEntityId: number) => (dispatch: AppDispatch) =>
  dispatch({ type: TYPES.RESET_ACTIVE_ENTITY_REVIEW_PROCESS, payload: activeEntityId });
