import * as d3 from 'd3';

import { getTickValue } from 'common/components/graph/base/helpers/_functions';

import {
  findYAxisDimension,
  getYAxisWithDirection,
  getYAxisTitleFn,
  getDomainWithPadding,
  getCustomDomainYBasedOnValues,
  getXAxisTitleFn
} from './helpers';

import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import { hasValue } from '@/common/utils/numbers';

export const getD3XAxisTicksFn = ({ schema, x, getXAxisOption, dateTimeFormat, width }) => {
  const axis = d3.axisBottom(x);

  if (schema.isTimeseries) {
    axis.tickFormat(d3.timeFormat(dateTimeFormat));
  } else {
    axis.tickFormat(
      e => `${getXAxisOption('tickPrefix') || ''}${e}${getXAxisOption('tickSuffix') || ''}`
    );
  }

  axis.tickSizeOuter(0);

  return axis.ticks(getXAxisOption('ticks') || width / 200);
};

export const getD3YAxisTicksFn = ({ index, isAxisOnLeft, y, getYAxisOption, height }) => {
  return getYAxisTicks({ getYAxisOption, height })(
    getYAxisWithDirection(y, index, isAxisOnLeft),
    y[index]
  );
};

export const getXAxisElement =
  ({ getXAxisOption, getGraphStyle, innerGraphHeight, schema, x, dateTimeFormat, width }) =>
  g => {
    const d3AxisFn = getD3XAxisTicksFn({ schema, x, getXAxisOption, dateTimeFormat, width });

    g.call(g => g.selectAll('.tick').attr('class', `tick ${getXAxisOption('tickClass')}`));
    g.call(g => getXAxisTitleFn(g, getXAxisOption, x));

    return g
      .attr('transform', `translate(0,${innerGraphHeight + getGraphStyle('marginTop')})`)
      .call(d3AxisFn);
  };

export const getYAxisTicks =
  ({ getYAxisOption, height }) =>
  (d3AxisElement, yAxis) =>
    d3AxisElement
      .ticks(yAxis.hasVariety ? getYAxisOption('ticks') : height / 200)
      .tickSizeOuter(0)
      .tickFormat(
        e =>
          `${getYAxisOption('tickPrefix') || ''}${getTickValue(e)}${
            getYAxisOption('tickSuffix') || ''
          }`
      );

export const translateYAxes = ({
  yAxesElements,
  width,
  getGraphOption,
  getGraphStyle,
  leftOffset,
  rightOffset,
  axesDimensions
}) => {
  yAxesElements.map((yAxisElement, index) => {
    yAxisElement.element.attr(
      'transform',
      `translate(${findYAxisDimension({
        width,
        index,
        getGraphOption,
        getGraphStyle,
        leftOffset,
        rightOffset,
        axesDimensions
      })},0)`
    );
  });
};

export const getYAxesElements = ({
  element,
  yAxes,
  height,
  getYAxisOption,
  y,
  dataFromAllPlots,
  disabledPlots,
  schema
}) => {
  const axesDimensions = [];

  let hiddenAxesCount = 0;

  const yAxesElements = yAxes
    .map((axis, index) => {
      if (axis.hideAxis) {
        hiddenAxesCount++;
        return null;
      }

      const isAxisOnLeft = (index - hiddenAxesCount) % 2 === 0;

      if (schema.isTimeseries) {
        const [yMin, yMax] = getCustomDomainYBasedOnValues({
          yScale: y[index].axis,
          dataFromAllPlots,
          disabledPlots,
          getYAxisOption
        });

        if (yMin && yMax) y[index].axis.domain([yMin, yMax]);
      }

      const yAxisTicks = element
        .select(`.y-axis-${index} .y-axis-ticks__container`)
        .call(getD3YAxisTicksFn({ index, y, isAxisOnLeft, getYAxisOption, height }))
        .call(g => g.selectAll('.tick').attr('class', `tick ${getYAxisOption('tickClass') || ''}`));

      const yAxisTicksWidth = _get(yAxisTicks, '_groups[0][0]')
        ? yAxisTicks._groups[0][0].getBoundingClientRect().width
        : 0;

      const yAxis = element
        .select(`.y-axis-${index}`)
        .style('color', getYAxisOption('color'))
        .call(g =>
          getYAxisTitleFn({
            g,
            axis,
            index,
            isAxisOnLeft,
            getYAxisOption,
            yAxisTicksWidth,
            height
          })
        );

      axesDimensions.push(getYAxisOption('fixedAxisWidth') || yAxisTicksWidth);

      return { element: yAxis, yIndex: index };
    })
    .filter(t => t);
  return { axesDimensions, yAxesElements };
};

const constructCustomScale = (customYScaleType, customYScaleTypeBase, data, valueKey) => {
  if (!customYScaleType) return [null, null];

  if (customYScaleType === 'scaleLog') {
    if (customYScaleTypeBase) return d3.scaleLog().base(customYScaleTypeBase);

    return [d3.scaleLog(), null];
  }

  if (customYScaleType === 'scaleBand') {
    return [d3.scaleBand(), data.map(d => d[valueKey])];
  }

  return [d3[customYScaleType](), null];
};

export const constructYAxes = ({
  getDimensions,
  getGraphStyle,
  dataFromAllPlots,
  getYAxisOption,
  yAxes,
  schema
}) => {
  const { height } = getDimensions();

  const innerGraphHeight = height - getGraphStyle('marginBottom');

  const groupedData = _groupBy(dataFromAllPlots, 'axisIndex');
  const yAxisValueKey = _get(schema, 'yAxis');

  return yAxes.map((_, i) => {
    const customYDomain = getYAxisOption('customDomain', i) || [];
    const customYScaleType = getYAxisOption('scaleType', i);
    const customYScaleTypeBase = getYAxisOption('scaleTypeBase', i);

    const filteredData = (groupedData[i] || []).filter(t => t.y);

    let minY =
      customYDomain[0] !== 0 && !customYDomain[0]
        ? d3.min(filteredData, d => +d.y)
        : customYDomain[0];

    let maxY =
      customYDomain[1] !== 0 && !customYDomain[1]
        ? d3.max(filteredData, d => +d.y)
        : customYDomain[1];

    const hasVariety = minY !== maxY;

    const [customScale, customDomain] = constructCustomScale(
      customYScaleType,
      customYScaleTypeBase,
      filteredData,
      yAxisValueKey
    );

    return {
      hasVariety,
      axis: (customScale ?? d3.scaleLinear())
        .domain(
          customDomain ?? getDomainWithPadding([minY, hasVariety ? maxY : maxY + 1], getYAxisOption)
        )
        .range([
          innerGraphHeight,
          hasValue(getGraphStyle('yAxisTickTopPosition'))
            ? getGraphStyle('yAxisTickTopPosition')
            : getGraphStyle('marginTop')
        ])
    };
  });
};

export const constructXAxis = ({
  dataFromAllPlots,
  leftOffset,
  rightOffset,
  schema,
  getDimensions,
  getGraphStyle,
  getXAxisOption
}) => {
  const { width } = getDimensions();
  const customXDomain = getXAxisOption('customDomain');
  const xAxis = _get(schema, 'xAxis');

  const scaleType = getXAxisOption('scaleType') || 'scaleLinear';
  const marginLeft = getGraphStyle('marginLeft') + leftOffset;
  const graphWidth = width - getGraphStyle('marginRight') - rightOffset;

  const minX = d3.min(dataFromAllPlots || [], d => +d.x);
  const maxX = d3.max(dataFromAllPlots || [], d => +d.x);

  const xTimeseries = d3
    .scaleTime()
    .rangeRound([marginLeft, graphWidth])
    .domain(customXDomain || d3.extent(dataFromAllPlots.map(d => d.x)));

  let xSimple;

  if (scaleType === 'scaleBand') {
    xSimple = d3
      .scaleBand()
      .domain(customXDomain || dataFromAllPlots.map(d => d?.[xAxis]))
      .range([marginLeft, graphWidth])
      .padding(0.2);
  } else {
    xSimple = d3[scaleType]() // equals to something like d3.scaleLinear()
      .rangeRound([marginLeft, graphWidth])
      .domain(customXDomain || [minX, maxX]);
  }

  return schema.isTimeseries ? xTimeseries : xSimple;
};

export const makeGridLines = ({
  getGraphStyle,
  height,
  x,
  leftOffset,
  element,
  width,
  y,
  getXAxisOption,
  getYAxisOption
}) => {
  const xAxisGrid = d3
    .axisBottom(x)
    .tickSize(height)
    .tickFormat('')
    .ticks(getXAxisOption('ticks') || 5);

  element
    .select('.x-axis--grid')
    .call(xAxisGrid)
    .attr('transform', `translate(0 ${getGraphStyle('marginTop')})`);

  const yAxisGrid = d3
    .axisLeft(y[0].axis)
    .tickSize(-width)
    .tickFormat('')
    .ticks(getYAxisOption('ticks') || 5);
  element
    .select('.y-axis--grid')
    .call(yAxisGrid)
    .attr('display', getGraphStyle('hideYAxisGridLines') ? 'none' : 'block')
    .attr('transform', `translate(${getGraphStyle('marginLeft') + leftOffset} 0)`);
};

export * from './helpers';
