import SvgRender from '@/ts-common/components/general/SvgRender.tsx';
import comment from '@/common/assets/svg/common/comments.svg';
import commentSolid from '@/common/assets/svg/common/comments-solid.svg';
import { Button } from 'reactstrap';
import { setPurchasingRequisitionCommentsDrawerOpen } from 'common/components/purchasing/requisition/store/actions';
import { useAppDispatch } from '@/store/hooks';
import { PurchasingRequisitionItemComment } from '@/common/types/purchasing';
import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';
import { removeHtmlTags } from '@/ts-common/utils/texts';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type CommentsProps = {
  requisitionItemID?: number | null;
  itemID?: number;
  disabled?: boolean;
  value: PurchasingRequisitionItemComment[];
};

const ToggleDrawerButton: React.FC<CommentsProps> = ({
  requisitionItemID,
  itemID,
  disabled,
  value
}) => {
  const dispatch = useAppDispatch();
  const { tooltipID, avoidRender } = useTooltipID('comment');

  const openDrawer = () => {
    dispatch(setPurchasingRequisitionCommentsDrawerOpen(true, { requisitionItemID, itemID }));
  };
  const hasComments = useMemo(() => value?.length > 0, [value?.length]);

  const lastComment = useMemo(() => {
    if (hasComments) {
      const comment = _orderBy(value, 'created_at', 'desc')?.[0];

      return removeHtmlTags(comment?.text);
    }

    return null;
  }, [hasComments, value]);

  return (
    <div className="d-flex align-items-center h-100p">
      <Button
        type="button"
        color="link"
        onClick={openDrawer}
        className="text-purple p-0 me-auto ms-1 d-inline-flex align-items-center"
        disabled={disabled}
        id={tooltipID}
      >
        <SvgRender src={hasComments ? commentSolid : comment} style={{ width: 15, height: 15 }} />
      </Button>

      {!avoidRender && lastComment ? <Tooltip target={tooltipID}>{lastComment}</Tooltip> : null}
    </div>
  );
};

export default ToggleDrawerButton;
