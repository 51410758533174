import { createSelector } from 'reselect';
import { RootState } from '@/store';

type Key = string | number;

const selectReviewProcess = (state: RootState) => state.reviewProcess;

export const selectReviewProcessEntities = (state: RootState) =>
  selectReviewProcess(state)?.entities;

export const selectReviewProcessEntity = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntities(state)?.[entityId];

export const selectReviewProcessTemplateMatchedWithEntity = (state: RootState, id: Key) =>
  selectReviewProcessEntity(state, id)?.template_matches_with_entity;

export const selectReviewProcessId = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.id;

export const selectReviewProcessSteps = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.steps || [];

export const selectReviewProcessStepsLength = (state: RootState, entityId: number | string) =>
  selectReviewProcessSteps(state, entityId)?.length;

export const selectReviewProcessStepsFilledLength = createSelector(
  selectReviewProcessSteps,
  steps =>
    steps
      .map((step: any) => step.substeps.every((subStep: any) => subStep.completed_by))
      .filter((substep: any) => substep)?.length
);

export const shouldCreateReviewProcess = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.isEnabled;

export const isReviewProcessCompleted = createSelector(
  selectReviewProcessId,
  selectReviewProcessStepsLength,
  selectReviewProcessStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, steps, stepsCompleted, canCreate) =>
    (!reviewProcessId && !canCreate) ||
    (reviewProcessId && steps === stepsCompleted) ||
    (reviewProcessId && !steps)
);

export const reviewProcessHasSomeCompletedSteps = createSelector(
  selectReviewProcessId,
  selectReviewProcessStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, stepsCompleted) => (reviewProcessId && stepsCompleted ? true : false)
);
