import DifferenceNumberField from './DifferenceNumberField';

const DeliveredQuantityField = ({ itemID, supplierRequisitionID, isOnBoard, ...rest }) => {
  return (
    <DifferenceNumberField
      {...rest}
      itemID={itemID}
      supplierRequisitionID={supplierRequisitionID}
    />
  );
};

export default DeliveredQuantityField;
