import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

import _get from 'lodash/get';

/* Groups's Actions */
export const getPurchasingGroups = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_GROUPS.START, payload: { params } });

  return get(`/store-groups`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_GROUPS.SUCCESS,
        payload: { data: response.data, params }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_GROUPS.ERROR, payload: error });

      throw error;
    });
};

export const getPurchasingGroup = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_GROUP.START, payload: { params } });

  return get(`/store-groups/${params.id}`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_GROUP.SUCCESS,
        payload: { data: response.data, params }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_GROUP.ERROR, payload: error });

      throw error;
    });
};

export const createPurchasingGroup = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PURCHASING_GROUP.START, payload: { params } });

  return post(`/store-groups`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_PURCHASING_GROUP.SUCCESS, payload: response.data });
      dispatch(getPurchasingGroups());
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PURCHASING_GROUP.ERROR, payload: error });

      throw error;
    });
};

export const editPurchasingGroup = params => dispatch => {
  dispatch({ type: TYPES.EDIT_PURCHASING_GROUP.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/store-groups/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_PURCHASING_GROUP.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_PURCHASING_GROUP.ERROR, payload: error });

      throw error;
    });
};

export const deletePurchasingGroup = params => (dispatch, getState) => {
  dispatch({ type: TYPES.DELETE_PURCHASING_GROUP.START, payload: { params } });
  const { id, ...rest } = params;

  return deleteRequest(`/store-groups/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_PURCHASING_GROUP.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));
      dispatch(
        getPurchasingGroups({ search: getState().purchasing.setup.treeSearch.groups || undefined })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_GROUP.ERROR, payload: error });

      throw error;
    });
};

export const connectPurchasingGroupToMgaAccount = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.CONNECT_PURCHASING_GROUP_TO_MGA_ACCOUNT.START, payload: { params } });

  return put(`/store-groups/${id}/mga-account`, params)
    .then(response => {
      dispatch({
        type: TYPES.CONNECT_PURCHASING_GROUP_TO_MGA_ACCOUNT.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CONNECT_PURCHASING_GROUP_TO_MGA_ACCOUNT.ERROR, payload: error });

      throw error;
    });
};

/* Expand Groups */
export const expandPurchasingGroup = (id, isOpen) => dispatch => {
  dispatch({ type: TYPES.EXPAND_PURCHASING_GROUP, payload: { id, isOpen } });
};
/* -- */

/*  Modals */
export const togglePurchasingModal = type => dispatch => {
  dispatch({ type: TYPES.TOGGLE_PURCHASING_MODAL, payload: type });
};

export const setActivePurchasingGroupInModal =
  (id = null, parentId = null) =>
  dispatch => {
    dispatch({ type: TYPES.SET_ACTIVE_PURCHASING_GROUP_IN_MODAL, payload: { id, parentId } });
  };

export const setActivePurchasingItemInModal = item => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_PURCHASING_ITEM_IN_MODAL, payload: item });
};
/* -- */

/* Active Group */
export const setActiveGroupId = id => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_GROUP_ID, payload: id });
};
/* -- */

export const setTreeSearch = (type, search) => dispatch => {
  dispatch({ type: TYPES.SET_TREE_SEARCH, payload: { type, search } });
};

export const setPurchasingLibraryIsUsedIn = isUsedIn => dispatch => {
  dispatch({ type: TYPES.SET_PURCHASING_LIBRARY_IS_USED_IN, payload: isUsedIn });
};

export const setSelectedSettingsCategoryId = id => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_SETTINGS_CATEGORY_ID, payload: id });
};

/* Items's Actions */
export const getPurchasingItems =
  (requestParams = {}) =>
  (dispatch, getState) => {
    const { per_page, current_page, total } = getState().purchasing.setup.itemsPaging;
    const { itemsLoading } = getState().purchasing.setup;
    const { firstPage, ...rest } = requestParams;

    if (itemsLoading || (current_page >= total && !firstPage)) return;

    const params = {
      ...rest,
      paging: {
        per_page,
        current_page: firstPage ? 1 : current_page < total ? current_page + 1 : 1
      }
    };

    dispatch({ type: TYPES.GET_PURCHASING_ITEMS.START, payload: { params } });

    return get(`/stores`, params)
      .then(response => {
        const items = _get(response, 'data.data', []);
        const current_page = _get(response, 'data.meta.current_page', 1);
        const total = _get(response, 'data.meta.last_page', 1);

        dispatch({
          type: TYPES.GET_PURCHASING_ITEMS.SUCCESS,
          payload: { items, current_page, total }
        });

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_PURCHASING_ITEMS.ERROR, payload: error });

        throw error;
      });
  };

export const getPurchasingItem = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_ITEM.START, payload: { params } });

  return get(`/stores/${params.id}`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_ITEM.SUCCESS,
        payload: { data: response.data, params }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_ITEM.ERROR, payload: error });

      throw error;
    });
};

export const createPurchasingItem = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PURCHASING_ITEM.START, payload: { params } });

  return post(`/stores`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_PURCHASING_ITEM.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PURCHASING_ITEM.ERROR, payload: error });

      throw error;
    });
};

export const editPurchasingItem = params => dispatch => {
  dispatch({ type: TYPES.EDIT_PURCHASING_ITEM.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/stores/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_PURCHASING_ITEM.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_PURCHASING_ITEM.ERROR, payload: error });

      throw error;
    });
};

export const deletePurchasingItem = params => (dispatch, getState) => {
  dispatch({ type: TYPES.DELETE_PURCHASING_ITEM.START, payload: { params } });
  const { id, ...rest } = params;

  const activeGroupId = getState().purchasing.setup.activeGroupId;

  return deleteRequest(`/stores/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_PURCHASING_ITEM.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));
      dispatch(
        getPurchasingItems({
          search: getState().purchasing.setup.treeSearch.items || undefined,
          firstPage: true,
          store_group_id: activeGroupId || undefined
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_ITEM.ERROR, payload: error });

      throw error;
    });
};

export const setIsCreatingItem = isCreating => dispatch => {
  dispatch({ type: TYPES.SET_IS_CREATING_ITEM, payload: isCreating });
};

/* Active Item */
export const setActiveItemId = id => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_ITEM_ID, payload: id });
};

export const setTriggerItemRefetch = status => dispatch => {
  dispatch({ type: TYPES.SET_TRIGGER_ITEM_REFETCH, payload: status });
};
/* -- */

export const resetState = () => dispatch => {
  dispatch({ type: TYPES.RESET_LIBRARY_SETUP_STATE, payload: null });
};

export const initializeSelectedLibraryItemIds = data => dispatch => {
  dispatch({ type: TYPES.INITIALIZE_SELECTED_LIBRARY_ITEM_IDS, payload: data });
};

export const addSelectedLibraryItemId = data => dispatch => {
  dispatch({ type: TYPES.ADD_SELECTED_LIBRARY_ITEM_ID, payload: data });
};

export const removeSelectedLibraryItemId = data => dispatch => {
  dispatch({ type: TYPES.REMOVE_SELECTED_LIBRARY_ITEM_ID, payload: data });
};
