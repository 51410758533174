import { useAppSelector } from '@/store/hooks';
import {
  FIELDS,
  SUPPLIER_FIELDS
} from '@/common/components/purchasing/requisition/categories/items/config';
import {
  itemRevisedQuantityQuantityIsZero,
  selectActiveRequisitionID,
  selectApprovedQuantityFieldIsDisabled,
  selectCanEditRequisition,
  selectIsItemInitial,
  selectRequisitionStatusLabel,
  supplierAvailableQuantityIsZero
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import {
  getApprovedQuantityFieldIsLocked,
  getBasicItemFieldIsLocked,
  getDeliveredQuantityFieldIsLocked,
  getProposedQuantityFieldIsLocked,
  getQuotationFieldIsLocked,
  getRevisedQuantityFieldIsLocked
} from '@/common/components/purchasing/requisition/categories/items/helpers-ts';
import {
  selectOnBoardStatus,
  selectRequisitionIsForVessel,
  selectSupplierDeliveryStatus
} from '@/common/components/purchasing/requisition/store/selectors';
import { selectAccount } from '@/store/account/selectors-ts';
import { reviewProcessHasSomeCompletedSteps } from '@/common/components/review-process/store/selectors-ts';

type RestProps = { itemID?: string; supplierRequisitionID?: string }; // When in AutoFillButton, itemID is empty

const useFieldIsDisabled = (fieldKey: string, props: RestProps = {}) => {
  const { itemID, supplierRequisitionID } = props;
  const account = useAppSelector(selectAccount);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const activeID = useAppSelector(selectActiveRequisitionID);
  const isForVessel = useAppSelector(selectRequisitionIsForVessel);
  const canEditRequisition = useAppSelector(selectCanEditRequisition);
  const onBoardStatus = useAppSelector(selectOnBoardStatus);
  const status = useAppSelector(selectRequisitionStatusLabel);
  const supplierDeliveryStatus = useAppSelector(state =>
    selectSupplierDeliveryStatus(state, supplierRequisitionID)
  );

  const reviewProcessIsApproved = useAppSelector(state =>
    reviewProcessHasSomeCompletedSteps(state, activeID)
  );

  const isInitial = useAppSelector(state => (itemID ? selectIsItemInitial(state, itemID) : true));
  const revisedQuantityQuantityIsZero = useAppSelector(state =>
    itemID ? itemRevisedQuantityQuantityIsZero(state, itemID) : false
  );
  const availableQuantityIsZero = useAppSelector(state =>
    itemID && supplierRequisitionID
      ? supplierAvailableQuantityIsZero(state, itemID, supplierRequisitionID)
      : false
  );

  const approvedQuantityFieldIsDisabled = useAppSelector(state =>
    fieldKey === SUPPLIER_FIELDS.approved_quantity.key
      ? selectApprovedQuantityFieldIsDisabled(state)
      : false
  );

  switch (fieldKey) {
    case FIELDS.rob.key:
    case FIELDS.requested_packaging_id.key:
    case FIELDS.requested_quantity.key:
      return getBasicItemFieldIsLocked({ status, onBoardStatus, isOnBoard, isInitial });

    case FIELDS.comments.key:
    case FIELDS.file.key:
    case FIELDS.unlisted_description.key:
      return !canEditRequisition;

    case FIELDS.revised_quantity.key:
      return getRevisedQuantityFieldIsLocked({ account, reviewProcessIsApproved });

    case SUPPLIER_FIELDS.available_packaging_id.key:
    case SUPPLIER_FIELDS.quality_id.key:
    case SUPPLIER_FIELDS.available_quantity.key:
    case SUPPLIER_FIELDS.unit_price.key:
    case SUPPLIER_FIELDS.discount_percentage.key:
    case SUPPLIER_FIELDS.availability.key:
      return getQuotationFieldIsLocked({
        account,
        reviewProcessIsApproved,
        itemID,
        revisedQuantityQuantityIsZero
      });

    case SUPPLIER_FIELDS.proposed_quantity.key:
      return getProposedQuantityFieldIsLocked({
        account,
        reviewProcessIsApproved,
        itemID,
        revisedQuantityQuantityIsZero,
        availableQuantityIsZero
      });

    case SUPPLIER_FIELDS.approved_quantity.key:
      return (
        approvedQuantityFieldIsDisabled ||
        getApprovedQuantityFieldIsLocked({
          account,
          reviewProcessIsApproved,
          itemID,
          revisedQuantityQuantityIsZero,
          availableQuantityIsZero
        })
      );

    case SUPPLIER_FIELDS.delivered_quantity.key:
      return getDeliveredQuantityFieldIsLocked({
        itemID,
        supplierDeliveryStatus,
        isForVessel
      });

    default:
      return true;
  }
};

export default useFieldIsDisabled;
