import React from 'react';
import { Row, Col } from 'reactstrap';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import AsyncSelector from 'common/components/selectors/AsyncSelector';
import NumberInput from 'common/components/form/styled-inputs/NumberInput';
import EmptyField from './EmptyField';
import { numberToStr, strToNumber } from 'common/utils/numbers';

const MainFields = ({ fields, participantsCount, selectField, changeField, previewMode }) => {
  return (
    <div className="ps-4 offer-main-fields">
      <Row className="align-items-center">
        <Col xs={3} className={'mb-2 pe-1'}>
          {previewMode && !fields.agent.value ? (
            <EmptyField label="TRAVEL AGENT" />
          ) : (
            <AsyncSelector
              label="TRAVEL AGENT"
              onChange={e => selectField('agent')(e)}
              placeholder="Select travel agent"
              styled={true}
              name="agent"
              getOptionLabel={option => option.full_name}
              loadOptions={search => getAsyncOptions(search, 'parties', { role: 'travel_agent' })}
              defaultOptions={() =>
                getInitialAsyncValues('parties', null, false, { role: 'travel_agent' })
              }
              isDisabled={previewMode}
              {...fields.agent}
            />
          )}
        </Col>

        <Col xs={3} className="mb-2 ps-1">
          <Row className="align-items-end">
            <Col xs={4}>
              {previewMode && !fields.currency.value ? null : (
                <AsyncSelector
                  label={participantsCount > 1 ? 'INITIAL PRICE PER PERSON' : 'INITIAL PRICE'}
                  onChange={e => selectField('currency')(e)}
                  getOptionLabel={o =>
                    previewMode
                      ? `${o.symbol} ${numberToStr(fields.initial_price.value, 2, 2)}`
                      : o.name
                  }
                  type="currencies"
                  placeholder="Currency"
                  styled={true}
                  isClearable={false}
                  isDisabled={previewMode}
                  className="mb-0 text-nowrap"
                  {...fields.currency}
                />
              )}
            </Col>
            {!previewMode ? (
              <Col>
                <NumberInput
                  onChange={changeField('initial_price')}
                  disabled={previewMode}
                  className="mb-0"
                  fixedDecimalScale
                  innerClassName="fw-bold"
                  {...fields.initial_price}
                />
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col xs={3} className="mb-2">
          <Row>
            <Col xs={6} className={`h-100p d-flex align-items-center`}>
              {previewMode && !fields.discount.value ? (
                <EmptyField label="DISC %" />
              ) : (
                <NumberInput
                  label="DISC %"
                  onChange={changeField('discount')}
                  disabled={previewMode}
                  {...fields.discount}
                />
              )}
            </Col>
            {/* <Col className="h-100p d-flex align-items-center border-end border-primary me-1">
              {previewMode && !fields.tax.value ? (
                <EmptyField label="TAX %" />
              ) : (
                <NumberInput
                  className="tax-field"
                  label="Tax %"
                  onChange={changeField('tax')}
                  {...fields.tax}
                />
              )}
            </Col>
            */}
            <Col xs={5} className="border-end ms-2"></Col>
          </Row>
        </Col>
        <Col className="mb-2">
          <Row>
            <Col>
              {previewMode && !fields.payable_price.value ? (
                <EmptyField
                  label={`${participantsCount > 1 ? 'PAYABLE PRICE PER PERSON' : 'PAYABLE PRICE'} `}
                />
              ) : (
                <div>
                  <div className="form-label fs-10">{`${
                    participantsCount > 1 ? 'PAYABLE PRICE PER PERSON' : 'PAYABLE PRICE'
                  } `}</div>
                  <div className="d-flex">
                    {fields.currency.value ? (
                      <div className="d-inline-flex align-items-center text-primary">
                        <strong className="fs-12 lh-19 align-items-center">
                          {fields.currency.value.symbol}&nbsp;
                        </strong>
                      </div>
                    ) : null}
                    <NumberInput
                      label=""
                      onChange={changeField('payable_price')}
                      disabled={previewMode}
                      className="flex-1 mb-0"
                      fixedDecimalScale
                      innerClassName="fw-bold"
                      {...fields.payable_price}
                    />
                  </div>
                </div>
              )}
            </Col>
            {participantsCount > 1 ? (
              <Col>
                <div>
                  <div className="form-label text-violet cpb-1">TOTAL PRICE FOR ALL</div>
                  <div className="d-flex">
                    <div className="d-inline-flex align-items-center">
                      <strong className="fs-12 lh-19 text-violet cpt-2">
                        {fields.currency.value?.symbol}&nbsp;
                        {fields.payable_price.value && strToNumber(fields.payable_price.value) > 0
                          ? numberToStr(
                              strToNumber(fields.payable_price.value) * participantsCount,
                              2,
                              2
                            )
                          : '-'}
                      </strong>
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MainFields;
