import React from 'react';
import ShadowBox from 'common/components/general/ShadowBox';
import { useSelector } from 'react-redux';
import { selectTicketsTotals } from 'store/ticketing/selectors';
import Information from 'common/components/general/Information';
import SvgRender from 'common/components/general/SvgRender';
import budget from 'common/assets/svg/accounting/budget.svg';
import { numberToStr } from 'common/utils/numbers';

const Totals = () => {
  const totals = useSelector(selectTicketsTotals);
  return (
    <ShadowBox className="px-3 cpy-4 d-flex text-primary w-100p fs-12 cmb-12" color="light-3" flat>
      <div xs={2} className="d-flex">
        <div className="d-flex align-items-center">
          <SvgRender className="me-1" src={budget} style={{ height: 12, width: 12 }} />
          Budgeted:
        </div>
        &nbsp;
        <strong>USD&nbsp;{numberToStr(totals?.total, 2, 2)}</strong>
      </div>

      <div className="border-end border-primary mx-3 height-16"></div>

      <div className="d-flex">
        <div className="d-flex align-items-center">
          <Information
            tooltipClassname="min-width-fit max-width-fit"
            className={'me-1'}
            message={`Tickets Issued: All tickets with ''Accepted'' offer`}
            svgStyle={{ width: 10, height: 10 }}
          />
          Tickets Issued:
        </div>
        &nbsp;
        <strong>
          USD&nbsp;{numberToStr(totals.tickets_issued, 2, 2)} | {totals?.tickets_issued_percentage}
        </strong>
      </div>

      <div className="border-end border-primary mx-3 height-16"></div>

      <div className="d-flex">
        <div className="d-flex align-items-center">Remaining:</div>
        &nbsp;
        <strong>
          USD&nbsp;{numberToStr(totals.remaining, 2, 2)} | {totals?.remaining_percentage}
        </strong>
      </div>

      <div className="border-end border-primary mx-3 height-16"></div>

      <div className="d-flex">
        <div className="d-flex align-items-center">Elapsed Budgeted Time:</div>
        &nbsp;
        <strong>
          {totals?.elapsed_budget_time} | {totals?.elapsed_budget_time_percentage}
        </strong>
      </div>
    </ShadowBox>
  );
};

export default Totals;
