import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import arrowRight from 'common/assets/svg/actions/right-arrow.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  selectJobDueDate,
  selectJobDueDateChangedAt,
  selectJobDueDateChangedBy,
  selectJobDueDatePrevious
} from 'common/components/jobs/_base/store/selectors';
import { displayDate } from '@/ts-common/utils/dates';

const DueDateInputTooltip = ({ avoidRender, tooltipID }) => {
  const jobDueDate = useSelector(selectJobDueDate);
  const jobDueDateChangedAt = useSelector(selectJobDueDateChangedAt);
  const jobDueDateChangedBy = useSelector(selectJobDueDateChangedBy);
  const jobDueDatePrevious = useSelector(selectJobDueDatePrevious);

  if (avoidRender) return null;

  const hideTooltip = !jobDueDateChangedAt || !jobDueDateChangedBy || !jobDueDatePrevious;

  return (
    <Tooltip
      target={tooltipID}
      innerClassName="min-width-fit"
      className={`${hideTooltip ? 'd-none' : ''}`}
    >
      <div className="d-flex-column">
        <div className="d-flex align-items-center border-bottom-gray-200 flex-nowrap pb-1">
          {moment(jobDueDatePrevious).format('DD/MM/YYYY')}&nbsp;
          <SvgRender style={{ height: 14, width: 14 }} src={arrowRight} />
          &nbsp;{moment(jobDueDate).format('DD/MM/YYYY')}
        </div>

        <div className="fs-12 text-white flex-nowrap pt-1 w-100p text-truncate">
          Changed by <span className="fw-bold">{jobDueDateChangedBy?.full_name} </span>
          at <span className="fw-bold">{displayDate(jobDueDateChangedAt)}</span>
        </div>
      </div>
    </Tooltip>
  );
};

export default DueDateInputTooltip;
