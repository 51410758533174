export const getFilterSelectorValues = (
  selected,
  filterValues,
  isMulti = true,
  valueKey = 'id'
) => {
  const values = isMulti ? filterValues || [] : filterValues ? [filterValues] : [];

  return selected?.filter(el =>
    values?.map(v => (isNaN(v) ? v : Number(v))).includes(el?.[valueKey])
  );
};
