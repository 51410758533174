import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import spinner from 'common/assets/svg/common/spinner.svg';

const Spinner = ({ size, style, ...rest }) => (
  <div className="spinner-loader d-inline-flex align-items-center">
    <SvgRender src={spinner} style={{ width: size, height: size, ...style }} {...rest} />
  </div>
);
Spinner.defaultProps = {
  size: 32
};

export default Spinner;
