import { get } from '@/api';
import { Employee } from '@/common/types/companies';
import { PartySimilarityType } from '@/common/types/parties';

export const getCompanyPartyEmployees = async (params: { id: number }) => {
  const response = await get<Employee[]>(`/parties/${params.id}/employees`);

  return response.data;
};

export type GetPartiesSimilarityType = {
  type: 'person' | 'company';
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  company_name?: string | null;
  phone?: string | null;
  email?: string | null;
};

export const getPartiesSimilarity = async (params: GetPartiesSimilarityType) => {
  const response = await get<PartySimilarityType[]>('/parties/similarity', params);

  return response.data;
};
