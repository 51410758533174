const paths = {
  landing: '/',
  login: '/login',
  logout: '/logout',
  not_found: '/404',
  unauthorized: '/unauthorized',

  dashboard: '/dashboard',

  persons: '/persons',
  companies: '/companies',

  crew: '/crew',
  crew_planning: '/crew-planning',
  crew_evaluation_templates: '/crew-evaluation-templates',
  crew_evaluation_reasons: '/crew-evaluation-reasons',
  crew_document_types: '/crew-document-types',
  crew_matrices: '/crew-matrices',
  crew_evaluation_obligation_templates: '/crew-evaluation-obligation-templates',
  crew_evaluation_obligations: '/crew-evaluations',
  crew_evaluation_criteria: '/crew-evaluation-criteria',
  crew_document_manager: '/crew-document-manager',
  crew_seamen_contracts: '/crew-seamen-contracts',
  crew_wages: '/crew-wages',
  crew_skill_types: '/crew-skills',
  crew_ticketing_types: '/ticketing-types',
  crew_trainings: '/crew-trainings',
  crew_permissions: '/permissions/crew',
  crew_ranks: '/crew-ranks',
  crew_rank_policies: '/crew-ranks/policies',

  crew_list: '/crew-list',
  crew_watchkeeping: '/crew-watchkeeping',
  crew_holidays: '/holidays',
  crew_termination_reasons: '/crew-termination-reasons',

  drills: '/drills',

  hsqe_trainings: '/hsqe-trainings',

  reports: '/reports',
  reports_crew: '/reports/crew',
  reports_voyage: '/reports/voyage',
  reports_purchasing: '/reports/purchasing',
  reports_purchasing_items_ordered_on_board: '/reports/purchasing/items-ordered',
  reports_purchasing_requested_but_not_delivered: '/reports/purchasing/requested-but-not-delivered',
  reports_purchasing_budgets: '/reports/purchasing/budget',
  reports_past_promotions: '/reports/crew/promotions/past',
  reports_promotions: '/reports/crew/promotions',
  reports_crew_medical_statistics: '/reports/crew/crew-medical-statistics',
  reports_lubricants_supply_monitoring: '/reports/voyage/lubricants-supply-monitoring',
  reports_change_logs: '/reports/crew/crew-change-logs',
  reports_evaluations: '/reports/crew/evaluations',
  reports_training_needs: '/reports/crew/training-needs',
  reports_trainings: '/reports/crew/trainings',
  reports_retention_rate: '/reports/crew/retention-rate',
  reports_crew_wages: '/reports/crew/wages',
  reports_onboard_nationalities: '/reports/crew/onboard-nationalities',
  reports_on_signer_vessel_agent_statistics: '/reports/crew/on-signer-vessel-agent-statistics',
  reports_on_signer_evaluation_statistics: '/reports/crew/vessel-agent-evaluation-statistics',
  reports_vessel_agent_evaluation_statistics: '/reports/crew/vessel-agent-evaluation-statistics',
  reports_crew_promotions_contracts: '/reports/crew/crew-promotions-contracts',
  reports_overlapping_days: '/reports/crew/overlapping-days',
  reports_vaccinations: '/reports/crew/vaccination-report',
  reports_not_for_reemployment: '/reports/crew/not-for-reemployment',
  reports_newly_hired: '/reports/crew/newly-hired',
  reports_lubricants_remaining_days_estimator:
    '/reports/voyage/lubricants-remaining-days-estimator',
  reports_fresh_water_generator_performance: '/reports/voyage/fresh-water-generator-performance',
  reports_fresh_water_generator_performance_fleet:
    '/reports/voyage/fresh-water-generator-performance-fleet',
  reports_stern_tube_report: '/reports/voyage/stern-tube-report',
  reports_cii_reference_lines: '/reports/voyage/cii-reference-lines',
  reports_cii_fleet_running_cps: '/reports/voyage/cii-fleet-running-cps',
  reports_cii_fleet_for_one_year: '/reports/voyage/cii-fleet-year',
  reports_running_cps_redelivery_dates: '/reports/voyage/running-cps-redelivery-dates',
  reports_vessel_tce_results: '/reports/voyage/vessel-tce-results',
  reports_cii_fleet_running_legs: '/reports/voyage/cii-fleet-running-legs',
  reports_port_statement_bunkering_and_bunker_survey:
    '/reports/voyage/port-statement-bunkering-and-bunker-survey',
  reports_captain_reports_bunkers: '/reports/voyage/captain-reports-bunkers',
  reports_mrv: '/reports/voyage/mrv',
  reports_imo_dcs: '/reports/voyage/imo-dcs',
  reports_fixtures: '/reports/voyage/fixtures',
  reports_pms: '/reports/pms',
  reports_tanks_condition_summary: '/reports/pms/tanks-condition-summary',
  reports_critical_equipment_performance_test_and_data:
    '/reports/pms/critical-equipment-performance-test-and-data',
  reports_overdue_jobs: '/reports/pms/overdue-jobs',
  reports_job_statistics: '/reports/pms/job-statistics',
  reports_megger_and_vibration_report_for_motors:
    '/reports/pms/megger-and-vibration-report-for-motors',
  reports_main_engine_piston_and_cylinder_liner_wear_down:
    '/reports/pms/main-engine-piston-and-cylinder-liner-wear-down',
  reports_event: '/reports/events',
  reports_performance: '/reports/performance',
  reports_environmental: '/reports/environmental',
  reports_lubricants_fleet_consumption: '/reports/voyage/lubricants-fleet-consumption',
  reports_root_cause_analysis_statistics: 'reports/events/root-cause-analysis-statistics',
  reports_audits_and_inspections_statistics: '/reports/events/audits-and-inspections-statistics',
  reports_risk_assessment_statistics: '/reports/events/risk-assessment-statistics',
  reports_stoppages: '/reports/voyage/stoppages',
  reports_accounting: '/reports/accounting',
  reports_accounting_budgeting: '/reports/accounting/budgeting-report',
  reports_crew_debriefing: '/reports/crew/crew-debriefing',

  vessels: '/vessels',
  vessels_document_manager_per_document: '/vessels-document-manager/per-document',
  vessels_document_manager: '/vessels-document-manager/list',
  vessel_groups: '/vessel-groups',
  vessel_document_categories: '/vessels-documents-categories',
  vessel_document_types: '/vessels-documents-types',
  vessel_document_reminders: '/reminders/vessels-documents',

  voyages: '/voyages',
  port_da_items: '/port-da-items',
  port_da_cases: '/port-da-cases',
  freight_hire_collection_items: '/freight-hire-collection-items',
  freight_hire: '/freight-hire',

  position_list: '/position-list',
  fleet_crew: '/fleet-crew',

  estimations: '/estimations',
  market_indexes: '/market-indexes',
  bunker_prices: '/bunker-prices',

  captain_reports: '/captain-reports',
  captain_report_templates: '/captain-report-templates',

  comparison: '/comparison',

  profile: '/profile',

  jobs: '/jobs',
  regular_jobs: '/jobs/regular',
  flag_extensions_dispensations_jobs: '/jobs/flag-extension-dispensation',
  orca_jobs: '/jobs/orca-jobs',

  jobs_templates: '/jobs-and-findings/templates',
  jobs_triggers: '/jobs-and-findings/triggers',
  jobs_status: '/jobs-and-findings/status',
  jobs_restricted: '/jobs-and-findings/fields',
  jobs_findings: '/jobs-and-findings/findings',

  projects: '/projects',

  pms_library: '/pms-library',
  pms_configuration: '/pms-configuration',

  pms_jobs_label: '/pms-jobs',
  pms_jobs: '/pms-jobs/list',
  pms_jobs_per_system: '/pms-jobs/per-system',
  pms_running_hours: '/pms-running-hours',
  pms_inventory: '/pms-inventory',

  departments: '/departments',
  department_polices: '/department/policies',
  department_roles: '/department-roles',
  department_role_polices: '/department-roles/policies',

  persons_permissions: '/permissions/persons',
  persons_permission_policies: '/permissions/persons/policies',

  case_tags: '/case-tags',
  company_types: '/company-types',
  ports: '/ports',
  manuals: '/manuals',
  filesystem: '/filesystem',

  beacon: '/beacon',
  events: '/events',
  events_calendar: '/events-calendar',

  forms: '/forms',
  form_settings: '/form-settings',
  form_fields: '/form-settings/form-fields',
  form_planning: '/form-planning',
  form_obligations: '/form-obligations',

  event_settings: '/event-settings',
  event_templates: '/event-settings/templates',
  event_underwater_services_types: '/event-settings/underwater-services-types',
  event_off_hire: '/event-settings/event-off-hire',
  event_lub_oil_analysis: '/event-settings/lub-oil-analysis',
  event_medical_cases: '/event-settings/medical-cases',

  risk_assessment: '/risk-assessment',

  onboard_syncs: '/onboard-syncs',

  news_announcements: '/news-announcements',

  ticketing: '/ticketing',
  purchasing: '/purchasing',
  purchasing_library_setup: '/purchasing/library-setup',
  purchasing_categories: '/purchasing/categories',
  purchasing_packing: '/purchasing/packing',
  purchasing_unit_types: '/purchasing/types',
  purchasing_requisitions: '/purchasing/requisitions',
  purchasing_requisitions_ev: '/purchasing/requisitions/ev',
  purchasing_requisitions_dlv: '/purchasing/requisitions/dlv',
  purchasing_requisitions_approval: '/purchasing/requisitions/approval-view',
  purchasing_requisition_templates: '/purchasing/requisitions-templates',
  purchasing_forwarding: '/purchasing-forwarding',
  purchasing_forwarding_items: '/purchasing-forwarding/items',
  purchasing_forwarding_orders: '/purchasing-forwarding/orders',
  purchasing_forwarding_cases: '/purchasing-forwarding/cases',
  purchasing_inventory: '/purchasing-inventory',
  purchasing_pricelist: '/purchasing-pricelist',
  purchasing_budgeted_quantity: '/purchasing-budgeted-quantity',

  mga_coa_settings: '/mga-settings/coa',
  mga_action_types_settings: '/mga-settings/action-types',
  mga_items_settings: '/mga-settings/items',
  mga: '/mga',

  accounting_invoices: '/invoices',
  accounting_contracts: '/contracts',
  accounting_coa_groups_settings: '/accounting-settings/coa-groups',
  accounting_ledger_cards_settings: '/accounting-settings/ledger-cards',
  accounting_companies_settings: '/accounting-settings/companies',
  accounting_contract_types_settings: '/accounting-settings/contract-types',
  accounting_medical_expenses_settings: '/accounting-settings/medical-expenses',
  accounting_budget_settings: '/accounting-settings/budget',
  accounting_budget_scenarios_settings: '/accounting-settings/budget-scenarios',
  budgeting_report_setup: '/accounting-settings/budgeting-reports',

  root_cause_analysis: '/root-cause-analysis',
  it_dashboard: '/it-dashboard',
  performance_setups: '/performance/setups',

  inventory: '/inventory',

  vetting_setup: '/vetting-setup',

  findings: '/findings',

  policies: '/policies',
  notification_preferences_administration: '/notification-preferences-administration',
  review_process: '/review-process'
};

export const label_path = {
  document_manager_vessels_per_document: {
    path: paths.vessels_document_manager_per_document,
    label: 'Document Manager - Vessels Per document'
  },
  document_manager_vessels_list: {
    path: paths.vessels_document_manager,
    label: 'Document Manager - Vessels List'
  },
  document_manager_crew: { path: paths.crew_document_manager, label: 'Document Manager - Crew' },
  document_manager_crew_documents: {
    path: `${paths.crew_document_manager}/documents`,
    label: 'Document Manager - Crew'
  },
  crew_list: {
    path: paths.crew_list,
    label: 'Crew List'
  },
  inventory_spare_parts: {
    path: paths.pms_inventory,
    label: 'Spare Parts Inventory'
  },
  purchasing_requisitions: {
    path: paths.purchasing_requisitions,
    label: 'Purchasing Requisition'
  },
  purchasing_requisitions_dlv: {
    path: paths.purchasing_requisitions_dlv,
    label: 'Purchasing To Be Delivered'
  },
  purchasing_requisitions_ev: {
    path: paths.purchasing_requisitions_ev,
    label: 'Purchasing Delivered'
  },
  purchasing_requisitions_approvals: {
    path: paths.purchasing_requisitions_approval,
    label: 'Purchasing Approval View'
  },
  dashboard: { path: paths.dashboard, label: 'Dashboard' },
  persons: { path: paths.persons, label: 'Contacts' },
  companies: { path: paths.companies, label: 'Contacts' },
  crew: { path: paths.crew, label: 'Contacts' },
  crew_planning: { path: paths.crew_planning, label: 'Crew Planning' },
  vessels: { path: paths.vessels, label: 'Vessels' },
  voyages: { path: paths.voyages, label: 'Voyages' },
  estimations: { path: paths.estimations, label: 'Tools - Estimation' },
  'market-indexes': { path: paths.market_indexes, label: 'Tools - Market Indexes' },
  'bunker-prices': { path: paths.bunker_prices, label: 'Tools - Bunker Prices' },
  ticketing_events: { path: paths.ticketing, label: 'Tools - Ticketing' },
  ticketing_crew: { path: `${paths.ticketing}/crew`, label: 'Tools - Ticketing' },
  captain_reports: {
    path: paths.captain_reports,
    label: 'Vessel Reports - Captain Reports'
  },
  projects: {
    path: paths.projects,
    label: 'Projects'
  },
  jobs_list: {
    path: paths.regular_jobs,
    label: 'Jobs'
  },
  jobs_flag_extensions_dispensations_list: {
    path: paths.flag_extensions_dispensations_jobs,
    label: 'Jobs'
  },
  jobs_orca_jobs_list: {
    path: paths.orca_jobs,
    label: 'Jobs'
  },
  pms_jobs_list: {
    path: paths.pms_jobs,
    label: 'PMS Jobs'
  },
  pms_jobs_detailed: {
    path: paths.pms_jobs,
    label: 'PMS Jobs'
  },
  pms_jobs_per_system: {
    path: paths.pms_jobs_per_system,
    label: 'PMS Jobs / Per System'
  },
  reports_event: {
    path: paths.reports_event,
    label: 'Event Reports'
  },
  pms_reports: {
    path: paths.reports_pms,
    label: 'PMS Reports'
  },
  pms_running_hours: {
    path: paths.pms_running_hours,
    label: 'Running Hours'
  },
  reports_overdue_jobs: {
    path: paths.overdue_jobs,
    label: 'Overdue Jobs'
  },
  reports_tanks_condition_summary: {
    path: paths.reports_tanks_condition_summary,
    label: 'Tanks Condition Summary'
  },
  reports_critical_equipment_performance_test_and_data: {
    path: paths.reports_critical_equipment_performance_test_and_data,
    label: 'Critical Equipment Performance Test & Data'
  },
  reports_megger_and_vibration_report_for_motors: {
    path: paths.reports_megger_and_vibration_report_for_motors,
    label: 'Megger and Vibration Report for motors'
  },
  reports_root_cause_analysis_statistics: {
    path: paths.reports_root_cause_analysis_statistics,
    label: 'Root Cause Analysis Statistics'
  },
  reports_stoppages: {
    path: paths.reports_stoppages,
    label: 'Stoppage Report'
  },
  reports_audits_and_inspections_statistics: {
    path: paths.reports_audits_and_inspections_statistics,
    label: 'Audits and Inspections Statistics'
  },
  reports_risk_assessment_statistics: {
    path: paths.reports_risk_assessment_statistics,
    label: 'Risk Assessment Statistics'
  },
  reports_job_statistics: {
    path: paths.reports_job_statistics,
    label: 'Job Statistics for a Specific Period'
  },
  reports_crew_wages: {
    path: paths.reports_crew_wages,
    label: 'Crew Wages Report'
  },
  reports_crew_medical_statistics: {
    path: paths.reports_crew_medical_statistics,
    label: 'Crew Medical'
  },
  jobs_triggers: { path: paths.jobs_triggers, label: 'Jobs & Findings - Triggers' },
  comparison: {
    path: paths.comparison,
    label: 'Comparison'
  },
  vessel_document_reminders: {
    path: paths.vessel_document_reminders,
    label: 'Reminders on Docs Reminders'
  },
  reports_crew: {
    path: paths.reports_crew,
    label: 'Crew Reports'
  },
  reports_past_promotions: {
    path: paths.reports_past_promotions,
    label: 'Reports Past Promotion'
  },
  reports_promotions: {
    path: paths.reports_promotions,
    label: 'Reports Promotions'
  },
  events: {
    path: paths.events,
    label: 'Events'
  },
  form_submissions: {
    path: paths.forms,
    label: 'Forms'
  },
  ports: {
    path: paths.ports,
    label: 'Ports'
  },
  reports_voyage: {
    path: paths.reports_voyage,
    label: 'Voyage Reports'
  },
  reports_purchasing: {
    path: paths.reports_purchasing,
    label: 'Purchasing Reports'
  },
  reports_purchasing_items_ordered_on_board: {
    path: paths.reports_purchasing_items_ordered_on_board,
    label: 'Purchasing Items Ordered Report'
  },
  reports_purchasing_budgets: {
    path: paths.reports_purchasing_budgets,
    label: 'Purchasing Budget Report'
  },
  reports_lubricants_supply_monitoring: {
    path: paths.reports_lubricants_supply_monitoring,
    label: 'Lubricants Supply Monitoring'
  },
  reports_lubricants_remaining_days_estimator: {
    path: paths.reports_lubricants_remaining_days_estimator,
    label: 'Lubricants Remaining Days Estimator'
  },
  reports_fresh_water_generator_performance: {
    path: paths.reports_fresh_water_generator_performance,
    label: 'Fresh Water Generator Performance'
  },
  reports_fresh_water_generator_performance_fleet: {
    path: paths.reports_fresh_water_generator_performance_fleet,
    label: 'Fresh Water Generator Performance - Fleet'
  },
  reports_mrv: {
    path: paths.reports_mrv,
    label: 'EU MRV'
  },
  reports_imo_dcs: {
    path: paths.reports_imo_dcs,
    label: 'IMO DCS'
  },
  reports_stern_tube_report: {
    path: paths.reports_stern_tube_report,
    label: 'Stern Tube Report'
  },
  reports_cii_reference_lines: {
    path: paths.reports_cii_reference_lines,
    label: 'Reference Lines for the whole Fleet'
  },
  reports_cii_fleet_for_one_year: {
    path: paths.reports_cii_fleet_for_one_year,
    label: 'Fleet for one Year'
  },
  reports_cii_fleet_running_legs: {
    path: paths.reports_cii_fleet_running_legs,
    label: 'Fleet for one Year'
  },
  reports_port_statement_bunkering_and_bunker_survey: {
    path: paths.reports_port_statement_bunkering_and_bunker_survey,
    label: 'Port Statement Bunkering & Bunker Surveys'
  },
  reports_captain_reports_bunkers: {
    path: paths.reports_captain_reports_bunkers,
    label: 'Captain Reports Bunkers'
  },
  reports_change_logs: {
    path: paths.reports_change_logs,
    label: 'Change Logs'
  },
  reports_evaluations: {
    path: paths.reports_evaluations,
    label: 'Evaluations'
  },
  reports_onboard_nationalities: {
    path: paths.reports_onboard_nationalities,
    label: 'Nationalities'
  },
  reports_on_signer_vessel_agent_statistics: {
    path: paths.reports_on_signer_vessel_agent_statistics,
    label: 'Seamen Onboard Statistics'
  },

  reports_on_signer_evaluation_statistics: {
    path: paths.reports_on_signer_evaluation_statistics,
    label: 'Evaluation Statistics'
  },

  reports_vessel_agent_evaluation_statistics: {
    path: paths.reports_vessel_agent_evaluation_statistics,
    label: 'On Signer Evaluation Statistics'
  },

  reports_overlapping_days: {
    path: paths.reports_overlapping_days,
    label: 'Overlapping Days'
  },

  reports_training_needs: {
    path: paths.reports_training_needs,
    label: 'Training Needs'
  },

  reports_trainings: {
    path: paths.reports_trainings,
    label: 'Trainings'
  },

  reports_retention_rate: {
    path: paths.reports_retention_rate,
    label: 'Retention Rate'
  },

  reports_vaccinations: {
    path: paths.reports_vaccinations,
    label: 'Vaccination Report'
  },
  reports_not_for_reemployment: {
    path: paths.reports_not_for_reemployment,
    label: 'Not for Reemployment'
  },
  reports_newly_hired: {
    path: paths.reports_newly_hired,
    label: 'Newly Hired'
  },
  reports_fixtures: {
    path: paths.reports_fixtures,
    label: 'Fixtures'
  },
  form_fields: {
    path: paths.form_fields,
    label: 'Form Fields'
  },
  form_planning: {
    path: paths.form_planning,
    label: 'Form Planning'
  },
  mga_action_types: {
    path: paths.mga_action_types_settings,
    label: 'MGA Actions'
  },
  accounting_budget: {
    path: paths.accounting_budget_settings,
    label: 'Budget'
  },
  root_cause_analysis: {
    path: paths.root_cause_analysis,
    label: 'Root Cause Analysis'
  },
  it_dashboard: {
    path: paths.it_dashboard,
    label: 'IT Dashboard'
  },
  crew_evaluation_obligations: {
    path: paths.crew_evaluation_obligations,
    label: 'Evaluation'
  },
  event_templates: {
    path: paths.event_templates,
    label: 'Event Templates'
  },
  event_underwater_services_types: {
    path: paths.event_underwater_services_types,
    label: 'Event Underwater Svc. Types'
  },
  event_off_hire: {
    path: paths.event_off_hire,
    label: 'Event Off Hire'
  },
  vetting_setup: {
    path: paths.vetting_setup,
    label: 'Vetting Setup'
  },
  reports_findings: {
    path: paths.findings,
    label: 'Findings'
  },
  purchasing_budgeted_quantity: {
    path: paths.purchasing_budgeted_quantity,
    label: 'Purchasing Budgeted Quantity'
  }
};

export default paths;
