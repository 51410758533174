import { FC, useCallback, useState } from 'react';
import { ItemFieldType } from '../config';
import { selectSupplierStatus } from '@/common/components/purchasing/requisition/store/selectors-ts';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  allSupplierItemFieldsHaveValue,
  someSupplierItemFieldsHaveValue,
  selectItemSupplierDetailsField
} from 'common/components/purchasing/requisition/store/selectors-ts';
import {
  selectItemBasicField,
  selectActiveRequisitionID
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import {
  bulkUpdatePurchasingRequisitionCategoryItemsAction,
  getPurchasingRequisitionItems,
  setItemField,
  updatePurchasingRequisitionItem
} from '@/common/components/purchasing/requisition/store/actions';

import ActivationCirlce from '@/common/components/general/ActivationCirlce';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import check from '@/common/assets/svg/common/check-circle-solid.svg';
import dashCircled from '@/common/assets/svg/common/dash-circle.svg';
import circle from '@/common/assets/svg/common/circle.svg';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type AutoIncludeItemButtonProps = {
  isFooter?: boolean;
  isHidden?: boolean;
  supplierRequisitionID?: string;
  itemID: string;
  categoryID: number;
};

const includedInKey = 'included_in_qtn';

const bulkInfo = {
  set_included_in_qtn: 'Include all in Quotation',
  set_not_included_in_qtn: 'Exclude all from Quotation'
};
const activationInfo = {
  set_included_in_qtn: 'Include in Quotation',
  set_not_included_in_qtn: 'Exclude from Quotation',
  is_included_in_qtn: 'Included in Quotation',
  is_not_included_in_qtn: 'Excluded from Quotation'
};

const AutoIncludeItemButton: FC<AutoIncludeItemButtonProps> = ({
  isFooter,
  isHidden,
  supplierRequisitionID,
  itemID,
  categoryID
}) => {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const activeId = useAppSelector(selectActiveRequisitionID);
  const { tooltipID, avoidRender } = useTooltipID('include-btn');

  const allSelected = useAppSelector(state =>
    isFooter && supplierRequisitionID
      ? allSupplierItemFieldsHaveValue(state, includedInKey, supplierRequisitionID, categoryID)
      : false
  );
  const someSelected = useAppSelector(state =>
    isFooter && supplierRequisitionID
      ? someSupplierItemFieldsHaveValue(state, includedInKey, supplierRequisitionID, categoryID)
      : false
  );
  const fieldValue = useAppSelector(state =>
    !isFooter && supplierRequisitionID
      ? selectItemSupplierDetailsField(state, itemID, includedInKey, supplierRequisitionID)
      : false
  );
  const requisitionItemID = useAppSelector(state =>
    selectItemBasicField(state, itemID, 'requisition_item_id')
  );

  const onBulkUpdate = useCallback(
    async (value: boolean) => {
      if (!supplierRequisitionID) return;

      try {
        setIsSaving(true);
        await dispatch(
          bulkUpdatePurchasingRequisitionCategoryItemsAction({
            [includedInKey]: value,
            categoryID,
            supplierRequisitionID
          })
        );

        await dispatch(
          getPurchasingRequisitionItems({
            id: activeId,
            requisition_supplier_id: supplierRequisitionID
          })
        );
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
      }
    },
    [dispatch, supplierRequisitionID, activeId, categoryID]
  );

  const onSingleFieldUpdate = useCallback(
    async (value: boolean) => {
      if (!supplierRequisitionID) return;

      try {
        setIsSaving(true);
        dispatch(setItemField({ itemID, supplierRequisitionID }, { [includedInKey]: value }));

        await dispatch(
          updatePurchasingRequisitionItem({
            [includedInKey]: value,
            id: requisitionItemID,
            supplierRequisitionID: supplierRequisitionID
          })
        );

        await dispatch(
          getPurchasingRequisitionItems({
            id: activeId,
            requisition_supplier_id: supplierRequisitionID
          })
        );
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        dispatch(setItemField({ itemID, supplierRequisitionID }, { [includedInKey]: !value }));
      }
    },
    [dispatch, supplierRequisitionID, requisitionItemID, itemID, activeId]
  );

  return (
    <>
      <div id={tooltipID}>
        {isFooter ? (
          isHidden ? null : (
            <CircledButton
              icon={allSelected ? check : someSelected ? dashCircled : circle}
              size={16}
              svgColor={allSelected || someSelected ? 'green' : 'inactive'}
              svgStyle={{ width: 10, height: 10 }}
              disabled={isSaving}
              onClick={() => onBulkUpdate(allSelected ? false : true)}
            />
          )
        ) : (
          <ActivationCirlce
            isActive={!!fieldValue}
            disabled={isSaving || isHidden}
            onClick={() => onSingleFieldUpdate(!fieldValue)}
          />
        )}
      </div>

      {!avoidRender ? (
        <Tooltip target={tooltipID}>
          {isFooter
            ? allSelected
              ? bulkInfo[`set_not_${includedInKey}`]
              : bulkInfo[`set_${includedInKey}`]
            : isHidden
            ? fieldValue
              ? activationInfo[`is_${includedInKey}`]
              : activationInfo[`is_not_${includedInKey}`]
            : fieldValue
            ? activationInfo[`set_not_${includedInKey}`]
            : activationInfo[`set_${includedInKey}`]}
        </Tooltip>
      ) : null}
    </>
  );
};

type SupplierincludedInKeyQtnButtonProps = {
  isFooter: boolean;
  field: ItemFieldType;
  itemID: string;
  categoryID: number;
};

const SupplierincludedInKeyQtnButton: FC<SupplierincludedInKeyQtnButtonProps> = ({
  isFooter,
  itemID,
  categoryID,
  field
}) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const supplierStatus = useAppSelector(state =>
    field.supplierRequisitionID ? selectSupplierStatus(state, field.supplierRequisitionID) : null
  );
  const isHidden = supplierStatus?.label !== 'qtn';

  if (isOnBoard) return null;

  return (
    <div className="cme-n4">
      <AutoIncludeItemButton
        isFooter={isFooter}
        isHidden={isHidden}
        itemID={itemID}
        categoryID={categoryID}
        supplierRequisitionID={field.supplierRequisitionID}
      />
    </div>
  );
};

export default SupplierincludedInKeyQtnButton;
