import FormsSelector from 'common/components/selectors/FormsSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import DepartmentRolesSelector from 'common/components/selectors/DepartmentRolesSelector';
import VesselSelector from 'common/components/selectors/VesselSelector';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import eventsIcon from 'common/assets/svg/common/events.svg';
import FormObligationTriggerSelector from 'common/components/selectors/FormObligationTriggerSelector';
import ListSelect from 'common/components/selectors/ListSelect';
import CrewSelector from 'common/components/selectors/CrewSelector';

import vesselIcon from 'common/assets/svg/common/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import crewIcon from 'common/assets/svg/common/crew_planning.svg';

import { stringifyObj } from 'utils/urls';
import paths from 'routing/routes/_paths';

export const getColumns = isOnBoard => [
  {
    header: 'Form',
    key: 'form',
    type: 'collection',
    sticky: true,
    left: 0,
    minWidth: 370,
    maxWidth: 370,
    sort: true,
    canFilter: true,
    component: FormsSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Trigger',
    key: 'trigger',
    sticky: true,
    left: 370,
    minWidth: 370,
    maxWidth: 370,
    sort: true,
    canFilter: false
  },
  {
    header: 'Trigger',
    key: 'trigger_type_id',
    type: 'collection',
    component: FormObligationTriggerSelector,
    hiddenOnBoard: true,
    componentRest: { isMulti: true },
    sort: false,
    hidden: true,
    canFilter: true
  },
  {
    header: 'For The Event Type',
    key: 'event_type',
    type: 'collection',
    minWidth: 250,
    maxWidth: 250,
    sort: true,
    canFilter: true,
    component: ListSelect,
    componentRest: {
      isMulti: true,
      list: 'event-types',
      getOptionValue: option => option.id
    }
  },
  {
    header: 'Event Date',
    key: 'event_date',
    data_key: 'trigger_entity.started_at',
    type: 'date',
    minWidth: 120,
    maxWidth: 120,
    sort: true,
    canFilter: true
  },
  {
    header: 'DOS',
    key: 'date_of_switch',
    type: 'date',
    minWidth: 105,
    maxWidth: 105,
    sort: true,
    canFilter: false
  },
  {
    header: 'Responsible',
    key: 'responsible',
    type: 'collection',
    minWidth: 205,
    maxWidth: 205,
    sort: true,
    canFilter: false
  },
  {
    header: 'For the Rank',
    key: 'crew_rank',
    sort: true,
    minWidth: 160,
    maxWidth: 160,
    canFilter: false
  },
  {
    header: 'For the Rank',
    key: 'crew_rank_id',
    type: 'collection',

    sort: false,
    hidden: true,
    canFilter: true,
    component: CrewRankSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Seaman',
    key: 'crew_member',
    sort: true,
    minWidth: 225,
    maxWidth: 225,
    canFilter: false
  },
  {
    header: 'Seaman',
    key: 'crew_member_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: CrewSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Responsible Department',
    key: 'responsible_department_id',
    hiddenOnBoard: true,
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: DepartmentSelector
  },
  {
    header: 'Responsible Role',
    key: 'responsible_role_id',
    type: 'collection',
    hiddenOnBoard: true,
    sort: false,
    hidden: true,
    canFilter: true,
    component: DepartmentRolesSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Responsible Crew Rank',
    key: 'responsible_crew_rank_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: CrewRankSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Vessel',
    key: 'vessel',
    data_key: 'vessel.name',
    type: 'collection',
    sort: true,
    canFilter: true,
    component: VesselSelector,
    componentRest: { isMulti: true },
    minWidth: 165,
    maxWidth: 165
  },
  {
    header: 'Days Left',
    key: 'days_left',
    type: 'number',
    sort: true,
    canFilter: false,
    minWidth: 125,
    maxWidth: 125,
    className: 'd-flex align-items-end justify-content-center'
  },
  {
    header: 'Ignore',
    key: 'ignore',
    type: 'boolean',
    sort: false,
    canFilter: false,
    minWidth: 200,
    maxWidth: 200,
    hiddenOnBoard: true,
    className: 'd-flex align-items-center justify-content-center'
  },
  {
    header: 'Timeframe Opened',
    key: 'is_visible',
    type: 'boolean',
    sort: false,
    hidden: true,
    canFilter: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Where',
    key: 'is_for_vessel',
    type: 'boolean',
    hidden: true,
    sort: false,
    canFilter: true,
    options: [
      { value: 'true', label: 'Onboard' },
      { value: 'false', label: 'Office' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

const columnsToExcludeFromCompleted = ['days_left', 'ignore', 'actions'];

export const getCompletedColumns = isOnBoard => [
  ...getColumns(isOnBoard).filter(col => !columnsToExcludeFromCompleted.includes(col.key)),
  {
    header: 'Form Date',
    key: 'form_date',
    data_key: 'form_submission.submission_date',
    type: 'date',
    minWidth: 120,
    maxWidth: 120,
    sort: true,
    canFilter: false
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const getFormNameIcon = type => {
  switch (type) {
    case 'event':
      return eventsIcon;
    case 'crew_rotation':
      return crewIcon;

    default:
      return null;
  }
};

export const getTriggerEntityUrl = data => {
  const { trigger_entity_type, trigger_entity, ...rest } = data;
  switch (trigger_entity_type) {
    case 'event':
      return `${paths.events}/${trigger_entity?.id}`;
    case 'crew_rotation':
      return `${paths.crew_planning}${
        trigger_entity?.vessel_id
          ? `?${stringifyObj({
              filters: [
                { name: 'vessel_id', operation: 'oneOf', value: [trigger_entity.vessel_id] }
              ]
            })}`
          : ''
      }`;

    default:
      return null;
  }
};

export const getTriggerEntityIcon = isForVessel => {
  if (isForVessel) return vesselIcon;
  return companyIcon;
};

export const getCanSubmitForm = ({ data, isOnBoard, account }) => {
  const { is_for_vessel, form_submission, responsible_type, responsible_id } = data;

  if (form_submission?.id) return false;
  if ((is_for_vessel && !isOnBoard) || (!is_for_vessel && isOnBoard)) return false;

  if (responsible_type === 'crew_rank' && account.crew_rank?.id === responsible_id) return true;
  if (
    responsible_type === 'department_role' &&
    account.department_roles?.some(role => role.id === responsible_id)
  )
    return true;
  if (
    responsible_type === 'department' &&
    account.departments?.some(department => department.id === responsible_id)
  )
    return true;

  return false;
};
