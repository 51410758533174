import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import PageSaving from 'common/components/general/PageSaving';

import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import FormHeader from './FormHeader';
import FormBody from './FormBody';

import * as formActions from 'store/forms/actions';

import moment from 'moment';

import {
  getInitialStateWithoutValues,
  getInitialStateWithValues,
  prepareValuesForSubmission
} from 'common/components/forms/digital/state-utils';

import { useActions } from 'utils/hooks';
import { updateFormSubmissionStatus } from 'store/forms/actions';

import { useSelector } from 'react-redux';
import * as listActions from 'store/lists/actions';
import { errorHandler } from 'common/utils/notifications';
import { useDispatch } from 'react-redux';
import SvgRender from 'common/components/general/SvgRender';
import activate from 'common/assets/svg/actions/check.svg';

import { shouldShowCloseConfirmation } from 'common/components/forms/helpers';

const FormManager = ({
  hiddenFields,
  className = '',
  excludeFormIds,
  form,
  selectedForm,
  setSelectedForm,
  formSubmission,
  formSubmissionPreviousValues,
  lastSubmittedForm,
  setLastSubmittedForm,
  setLastSubmittedFormButtonLocked,
  isLoadingLastSubmittedForm,
  isEditing,
  setIsEditing,
  closeDrawer,
  isDrawerOpen,
  triggerFormSubmission,
  onSaveFormSubmission,
  onSubmitCallback,
  refetchFormSubmission,
  isSubmitting,
  setIsSubmitting,
  isLocked,
  previewMode,
  isVesselLocked,
  filterVesselSelectorOptions,
  initialVesselID,
  isOnLinkMode,
  customSelectorComponent,
  onStatusChangeCallback,
  setShowCloseConfirmationModal,
  showCloseConfirmationModal,
  setIsDrawerClosing,
  isDrawerClosing,
  containerUniqueClassName = 'forms-listing-drawer'
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);

  const [isFetching, setIsFetching] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);

  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedFormUId, setSelectedFormUId] = useState(null);
  const [formState, setFormState] = useState({});

  const [originalFile, setOriginalFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileError, setUploadedFileError] = useState(null);

  const [status, setStatus] = useState(null);
  const [submissionDate, setSubmissionDate] = useState(null);
  const [submissionDateError, setSubmissionDateError] = useState(null);
  const [remarks, setRemarks] = useState({ core: null, onboard: null });
  const [actionable, setActionable] = useState({ core: false, onboard: false });

  const [collapsedRemarks, setCollapsedRemarks] = useState(true);

  const [selectedVesselId, setSelectedVesselId] = useState(initialVesselID || null);
  const [showLockModal, setShowLockModal] = useState(false);
  const dispatch = useDispatch();

  const [createFormSubmission, updateFormSubmission] = useActions([
    formActions.createFormSubmission,
    formActions.updateFormSubmission
  ]);

  const openLockModal = () => setShowLockModal(true);
  const closeLockModal = () => setShowLockModal(true);

  const cancelLockModal = () => {
    setShowLockModal(false);
    setStatus(formSubmission?.status || null);
  };

  const lockModalProps = {
    className: 'blurred-backdrop-modal',
    backdropClassName: 'blurred-backdrop'
  };

  const hideCloseConfirmationModal = () => {
    setShowCloseConfirmationModal(false);
    setIsDrawerClosing(false);
  };

  const isCloseConfirmationModalOpen = useMemo(
    () =>
      shouldShowCloseConfirmation(
        isDrawerClosing,
        isOnboard,
        formSubmission?.review_remarks,
        remarks.core,
        status?.progress
      ),
    [formSubmission?.review_remarks, isDrawerClosing, isOnboard, remarks.core, status?.progress]
  );

  useEffect(() => {
    if (isCloseConfirmationModalOpen) {
      setShowCloseConfirmationModal(true);
    } else if (isDrawerClosing) {
      closeDrawer();
    }
  }, [closeDrawer, isDrawerClosing, setShowCloseConfirmationModal, isCloseConfirmationModalOpen]);

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const formType = _get(selectedForm, 'type');

  useEffect(() => {
    fetchListOptions('forms');
  }, [fetchListOptions]);

  useUpdateEffect(() => {
    if (!triggerFormSubmission || isSubmitting) return;
    onSubmit();
  }, [triggerFormSubmission]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedForm(null);
      setCollapsedRemarks(true);
      setStatus(null);
      setSubmissionDate(null);
      setSelectedVesselId(null);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (selectedForm?.id && !lastSubmittedForm?.id) setLastSubmittedFormButtonLocked(false);

    if (
      !selectedForm?.id ||
      !lastSubmittedForm?.form_id ||
      selectedForm?.id !== lastSubmittedForm?.form_id
    )
      return;

    const digitalFormState = getInitialStateWithValues(selectedForm, lastSubmittedForm?.values); // digital forms

    setUploadedFile(selectedForm.file || null);
    setFormState(digitalFormState);
    setLastSubmittedForm(null);
  }, [lastSubmittedForm?.id, selectedForm?.id]);

  useEffect(() => {
    setShowLockModal(false);
    setCollapsedRemarks(true);

    if (form) {
      setStatus(formSubmission?.status || null);
      setSubmissionDate(
        formSubmission?.submission_date
          ? moment(formSubmission.submission_date, 'YYYY-MM-DD')
          : null
      );

      setRemarks({
        core: formSubmission?.review_remarks || null,
        onboard: formSubmission?.for_correction_remarks || null
      });

      setActionable({
        core: formSubmission?.review_remarks_actionable,
        onboard: formSubmission?.for_correction_remarks_actionable
      });

      setSelectedFormId(form.id);
      setSelectedFormUId(form.uid);
      setSelectedForm(form);

      setSelectedVesselId(formSubmission?.vessel_id || initialVesselID || null);

      setUploadedFile(formSubmission ? formSubmission.file : null); // upload froms
      setOriginalFile(form ? form?.file : null); // upload froms

      const digitalFormState = formSubmission?.values
        ? getInitialStateWithValues(form, formSubmission?.values)
        : getInitialStateWithoutValues(form); // digital forms // digital forms
      setFormState(digitalFormState);

      !['draft', 'scheduled'].includes(formSubmission?.status?.progress) &&
        setCollapsedRemarks(false);
    } else {
      setStatus(null);
      setSubmissionDate(null);

      setSelectedFormId(null);
      setSelectedFormUId(null);
      setSelectedForm(null);

      setSelectedVesselId(initialVesselID || null);

      setUploadedFile(null);
      setOriginalFile(null);

      setFormState({});

      setRemarks({ core: null, onboard: null });

      setIsEditing(true);
    }
  }, [form?.id, formSubmission?.id, initialVesselID]);

  const onSave = async params => {
    if (!params) return;

    setIsSubmitting(true);

    try {
      let res;

      if (formSubmission?.id) {
        res = await (onSaveFormSubmission ? onSaveFormSubmission : updateFormSubmission)({
          ...params,
          id: formSubmission.id
        });

        setIsEditing(false);

        if (params.values || params.file_id) {
          refetchFormSubmission();
        }
      } else {
        res = await (onSaveFormSubmission ? onSaveFormSubmission : createFormSubmission)({
          ...params,
          id: params.form_id
        });

        closeDrawer();
      }

      setIsSubmitting(false);

      return res;
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);

      throw error;
    }
  };

  const onStatusChange = newStatus => {
    if (status?.id === newStatus?.id || isSubmitting) return;

    setStatus(newStatus);

    if (formSubmission && formSubmission.created_at) {
      if (
        !showLockModal &&
        ((!isOnboard && newStatus?.edit_side === 'vessel') ||
          (isOnboard && newStatus?.edit_side === 'office'))
      ) {
        openLockModal();
        return;
      }

      updateStatus(newStatus);
    }
  };

  const updateStatus = async (newStatus, fromLockModal = false) => {
    if (!newStatus?.id) return null;
    const currentStatus = status;

    try {
      const { reviewed_by, reviewed_at } = await dispatch(
        updateFormSubmissionStatus({
          id: formSubmission?.id ? formSubmission.id : form?.id,
          form_status_id: newStatus?.id
        })
      ).unwrap();

      if (!['draft', 'scheduled'].includes(newStatus?.progress)) {
        setCollapsedRemarks(false);
      } else {
        setCollapsedRemarks(true);
      }

      if (fromLockModal) {
        closeLockModal();
        refetchFormSubmission();
      }

      onStatusChangeCallback({ reviewed_by, reviewed_at });
    } catch (err) {
      console.error(err);
      setStatus(currentStatus);
    }
  };

  const debouncedSaved = useCallback(
    _debounce(async params => {
      setIsAutoSaving(true);
      await onSave(params);
      setIsAutoSaving(false);
    }, 500),
    []
  );

  const onRemarksChange = async rem => {
    const { core, onboard } = rem;

    if (core === remarks.core && onboard === remarks.onboard) return;

    const currentRemarks = remarks;
    setRemarks(rem);

    try {
      await debouncedSaved({
        form_id: form?.id,
        form_uid: form?.uid,
        form_submission_id: formSubmission.id,
        for_correction_remarks: onboard || '',
        review_remarks: !isOnboard && core ? core : ''
      });
      if (showLockModal) closeLockModal();
    } catch (e) {
      console.error(e);
      setRemarks(currentRemarks);
    }
  };

  const onActionableChange = async obj => {
    const { core, onboard } = obj;

    if (core === actionable.core && onboard === actionable.onboard) return;

    const currentActionable = actionable;
    setActionable(obj);

    try {
      await onSave({
        form_id: form?.id,
        form_uid: form?.uid,
        form_submission_id: formSubmission.id,
        for_correction_remarks_actionable: onboard,
        review_remarks_actionable: core
      });
      if (showLockModal) closeLockModal();
    } catch (e) {
      console.error(e);
      setActionable(currentActionable);
    }
  };

  const onSubmissionDateChange = async newDate => {
    if (newDate?.format('YYYY-MM-DD') === submissionDate?.format('YYYY-MM-DD') || isSubmitting)
      return;

    setSubmissionDateError(false);

    const currentDate = submissionDate;
    setSubmissionDate(newDate);

    if (!formSubmission) return;

    try {
      await onSave({
        form_id: form?.id,
        form_uid: form?.uid,
        form_submission_id: formSubmission.id,
        submission_date: newDate ? newDate.format('YYYY-MM-DD') : null
      });
      if (showLockModal) closeLockModal();
    } catch (e) {
      console.error(e);
      setSubmissionDate(currentDate);
    }
  };

  const onFormTypeChange = async newForm => {
    try {
      setSelectedFormId(newForm.id);
      setSelectedFormUId(newForm.uid);
      setSelectedForm(newForm);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async () => {
    if (isSubmitting || !selectedForm) return;
    let hasValidationError = false;

    const params = {
      form_id: form && form.id ? form.id : selectedFormId || undefined,
      form_uid: form && form.uid ? form.uid : selectedFormUId || undefined,
      form_submission_id: formSubmission ? formSubmission.id : undefined
    };

    if (selectedForm?.type === 'digital') {
      const { hasError, result } = prepareValuesForSubmission(formState, selectedForm);

      if (hasError) {
        setFormState(result);
        hasValidationError = true;
      } else {
        const { values } = result;

        params.values = [...values];
      }
    } else {
      if (uploadedFile) {
        params.file_id = uploadedFile?.id || (form && form.file ? null : undefined);
      } else {
        setUploadedFileError(true);
        hasValidationError = true;
      }
    }

    if (!formSubmission) {
      if (!submissionDate) {
        setSubmissionDateError('Submission date is required.');
        hasValidationError = true;
      }

      if (!status && !hiddenFields?.status) {
        hasValidationError = true;
      }

      params.form_status_id = _get(status, 'id', status) || undefined;
      params.submission_date = submissionDate ? submissionDate.format('YYYY-MM-DD') : null;
      params.vessel_id = selectedVesselId || undefined;

      if (!isOnboard) params.review_remarks = _get(remarks, 'core', undefined);
      params.for_correction_remarks = _get(remarks, 'onboard', undefined);
    }

    if (showLockModal) {
      params.form_status_id = _get(status, 'id', status) || undefined;
    }

    if (
      !showLockModal &&
      !hasValidationError &&
      ((!isOnboard && status?.edit_side === 'vessel') ||
        (isOnboard && status?.edit_side === 'office'))
    ) {
      openLockModal();
      return;
    }

    if (hasValidationError) {
      dispatch(
        errorHandler({
          title: 'ERROR',
          message: `Fill in all required form fields`
        })
      );
      return;
    }

    try {
      if (isOnLinkMode) {
        onSubmitCallback();
      } else {
        const res = await onSave(params);

        if (showLockModal) closeLockModal();
        if (showCloseConfirmationModal) closeLockModal();
        if (onSubmitCallback) onSubmitCallback(res);
      }
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
      setIsEditing(true);
    }
  };

  const commonProps = {
    form: selectedForm,
    formSubmission: formSubmission,
    formSubmissionPreviousValues: formSubmissionPreviousValues,
    isLocked: isLocked,
    setFormState: setFormState,
    setUploadedFile: setUploadedFile,
    setUploadedFileError: setUploadedFileError,
    previewMode: previewMode
  };

  return (
    <div className={`form-manager--wrapper h-100p d-flex flex-column pt-2 ${className}`}>
      <FormHeader
        setSelectedForm={setSelectedForm}
        selectedFormId={selectedFormId}
        setSelectedFormId={setSelectedFormId}
        status={status}
        isVesselLocked={isVesselLocked}
        setStatus={setStatus}
        selectedVesselId={selectedVesselId}
        setSelectedVesselId={setSelectedVesselId}
        submissionDate={submissionDate}
        setSubmissionDate={setSubmissionDate}
        submissionDateError={submissionDateError}
        remarks={remarks}
        setRemarks={setRemarks}
        collapsedRemarks={collapsedRemarks}
        onRemarksChange={onRemarksChange}
        setCollapsedRemarks={setCollapsedRemarks}
        actionable={actionable}
        onActionableChange={onActionableChange}
        setIsFetching={setIsFetching}
        onFormTypeChange={onFormTypeChange}
        excludeFormIds={excludeFormIds}
        canChangeFormType={!formSubmission}
        onStatusChange={onStatusChange}
        onSubmissionDateChange={onSubmissionDateChange}
        hiddenFields={hiddenFields}
        filterVesselSelectorOptions={filterVesselSelectorOptions}
        formType={formType}
        isOnLinkMode={isOnLinkMode}
        refetchFormSubmission={refetchFormSubmission}
        customSelectorComponent={customSelectorComponent}
        {...commonProps}
      />

      <FormBody
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        isFetching={isFetching || isLoadingLastSubmittedForm}
        formState={formState}
        formType={formType}
        uploadedFileError={uploadedFileError}
        uploadedFile={uploadedFile}
        originalFile={originalFile}
        isLocked={isLocked}
        hideEditButton={isOnLinkMode}
        headerFormState={{
          selectedFormId,
          selectedFormUId,
          selectedVesselId,
          status,
          submissionDate,
          remarks
        }}
        {...commonProps}
      />

      <DangerousActionModal
        transparent
        action={'send'}
        onAccept={() => updateStatus(status, true)}
        onClose={cancelLockModal}
        closeModal={closeLockModal}
        actionHoverColor="primary"
        isOpen={showLockModal}
        actionText={'SEND'}
        header={`Send to ${isOnboard ? 'Office' : 'Vessel'}`}
        body={`Are you sure you want to send this form to ${
          isOnboard ? 'Office' : 'Vessel'
        }? Editing from the ${isOnboard ? 'vessel' : 'office'} will be locked.`}
        container={`.${containerUniqueClassName}`}
        contentClassName="modal-content-box-shadow"
        backdropClassName="blurred-backdrop"
        {...lockModalProps}
      />

      <DangerousActionModal
        transparent
        action={'activate'}
        onAccept={closeDrawer}
        onClose={hideCloseConfirmationModal}
        closeModal={hideCloseConfirmationModal}
        actionIcon={
          <SvgRender src={activate} className="text-white" style={{ width: 80, height: 80 }} />
        }
        actionHoverColor="primary"
        isOpen={showCloseConfirmationModal}
        actionText={'CLOSE'}
        header={'Close & Proceed'}
        body="Are you sure you want to close this form without changing its status?"
        container={`.${containerUniqueClassName}`}
        contentClassName="modal-content-box-shadow"
        backdropClassName="blurred-backdrop"
      />
      <PageSaving isSaving={isAutoSaving || isSubmitting} />
    </div>
  );
};

FormManager.propTypes = {
  hiddenFields: PropTypes.shape({
    type: PropTypes.bool, // Use false to disabled the type select
    vessel: PropTypes.bool // Use false to hide vessel
  }),
  className: PropTypes.string,
  excludeFormIds: PropTypes.array, // array of form ids to prevent creating duplicated forms

  isEditing: PropTypes.bool, // edit mode
  setIsEditing: PropTypes.func, // change the isEditing

  isSubmitting: PropTypes.bool, // check if a form/submission is being submitted.
  setIsSubmitting: PropTypes.func, // change the isSubmitting

  closeDrawer: PropTypes.func, // function to close the drawer,
  isDrawerOpen: PropTypes.bool, // to check whether the drawer is open/closed

  selectedForm: PropTypes.object, //The form selected from the form type selector
  setSelectedForm: PropTypes.func, //The function to set the form  in the form type selector

  lastSubmittedForm: PropTypes.object, //Last formSubmission of the selected form
  setLastSubmittedFormButtonLocked: PropTypes.func, //The state of the last submitted form button, to lock/unlock it once you get/remove the last submitted form.

  onSaveFormSubmission: PropTypes.func, // function to save formSubmission from other components like events/jobs/mocs etc. It overrides the default form-manager save function.
  onSubmitCallback: PropTypes.func, // function to do stuff after a form/submission is submitted succefully
  onStatusChangeCallback: PropTypes.func, // function to do stuff after the status is updated succefully
  triggerFormSubmission: PropTypes.bool, // Use to trigger the form submission with an external button, like the Drawer footer buttons
  refetchFormSubmission: PropTypes.func, // function to refetch a formSubmission
  isVesselLocked: PropTypes.bool,

  form: PropTypes.shape({
    // This is the form template we created in the form settings
    form_fields: PropTypes.array, // used in 'digital' forms
    file: PropTypes.object, // used in 'uploads' forms
    file_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.oneOf(['digital', 'upload'])
  }),
  formSubmission: PropTypes.shape({
    // This is the submitted form
    created_at: PropTypes.string,
    created_by: PropTypes.object,
    file: PropTypes.object, // used in 'uploads' forms
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    submitted_at: PropTypes.string,
    submitted_by: PropTypes.object,
    updated_at: PropTypes.string,
    updated_by: PropTypes.object,
    values: PropTypes.array // used in 'digital' forms
  }),

  setShowCloseConfirmationModal: PropTypes.func,
  showCloseConfirmationModal: PropTypes.bool,
  setIsDrawerClosing: PropTypes.func,
  isDrawerClosing: PropTypes.bool,

  containerUniqueClassName: PropTypes.string,

  isOnLinkMode: PropTypes.bool, // used when the state is on link mode

  previewMode: PropTypes.bool, //used to lock the form and only preview it, simmilar to form setup

  initialVesselID: PropTypes.number //A predefined vessel id for the vessel selector
};

export default FormManager;
