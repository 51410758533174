import { differenceInMilliseconds, format, isAfter, isValid, parseISO, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import _findKey from 'lodash/findKey';

type HideType = {
  time?: boolean;
  date?: boolean;
  day?: boolean;
  month?: boolean;
  year?: boolean;
};

const defs = {
  year: 'yyyy',
  month: 'MM',
  day: 'dd'
};

export const getDefaultFormat = (hide: HideType = {}) => {
  const dateFormat = 'dd/MM/yyyy';
  const timeFormat = 'HH:mm';
  let format = '';

  if (hide) {
    let formated_date = '';

    if (!hide.date) {
      // Checks for YYYY, MM, DD
      let parts = dateFormat.split('/');
      parts = parts.filter(part => {
        const foundKey = _findKey(defs, def => def === part) as keyof HideType;

        if (foundKey) {
          return !hide[foundKey];
        }

        return true;
      });

      if (parts.length) {
        formated_date = parts.join('/');
      }
    }

    if (!hide.time) {
      format = `${formated_date !== '' ? `${formated_date}, ` : ''}${timeFormat}`;
    } else {
      format = `${formated_date}`;
    }
  } else {
    format = `${dateFormat}, ${timeFormat}`;
  }

  return format;
};

const getIsValidDateObject = (date?: Date | string | null): date is Date => {
  if (isValid(date) && date instanceof Date) return true;

  return false;
};

const getParsedDate = (date?: string | null) => {
  if (typeof date === 'string') {
    const parsedDate = parseISO(date);

    if (!isValid(parsedDate)) return null;

    return parsedDate;
  }

  return null;
};

export const displayUTCDate = (
  date?: Date | string | null,
  hide: HideType = {},
  customFormat?: string
) => {
  if (getIsValidDateObject(date))
    return formatInTimeZone(date, 'UTC', customFormat ?? getDefaultFormat(hide));

  const parsedDate = getParsedDate(date);

  if (!parsedDate) return null;

  return formatInTimeZone(parsedDate, 'UTC', customFormat ?? getDefaultFormat(hide));
};

export const displayDate = (
  date?: Date | string | null,
  hide: HideType = {},
  customFormat?: string
) => {
  if (getIsValidDateObject(date)) return format(date, customFormat ?? getDefaultFormat(hide));

  const parsedDate = getParsedDate(date);

  if (!parsedDate) return null;

  return format(parsedDate, customFormat ?? getDefaultFormat(hide));
};

export const getClosestNextDate = (
  datesArray: (string | Date)[],
  comparisonDate?: string | Date,
  includeToday = true
) => {
  const now = comparisonDate
    ? typeof comparisonDate === 'string'
      ? parseISO(comparisonDate)
      : comparisonDate
    : new Date();

  const futureDates = datesArray.filter(date => {
    const compareDate = typeof date === 'string' ? parseISO(date) : date;

    if (includeToday) {
      return isAfter(compareDate, now) || isSameDay(compareDate, now);
    }

    return isAfter(compareDate, now);
  });

  if (!futureDates.length) return null;

  const closestDate = futureDates.reduce<Date | null>((prev, curr) => {
    const currentDate = typeof curr === 'string' ? parseISO(curr) : curr;

    if (!prev) return currentDate;

    const diffPrev = differenceInMilliseconds(prev, now);
    const diffCurr = differenceInMilliseconds(currentDate, now);
    return diffCurr < diffPrev ? currentDate : prev;
  }, null);

  return closestDate;
};
