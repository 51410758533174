import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_ACCOUNTING_INVOICE'),
  ...asyncTypes('UPDATE_ACCOUNTING_INVOICE'),
  ...asyncTypes('CREATE_ACCOUNTING_INVOICE'),
  ...asyncTypes('DELETE_ACCOUNTING_INVOICE'),
  ...asyncTypes('UPDATE_ACCOUNTING_INVOICE_REVIEWED_STATUS'),
  ...asyncTypes('SEND_TO_ACCOUNTING_STATUS')
};

export default TYPES;
