import emptyRibbon from 'common/assets/svg/common/ribbon-line-colored.svg';
import burger from 'common/assets/svg/common/chapter-colored.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';

import classIcon from 'common/assets/svg/common/class-color.svg';
import critical from 'common/assets/svg/common/critical-color.svg';
import environmental from 'common/assets/svg/common/environmental-color.svg';
import navigational from 'common/assets/svg/common/navigational-color.svg';
import PropTypes from 'prop-types';

const VesselSystemInfo = ({
  className = '',
  vesselSystem,
  showGroup = true,
  attributes,
  cmsReferenceCode,
  showSystemIcon = true
}) => {
  return (
    <div
      className={`d-flex align-items-center flex-1 overflow-hidden ${className}`}
      data-testid="vessel-system-info"
    >
      <div className="d-flex align-items-center text-truncate">
        {showSystemIcon ? (
          <img
            alt="vessel system"
            src={vesselSystem.parent_id ? emptyRibbon : burger}
            width={14}
            height={14}
            className="me-1"
          />
        ) : null}
        <TextWithTooltip>
          {showGroup ? (
            <strong>{vesselSystem.description}</strong>
          ) : (
            <span>{vesselSystem.description}</span>
          )}
          {vesselSystem.extra_description ? (
            <span className="fw-normal">&nbsp;-&nbsp;{vesselSystem.extra_description}</span>
          ) : null}
          {showGroup &&
          vesselSystem.group &&
          vesselSystem.group?.code &&
          vesselSystem.group?.name ? (
            <>
              <span className="seperator-violet height-12"></span>{' '}
              <span className="fw-normal text-violet">{`${vesselSystem.group?.code}. ${vesselSystem.group?.name}`}</span>
            </>
          ) : null}
        </TextWithTooltip>

        {attributes?.multiplicity && (
          <div className="fw-bold fs-10 cme-12 cms-6">x{attributes?.multiplicity}</div>
        )}

        <div className="d-flex align-items-center ms-2">
          {attributes?.is_navigational && (
            <SvgRender
              data-testid="is_navigational"
              className="me-1 text-purple"
              src={navigational}
              style={{ width: 14, height: 14 }}
            />
          )}
          {attributes?.is_critical && (
            <SvgRender
              data-testid="is_critical"
              className="me-1 text-purple"
              src={critical}
              style={{ width: 14, height: 14 }}
            />
          )}
          {attributes?.is_environmental_critical && (
            <SvgRender
              data-testid="is_environmental_critical"
              className="me-1 text-purple"
              src={environmental}
              style={{ width: 14, height: 14 }}
            />
          )}
          {attributes?.is_class && (
            <div className="d-flex align-items-center">
              <SvgRender
                data-testid="is_class"
                className="me-1 text-purple"
                src={classIcon}
                style={{ width: 14, height: 14 }}
              />
              {cmsReferenceCode ? (
                <div className="fs-10 text-primary me-1">{cmsReferenceCode}</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

VesselSystemInfo.propTypes = {
  className: PropTypes.string,
  vesselSystem: PropTypes.shape({
    description: PropTypes.string
  }),
  attributes: PropTypes.shape({
    is_class: PropTypes.bool,
    is_critical: PropTypes.bool,
    is_environmental_critical: PropTypes.bool,
    is_navigational: PropTypes.bool
  }),
  showGroup: PropTypes.bool,
  showSystemIcon: PropTypes.bool,
  cmsReferenceCode: PropTypes.string
};

export default VesselSystemInfo;
