import CircledButton from '@/ts-common/components/buttons/CircledButton';
import {
  isPurchasingItemsSelectionDrawerOpen,
  isPmsItemsSelectionDrawerOpen
} from 'common/components/purchasing/requisition/store/selectors';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { selectItem } from '@/common/components/purchasing/requisition/store/selectors-ts';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal.tsx';
import { useState } from 'react';
import {
  deletePurchasingRequisitionItem,
  togglePurchasingItemsSelectionDrawer,
  togglePmsItemsSelectionDrawer
} from 'common/components/purchasing/requisition/store/actions';
import doubleArrows from '@/common/assets/svg/actions/double-arrows.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import {
  setActiveCategoryId,
  setSelectedItemToBeReplaced
} from 'common/components/purchasing/requisition/store/actions';
import permissions from '@/common/utils/permissions/constants';
import AuthCheck from '@/components/permissions/AuthCheck';

interface ItemActionsProp {
  itemID: string;
  categoryID: number;
}

const ItemActions: React.FC<ItemActionsProp> = ({ itemID, categoryID }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useAppDispatch();

  const isPurchasingItemsDrawerOpen = useAppSelector(isPurchasingItemsSelectionDrawerOpen);
  const isPmsItemsDrawerOpen = useAppSelector(isPmsItemsSelectionDrawerOpen);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const isDisabled = isPurchasingItemsDrawerOpen || isPmsItemsDrawerOpen;

  const item = useAppSelector(state => selectItem(state, itemID));

  const onReplace = () => {
    dispatch(setActiveCategoryId(categoryID));
    dispatch(setSelectedItemToBeReplaced(item?.requisition_item_id));

    if (item?.item_type === 'spare_part') {
      dispatch(togglePmsItemsSelectionDrawer(true));
    } else {
      dispatch(togglePurchasingItemsSelectionDrawer(true));
    }
  };

  const onRemove = async () => {
    await dispatch(deletePurchasingRequisitionItem({ id: item?.requisition_item_id }));
  };

  return isOnBoard ? null : (
    <div className="supplier-item-actions width-48 right-0">
      <AuthCheck permissions={[permissions.office.purchasing.requisitions.items.replace]}>
        <CircledButton
          icon={doubleArrows}
          onClick={onReplace}
          svgStyle={{ width: 12, height: 12 }}
          size={18}
          className="me-1"
          disabled={isDisabled}
          svgColor={'royal-blue'}
        ></CircledButton>
      </AuthCheck>

      <AuthCheck permissions={[permissions.office.purchasing.requisitions.items.delete]}>
        <CircledButton
          icon={remove}
          onClick={() => setShowDeleteModal(true)}
          svgStyle={{ width: 9, height: 9 }}
          size={18}
          disabled={isDisabled}
          svgColor={'coral'}
        ></CircledButton>
      </AuthCheck>

      <DangerousActionModal
        onAccept={onRemove}
        onCancel={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        acceptButtonText={'REMOVE'}
        cancelButtonText={'CANCEL'}
        header={'Remove Item'}
        icon={remove}
      >
        <div>
          Are you sure you want to remove the <br /> <strong>{item.description}</strong> ?
        </div>
      </DangerousActionModal>
    </div>
  );
};

export default ItemActions;
