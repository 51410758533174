import { GetOptionValue, GroupBase, PropsValue } from 'react-select';
import {
  DefaultOptionLabelType,
  DefaultOptionValueType,
  FindOptionKeyValueParamsType,
  GetSelectedValueParamsType
} from '@/ts-common/components/form/inputs/select/types';
import { UseQueryResult } from '@tanstack/react-query';

const isOptionLabel = (option: unknown): option is DefaultOptionLabelType => {
  if (!option) return false;

  return (option as DefaultOptionLabelType).label !== undefined;
};

const isOptionValue = (option: unknown): option is DefaultOptionValueType => {
  if (!option) return false;

  const valueOption = option as DefaultOptionValueType;
  return !!valueOption.id || !!valueOption.value;
};

export const getDefaultOptionLabel = (option: unknown): string => {
  if (isOptionLabel(option)) return option.label.toString();

  return '';
};

export const getDefaultOptionValue = (option: unknown): string => {
  if (isOptionValue(option)) return (option.value || option.id).toString();

  return '';
};

const findOptionKeyValue = <Option, Group extends GroupBase<Option> = GroupBase<Option>>({
  option,
  getOptionValue,
  value
}: FindOptionKeyValueParamsType<Option, Group>) => {
  if (getOptionValue && option) return getOptionValue(option as Option) == value;

  if (option && typeof option === 'object' && 'value' in option) {
    return option.value == value;
  }

  if (option && typeof option === 'object' && 'id' in option) {
    return option.id == value;
  }
};

const getOption = <Option, Group extends GroupBase<Option> = GroupBase<Option>>(
  { value, options = [], isMulti }: GetSelectedValueParamsType<Option, Group>,
  getOptionValue: GetOptionValue<Option> | undefined
): PropsValue<Option> => {
  if (isMulti && Array.isArray(value) && value.length) {
    return value.map(v => {
      const found = options.find(option =>
        findOptionKeyValue({
          option: option,
          getOptionValue: getOptionValue,
          value: v
        })
      );

      return found as Option;
    });
  }

  if (!isMulti && options.length) {
    const found = options.find(option =>
      findOptionKeyValue({
        option: option,
        getOptionValue: getOptionValue,
        value: value
      })
    );

    return found as Option;
  }

  return null;
};

export const getSelectedValue = <Option, Group extends GroupBase<Option> = GroupBase<Option>>(
  { isMulti, options, value }: GetSelectedValueParamsType<Option, Group>,
  getOptionValue: GetOptionValue<Option> | undefined
): PropsValue<Option> => {
  // This will handle every case for Multi selects. It will work with ids and objects for both async and non async.

  if (isMulti) {
    if (!Array.isArray(value)) return null;
    if (!value.length) return [];

    if (!Array.isArray(value[0]) && typeof value[0] === 'object') return value;
  }

  // The following 2 lines will handle every case for Single selects. It will work with ids and objects for both async and non async.
  if (!Array.isArray(value) && typeof value === 'object') return value;

  return getOption({ value, options, isMulti }, getOptionValue);
};

export const constructKeyFromPath = (path?: string) =>
  path
    ? path
        .split('/')
        .filter(part => part !== '')
        .join('-')
    : '';

type isEntityLabeledParams = {
  hasValue?: boolean;
  selectProps: { isEntityLabeled?: boolean };
};

export const isEntityLabeled = (props: isEntityLabeledParams) =>
  props.hasValue && props.selectProps.isEntityLabeled;

const getQueryOptions = (initialOptionsQuery: UseQueryResult<unknown[] | null>) => {
  if (
    initialOptionsQuery.isSuccess &&
    initialOptionsQuery.data &&
    Array.isArray(initialOptionsQuery.data)
  )
    return initialOptionsQuery.data;

  return [];
};

export const getSynchronousOptions = <Option>(
  isAsync: boolean,
  initialOptionsQuery: UseQueryResult<unknown[] | null>,
  parseOptions?: (options: Option[]) => Option[]
): unknown[] => {
  if (isAsync) return [];
  const queryOptions = getQueryOptions(initialOptionsQuery);

  return parseOptions ? parseOptions(queryOptions as Option[]) : queryOptions;
};

export const getAsyncOptions = <Option>(
  isAsync: boolean,
  initialOptionsQuery: UseQueryResult<unknown[] | null>,
  parseOptions?: (options: Option[]) => Option[]
): unknown[] => {
  if (!isAsync) return [];
  const queryOptions = getQueryOptions(initialOptionsQuery);

  return parseOptions ? parseOptions(queryOptions as Option[]) : queryOptions;
};
