import React, { useState } from 'react';

import _pickBy from 'lodash/pickBy';
import _isEmpty from 'lodash/isEmpty';

import AsyncSelector from 'common/components/selectors/AsyncSelector';
import SelectSearchIndicator from 'common/components/form/helpers/SelectSearchIndicator';
import { numberToStr } from 'common/utils/numbers';

import {
  selectNumberOfCategoryItems,
  selectAllItems,
  selectOnBoardStatus,
  selectRequisitionVessel
} from 'common/components/purchasing/requisition/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoryItem } from 'common/components/purchasing/requisition/store/actions';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { components } from 'react-select';
import { Row, Col, Button } from 'reactstrap';
import { showSparePartsNumber } from 'common/utils/form/display';

import _debounce from 'lodash/debounce';
import AddFromListButton from './AddFromListButton';
import add from 'common/assets/svg/actions/add.svg';
import SvgRender from 'common/components/general/SvgRender';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { constructOutOfListItemId } from 'common/components/purchasing/requisition/categories/items/helpers';
import ColorViewer from 'common/components/general/ColorViewer';

const ItemSelector = ({ categoryID, isPms }) => {
  const [outOfListItem, setOutOfListItem] = useState(null);
  const dispatch = useDispatch();
  const categoryItemsLength = useSelector(state => selectNumberOfCategoryItems(state, categoryID));
  const allItems = useSelector(selectAllItems);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const onBoardStatus = useSelector(selectOnBoardStatus);
  const vessel = useSelector(selectRequisitionVessel);

  const units = useSelector(state => selectListOptionsFromStore(state, 'purchasing-units'));
  const pieceUnit = units?.find(u => u.label === 'piece');

  const fetchSearchResults = async search => {
    const items = await getAsyncOptions(search, isPms ? 'spare-parts' : 'stores', {
      purchasing_category_id: categoryID,
      type: isPms ? 'spare_part' : 'store',
      vessel_id: isPms && !isOnBoard ? vessel?.id : undefined
    });

    if (!items?.length) {
      setOutOfListItem(search);
    } else {
      setOutOfListItem(null);
    }

    return items;
  };

  const debouncedRemoveButton = _debounce(() => {
    setOutOfListItem(null);
  }, 300);

  if (isOnBoard && onBoardStatus !== 'draft') return null;

  return (
    <Row
      data-index={categoryItemsLength + 1}
      className="align-items-center cmt-2 bg-transparent purchasing-requisition__item-selector-container g-0 ps-3"
    >
      <Col className="rounded-lg bg-white cps-4 cpe-4" xs="auto">
        <AsyncSelector
          className="mb-0 purchasing-requisition__gray-selector purchasing-requisition__item-selector white-bg"
          onChange={item =>
            item &&
            dispatch(
              addCategoryItem({
                item: { ...item, purchasing_unit: !isPms ? item.purchasing_unit : pieceUnit },
                categoryID,
                type: isPms ? 'spare_part' : 'store',
                requested_packaging_id: !isPms ? item.purchasing_unit?.id : pieceUnit?.id
              })
            )
          }
          type={isPms ? 'spare-parts' : 'stores'}
          placeholder="Select an item to add in your list by 6-digit code or description"
          gray={true}
          menuPortalTarget={document.body}
          components={{ DropdownIndicator: SelectSearchIndicator, Option, Menu }}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.description}
          loadOptions={search => fetchSearchResults(search)}
          defaultOptions={() =>
            getInitialAsyncValues(isPms ? 'spare-parts' : 'stores', null, null, {
              purchasing_category_id: categoryID,
              type: isPms ? 'spare_part' : 'store',
              vessel_id: isPms ? vessel?.id : undefined
            })
          }
          filterOption={option =>
            _isEmpty(
              _pickBy(
                allItems,
                it =>
                  it.id === option.value &&
                  (isPms
                    ? it?.entity_type === 'spare_part' || it?.item_type === 'spare_part'
                    : it?.entity_type === 'store' || it?.item_type === 'store')
              )
            )
          }
          onMenuClose={debouncedRemoveButton}
          defaultOptionsTriggerChange={vessel?.id}
        />
      </Col>
      {isOnBoard && outOfListItem && (
        <Col xs="auto" className="bg-white fs-14">
          <Button
            className="d-flex align-items-center justify-content-center text-white bg-primary cpt-6 cpb-6 px-1"
            type="button"
            onClick={() =>
              dispatch(
                addCategoryItem({
                  item: {
                    id: constructOutOfListItemId(outOfListItem, isPms),
                    unlisted_description: outOfListItem,
                    is_out_of_the_list: true
                  },
                  categoryID
                })
              )
            }
          >
            <SvgRender src={add} style={{ width: 12, height: 12 }} />
            <span className="fs-12 text-nowrap ms-1 fw-medium">Add out of the list item</span>
          </Button>
        </Col>
      )}

      <Col className="bg-transparent" xs="auto">
        <AddFromListButton categoryID={categoryID} isPms={isPms} />
      </Col>
    </Row>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex w-100p">
        {props.data?.store ? (
          <>
            <strong className="cpe-6">{props.data.store.code}</strong>
            {props.data.store.description} | {props.data.remaining_quantity} @ $
            {numberToStr(props.data.price_per_unit, 2, 2)}{' '}
          </>
        ) : (
          <>
            {showSparePartsNumber(props.data.drawing_no) ? (
              <strong>{props.data.drawing_no} - </strong>
            ) : null}
            {showSparePartsNumber(props.data.code) ? <strong>{props.data.code} - </strong> : null}
            {props.data.description}
          </>
        )}
        {props.data?.color_name ? (
          <span className="text-violet">&nbsp;-&nbsp;{props.data.color_name}</span>
        ) : null}
        <div className="d-flex ms-auto">
          {props.data?.color_hex_code ? (
            <ColorViewer color={`#${props.data.color_hex_code}`} className={'ms-auto'} hideLabel />
          ) : null}
        </div>
      </div>
    </components.Option>
  );
};

const Menu = ({ children, ...props }) => {
  return (
    <components.Menu className="purchasing-requisition__item-selector" {...props}>
      {children}
    </components.Menu>
  );
};

export default ItemSelector;
