import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import clock from 'common/assets/svg/jobs/clock.svg';
import moment from 'moment';
import hourglass from 'common/assets/svg/common/hourglass.svg';

import { formatDate, dateToLocal } from 'common/utils/dates';
import { useSelector } from 'react-redux';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import PropTypes from 'prop-types';

export const getColorClass = (data, jobStatuses, condition) => {
  if (!data) return '';

  const { dueDate, createdAt, status } = data;

  if (condition) {
    const { is_becoming_due_date, is_overdue_date } = condition;

    if (is_overdue_date) {
      return 'text-red';
    } else if (is_becoming_due_date) {
      return 'text-orange';
    } else {
      return 'text-violet';
    }
  } else if (dueDate && createdAt && status) {
    const jobStatus = status ? jobStatuses.find(st => st.id === status) : null;
    const jobIsDone = jobStatus?.progress === 'done';

    const jobDuration = moment(dueDate).startOf('day').diff(dateToLocal(createdAt), 'seconds');
    const todayDuration = moment().diff(dateToLocal(createdAt), 'seconds');

    if (jobDuration === 0) {
      return 'text-violet';
    }

    const percentage = (todayDuration / jobDuration) * 100;

    if (jobDuration < todayDuration && jobStatus && !jobIsDone) {
      return 'text-red';
    }

    if (percentage < 70 || (jobStatus && jobStatus.progress === 'done')) {
      return 'text-violet';
    } else if (percentage < 90) {
      return 'text-orange';
    } else {
      return 'text-red';
    }
  }
};

const DueDate = ({
  dueDate = '',
  createdAt,
  status,
  className = '',
  showTime = false,
  dueDateChanged = '',
  condition
}) => {
  const jobStatuses = useSelector(selectJobStatuses);

  return (
    <div
      data-testid="due-date-view"
      className={`d-flex align-items-center text-nowrap ${getColorClass(
        {
          dueDate,
          createdAt,
          status
        },
        jobStatuses,
        condition
      )} fw-bold fs-8 ${className}`}
    >
      {dueDateChanged ? (
        <SvgRender src={hourglass} style={{ width: 8, height: 14 }} />
      ) : (
        <SvgRender src={clock} style={{ width: 12, height: 12 }} />
      )}
      <span className="ms-1">
        {showTime
          ? dateToLocal(dueDate).format('DD/MM/YYYY | HH:mm')
          : formatDate(dueDate, { time: true })}
      </span>
    </div>
  );
};

DueDate.propTypes = {
  dueDate: PropTypes.string,
  dueDateChanged: PropTypes.string,
  createdAt: PropTypes.string,
  showTime: PropTypes.bool,
  status: PropTypes.number,
  className: PropTypes.string,
  condition: PropTypes.shape({
    is_becoming_due_date: PropTypes.bool,
    is_overdue_date: PropTypes.bool
  })
};

export default DueDate;
