import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

const PurchasingRequisitions = React.lazy(() =>
  retry(() => import('@/views/purchasing/requisitions/index.jsx'))
);
const PurchasingRequisition = React.lazy(() =>
  retry(() => import('@/views/purchasing/requisitions/requisition/index.jsx'))
);
const Suppliers = React.lazy(() =>
  retry(() => import('@/views/purchasing/requisitions/suppliers/index.jsx'))
);

const PurchasingCategories = React.lazy(() =>
  retry(() => import('@/views/purchasing/categories/index.jsx'))
);
const PurchasingUnits = React.lazy(() => retry(() => import('@/views/purchasing/units/index.jsx')));
const PurchasingTypes = React.lazy(() =>
  retry(() => import('@/views/purchasing/units-types/index.jsx'))
);
const PurchasingLibrarySetup = React.lazy(() =>
  retry(() => import('@/views/purchasing/setup/index.jsx'))
);
const PurchasingTemplates = React.lazy(() =>
  retry(() => import('@/views/purchasing/templates/index.jsx'))
);

const PurchasinForwarding = React.lazy(() =>
  retry(() => import('@/views/purchasing/forwarding/index.jsx'))
);
const Inventory = React.lazy(() => retry(() => import('@/views/purchasing/inventory/index.jsx')));
const Pricelist = React.lazy(() => retry(() => import('@/views/purchasing/pricelist/index.jsx')));
const PricelistForm = React.lazy(() =>
  retry(() => import('@/views/purchasing/pricelist/form/index.jsx'))
);
const BudgetedQuantity = React.lazy(() =>
  retry(() => import('@/views/purchasing/budgeted-quantity/index.jsx'))
);

const purchasing = [
  {
    path: paths.purchasing_requisitions,
    component: PurchasingRequisitions,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.purchasing.requisitions.view],
    documentTitle: 'Purchasing Requisitions'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.purchasing_requisitions}/approval-view`,
    component: PurchasingRequisitions,
    documentTitle: 'Purchasing Requisitions - Approval View',
    permissions: [permissions.office.purchasing.requisitions.view]
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.purchasing_requisitions}/:status(ev|dlv)`,
    component: Suppliers,
    permissions: [permissions.office.purchasing.requisitions.view],
    documentTitle: 'Purchasing Requisitions'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.purchasing_requisitions}/create`,
    component: PurchasingRequisition,
    documentTitle: 'Create Purchasing Requisitions',
    permissions: [permissions.office.purchasing.requisitions.create]
  },
  {
    path: `${paths.purchasing_requisitions}/:id`,
    component: PurchasingRequisition,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Purchasing Requisition',
    permissions: [permissions.office.purchasing.requisitions.edit]
  },
  {
    path: [
      paths.purchasing_library_setup,
      `${paths.purchasing_library_setup}/groups/:id`,
      `${paths.purchasing_library_setup}/:tab(list)`
    ],
    component: PurchasingLibrarySetup,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Purchasing Library Setup',
    permissions: [permissions.office.purchasing.library_setup.view]
  },
  {
    path: paths.purchasing_categories,
    component: PurchasingCategories,
    app: true,
    type: 'private',
    exact: true,
    pageTitle: 'Purchasing Categories',
    documentTitle: 'Purchasing Categories',
    permissions: [permissions.office.purchasing.categories.view]
  },
  {
    path: paths.purchasing_packing,
    component: PurchasingUnits,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Purchasing Units',
    permissions: [permissions.office.purchasing.packing.view]
  },
  {
    path: paths.purchasing_unit_types,
    component: PurchasingTypes,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Purchasing Unit Types',
    permissions: [permissions.office.purchasing.packing.view]
  },

  {
    path: paths.purchasing_requisition_templates,
    component: PurchasingTemplates,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Purchasing Requisition Templates',
    permissions: [permissions.office.purchasing.requisitions.view]
  },
  {
    path: `${paths.purchasing_requisition_templates}/:id`,
    component: PurchasingRequisition,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Purchasing Requisition Template',
    permissions: [permissions.office.purchasing.requisitions.view]
  },
  {
    path: [`${paths.purchasing_forwarding}/:view(items|orders|cases)?/:id?`],
    component: PurchasinForwarding,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.purchasing.forwarding.view],
    documentTitle: 'Purchasing Forwarding'
  },
  {
    path: paths.purchasing_inventory,
    component: Inventory,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.purchasing.stores_inventory.view],
    documentTitle: 'Stores Inventory'
  },
  {
    path: paths.purchasing_pricelist,
    component: Pricelist,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.purchasing.pricelist.view],
    documentTitle: 'Purchasing Pricelist'
  },
  {
    path: [
      `${paths.purchasing_pricelist}/create`,
      `${paths.purchasing_pricelist}/:id`,
      `${paths.purchasing_pricelist}/:id/:tab(price-zones)`
    ],
    component: PricelistForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.purchasing.pricelist.view],
    documentTitle: 'Purchasing Pricelist'
  },
  {
    path: paths.purchasing_budgeted_quantity,
    component: BudgetedQuantity,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.purchasing.budget_quantity.view],
    documentTitle: 'Budgeted Quantity'
  }
];

export default purchasing;
