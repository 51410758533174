import { AccountingAccount } from '@/common/types/accounting.ts';
import { CompanyType, Country, Currency, Department } from '@/common/types/enums.ts';
import { installmentsPeriodicityTypes } from '@/common/utils/fixed';
import { Vessel } from './vessel';
import { CompanyParty, OrcaCompany } from './companies';
import { Attachment } from '@/ts-common/types/files';
import { PersonParty } from './persons';
import { Supplier } from './purchasing';
import { TicketOffer } from './ticketing';
import { PortDaCaseAgent, PortDaCaseItem } from './port-da-cases';

interface InvoiceLinkedAccountingCompanyAccount extends AccountingAccount {
  accounting_company: CompanyType | null;
  accounting_company_id: number | null;
  parents?: AccountingAccount[];
}

export interface InvoiceLinkedAccountingCompanyType {
  local_amount: number | null;
  account: InvoiceLinkedAccountingCompanyAccount | null;
}

export type AccountingCompany = {
  accounting_company: OrcaCompany | null;
  account: AccountingAccount | null;
  account_id: number | null;
  account_group: AccountingAccount | null;
  local_amount: number | null;
  debit_or_credit: 'debit' | 'credit';
};

export interface InvoiceMatchedVendorType {
  id: number;
  full_name: string;
  matched_field?: string;
  confidence: number;
}

export const AccountPayableInvoiceDocumentType = 'account_payable' as const;

export const CreditNotenvoiceDocumentType = 'credit_note' as const;

export type InvoiceDocumentTypeLabel =
  | typeof AccountPayableInvoiceDocumentType
  | typeof CreditNotenvoiceDocumentType;

export interface InvoiceDocumentType {
  label: InvoiceDocumentTypeLabel;
  name: string;
}

export type InstallmentPeriodicityValue = (typeof installmentsPeriodicityTypes)[number]['value'];

export type InstallmentPeriodicityType = (typeof installmentsPeriodicityTypes)[number];

export type InvoiceTypeLabel =
  | 'ticketing'
  | 'purchasing'
  | 'events-accounts'
  | 'event-crew-medical-cases'
  | 'port-da-case-agents'
  | 'port-da-case-agent-items';

export type InvoiceType = {
  id: number;
  name: string;
  label: InvoiceTypeLabel;
};

export interface Invoice {
  id?: number;
  invoice_type_id: number;
  invoice_type: InvoiceType | null;
  vessel_id: number | null;
  vendor_id: number;
  vat_no: string | null;
  address: string;
  country_id: number;
  invoice_no: string;
  date: string;
  amount: string | null;
  currency_id: number;
  amount_base_currency_equivalent: string | null;
  base_currency_equivalent_id: number | null;
  base_currency_equivalent_rate: string | null;
  reviewed_by_id: number | null;
  reviewed_at: string | null;
  sent_to_accounting: boolean;
  remarks: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: null;
  file_id: number | null;
  is_domestic: boolean;
  code: string;
  pay_party_id: number | null;
  created_by_id: number | null;
  updated_by_id: number | null;
  due_date: string | null;
  vat_percentage: string | null;
  custom_rate: string | null;
  amount_custom_rate_equivalent: string | null;
  document_type: InvoiceDocumentTypeLabel | null;
  linked_case_total_local_amount: string | null;
  linked_case_total_base_amount: string | null;
  linked_case_difference_amount: string | null;
  linked_case_difference_percentage: string | null;
  is_paid: boolean;
  number_of_installments: number | null;
  installments_periodicity_value: number | null;
  installments_periodicity_type: InstallmentPeriodicityType | null;
  downpayments_amount: number | null;
  downpayments_amount_base_currency_equivalent: number | null;
  has_downpayments: boolean;
  has_installments: boolean;
  company_id: number | null;
  purchasing_total_ordered_sum: string | null;
  purchasing_total_delivered_sum: string | null;
  becoming_overdue: false;
  is_overdue: false;
  vessel: Vessel | null;
  vendor: CompanyParty;
  currency: Currency;
  country: Country;
  file: Attachment | null;
  attachments: Attachment[];
  departments: Department[];
  reviewed_by: PersonParty | null;
  pay_party: CompanyParty | null;
  created_by: PersonParty | null;
  updated_by: PersonParty | null;
  accounting_companies: AccountingCompany[];
  company: CompanyParty | null;
  invoice_links_count: number | null;
  requisition_suppliers?: Supplier[];
  tickets?: TicketOffer[];
  da_case_agents?: PortDaCaseAgent[];
  da_case_items?: PortDaCaseItem[];
  event_accounts?: []; // Link missing type
  medical_expense?: []; // Link missing type
}
