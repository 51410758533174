import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';
import React from 'react';

import { Button } from 'reactstrap';

import send from 'common/assets/svg/actions/send.svg';

const PreventActionButton = ({
  disabled,
  onButtonClick,
  buttonLabel,
  buttonColor = 'primary',
  buttonIcon = send,
  iconStyle,
  buttonClassName = '',
  labelClassName = '',
  svgClassName = '',
  onModalAccept,
  closeModal,
  isModalOpen,
  modalProps = {},
  size,
  dataCy
}) => {
  return (
    <>
      <Button
        color={buttonColor}
        className={`border-0 d-inline-flex align-items-center ${buttonClassName} ${
          !buttonClassName?.includes('px-') ? 'px-2' : ''
        }`}
        disabled={disabled}
        onClick={onButtonClick}
        size={size}
        data-cy={dataCy}
      >
        {buttonIcon ? (
          <SvgRender
            src={buttonIcon}
            style={iconStyle || { width: 16, height: 16 }}
            className={`me-1 ${svgClassName}`}
          />
        ) : null}
        <span className={labelClassName}>{buttonLabel}</span>
      </Button>

      <DangerousActionModal
        transparent
        onAccept={onModalAccept}
        closeModal={closeModal}
        isOpen={isModalOpen}
        {...modalProps}
      />
    </>
  );
};

export default PreventActionButton;
