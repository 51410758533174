import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import SharedByLabel from 'common/components/general/SharedByLabel';
import arrowBtn from 'assets/svg/common/arrow.svg';
import { selectTableDefaultRequestParams } from 'store/tables/selectors';
import { goToSavedTable } from './helpers';

const CreateNewSearch = ({
  setModalOpened,
  savedSearchParam,
  tableSearch,
  queryChanged,
  account
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const searchSaving = useSelector(state => state.tables.searchSaving);
  const { history } = useRouter();

  const tableDefaultRequestParams = useSelector(selectTableDefaultRequestParams);

  return (tableSearch &&
    tableSearch.party_id !== account.id &&
    tableSearch.id === savedSearchParam) ||
    queryChanged ? (
    <>
      {tableSearch && <div className="fs-14 text-gray-400 ms-1">- Edited</div>}

      {tableSearch ? (
        <SharedByLabel
          shouldShowName={tableSearch.party_id !== account.id}
          name={tableSearch.owner?.full_name}
          sharedWithNumber={tableSearch.shares?.length}
          wrapperClassName="ms-3 ps-3 border-start height-32"
        />
      ) : null}

      <div
        className={`${
          queryChanged || tableSearch?.shares?.length ? 'ms-3 ps-3 border-start' : 'ms-5'
        }`}
      >
        <Button type="button" color="grey" onClick={() => setModalOpened(true)}>
          Save as
        </Button>
      </div>

      {tableSearch && (
        <Dropdown
          direction="down"
          isOpen={dropdownOpened}
          toggle={() => setDropdownOpened(!dropdownOpened)}
          disabled={searchSaving}
        >
          <DropdownToggle color="dropdown-toggler">
            <SvgRender src={arrowBtn} style={{ width: 10, height: 10 }} />
          </DropdownToggle>
          <DropdownMenu className="ms-2" end={true} strategy="fixed">
            <DropdownItem
              className="pointer"
              onClick={() => goToSavedTable(history, tableDefaultRequestParams, tableSearch)}
            >
              Discard changes
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  ) : (
    tableSearch && (
      <SharedByLabel
        shouldShowName={tableSearch.party_id !== account.id}
        name={tableSearch.owner?.full_name}
        sharedWithNumber={tableSearch.shares?.length}
        wrapperClassName="ms-3 ps-3 border-start height-32"
      />
    )
  );
};

export default CreateNewSearch;
