import React from 'react';
import BasicTextField from '../BasicTextField';

interface TextInputProps {
  value: string | null;
  disabled: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAutoSave?: () => void;
  fieldKey?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  disabled,
  onChange,
  onAutoSave,
  fieldKey,
  ...rest
}) => {
  return (
    <BasicTextField
      value={value}
      placeholder="Add comments"
      disabled={disabled}
      onChange={onChange}
      onAutoSave={onAutoSave}
      fieldKey={fieldKey}
      {...rest}
    />
  );
};

export default TextInput;
