import { AccountType } from '@/common/types/account';
import TYPES from './types';
import AUTH_TYPES from 'store/auth/types';
import DASHBOARD_TYPES from 'store/dashboards/types';

export interface AccountStateType extends AccountType {
  intercom_hash_fetched: boolean;
  notification_preferences: any;
  is_saving_notification_preferences: boolean;
  last_dashboard_id?: number | null;
}

const INITIAL_STATE = {
  intercom_hash_fetched: false,
  notification_preferences: [],
  is_saving_notification_preferences: false
} as AccountStateType;

const reducer = (state: AccountStateType = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_ACCOUNT.SUCCESS:
      return {
        ...state,
        ...payload,
        intercom_hash_fetched: true
      };

    case TYPES.UPDATE_ACCOUNT.SUCCESS:
      return {
        ...state,
        ...payload
      };

    case TYPES.UPDATE_LAST_DASHBOARD_ID.SUCCESS: {
      return { ...state, last_dashboard_id: payload.last_dashboard_id };
    }

    case TYPES.GET_ACCOUNT_PERMISSIONS.SUCCESS: {
      const { permissions } = payload;

      return {
        ...state,
        permissions
      };
    }

    case TYPES.GET_ACCOUNT_WEBPUSH_CONFIG.SUCCESS: {
      const { webpush } = payload;

      return {
        ...state,
        webpush
      };
    }

    case TYPES.GET_NOTIFICATION_PREFERENCES.SUCCESS:
    case TYPES.GET_PARTY_NOTIFICATION_PREFERENCES.SUCCESS:
      return {
        ...state,
        notification_preferences: payload
      };

    case 'UPDATE_NOTIFICATION_PREFERENCES/pending':
    case 'UPDATE_BULK_NOTIFICATION_PREFERENCES/pending':
      return {
        ...state,
        is_saving_notification_preferences: true
      };

    case 'UPDATE_NOTIFICATION_PREFERENCES/fulfilled':
    case 'UPDATE_NOTIFICATION_PREFERENCES/rejected':
    case 'UPDATE_BULK_NOTIFICATION_PREFERENCES/fulfilled':
    case 'UPDATE_BULK_NOTIFICATION_PREFERENCES/rejected':
      return {
        ...state,
        is_saving_notification_preferences: payload?.is_saving
      };

    case DASHBOARD_TYPES.DELETE_DASHBOARD.SUCCESS:
      return {
        ...state,
        last_dashboard_id:
          state.last_dashboard_id === parseInt(payload.last_dashboard_id)
            ? null
            : state.last_dashboard_id
      };

    case AUTH_TYPES.LOGOUT_CLEANUP:
      return {};

    default:
      return state;
  }
};

export default reducer;
