import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ToggleButton = ({ children, className = '', onClick, size = null, ...rest }) => {
  return (
    <Button
      type="button"
      className={`toggle-button ${className}`}
      onClick={onClick}
      size={size}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg'])
};
