import _get from 'lodash/get';
import { stringifyObj } from 'common/utils/urls';

export const getTableQueryString = (filters, search) => {
  const obj = {
    filters: filters
      .filter(el => el.value)
      .map(filter => ({
        name: _get(filter, 'column.value', ''),
        operation: _get(filter, 'operation.value', ''),
        value: _get(filter, 'value', '')
      }))
  };

  if (search) {
    obj.searchText = search;
  }

  const params = stringifyObj(obj);
  return params;
};

export const initializeFilters = (
  filters,
  navigatorAction,
  requestParams,
  label,
  columns,
  setTableFilters
) => {
  if (requestParams && requestParams.filters) {
    if (
      filters.length === 0 ||
      navigatorAction === 'POP' ||
      (Array.isArray(filters) && filters[0].column === null)
    ) {
      //case where redux is not initialized or going back in browser
      setTableFilters({
        label,
        requestParams,
        columns
      });
    } else if (requestParams.filters.length === 0) {
      // case where the user press the menu when already having filters
      const newFilters = filters.map(el => ({ ...el, value: '' }));
      setTableFilters({
        label,
        filters: newFilters
      });
    } else {
      //case where url changes but we want the filters to remain even if
      //they have no value

      const newFilters = filters.map((el, elKey) => {
        const found = requestParams.filters.find(
          (f, fKey) => f.name === _get(el, 'column.key', undefined) && fKey === elKey
        );

        if (!found) {
          return el;
        } else {
          return {
            ...el,
            value: found.value
          };
        }
      });

      setTableFilters({
        label,
        filters: newFilters
      });
    }
  }
};

export const refetchPageTableData = async (
  tableRefetchData,
  paging,
  newPage,
  scrollToTop = true
) => {
  try {
    await tableRefetchData({ paging: { per_page: paging.per_page, current_page: newPage } });
  } catch (e) {
    console.log(e);
  }

  if (scrollToTop) window.scrollTo(0, 0);
};

export const getTableVisibilityParams = table => {
  const {
    columns,
    requestParams: { visible }
  } = table;

  if (visible) {
    return visible.filter(v => v.visible === 'true' || v.visible === true).map(v => v.key);
  } else {
    return columns
      .filter(col => !col.hidden && col.key !== 'actions' && !col?.className?.includes('d-none'))
      .map(c => c.key);
  }
};

export const appendTableFilterPropsToColumns = (columns = [], config = {}) => {
  /* config = { 'column.key': { filterKey, type: 'collection', component: ..., componentRest: ..., ...anythingElse } } 
    When filterKey is set, a new hidden column will be added that would be used only for filtering
  */

  const updatedColumns = columns
    .filter(column => !config?.[column.key]?.filterKey)
    .map(column => ({ ...column, ...(config?.[column.key] || {}) }));

  // Append the hidden filter columns (if any)
  columns
    .filter(column => config?.[column.key]?.filterKey)
    .forEach(column => {
      // visible column
      updatedColumns.push(column);

      // hidden filter column
      const { filterKey, ...rest } = config?.[column.key];
      updatedColumns.push({
        ...column,
        ...rest,
        key: filterKey,
        hidden: true,
        canFilter: true
      });
    });

  return updatedColumns;
};

/* 
  This function is for the case where we have initial top filters that are not in the url 
  so when we apply other filters they get overriden
*/
export const parseOverridenTopFilters = (topFilters, requestFilters) => {
  if (!topFilters || !topFilters?.length) return requestFilters;

  if (!requestFilters || !requestFilters?.length) return topFilters.filter(f => f.value);

  const filters = [...requestFilters];

  topFilters.forEach(filter => {
    const filterAlreadyInParams = !!filters?.find(f => f.name === filter.name);

    if (!filterAlreadyInParams && filter.value) {
      filters.push(filter);
    }
  });

  return filters;
};

export const getVisibleColumns = ({ requestParams, columns, isOnBoard }) => {
  let filteredCols = [];

  if (requestParams && requestParams?.visible?.length) {
    // Always have the left-sticky columns first
    columns
      .filter(column => column.sticky && column.left >= 0 && !column.right)
      .forEach(column => {
        filteredCols.push(column);
      });
    // Then add the non-sticky visible columns
    requestParams.visible
      .filter(
        v => (v.visible === 'true' || v.visible === true) && columns.find(c => c.key === v.key)
      )
      .map(v => columns.find(c => c.key === v.key))
      .forEach(column => {
        if (!column.sticky) filteredCols.push(column);
      });

    // Finally, add last the right-sticky && !canReorder columns
    columns
      .filter(column => column.sticky && column.right >= 0 && !column.left)
      .forEach(column => {
        filteredCols.push(column);
      });
  } else {
    filteredCols = [...columns];
  }

  return filteredCols.filter(column => (column.hiddenOnBoard && isOnBoard ? false : column));
};
