import { createSelector } from 'reselect';
import { MgaCurrencyType, MgaStateType, PastRatesType } from '@/common/types/mga.ts';
import { RootState } from '@/store';

const selectReducer = (state: RootState): MgaStateType => state.mga;

/* Forex Rate Currencies */
export const selectCurrencies = (state: RootState): MgaCurrencyType[] =>
  selectReducer(state).currencies;

export const selectPastRates = (state: RootState): PastRatesType[] =>
  selectReducer(state).pastRates;

export const selectBaseCurrency = createSelector(
  selectCurrencies,
  (currencies: MgaCurrencyType[]) => currencies.find(currency => currency?.isBase)
);

export const selectBaseCurrencyLabel = createSelector(
  selectCurrencies,
  (currencies: MgaCurrencyType[]) => currencies.find(currency => currency?.isBase)?.label || 'USD'
);

export const selectCurrenciesWithoutBase = createSelector(
  selectCurrencies,
  (currencies: MgaCurrencyType[]) => currencies.filter(currency => !currency?.isBase)
);
/* -- */
