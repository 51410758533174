import { fieldTypes } from '../field-types/helpers';
import {
  commonSuffixes,
  getLoadingOrDischargingAction,
  getLoadingOrDischargingActionTimeline,
  getLoadingOrDischargingActionTimelineQuantitySum
} from './helpers';
import { getActionValue } from 'common/utils/voyages/helpers';

import numeral from 'numeral';
import _get from 'lodash/get';
import {
  validateSpeedOG,
  validateAllFuelConsumptions,
  validateAtPortConsumptions,
  validateAtPortPortTotalsConsumptions,
  validateOceanCurrentSpeed,
  validateWindSpeed,
  validateSignificantWaveHeight
} from './validations';
import { transformNumber, transformOceanCurrent } from './transformations';
import { getGoodWeatherClauses } from 'views/the-fleet/components/position-list/card/components/body/info/detailed-info/fields-list/helpers';

import { numberToStr, strToNumber } from 'common/utils/numbers';

// Custom fields
import InstructedField from '../custom-fields/instructed';
import LastUpdated from '../custom-fields/last-updated';
import StatusLiveActions from '../custom-fields/StatusLiveActions';

export const lastUpdated = key => ({
  CustomComponent: LastUpdated,
  key
});

export const instructedLast = {
  CustomComponent: InstructedField,
  keys: {
    for: 'previous_instructed.last_instructed_speed_consumption_target',
    eta: 'previous_instructed.last_instructed_eta',
    speed: 'previous_instructed.last_instructed_speed',
    foCons: 'previous_instructed.last_instructed_fo_consumption',
    goCons: 'previous_instructed.last_instructed_go_consumption',
    minSpeed: 'previous_instructed.last_instructed_speed_minimum_allowance',
    maxFOCons: 'previous_instructed.last_instructed_consumption_fo_maximum_allowance',
    maxGOCons: 'previous_instructed.last_instructed_consumption_go_maximum_allowance'
  }
};

export const instructedPreviousReport = {
  CustomComponent: InstructedField,
  keys: {
    for: 'previous_instructed.instructed_speed_consumption_target',
    eta: 'previous_instructed.instructed_eta',
    speed: 'previous_instructed.average_instructed_speed',
    foCons: 'previous_instructed.average_instructed_fo_consumption',
    goCons: 'previous_instructed.average_instructed_go_consumption',
    minSpeed: 'previous_instructed.instructed_speed_minimum_allowance',
    maxFOCons: 'previous_instructed.instructed_consumption_fo_maximum_allowance',
    maxGOCons: 'previous_instructed.instructed_consumption_go_maximum_allowance'
  }
};

export const instructedLegTotals = {
  CustomComponent: InstructedField,
  groupKey: 'leg_average',
  keys: {
    for: 'leg_totals.leg_instructed_speed_consumption_target',
    eta: 'leg_totals.leg_instructed_eta',
    speed: 'leg_totals.leg_average_instructed_speed',
    foCons: 'leg_totals.leg_average_instructed_fo_consumption',
    goCons: 'leg_totals.leg_average_instructed_go_consumption',
    minSpeed: 'leg_totals.leg_instructed_speed_minimum_allowance',
    maxFOCons: 'leg_totals.leg_instructed_consumption_fo_maximum_allowance',
    maxGOCons: 'leg_totals.leg_instructed_consumption_go_maximum_allowance'
  }
};

export const seaFields = {
  speedOG: (key, comparisonKey, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => {
    return {
      fieldType: fieldTypes.simpleField,
      title: 'Speed OG',
      key,
      groupKey: 'leg_average',
      suffix: commonSuffixes.knots,
      className: 'ms-1',
      showOnlyAutomatedValue,
      showAbbreviationWhenEmpty,
      validationClassName: (fieldValue, values) =>
        validateSpeedOG(fieldValue, values, comparisonKey),
      transformation: val => transformNumber(val)?.toFixed(1)
    };
  },
  speedTW: (key, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.simpleField,
    title: 'Speed TW',
    key,
    groupKey: 'leg_average',
    suffix: commonSuffixes.knots,
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    className: 'ms-1',
    transformation: val => transformNumber(val)?.toFixed(1)
  }),
  speedOGTW: (key, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.simpleField,
    title: 'Speed OG - TW',
    key,
    groupKey: 'leg_average',
    suffix: commonSuffixes.knots,
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    transformation: val => transformNumber(val)?.toFixed(1),
    className: 'ms-1'
  }),
  distanceRun: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Distance Run',
    key,
    groupKey: 'leg_average',
    suffix: commonSuffixes.miles,
    transformation: transformNumber,
    className: 'ms-1'
  }),
  slip: (key, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.simpleField,
    title: 'Slip',
    key,
    groupKey: 'leg_average',
    suffix: commonSuffixes.percentage,
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    transformation: transformNumber,
    className: 'ms-1'
  }),
  meRPM: (key, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.simpleField,
    title: 'ME RPM',
    key,
    groupKey: 'leg_average',
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    transformation: val => transformNumber(val)?.toFixed(0),
    className: 'ms-1'
  }),
  mePower: (key, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.simpleField,
    title: 'ME Power',
    key,
    groupKey: 'leg_average',
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    suffix: commonSuffixes.kilowatts,
    transformation: val => numberToStr(val, 0),
    className: 'ms-1'
  })
};

export const portFields = {
  status: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Status',
    key,
    CustomComponent: StatusLiveActions,
    className: 'ms-1',
    hideTooltip: true
  }),
  loadingRate: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Rate',
    key,
    hide: values => !getLoadingOrDischargingAction(values, key),
    transformation: values => {
      const action = getLoadingOrDischargingAction(values, key);
      if (!action) return null;

      const rate = getActionValue(action, `${action.type_label}_rate`);
      const tRate = transformNumber(rate);

      return tRate !== null ? numberToStr(tRate, 2, 2) : null;
    },
    suffix: (values, units) => {
      const action = getLoadingOrDischargingAction(values, key);
      if (!action || !units?.length) return null;

      const actionUnitId = getActionValue(action, `${action.type_label}_rate_unit_id`);
      const unit = units?.find(u => u.id === actionUnitId);

      return ` ${unit?.notation || ''}`;
    },
    className: 'ms-1',
    hideTooltip: true
  }),
  terms: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Terms',
    key,
    hide: values => !getLoadingOrDischargingAction(values, key),
    transformation: values => {
      const action = getLoadingOrDischargingAction(values, key);
      if (!action) return null;

      return _get(getActionValue(action, 'terms'), 'name');
    },
    className: 'ms-1',
    hideTooltip: true
  }),
  cranes: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Cranes',
    key,
    hide: values => !getLoadingOrDischargingAction(values, key),
    transformation: values => {
      const loadingAction = _get(
        getLoadingOrDischargingAction(values, key),
        'port_statement_details.cargo_operation_timeline[0]'
      );

      return _get(loadingAction, 'cargo_operation_cranes_used') || !loadingAction
        ? 'Port'
        : 'Vessel';
    },
    className: 'ms-1',
    hideTooltip: true
  }),
  grabs: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Grabs',
    key,
    hide: values => !getLoadingOrDischargingAction(values, key),
    transformation: values => {
      const loadingAction = _get(
        getLoadingOrDischargingAction(values, key),
        'port_statement_details.cargo_operation_timeline[0]'
      );

      return _get(loadingAction, 'cargo_operation_grabs_used') || !loadingAction
        ? 'Port'
        : 'Vessel';
    },
    className: 'ms-1',
    hideTooltip: true
  }),
  instructedConsIdle: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Instructed Cons (Idle)',
    key,
    transformation: values => {
      const goCons = _get(values, 'go_consumption');
      const foCons = _get(values, 'fo_consumption');

      return `${foCons ? numberToStr(foCons, 2) : !goCons ? '-' : ''}${
        foCons && goCons ? '+' : ''
      }${goCons ? numberToStr(goCons, 2) : !foCons ? '-' : ''}`;
    },
    suffix: commonSuffixes.metricTonsPerDay,
    className: 'ms-1',
    hideTooltip: true
  }),
  instructedConsWork: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Instructed Cons (Work)',
    key,
    transformation: values => {
      const goCons = _get(values, 'go_consumption');
      const foCons = _get(values, 'fo_consumption');

      return `${foCons ? numberToStr(foCons, 2) : !goCons ? '-' : ''}${
        foCons && goCons ? '+' : ''
      }${goCons ? numberToStr(goCons, 2) : !foCons ? '-' : ''}`;
    },
    suffix: commonSuffixes.metricTonsPerDay,
    className: 'ms-1',
    hideTooltip: true
  }),
  loaded: key => ({
    fieldType: fieldTypes.simpleField,
    title: (_, allValues) => {
      const action = getLoadingOrDischargingAction(allValues, key);

      if (action?.type_label === 'discharging') return 'Discharged';
      return 'Loaded';
    },
    key,
    hide: values => !getLoadingOrDischargingAction(values, key),
    transformation: values => {
      const action = getLoadingOrDischargingAction(values, key);
      const timeline = getLoadingOrDischargingActionTimeline(action);

      if (!timeline?.length) return null;

      const sum = getLoadingOrDischargingActionTimelineQuantitySum(timeline);

      return sum ? numberToStr(sum, 2, 2) : null;
    },
    suffix: (values, units) => {
      const action = getLoadingOrDischargingAction(values, key);
      if (!action || !units?.length) return null;

      const actionUnitId = getActionValue(action, 'expected_quantity_unit_id');
      const unit = units?.find(u => u.id === actionUnitId);

      return ` ${unit?.notation || ''}`;
    },
    className: 'ms-1',
    hideTooltip: true
  }),
  remaining: key => ({
    fieldType: fieldTypes.simpleField,
    title: 'Remaining',
    key,
    hide: values => !getLoadingOrDischargingAction(values, key),
    transformation: values => {
      const action = getLoadingOrDischargingAction(values, key);
      const timeline = getLoadingOrDischargingActionTimeline(action);

      if (!timeline?.length) return null;

      const sum = getLoadingOrDischargingActionTimelineQuantitySum(timeline);

      const diff = numeral(
        strToNumber(
          action?.port_statement_details?.expected_quantity || action?.expected_quantity || 0
        )
      ).subtract(strToNumber(sum))._value;

      return diff ? numberToStr(diff, 2, 2) : null;
    },
    suffix: (values, units) => {
      const action = getLoadingOrDischargingAction(values, key);
      if (!action || !units?.length) return null;

      const actionUnitId = getActionValue(action, 'expected_quantity_unit_id');
      const unit = units?.find(u => u.id === actionUnitId);

      return ` ${unit?.notation || ''}`;
    },
    className: 'ms-1',
    hideTooltip: true
  })
};

export const rob = (parentKey, childKey) => ({
  fieldType: fieldTypes.columns,
  key: parentKey,
  title: 'ROB',
  suffix: 'mt',
  getColumns: values => {
    return (values || []).map(value => {
      return { value: numberToStr(value[childKey], 0) };
    });
  }
});

export const draft = parentKey => ({
  fieldType: fieldTypes.columns,
  key: parentKey,
  title: 'Draft',
  suffix: 'm',
  getColumns: values => {
    return [
      { prefix: 'A', value: _get(values, 'draft_aft') },
      { prefix: 'M', value: _get(values, 'draft_mid') },
      { prefix: 'F', value: _get(values, 'draft_fwd') }
    ];
  }
});

export const consumptions = {
  atSea: (parentKey, comparisonKeys, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.groupedTable,
    key: `${parentKey}.consumptions`,
    unit: 'mt/d',
    titleKey: 'fuel_grade.name',
    transformation: val => transformNumber(val)?.toFixed(2),
    validationClassName: (_, __, allValues) =>
      validateAllFuelConsumptions('total_consumption_rate', allValues, comparisonKeys, parentKey),
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    rows: [
      {
        title: 'ME',
        key: 'me_consumption_rate',
        validationClassName: false
      },
      {
        title: 'DG',
        key: 'dg_consumption_rate',
        validationClassName: false
      },
      {
        title: 'Boiler',
        key: 'boiler_consumption_rate',
        validationClassName: false
      },
      {
        title: 'Total',
        key: 'total_consumption_rate'
      }
    ]
  }),
  simpleAtPort: (parentKey, showOnlyAutomatedValue, showAbbreviationWhenEmpty) => ({
    fieldType: fieldTypes.groupedTable,
    key: `${parentKey}.consumptions`,
    unit: 'mt/d',
    titleKey: 'fuel_grade.name',
    showOnlyAutomatedValue,
    showAbbreviationWhenEmpty,
    transformation: val => transformNumber(val)?.toFixed(2),
    validationClassName: validateAtPortConsumptions,
    rows: [
      {
        title: 'DG Cons',
        key: 'dg_consumption_rate'
      }
    ]
  }),
  totalsAtSea: (parentKey, comparisonKeys) => ({
    fieldType: fieldTypes.groupedTable,
    key: `${parentKey}.consumptions`,
    groupKey: 'leg_average',
    unit: 'mt/d',
    titleKey: 'fuel_grade.name',
    transformation: val => transformNumber(val)?.toFixed(2),
    validationClassName: (_, __, allValues) =>
      validateAllFuelConsumptions('leg_consumption_rate', allValues, comparisonKeys, parentKey),
    rows: [
      {
        title: 'ME',
        key: 'avg_me_consumption_rate',
        validationClassName: false
      },
      {
        title: 'DG',
        key: 'avg_dg_consumption_rate',
        validationClassName: false
      },
      {
        title: 'Boiler',
        key: 'avg_boiler_consumption_rate',
        validationClassName: false
      },
      {
        title: 'Total',
        key: 'leg_consumption_rate'
      }
    ]
  }),
  totalsAtPort: parentKey => ({
    fieldType: fieldTypes.groupedTable,
    key: `${parentKey}.consumptions`,
    unit: 'mt',
    titleKey: 'fuel_grade.name',
    transformation: val => transformNumber(val)?.toFixed(2),
    validationClassName: (_, __, allValues) =>
      validateAtPortPortTotalsConsumptions('avg_dg_consumption_rate', allValues, parentKey),
    decimalScale: 1,
    rows: [
      {
        title: 'DG Cons',
        suffix: 'avg',
        key: 'avg_dg_consumption_rate'
      },
      {
        title: 'DG Cons',
        suffix: 'ttl',
        key: 'leg_dg_consumption_total',
        validationClassName: false
      }
    ]
  })
};

export const weatherFields = {
  current: (key, tab, oceanCurrentRelativeDirection) => ({
    key,
    hideTitleFromText: true,
    title: 'Ocean Current',
    suffix: 'kt',
    fullSuffix: 'knots',
    showOnlyAutomatedValue: tab === 'live',
    showOnlyCaptainReportValue: tab !== 'live',
    showAbbreviationWhenEmpty: tab === 'live',
    transformation: val => transformOceanCurrent(val, tab, oceanCurrentRelativeDirection),
    validationClassName: (val, voyage, configName) => {
      const isLive = configName?.includes('live');

      return validateOceanCurrentSpeed(
        val,
        isLive
          ? _get(voyage, 'previous_report.ocean_current_relative_direction')
          : _get(voyage, 'previous_report.ocean_current_relative_direction.noonValue'),
        voyage
      );
    }
  }),
  sigwaves: (key, tab) => ({
    key,
    hideTitleFromText: true,
    title: 'Combined Sea and Swell',
    suffix: 'm',
    fullSuffix: 'meters',
    showOnlyAutomatedValue: tab === 'live',
    showOnlyCaptainReportValue: tab !== 'live',
    showAbbreviationWhenEmpty: tab === 'live',
    transformation: transformNumber,
    validationClassName: (value, voyage) =>
      validateSignificantWaveHeight(value, getGoodWeatherClauses(voyage))
  }),
  wind: (key, tab) => ({
    key,
    hideTitleFromText: true,
    title: 'Wind Force',
    suffix: 'bft',
    fullSuffix: 'beaufort',
    showOnlyAutomatedValue: tab === 'live',
    showOnlyCaptainReportValue: tab !== 'live',
    showAbbreviationWhenEmpty: tab === 'live',
    transformation: transformNumber,
    validationClassName: (value, voyage) => validateWindSpeed(value, getGoodWeatherClauses(voyage))
  }),
  heading: (key, tab) => ({
    key,
    title: 'Heading',
    suffix: '°',
    transformation: transformNumber,
    showOnlyAutomatedValue: tab === 'live',
    showOnlyCaptainReportValue: tab !== 'live'
  })
};
