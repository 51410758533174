import { createSelector } from 'reselect';
import { strToNumber } from 'common/utils/numbers';
import { selectRob } from 'captain-reports/templates/components/helpers';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import numeral from 'numeral';
import { hasValue } from 'common/utils/numbers';
import { isReportStatusLocked } from 'captain-reports/store/helpers';

const selectCaptainReportsReducer = state => state.captainReports;
export const selectCaptainReportsFormReducer = state => state.captainReportsForm;

export const selectTemplateVisibleFields = state => state.captainReports.templateVisibleFields;

const selectOpenedSections = state => state.captainReports.openedSections;
const selectSectionActiveTab = state => state.captainReports.activeSectionsTab;

export const isCaptainReportsFormInitiliazed = state => state.captainReports.formInitialized;

/* Multi Sections Selectors */
const selectCaptainReportsFormEngines = state => state.captainReportsForm.engines;
const selectCaptainReportsFormGenerators = state => state.captainReportsForm.generators;

/* -- */

const getMulitpleIndex = (_, index) => index;
const getMultipleKey = (_, __, key) => key;

const getMultiSectionSubGroup = (_, __, key) => key;
const getMultiSectionSubGroupIndex = (_, __, ___, key) => key;
const getMultiSectionSubGroupFieldKey = (_, __, ___, ____, key) => key;

const getMultiSectionSubGroupFields = (_, __, ___, key) => key;

const getSubGroup = (_, key) => key;
const getSubGroupIndex = (_, __, key) => key;
const getSubGroupFieldKey = (_, __, ___, key) => key;

const getKey = (_, key) => key;
const getArr = (_, arr) => arr;

export const selectCaptainReportsConsumptions = createSelector(
  selectCaptainReportsFormReducer,
  fields => fields.consumptions
);

export const selectFieldValues = createSelector(
  selectCaptainReportsFormReducer,
  getArr,
  (fields, arr) => {
    return arr.map(el => fields[el].value);
  }
);

export const selectFieldValue = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    if (!fields[key]) {
      // console.log('missing key: ');
      // console.error(key);
      // console.log('--');
      return null;
    }

    if (fields[key].selectValue !== undefined) {
      return fields[key].selectValue;
    }

    return fields[key].value;
  }
);

export const selectFieldError = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    return fields[key].error;
  }
);

export const selectFieldComment = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    return fields[key].comment;
  }
);

export const selectFieldFile = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    return fields[key].file;
  }
);

export const selectMultipleSectionTabsCount = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    return fields[key] ? fields[key].length : 1;
  }
);

export const selectCaptainReportTemplate = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.template;
  }
);

export const selectCaptainReportTemplateType = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.template.type;
  }
);

export const selectCaptainReportTemplateGroup = state =>
  selectCaptainReportsReducer(state)?.template?.report_group;

export const selectCaptainReportTemplateName = createSelector(
  selectCaptainReportTemplate,
  template => template?.name
);

export const selectCaptainReportTemplateFuelGrades = createSelector(
  selectCaptainReportTemplate,
  template => {
    return template && template.fuel_grades ? template.fuel_grades : [];
  }
);

export const selectCaptainReportData = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.data;
  }
);

export const selectCaptainReportComments = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.data?.rebuild_comments;
  }
);

export const selectCaptainReportPlannedRouteId = state =>
  selectCaptainReportData(state)?.planned_route_id;

export const selectCaptainReportVessel = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.vessel;
  }
);

export const selectCaptainReportTotals = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.totals;
  }
);

export const selectCaptainReportBunkering = state => selectCaptainReportTotals(state)?.bunkering;

export const selectTotalLegStartTimestamp = state =>
  selectCaptainReportTotals(state)?.leg_start_timestamp;

export const selectTotalSteamingTime = state => selectCaptainReportTotals(state)?.steaming_time;

export const selectCaptainReportBunkeringPerFuelGrade = createSelector(
  selectCaptainReportBunkering,
  getKey,
  (bunkering, fuelGradeId) => {
    return bunkering?.filter(e => e.fuel_grade_id === fuelGradeId);
  }
);

export const selectCaptainReportNavigation = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.navigation
);

export const selectPreviousCaptainReport = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.previousReport;
  }
);

export const selectPreviousCaptainReportId = createSelector(
  selectPreviousCaptainReport,
  previousReport => previousReport?.id
);

export const selectFutureCaptainReports = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.futureReports;
  }
);

export const getSelectedFutureReports = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.selectedFutureReports;
  }
);

export const futureReportsIsFetching = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.futureReportsIsFetching;
  }
);

export const selectPreviousBsViscositiesCaptainReport = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.previousBsViscosities;
  }
);

export const selectPreviousBsBioFuelsCaptainReport = state =>
  selectCaptainReportsReducer(state).previousBsBioFuels;

export const selectPreviousBsViscositiesCaptainReportPerFuelGrade = createSelector(
  selectPreviousBsViscositiesCaptainReport,
  getKey,
  (bsViscosities, fuelGradeId) => bsViscosities?.find(e => e.fuel_grade_id == fuelGradeId) || []
);

export const selectPreviousNavigational = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.previousNavigational;
  }
);

export const selectCaptainReportRobDifferences = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.totals?.rob_differences || [];
  }
);

export const selectCaptainReportIsOnBoard = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.onBoard;
  }
);

export const selectCaptainReportValidationTry = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.formValidationTry
);

export const selectIsForceUpdateSectionProgress = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.updateSectionProgress
);

export const selectCaptainReportLubricantTotals = createSelector(
  selectCaptainReportTotals,
  totals => {
    return totals && totals.lubricants ? totals.lubricants : {};
  }
);

const selectPreviousCaptainReportBunkers = state =>
  _get(selectPreviousCaptainReport(state), 'bunkers', []);

export const selectPreviousCaptainReportBunkerPerFuelGrade = createSelector(
  selectPreviousCaptainReportBunkers,
  getKey,
  (prevConsumptions, fuelGradeId) =>
    (prevConsumptions || []).find(consumption => consumption?.fuel_grade_id === fuelGradeId)
);

export const selectPreviousCaptainReportEngines = createSelector(
  selectPreviousCaptainReport,
  previous => {
    return _get(previous, 'engines', []);
  }
);

const selectCaptainReportConsumptionTotals = state =>
  selectCaptainReportTotals(state)?.consumptions;

export const selectCaptainReportTotalConsumptionsPerFuelGrade = createSelector(
  selectCaptainReportConsumptionTotals,
  getKey,
  (consumptionTotals, fuelGradeId) => consumptionTotals?.find(c => c.fuel_grade_id === fuelGradeId)
);

export const selectPreviousCaptainReportLocalTimestamp = createSelector(
  selectPreviousCaptainReport,
  previous => {
    return _get(previous, 'local_timestamp', null);
  }
);

export const selectPreviousCaptainReportTimestamp = createSelector(
  selectPreviousCaptainReport,
  previous => {
    return _get(previous, 'timestamp', null);
  }
);

export const selectCaptainReportLastReportTimezone = createSelector(
  selectPreviousCaptainReport,
  previous => {
    return _get(previous, 'timezone', null);
  }
);

export const selectCaptainReportLastReportStoppageDates = createSelector(
  selectCaptainReportTotals,
  totals => {
    return totals.stoppage_start_end_dates || [];
  }
);

export const selectPreviousCaptainReportValueByKey = createSelector(
  selectPreviousCaptainReport,
  getKey,
  (data, key) => {
    return _get(data, `${key}.value`, null);
  }
);

export const selectPreviousCaptainReportFirstRevCounter = createSelector(
  selectPreviousCaptainReport,
  data => {
    return _get(data, 'engines[0].me_rev_counter.value', null) || null;
  }
);

export const selectPreviousNavigationalByKey = createSelector(
  selectPreviousNavigational,
  getKey,
  (data, key) => {
    return _get(data, key, null);
  }
);

export const selectCaptainReportId = createSelector(selectCaptainReportData, data => {
  return data?.id;
});

export const selectCaptainReportStatus = createSelector(selectCaptainReportData, data => {
  return data.status;
});

export const selectCaptainReportGroup = createSelector(selectCaptainReportData, data => {
  return data?.report_group;
});

export const isReportApproved = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.data?.status === 'approved'
);

export const selectReviewedByTechData = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return {
      reviewed_by_tech: captainReports.data?.reviewed_by_tech,
      is_reviewed_by_tech: captainReports.data?.is_reviewed_by_tech,
      reviewed_by_tech_at: captainReports.data?.reviewed_by_tech_at
    };
  }
);

export const selectEdittingApprovedReport = createSelector(
  selectCaptainReportsReducer,
  captainReports => {
    return captainReports.edittingApprovedReport;
  }
);

export const isReportLocked = createSelector(
  selectCaptainReportsReducer,
  selectCaptainReportStatus,
  selectCaptainReportIsOnBoard,
  selectEdittingApprovedReport,
  (captainReports, status, isOnboard, isEdittingApprovedReport) =>
    isReportStatusLocked(status, isOnboard, isEdittingApprovedReport)
);

export const isReportInvalid = createSelector(selectCaptainReportsReducer, captainReports => {
  return captainReports.invalidReport;
});

export const selectFieldVisibility = createSelector(
  selectTemplateVisibleFields,
  getKey,
  (visibleFields, key) => {
    return visibleFields[key];
  }
);

export const selectFirstVisibleFieldInGroup = createSelector(
  selectTemplateVisibleFields,
  getKey,
  (visibleFields, groupFields) => {
    return groupFields.find(f => visibleFields[f.key]);
  }
);

export const hasAtLeastOneVisibleField = createSelector(
  selectTemplateVisibleFields,
  getArr,
  (visibleFields, fields) => {
    return (fields?.length ? fields : []).some(f => visibleFields[f.key]);
  }
);

export const selectOpenedSection = createSelector(
  selectOpenedSections,
  getKey,
  (openedSections, key) => {
    return openedSections[key];
  }
);

export const selectAreOpenedSections = createSelector(selectOpenedSections, sections => {
  const array = Object.keys(sections).filter(e => sections[e]) || [];

  return !!array?.length;
});

export const selectActiveTab = createSelector(
  selectSectionActiveTab,
  getKey,
  (activeSectionsTab, key) => {
    return activeSectionsTab[key] !== undefined ? activeSectionsTab[key] : 0;
  }
);

const getFieldValue = field => {
  if (!field) {
    return null;
  }
  if (field.selectValue !== undefined) {
    return field.selectValue;
  }

  return field.value;
};

const getFieldError = field => {
  return field.error;
};

const getFieldComment = field => {
  return field.comment;
};

export const selectFieldTemplateValidation = key =>
  createSelector(selectCaptainReportsReducer, captainReports => {
    return captainReports.templateFieldValidations[key];
  });

export const selectRequiredValidationError = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.hasRequiredValidationError
);

export const selectTemplateValidationError = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.hasTemplateValidationError
);

export const selectFieldTemplateTooltip = key =>
  createSelector(selectCaptainReportsReducer, captainReports => {
    return captainReports.templateFieldTooltips[key];
  });

export const selectHiddenFromVesselTemplateValidations = createSelector(
  selectCaptainReportsReducer,
  captainReports => captainReports.templateHiddenFromVesselFieldValidations
);

export const selectHiddenFromVesselTemplateFieldValidation = createSelector(
  selectHiddenFromVesselTemplateValidations,
  getKey,
  (validations, key) => {
    return validations[key];
  }
);

export const selectLubricantTotalAutocompleteValues = createSelector(
  selectCaptainReportsFormReducer,
  state =>
    [
      state?.co_1_leg_total_consumption_total?.value,
      state?.co_2_leg_total_consumption_total?.value,
      state?.co_total_leg_total_consumption_total?.value,
      state?.co_3_leg_total_consumption_total?.value,
      state?.go_leg_total_consumption_total?.value,
      state?.co_total_leg_total_supplied?.value
    ]?.filter(e => e)
);

/* Multi Sections Selectors */

/* Engine */

export const selectEngineData = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultipleKey,
  (engines, index, key) => {
    return getFieldValue(engines[index][key]);
  }
);

export const selectEngineDataError = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultipleKey,
  (engines, index, key) => {
    return getFieldError(engines[index][key]);
  }
);

export const selectEngineDataComment = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultipleKey,
  (engines, index, key) => {
    return getFieldComment(engines[index][key]);
  }
);

/* */

/* Generator */

export const selectGeneratorsData = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultipleKey,
  (generators, index, key) => {
    return getFieldValue(generators[index][key]);
  }
);

export const selectGeneratorsDataError = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultipleKey,
  (generators, index, key) => {
    return getFieldError(generators[index][key]);
  }
);

export const selectGeneratorsDataComment = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultipleKey,
  (generators, index, key) => {
    return getFieldComment(generators[index][key]);
  }
);

export const selectMainEngineTotalByKey = createSelector(
  selectCaptainReportsFormEngines,
  getKey,
  (mainEngine, key) => {
    if (!key) return null;

    const arrayOfValues = mainEngine.map(g => g?.[key].value) || [];

    return arrayOfValues.reduce((acc, cur) => {
      if (cur !== null) {
        return numeral(acc || 0).add(cur)._value;
      } else {
        return acc;
      }
    }, null);
  }
);

export const selectMainEngineTotalPower = state => {
  const shaftPower = selectMainEngineTotalByKey(state, 'me_shaft_power');
  const effectivePower = selectMainEngineTotalByKey(state, 'me_effective_power');
  const indicatedPower = selectMainEngineTotalByKey(state, 'me_indicated_power');

  return hasValue(shaftPower)
    ? strToNumber(shaftPower) * 0.985
    : hasValue(effectivePower)
    ? effectivePower
    : hasValue(indicatedPower)
    ? indicatedPower
    : null;
};

export const selectCaptainReportTotalsMainEnginePower = state => {
  const totals = selectCaptainReportTotals(state);
  const legEffectivePower = totals?.me_effective_power || null;
  const legIndicatedPower = totals?.me_indicated_power || null;
  const legShaftPower = totals?.me_shaft_power || null;

  return hasValue(legShaftPower)
    ? strToNumber(legShaftPower) * 0.985
    : hasValue(legEffectivePower)
    ? legEffectivePower
    : hasValue(legIndicatedPower)
    ? legIndicatedPower
    : null;
};

/* */

/* Consumptions */

export const selectConsumptionsKey = createSelector(
  selectCaptainReportsConsumptions,
  getKey,
  (consumptions, key) => {
    return consumptions.map(el => el[key]);
  }
);

export const selectDgConsumptionPerGeneratorIndex = activeTab =>
  createSelector(selectCaptainReportsConsumptions, consumptions => {
    const conKey = `dg_${activeTab}_consumption_total`;

    return consumptions?.reduce((acc, cur) => {
      const number = numeral(acc);

      return number.add(strToNumber(cur?.[conKey]?.value) || 0);
    }, 0)._value;
  });

export const selectConsumptionFuelGrade = (state, subGroup, subGroupIndex) =>
  selectCaptainReportsFormReducer(state)?.[subGroup][subGroupIndex].fuel_grade;

export const selectConsumptionBunkerConsumptionIndex = (state, subGroup, subGroupIndex) =>
  selectCaptainReportsFormReducer(state)?.[subGroup][subGroupIndex].bunker_consumption_index?.value;

export const selectConsumptionBunkerId = (state, subGroup, subGroupIndex) =>
  selectCaptainReportsFormReducer(state)?.[subGroup][subGroupIndex].bunker_id?.value;

export const selectConsumptionBunkerIndex = (state, subGroup, subGroupIndex) =>
  selectCaptainReportsFormReducer(state)?.[subGroup][subGroupIndex].bunker_index?.value;

export const selectBunkerConsumptions = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getMultipleKey,
  (fields, subGroup, bunkerId) =>
    fields[subGroup].filter(({ bunker_id }) => bunker_id.value === bunkerId)
);

export const selectBunkerConsumptionsLength = createSelector(
  selectBunkerConsumptions,
  bunkerConsumptions => bunkerConsumptions?.length
);

export const selectConsumptionsTotalMainEngineCons = createSelector(
  selectCaptainReportsConsumptions,
  consumptions => {
    return consumptions?.reduce((acc, _, curIndex) => {
      return acc + getFieldValue(consumptions[curIndex]['me_consumption_total']);
    }, 0);
  }
);

export const selectFuelGradeCategoryConsumptions = createSelector(
  // Selects consumptions of specific fuel grade categories
  selectCaptainReportsConsumptions,
  getArr,
  (consumptions, categories) => {
    return consumptions.filter(el => categories.indexOf(el.fuel_grade.category) !== -1);
  }
);

export const selectFuelGradeCategoryConsumptionsTotalSum = createSelector(
  // Sum the consumptions of specific fuel grade categories
  selectFuelGradeCategoryConsumptions,
  consumptions => {
    return consumptions?.reduce((acc, cur) => {
      const number = numeral(acc);
      const category = cur?.fuel_grade?.category;

      return number.add(
        strToNumber(
          category === 'do' ? cur.total_consumption.value : cur.total_consumption_rate.value
        ) || 0
      );
    }, 0)._value;
  }
);

export const selectAerLegAverage = createSelector(
  selectCaptainReportsReducer,
  data => data.aerLegAverage?.data
);

export const selectAerLastReport = createSelector(
  selectCaptainReportsReducer,
  data => data.aerLastReport?.data
);

export const selectYearAttainedCii = createSelector(
  selectCaptainReportsReducer,
  data => data.yearAttainedCii?.data
);

/* */

/* Ballast Water */

export const selectPreviousCaptainReportBallastWater = createSelector(
  selectPreviousCaptainReport,
  previous => {
    return _get(previous, 'ballast_water', []);
  }
);

export const selectBallastWaterTank = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getSubGroupIndex,
  (fields, subGroup, subGroupIndex) => {
    return fields[subGroup][subGroupIndex]?.ballast_tank;
  }
);

export const selectBallastWaterAmountTotal = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getSubGroupIndex,
  (fields, subGroup) => {
    const dataArray = _flatten(fields[subGroup].map(e => +e.ballast_water_amount?.value));

    return dataArray.reduce((acc, cur) => {
      const number = numeral(acc);

      return number.add(strToNumber(cur) || 0);
    }, 0)._value;
  }
);

export const selectBallastWaterCapacityTotal = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getSubGroupIndex,
  (fields, subGroup) => {
    const dataArray = _flatten(fields[subGroup].map(e => +e.ballast_tank?.capacity));

    return dataArray.reduce((acc, cur) => {
      const number = numeral(acc);

      return number.add(strToNumber(cur) || 0);
    }, 0)._value;
  }
);

/* */

/* -- */

/* Multi Sections SubGroups Selectors */

/* Engine */

export const selectEnginesSubGroupsLength = createSelector(
  selectCaptainReportsFormEngines,
  selectSectionActiveTab,
  getKey,
  (engines, sectionsActiveTab, key) => {
    const activeTab = sectionsActiveTab['engines'];
    return engines[activeTab][key].length;
  }
);

export const selectEngineSubGroupTotal = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupFields,
  (engines, index, subGroup, subGroupFields) => {
    return engines[index][subGroup].reduce((acc, _, curIndex) => {
      return acc + getFieldValue(engines[index][subGroup][curIndex][subGroupFields]);
    }, 0);
  }
);

export const selectEngineSubGroupData = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupIndex,
  getMultiSectionSubGroupFieldKey,
  (engines, index, subGroup, subGroupIndex, key) => {
    return getFieldValue(engines[index][subGroup][subGroupIndex][key]);
  }
);

export const selectEngineSubGroupDataError = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupIndex,
  getMultiSectionSubGroupFieldKey,
  (engines, index, subGroup, subGroupIndex, key) => {
    return getFieldError(engines[index][subGroup][subGroupIndex][key]);
  }
);

export const selectEngineSubGroupDataComment = createSelector(
  selectCaptainReportsFormEngines,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupIndex,
  getMultiSectionSubGroupFieldKey,
  (engines, index, subGroup, subGroupIndex, key) => {
    return getFieldComment(engines[index][subGroup][subGroupIndex][key]);
  }
);

/* */

/* Generators */

export const selectGeneratorsSubGroupsLength = createSelector(
  selectCaptainReportsFormGenerators,
  selectSectionActiveTab,
  getKey,
  (generators, sectionsActiveTab, key) => {
    const activeTab = sectionsActiveTab['generators'];
    return generators[activeTab][key].length;
  }
);

export const selectGeneratorsSubGroupTotal = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupFields,
  (generators, index, subGroup, subGroupFields) => {
    return generators[index][subGroup].reduce((acc, _, curIndex) => {
      return acc + getFieldValue(generators[index][subGroup][curIndex][subGroupFields]);
    }, 0);
  }
);

export const selectGeneratorsSubGroupData = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupIndex,
  getMultiSectionSubGroupFieldKey,
  (generators, index, subGroup, subGroupIndex, key) => {
    return getFieldValue(generators[index][subGroup][subGroupIndex][key]);
  }
);

export const selectGeneratorsSubGroupDataError = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupIndex,
  getMultiSectionSubGroupFieldKey,
  (generators, index, subGroup, subGroupIndex, key) => {
    return getFieldError(generators[index][subGroup][subGroupIndex][key]);
  }
);

export const selectGeneratorsSubGroupDataComment = createSelector(
  selectCaptainReportsFormGenerators,
  getMulitpleIndex,
  getMultiSectionSubGroup,
  getMultiSectionSubGroupIndex,
  getMultiSectionSubGroupFieldKey,
  (generators, index, subGroup, subGroupIndex, key) => {
    return getFieldComment(generators[index][subGroup][subGroupIndex][key]);
  }
);

export const selectGeneratorsRunningHours = createSelector(
  selectCaptainReportsFormGenerators,
  generators => {
    return generators.map(g => g.dg_running_minutes.value);
  }
);

export const selectGeneratorsRunningHoursTotal = createSelector(
  selectGeneratorsRunningHours,
  generatorsRunningHoursArray => {
    return generatorsRunningHoursArray?.length
      ? generatorsRunningHoursArray.reduce((e, result) => result + e)
      : null;
  }
);

export const selectGeneratorsLegRunningHoursTotal = createSelector(
  selectCaptainReportTotals,
  captainReportsTotals => {
    return captainReportsTotals.dg_running_minutes;
  }
);

export const selectLegTotalDgPower = createSelector(
  selectCaptainReportTotals,
  captainReportsTotals => {
    return captainReportsTotals.dg_power;
  }
);

export const selectGeneratorLoadTotal = createSelector(
  selectCaptainReportsFormGenerators,
  generators => {
    const dgLoadArray = generators.map(g => g.dg_power.value);

    return dgLoadArray
      .filter(h => h)
      .reduce((acc, cur) => {
        const number = numeral(acc);

        return number.add(strToNumber(cur) || 0);
      }, 0)._value;
  }
);

export const selectGeneratorsLoConsumptionTotal = createSelector(
  selectCaptainReportsFormGenerators,
  generators => {
    const arrayOfValues = generators.map(g => g.dg_lub_oil_consumption_total.value) || [];

    return arrayOfValues.reduce((acc, cur) => {
      return numeral(acc || 0).add(cur || 0)?._value;
    }, 0);
  }
);
/* */

/* -- */

/* Array fields (NOT inside a mutli tab section) */
export const selectSubGroupData = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getSubGroupIndex,
  getSubGroupFieldKey,
  (fields, subGroup, subGroupIndex, key) => {
    return getFieldValue(fields[subGroup]?.[subGroupIndex]?.[key]);
  }
);

export const selectSubGroupDataError = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getSubGroupIndex,
  getSubGroupFieldKey,
  (fields, subGroup, subGroupIndex, key) => {
    return getFieldError(fields[subGroup][subGroupIndex][key]);
  }
);

export const selectSubGroupDataComment = createSelector(
  selectCaptainReportsFormReducer,
  getSubGroup,
  getSubGroupIndex,
  getSubGroupFieldKey,
  (fields, subGroup, subGroupIndex, key) => {
    return getFieldComment(fields[subGroup][subGroupIndex][key]);
  }
);

export const selectSubGroupLength = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    return fields[key].length;
  }
);

export const suppliedWarningKeys = createSelector(
  selectCaptainReportsConsumptions,
  selectCaptainReportBunkering,
  (consumptions, bunkering) => {
    return consumptions?.reduce((acc, curr) => {
      const warnings = [];
      const consumptionFuelGradeId = _get(curr, 'fuel_grade.id');
      const consumptionFuelGradeLabel = _get(curr, 'fuel_grade.label');
      const consumptionSupplied = strToNumber(_get(curr, 'supplied.value'));
      const consumptionSuppliedViscosity = strToNumber(_get(curr, 'supplied_viscosity.value'));
      const bunkerConsumptionIndex = _get(curr, 'bunker_consumption_index.value');

      const totalsMatchedBunkering = (bunkering || [])?.filter(
        totalBunkering => totalBunkering.fuel_grade_id === consumptionFuelGradeId
      );
      const bdnSupplied = strToNumber(
        _get(totalsMatchedBunkering, `[${bunkerConsumptionIndex}].bdn_supplied`)
      );
      const bdnSuppliedViscosity = strToNumber(
        _get(totalsMatchedBunkering, `[${bunkerConsumptionIndex}].bdn_supplied_viscosity`)
      );

      warnings.push({
        key: 'supplied',
        fuel_grade_label: consumptionFuelGradeLabel,
        show: bdnSupplied !== null ? consumptionSupplied !== bdnSupplied : null
      });

      warnings.push({
        key: 'supplied_viscosity',
        fuel_grade_label: consumptionFuelGradeLabel,
        show:
          bdnSuppliedViscosity !== null
            ? consumptionSuppliedViscosity !== bdnSuppliedViscosity
            : null
      });

      acc.push(...warnings);

      return acc;
    }, []);
  }
);

export const suppliedHasWarning = createSelector(
  suppliedWarningKeys,
  keys => !!(keys || []).filter(key => key.show)?.length
);

export const robDifferenceWarningKeys = createSelector(
  selectCaptainReportsConsumptions,
  selectCaptainReportRobDifferences,
  (consumptions, robDifferences) => {
    return consumptions?.reduce((acc, consumption) => {
      const warnings = [];

      const conFuelGradeId = _get(consumption, 'fuel_grade.id');
      const conFuelGradeLabel = _get(consumption, 'fuel_grade.label');
      const conFuelGradeCategory = _get(consumption, 'fuel_grade.category');

      const conCorrection = strToNumber(_get(consumption, 'correction.value'));
      const conCorrectionHfo = strToNumber(_get(consumption, 'correction_hfo.value'));
      const conCorrectionLfo = strToNumber(_get(consumption, 'correction_lfo.value'));

      const totalsMatchedRobDifference = (robDifferences || [])?.find(
        robDifference => strToNumber(robDifference.fuel_grade_id) === strToNumber(conFuelGradeId)
      );

      const robDifference = strToNumber(_get(totalsMatchedRobDifference, 'rob_difference'));
      const robDifferenceHfo = strToNumber(_get(totalsMatchedRobDifference, 'rob_difference_hfo'));
      const robDifferenceLfo = strToNumber(_get(totalsMatchedRobDifference, 'rob_difference_lfo'));

      if (conFuelGradeCategory !== 'go') {
        warnings.push({
          key: 'correction',
          fuel_grade_label: conFuelGradeLabel,
          show: robDifference !== null ? conCorrection !== robDifference : null
        });
      }

      if (conFuelGradeCategory !== 'go') {
        warnings.push({
          key: 'correction_hfo',
          fuel_grade_label: conFuelGradeLabel,
          show: robDifference !== null ? conCorrectionHfo !== robDifferenceHfo : null
        });
      }

      if (conFuelGradeCategory !== 'go') {
        warnings.push({
          key: 'correction_lfo',
          fuel_grade_label: conFuelGradeLabel,
          show: robDifference !== null ? conCorrectionLfo !== robDifferenceLfo : null
        });
      }

      acc.push(...warnings);

      return acc;
    }, []);
  }
);

export const robDifferenceHasWarning = createSelector(
  robDifferenceWarningKeys,
  keys => !!(keys || []).filter(key => key.show)?.length
);

export const selectBunkerConsumptionValidationError = createSelector(
  selectCaptainReportsConsumptions,
  consumptions => {
    return consumptions?.reduce((acc, curr) => {
      const rob_hfo = _get(curr, 'rob_hfo.value');
      const rob = _get(curr, 'rob.value');
      const rob_lfo = _get(curr, 'rob_lfo.value');
      const fuelGrade = _get(curr, 'fuel_grade');
      const isGo = fuelGrade.category === 'go';
      const errors = [];

      if (rob_lfo && rob_lfo < 0 && !isGo) {
        errors.push({
          fieldName: `${fuelGrade.name} ≤ 80 cSt ROB mt`,
          reason: 'negative',
          solution: 'Check viscosity & consumptions entered.'
        });
      }

      if (rob_hfo && rob_hfo < 0 && !isGo) {
        errors.push({
          fieldName: `${fuelGrade.name} > 80 ROB mt`,
          reason: 'negative',
          solution: 'Check viscosity & consumptions entered.'
        });
      }

      if (rob && rob < 0) {
        errors.push({
          fieldName: `${fuelGrade.name} ROB`,
          reason: 'negative',
          solution: 'Check consumptions entered.'
        });
      }

      acc.push(...errors);

      return acc;
    }, []);
  }
);

export const robBunkerConsumptionsHasWarnings = createSelector(
  selectBunkerConsumptionValidationError,
  errors => !!(errors || [])?.length
);

export const selectBsViscositiesByFuelGrade = createSelector(
  selectPreviousBsViscositiesCaptainReport,
  getKey,
  (previousBsViscosities, fuelGradeId) =>
    previousBsViscosities?.filter(e => e.fuel_grade_id == fuelGradeId) || []
);

export const selectBsBioFuelsByFuelGrade = createSelector(
  selectPreviousBsBioFuelsCaptainReport,
  getKey,
  (previousBsBioFuels, fuelGradeId) =>
    previousBsBioFuels?.find(e => +e.fuel_grade_id === +fuelGradeId) || []
);

export const selectViscosityOfFuelConsumedOptions = createSelector(
  selectBsViscositiesByFuelGrade,
  selectPreviousCaptainReportBunkerPerFuelGrade,
  getMultipleKey,
  (bsViscositiesByFuelGrade, prevConsumption, fuelGradeCategory) => {
    const hfo = _get(prevConsumption, 'rob_hfo.value');
    const lfo = _get(prevConsumption, 'rob_lfo.value');

    return (
      (bsViscositiesByFuelGrade[0]?.values || [])?.map(e => ({
        label: `label_${e}`,
        suffix: selectRob({ hfo, lfo }, e, fuelGradeCategory),
        name: e,
        value: strToNumber(e)
      })) || []
    );
  }
);

export const selectViscosityOfFuelConsumedWarning = createSelector(
  selectViscosityOfFuelConsumedOptions,
  getSubGroupFieldKey,
  (options, name) => (options?.length && name ? !options.filter(e => e.name == name).length : false)
);
/* -- */
