import { get, put, post, deleteRequest } from 'utils/api';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ForexRatesType } from '@/common/types/mga.ts';

/* Forex Rate Currencies */

type UpdateMgaCurrenciesParamsType = {
  extra_currency_ids: number[];
};

type RateType = {
  currency_id: number;
  rate: number;
};

type CreateMgaCurrenciesParamsType = {
  from_date: string;
  to_date: string;
  rates: RateType[];
};

type DeleteMgaCurrenciesParamsType = {
  id: string | number;
};

type GetLocalAmountParamsType = {
  local_amount: string | number;
  timestamp: string;
  currency_id: string | number;
};

export const getMgaCurrencies = createAsyncThunk(
  'GET_MGA_CURRENCIES',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get<ForexRatesType>(`/mga/currencies`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateMgaCurrencies = createAsyncThunk(
  'UPDATE_MGA_CURRENCIES',
  async (params: UpdateMgaCurrenciesParamsType, { rejectWithValue }) => {
    try {
      const res = await put(`/mga/currencies`, params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createMgaForexRate = createAsyncThunk(
  'CREATE_MGA_FOREX_RATE',
  async (params: CreateMgaCurrenciesParamsType, { rejectWithValue }) => {
    try {
      const res = await post(`/mga/forex-rates`, params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteMgaForexRate = createAsyncThunk(
  'DELETE_MGA_FOREX_RATE',
  async (params: DeleteMgaCurrenciesParamsType, { rejectWithValue, dispatch }) => {
    try {
      const { id, ...rest } = params;
      const res = await deleteRequest(`/mga/forex-rates/${id}`, rest);

      await dispatch(getMgaForexRate());

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getMgaForexRate = createAsyncThunk(
  'GET_MGA_FOREX_RATE',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get('/mga/forex-rates');

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getLocalAmountConversion = createAsyncThunk(
  'GET_LOCAL_AMOUNT_CONVERSION',
  async (params: GetLocalAmountParamsType, { rejectWithValue }) => {
    try {
      const res = await get('/mga/actions/convert-local-amount', params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* */
