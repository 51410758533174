import { CrewDocumentySimilarityType } from '@/common/types/crew';
import { get } from '@/utils/api';

export type GetCrewDocumentsSimilarityType = {
  id: number;
  issued_at?: string | null;
  crew_document_type_id?: number | null;
  issue_auths?: string[];
  grade?: string | null;
  periodicity?: string | null;
  code?: string | null;
  expires_at?: string | null;
  attachment_ids?: number[];
  country_id?: number | null;
  category?: string | null;
  comments?: string | null;
  is_archived?: boolean;
  number_of_doses?: number | null;
  manufacturer?: string | null;
  paper_agent?: string | null;
  crew_rank_id?: number | null;
};

export const getCrewDocumentsSimilarity = async (params: GetCrewDocumentsSimilarityType) => {
  const { id, ...rest } = params;

  const response = await get<CrewDocumentySimilarityType[]>(
    `/crew/${id}/documents/similarity`,
    rest
  );

  return response.data;
};
