import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import copyToClipboard from 'copy-to-clipboard';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const CopyText = ({
  children,
  text,
  customText,
  infoTooltip,
  tooltipClassName,
  containerClassName,
  onCopy,
  isDisabled,
  ...rest
}) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const { avoidRender, tooltipID } = useTooltipID('copy-body', [text]);

  const copy = async () => {
    try {
      if (onCopy) {
        await onCopy();
      } else {
        copyToClipboard(text);
      }

      setIsTooltipActive(true);
      setTimeout(() => {
        setIsTooltipActive(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      setIsTooltipActive(false);
    }
  };

  if (avoidRender) return null;

  return (
    <div className={isDisabled ? 'disabled' : 'cursor-pointer'} onClick={copy}>
      <UncontrolledTooltip
        innerClassName={`copy-tooltip-container ${
          isTooltipActive || infoTooltip ? '' : 'copy-tooltip-container-hide'
        } ${tooltipClassName || ''}`}
        arrowClassName="d-none"
        placement="top"
        target={tooltipID}
        {...rest}
      >
        {isTooltipActive ? customText || 'Copied!' : infoTooltip || ''}
      </UncontrolledTooltip>
      <div id={tooltipID} className={`text-truncate lh-1 ${containerClassName || ''}`}>
        {children}
      </div>
    </div>
  );
};

CopyText.propTypes = {
  text: PropTypes.string,
  customText: PropTypes.string,
  infoTooltip: PropTypes.string,
  containerClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  onCopy: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default CopyText;
