import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import Drawer, { DrawerHeader, FormDrawer, FormBody, FormFooter } from 'common/components/drawer';
import Participants from './Participants';
import Offers from './Offers';
import Spinner from 'common/components/general/Spinner';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';

import {
  isTripDrawerOpened,
  selectTripIDs,
  selectTripDisabled,
  selectTripPreventionModal,
  selectTripType
} from 'store/ticketing/selectors';
import { selectIsOnboard } from 'events/store/events/selectors';

import deleteSvg from 'common/assets/svg/actions/delete.svg';

import {
  getTrip,
  deleteTrip,
  deleteTripParticipant,
  setTripPreventionModal,
  getTicketsTotals,
  closeTripDrawer
} from 'store/ticketing/actions';
import { fetchListOptions } from 'store/lists/actions';

import _uniq from 'lodash/uniq';

import LogHistoryView from './LogHistoryView';
import ParticipantsWithoutTicketingCase from './ParticipantsWithoutTicketingCase';
import { useDispatch } from 'react-redux';

const TicketsDrawer = ({ onCloseFallback, components }) => {
  const isOnBoard = useSelector(selectIsOnboard);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);

  const isOpen = useSelector(isTripDrawerOpened);
  const tripIDs = useSelector(selectTripIDs);
  const tripType = useSelector(selectTripType);
  const disabled = useSelector(selectTripDisabled);
  const preventionModal = useSelector(selectTripPreventionModal);

  const { open, message, ...rest } = preventionModal;

  const getTotals = useCallback(async () => await dispatch(getTicketsTotals()), [dispatch]);

  const onClose = () => {
    dispatch(closeTripDrawer());

    if (onCloseFallback) {
      onCloseFallback();
    }
  };

  const initTrips = useCallback(async () => {
    setIsLoading(true);

    for (let tripID of _uniq(tripIDs)) {
      await dispatch(getTrip({ id: tripID }));
    }

    setIsLoading(false);
  }, [dispatch, tripIDs]);

  const onDeleteTrip = async () => {
    setIsDeleting(true);

    await dispatch(deleteTrip({ id: tripIDs?.[0] }));

    onClose();

    setIsDeleting(false);
  };

  const onDeleteTripParticipant = async params => {
    setIsDeleting(true);

    try {
      await dispatch(deleteTripParticipant(params));

      initTrips();
    } catch (error) {
      console.log(error);
    }

    setIsDeleting(false);
  };

  const toggleAddNew = useCallback(() => {
    setIsAddingNew(prev => !prev);
  }, []);

  useEffect(() => {
    if (isOpen && tripIDs?.length) {
      setIsAddingNew(false);
      initTrips();
    } else if (isOpen && !tripIDs?.length) {
      getTotals();
      setIsAddingNew(true);
    }
  }, [getTotals, initTrips, isOpen, tripIDs?.length]);

  useEffect(() => {
    if (!isOnBoard) dispatch(fetchListOptions('ticketing-types'));

    return () => {
      dispatch(closeTripDrawer());
    };
  }, [dispatch, isOnBoard]);

  return (
    <Drawer
      isOpen={isOpen}
      className="tickets-drawer"
      size="lg"
      dontCloseOnClickOutside={true}
      close={onClose}
    >
      <DrawerHeader>Create new ticket</DrawerHeader>

      <FormDrawer>
        <FormBody className="px-4" key={isOpen}>
          {isLoading ? (
            <Spinner />
          ) : tripIDs?.length ? (
            _uniq(tripIDs).map(tripID => (
              <Fragment key={tripID}>
                {tripType === 'list' ? (
                  <ParticipantsWithoutTicketingCase
                    tripID={tripID}
                    onDeleteTripParticipant={disabled ? null : onDeleteTripParticipant}
                  />
                ) : (
                  <Participants
                    tripID={tripID}
                    onDeleteTripParticipant={disabled ? null : onDeleteTripParticipant}
                  />
                )}
                <Offers
                  tripID={tripID}
                  isAddingNew={isAddingNew}
                  toggleAddNew={toggleAddNew}
                  components={components}
                />
                <LogHistoryView />
              </Fragment>
            ))
          ) : (
            <>
              {tripType === 'list' ? <ParticipantsWithoutTicketingCase /> : <Participants />}
              <Offers isAddingNew={isAddingNew} toggleAddNew={toggleAddNew} />
            </>
          )}
        </FormBody>

        {tripIDs?.length && !disabled ? (
          <FormFooter>
            <Button
              color="link"
              className="text-red fw-bold d-inline-flex align-items-center p-0 me-4"
              disabled={isDeleting}
              onClick={() =>
                dispatch(
                  setTripPreventionModal({
                    open: true,
                    onClose: () => dispatch(setTripPreventionModal({ open: false, message: '' })),
                    onAccept: onDeleteTrip,
                    header: 'Delete Ticket',
                    actionText: 'DELETE',
                    message: (
                      <span>
                        You are about to delete this ticket. <br />
                        Are you sure you want to continue?
                      </span>
                    )
                  })
                )
              }
            >
              <SvgRender className="me-1" src={deleteSvg} style={{ width: 14, height: 14 }} />
              Delete ticket
            </Button>
          </FormFooter>
        ) : null}
      </FormDrawer>

      <DangerousActionModal
        transparent
        cancelText="CANCEL"
        actionText="PROCEED"
        actionHoverColor={'delete'}
        isOpen={open}
        body={<div className="text-center fs-18">{message}</div>}
        closeModal={() => dispatch(setTripPreventionModal({ open: false, message: '' }))}
        {...rest}
      />
    </Drawer>
  );
};

export default TicketsDrawer;

TicketsDrawer.propTypes = {
  onCloseFallback: PropTypes.func,
  components: PropTypes.objectOf(PropTypes.elementType)
};
