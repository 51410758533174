export const checkIfActionIsChecked = (supplierStatus, statuses, statusToCheck) => {
  return supplierStatus?.sort_index > statuses?.find(f => f?.label === statusToCheck)?.sort_index;
};

export const checkIfActionIsVisible = (supplierStatus, statuses, statusToCheck) => {
  return supplierStatus?.sort_index >= statuses?.find(f => f?.label === statusToCheck)?.sort_index;
};

export const checkIfIsTheCurrentStatus = (supplierStatus, statuses, statusToCheck) => {
  return supplierStatus?.sort_index === statuses?.find(f => f?.label === statusToCheck)?.sort_index;
};
