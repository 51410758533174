import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const Name = React.memo(({ isLast, name, customSeparator }) => (
  <>
    {name}
    {isLast ? '' : customSeparator ? customSeparator : ', '}
  </>
));

const Tag = React.memo(({ name, isLast, isHidden, customSeparator }) =>
  isHidden ? (
    <div className={'flex-column justify-content-center d-inline-flex'}>
      <Name isLast={isLast} name={name} />
    </div>
  ) : (
    <Name isLast={isLast} name={name} customSeparator={customSeparator} />
  )
);

const NamesInTooltip = ({
  names = [],
  containerClassName = '',
  tooltipTargetClassName = '',
  maxHeight = 18,
  maxLimit = 1,
  renderTooltipContent,
  customSeparator,
  showThreeDots = true,
  customIndicator
}) => {
  const { avoidRender, tooltipID } = useTooltipID('names-in-tooltip');
  const innerRef = useRef(null);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [hiddenNames, setHiddenNames] = useState([]);

  useEffect(() => {
    setHiddenItems(names.slice(maxLimit, names?.length));
  }, [names, maxLimit]);

  useEffect(() => {
    const hidden = hiddenItems.map((_, index) =>
      innerRef.current?.childNodes[index]?.innerText?.replace(',', '')
    );

    setHiddenNames(hidden);
  }, [hiddenItems]);

  const visibleNames = useMemo(() => [...names].slice(0, maxLimit), [names, maxLimit]);
  const hiddenNamesCount = names.length - visibleNames.length;

  const tags = visibleNames.map((name, index) => (
    <Tag
      key={index}
      name={name}
      isLast={index === visibleNames?.length - 1}
      customSeparator={customSeparator}
    />
  ));

  return (
    <div
      className={`names-in-tooltip overflow-hidden d-flex flex-nowrap ${containerClassName}`}
      style={{ maxHeight }}
    >
      <div ref={innerRef} className="d-none">
        {hiddenItems.map((name, index) => (
          <Tag
            key={index}
            name={name}
            isLast={index === hiddenItems?.length - 1}
            index={index}
            isHidden
          />
        ))}
      </div>

      <div
        className={`names-in-tooltip__inner d-flex align-items-center overflow-hidden ${
          showThreeDots ? '' : 'flex-wrap'
        }${maxLimit === 1 && names?.length === 1 ? 'max-width-100p' : ''}`}
      >
        {showThreeDots ? <TextWithTooltip>{tags}</TextWithTooltip> : tags}
      </div>

      {hiddenNamesCount > 0 && !avoidRender ? (
        <div
          className={`names-in-tooltip__indicator ${
            customIndicator ? 'max-width-none' : ''
          } d-flex align-items-center justify-content-end overflow-hidden ${
            hiddenItems.length ? '' : 'invisible'
          }`}
        >
          {customIndicator ? (
            customIndicator(hiddenNamesCount, tooltipID)
          ) : (
            <strong
              id={tooltipID}
              className={`fs-10 lh-1 ${tooltipTargetClassName || ''} cursor-pointer text-truncate`}
            >
              +{hiddenNamesCount}
            </strong>
          )}

          <UncontrolledTooltip
            placement="top"
            boundariesElement="window"
            target={tooltipID}
            innerClassName="show-more-tooltip"
            className="multiple-names-tooltip"
          >
            {renderTooltipContent ? renderTooltipContent(hiddenNames) : hiddenNames.join(', ')}
          </UncontrolledTooltip>
        </div>
      ) : null}
    </div>
  );
};

export default NamesInTooltip;

NamesInTooltip.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string, PropTypes.object),
  containerClassName: PropTypes.string,
  tooltipTargetClassName: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.number,
  maxLimit: PropTypes.number,
  renderTooltipContent: PropTypes.func,
  customSeparator: PropTypes.string,
  customIndicator: PropTypes.func
};
