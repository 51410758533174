import SvgRender from 'common/components/general/SvgRender';
import ActivateButton from 'common/components/buttons/ActivateButton';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import check from 'common/assets/svg/actions/check.svg';

import { useSelector } from 'react-redux';
import {
  getPreventedApprovalInfoMessage,
  selectActiveEntityId
} from 'common/components/review-process/store/selectors';

const ApproveButton = ({
  handleApprove,
  disabled,
  textBeforeCompletion,
  textAfterCompletion,
  completedBy,
  canApproveStep,
  canRevokeApprovedStep,
  isCompleted
}) => {
  const activeEntityId = useSelector(selectActiveEntityId);
  const preventionMessage = useSelector(state =>
    !canApproveStep && !isCompleted ? getPreventedApprovalInfoMessage(state, activeEntityId) : null
  );
  const { avoidRender, tooltipID } = useTooltipID('step-approve');

  return (
    <div className="d-flex align-items-center justify-content-end text-end" id={tooltipID}>
      <ActivateButton
        color="green"
        disabled={disabled || (isCompleted ? !canRevokeApprovedStep : !canApproveStep)}
        isActive={completedBy}
        onClick={handleApprove}
      >
        {completedBy ? (
          <>
            <SvgRender className="me-1" src={check} style={{ width: 14, height: 14 }} />
            {textAfterCompletion}
          </>
        ) : (
          textBeforeCompletion
        )}
      </ActivateButton>

      {tooltipID && !avoidRender && preventionMessage && (
        <Tooltip target={tooltipID}>{preventionMessage}</Tooltip>
      )}
    </div>
  );
};

export default ApproveButton;
