import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import Select from 'common/components/form/inputs/Select';
import FormStatus from 'common/components/forms/_components/FormStatus';
import FormDate from 'common/components/forms/_components/FormDate';
import Priority from 'common/components/general/Priority';

import { refetchAsyncOptions } from 'utils/helpers';

import FormTypeSelector from 'common/components/forms/_components/form-manager/FormTypeSelector';
import DownloadFormButton from '../../DownloadFormButton';
import SvgRender from 'common/components/general/SvgRender';

import commentsSolid from 'common/assets/svg/common/comments-solid.svg';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import _get from 'lodash/get';
import FormResubmittedLabel from 'common/components/forms/_components/FormResubmittedLabel';

import FindingsExpandButton from 'common/components/buttons/FindingsExpandButton';
import { selectAccount } from 'store/account/selectors';
import { isAuthorized } from 'utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const FormInnerHeader = ({
  setSelectedForm,
  selectedFormId,
  setSelectedFormId,
  form,
  status,
  formSubmission,
  submissionDate,
  submissionDateError,
  setFormState,
  setUploadedFile,
  setUploadedFileError,
  setIsFetching,
  onFormTypeChange,
  excludeFormIds,
  onStatusChange,
  onSubmissionDateChange,
  selectedVesselId,
  setSelectedVesselId,
  shouldShowRemarksCollapse,
  setCollapsedRemarks,
  collapsedRemarks,
  collapsedFindings,
  setCollapsedFindings,
  isLocked,
  hiddenFields,
  filterVesselSelectorOptions,
  formType,
  isOnLinkMode,
  isVesselLocked,
  customSelectorComponent
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const [defaultVesselOptions, setDefaultVesselOptions] = useState([]);
  const account = useSelector(selectAccount);
  const isEditValuesPermissionLocked = !isAuthorized(
    account,
    isOnboard
      ? [permissions.onboard.forms.view]
      : [formSubmission ? permissions.office.forms.edit_values : permissions.office.forms.create]
  );
  const isEditStatusPermissionLocked =
    formSubmission &&
    !isAuthorized(
      account,
      isOnboard
        ? [permissions.onboard.forms.view]
        : [formSubmission ? permissions.office.forms.edit_status : permissions.office.forms.create]
    );

  const formSubmissionFindings = formSubmission?.findings;
  const hasFormSubmissionFindings = formSubmissionFindings?.length > 0;

  const fetchVessels = useCallback(async () => {
    const vessels = await refetchAsyncOptions('vessels');
    setDefaultVesselOptions(vessels);
  }, []);

  useEffect(() => {
    fetchVessels();
  }, [fetchVessels]);

  return (
    <Row
      className={`form-manager--inner-header align-items-center ${
        !formSubmission ? 'can-edit-type' : ''
      }`}
      noGutters
    >
      {(!formSubmission && !form) || hiddenFields?.status === true ? null : (
        <Col
          xs="auto"
          className={`form-manager--inner-header-status me-2 pe-2 d-flex align-items-center h-100p border-end`}
        >
          <FormStatus
            formSubmission={formSubmission}
            value={status}
            onChange={onStatusChange}
            hideLabel
            isLocked={isLocked || isEditStatusPermissionLocked}
            selectedVesselId={selectedVesselId}
            statusIsDisabled={isOnLinkMode}
            form={form}
          />
          {formSubmission?.resubmitted ? <FormResubmittedLabel /> : null}
        </Col>
      )}

      {!formSubmission && !customSelectorComponent ? (
        <Col xs={4} className="form-manager--inner-header-type">
          <FormTypeSelector
            selectedForm={form}
            setSelectedForm={setSelectedForm}
            selectedFormId={selectedFormId}
            setSelectedFormId={setSelectedFormId}
            setIsFetching={setIsFetching}
            setFormState={setFormState}
            onFormTypeChange={onFormTypeChange}
            excludeFormIds={excludeFormIds}
            setUploadedFile={setUploadedFile}
            setUploadedFileError={setUploadedFileError}
            disabled={hiddenFields?.type === true}
            selectedVesselId={selectedVesselId}
          />
        </Col>
      ) : (
        <Col className="form-manager--inner-header-name text-violet fs-14 d-flex align-items-center">
          <Priority
            className={`${isOnboard ? 'cpe-12' : 'pe-1'}`}
            value={form?.importance?.label}
            withText={false}
            size="small"
          />
          {!isOnboard && <div className="cme-12 text-violet fs-10">{formSubmission?.id}</div>}
          {form?.name}
          {form?.revision && <strong>&nbsp; - {form.revision}</strong>}
        </Col>
      )}

      {!formSubmission && !form ? null : (
        <Col
          xs="auto"
          className={`form-manager--inner-header-date d-flex me-1 ${
            formSubmission ? 'ms-auto' : ''
          }`}
        >
          <div className={`d-flex flex-column justify-content-center cpe-4 ms-1`}>
            {!formSubmission ? <span className="form-label">FORM DATE</span> : null}

            <FormDate
              value={submissionDate}
              onChange={onSubmissionDateChange}
              isViewMode={isLocked || isEditValuesPermissionLocked}
              error={submissionDateError}
              inlineLabel={`${formSubmission ? 'Form Date' : ''}`}
              disabled={isOnLinkMode}
            />
          </div>
        </Col>
      )}
      {form?.is_for_vessel && !isOnboard && !formSubmission && hiddenFields?.vessel !== true ? (
        <Col xs={4} className="d-flex flex-column justify-content-center">
          <div className={`d-flex flex-column justify-content-center cpe-4`}>
            <Select
              label={!formSubmission ? 'VESSEL' : ''}
              placeholder="Select vessel"
              isClearable={true}
              getOptionValue={option => option.id}
              disabled={formSubmission?.id || isVesselLocked || isEditValuesPermissionLocked}
              getOptionLabel={option => option.name}
              options={form?.vessels?.length ? form.vessels : defaultVesselOptions}
              value={selectedVesselId}
              onChange={vessel => setSelectedVesselId(vessel)}
              filterOption={filterVesselSelectorOptions}
              className="mb-0"
            />
          </div>
        </Col>
      ) : formSubmission && selectedVesselId ? (
        <Col
          xs="auto"
          className="text-violet pe-2 fs-12 lh-1 d-flex align-items-center border-start ms-1 ps-3"
        >
          <SvgRender className="me-1" src={vesselIcon} style={{ width: 14, height: 14 }} />
          {_get(
            defaultVesselOptions.find(v => v.id === selectedVesselId),
            'name',
            ''
          )}
        </Col>
      ) : null}

      {formSubmission?.id && formType !== 'upload' ? (
        <DownloadFormButton
          xs={1}
          className="me-3"
          form={form}
          formSubmission={formSubmission}
          disableDownloadButton={isOnLinkMode}
        />
      ) : null}

      {hasFormSubmissionFindings ? (
        <Col xs="auto" className="ms-auto cme-12">
          <FindingsExpandButton
            isCollapsed={collapsedFindings}
            className="cpy-4"
            onClick={() => {
              setCollapsedRemarks(true);
              setCollapsedFindings(prev => !prev);
            }}
            findingsCount={formSubmissionFindings?.length}
          />
        </Col>
      ) : null}

      {shouldShowRemarksCollapse ? (
        <Col xs="auto" className="ms-auto">
          <Button
            type="button"
            color="link"
            className={`form-manager--inner-header-remarks-button height-24 
              width-24 d-flex align-items-center justify-content-center px-1 border border-purple text-${
                collapsedRemarks ? 'white' : 'moody-blue'
              } ${collapsedRemarks ? 'bg-purple' : 'bg-white'}`}
            onClick={() => {
              setCollapsedFindings(true);
              setCollapsedRemarks(prev => !prev);
            }}
          >
            <SvgRender src={commentsSolid} style={{ width: 12, height: 12 }} />
          </Button>
        </Col>
      ) : null}
    </Row>
  );
};

export default FormInnerHeader;
