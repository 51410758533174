import _get from 'lodash/get';
import { RootState } from '@/store';
import { createSelector } from 'reselect';

const selectPmsReducer = (state: RootState) => state.pms.setup;
const selectId = (_: RootState, id: number) => id;

export const selectSelectedVesselFilter = (state: RootState) =>
  selectPmsReducer(state).selectedVesselFilter;

/* Groups */
export const selectGroupsLoading = (state: RootState) => selectPmsReducer(state).groupsLoading;

export const selectGroups = (state: RootState) => selectPmsReducer(state).groups;

export const selectRootGroups = (state: RootState) => selectPmsReducer(state).rootGroups;

export const selectPmsSingleGroupIsLoading = (state: RootState) =>
  selectPmsReducer(state).singleGroupIsLoading;

export const selectGroup = (state: RootState, groupId: number) => selectGroups(state)[groupId];

export const selectActiveGroup = (state: RootState) => selectPmsReducer(state).activeGroup;

export const selectSubGroups = (state: RootState) => selectPmsReducer(state).subGroups;

export const selectHasSubGroups = createSelector([selectSubGroups, selectId], (subGroups, id) => {
  const result: number[] = [];

  Object.keys(subGroups).forEach(el => {
    if (subGroups[+el] && subGroups[+el]?.parent_id === id) {
      result.push(subGroups[+el].id);
    }
  });

  return result.length ? result : null;
});

const selectOpenGroups = (state: RootState) => selectPmsReducer(state).openGroups;

export const selectIsGroupOpen = createSelector([selectOpenGroups, selectId], (openGroups, id) =>
  openGroups[id] ? true : false
);

export const selectAllGroupsExpanded = createSelector([selectOpenGroups], openGroups => {
  if (Object.keys(openGroups).length === 0) {
    return false;
  }

  return Object.keys(openGroups).every(el => openGroups[+el]);
});

export const selectHasCollapsedSubGroupsContent = createSelector(
  [selectSubGroups],
  subGroups => Object.keys(subGroups).length !== 0
);

export const selectGroupsSearch = (state: RootState) => selectPmsReducer(state).groupsSearch;
/* -- */

/* System */
export const selectSystemCollapsed = (state: RootState) => selectPmsReducer(state).systemCollapsed;

export const selectSystemFormType = createSelector([selectPmsReducer], pms => pms.systemFormType);
export const selectSystemFormParentId = createSelector(
  [selectPmsReducer],
  pms => pms.systemFormParentId
);
export const selectSelectedSystem = createSelector([selectPmsReducer], pms => pms.selectedSystem);
export const selectCurrentSystem = createSelector([selectPmsReducer], pms => pms.currentSystem);
export const selectCurrentSystemLoading = (state: RootState) =>
  selectPmsReducer(state).currentSystemLoading;

export const selectIsCreatingSubSystem = (state: RootState) =>
  selectPmsReducer(state).isCreatingSubSystem;
export const selectCurrentSystemVessels = createSelector(
  [selectPmsReducer],
  pms => pms.currentSystem?.vessels || []
);

export const selectSystemsLoading = createSelector([selectPmsReducer], pms => pms.systemsLoading);
export const selectSystems = createSelector([selectPmsReducer], pms => pms.systems);
export const selectRootSystems = createSelector([selectPmsReducer], pms => pms.rootSystems);

export const selectOpenSystems = createSelector([selectPmsReducer], pms => pms.openSystems);

export const selectSubSystems = createSelector([selectPmsReducer], pms => pms.subSystems);

export const selectSystem = createSelector([selectSystems, selectId], (systems, id) => systems[id]);

export const selectIsSystemOpened = createSelector(
  [selectOpenSystems, selectId],
  (openSystems, id) => (openSystems?.[id] ? true : false)
);

export const selectAllSystemsExpanded = createSelector(
  [selectOpenSystems, selectSubSystems],
  openSystems => {
    if (Object.keys(openSystems).length === 0) {
      return false;
    }
    return Object.keys(openSystems).every(el => openSystems[+el]);
  }
);

export const selectSystemSubSystems = createSelector(
  [selectSubSystems, selectId],
  (subSystems, id) => subSystems?.[id]
);

export const selectSystemHasSubSystems = createSelector(
  [selectSystemSubSystems],
  systemSubSystems => systemSubSystems && systemSubSystems.length !== 0
);

export const selectHasCollapsedSubSystemsContent = createSelector(
  [selectSubSystems],
  subSystems => Object.keys(subSystems).length !== 0
);

export const selectCurrentFormSystem = createSelector(
  [selectPmsReducer],
  pms => pms.currentFormSystem
);

export const selectSystemsSearch = createSelector([selectPmsReducer], pms => pms.systemsSearch);

export const selectSystemsPaging = createSelector([selectPmsReducer], pms => pms.systemsPaging);

const selectActiveSystemGroupState = createSelector(
  [selectPmsReducer],
  pms => pms.activeSystemGroupState
);

export const selectSystemGroupStateType = (state: RootState) =>
  selectActiveSystemGroupState(state).type;

export const selectSystemGroupStateData = (state: RootState) =>
  selectActiveSystemGroupState(state).data;

export const selectSystemsPagingTotal = createSelector(
  [selectSystemsPaging],
  systemsPaging => systemsPaging.total
);

export const selectSystemsPagingPerPage = createSelector(
  [selectSystemsPaging],
  systemsPaging => systemsPaging.per_page
);

export const selectSystemsPagingCurrentPage = createSelector(
  [selectSystemsPaging],
  systemsPaging => systemsPaging.current_page
);
/* -- */

// Spare parts
export const selectSparePartsLoading = createSelector(
  [selectPmsReducer],
  pms => pms.sparePartsLoading
);

export const selectSparePartsList = createSelector([selectPmsReducer], pms => pms.sparePartsList);
export const selectSelectedTab = createSelector([selectPmsReducer], pms => pms.selectedTab);
export const selectSelectedSparePart = createSelector(
  [selectPmsReducer],
  pms => pms.selectedSparePart
);

//assignments
export const selectCurrentAssignments = createSelector(
  [selectPmsReducer],
  pms => pms.currentAssignments
);

export const selectCurrentSubAssignments = createSelector(
  [selectPmsReducer],
  pms => pms.currentSubAssignments
);

export const selectSubAssignments = createSelector(
  [selectCurrentSubAssignments, selectId],
  (currentSubAssignments, id) => {
    return currentSubAssignments.filter(c => id === c.parent_id);
  }
);

export const selectselectedAssignment = createSelector(
  [selectPmsReducer],
  pms => pms.selectedAssignment
);

export const selectSelectedSubAssignment = createSelector(
  [selectPmsReducer],
  pms => pms.selectedSubAssignment
);

export const selectAssignmentsLoading = createSelector(
  [selectPmsReducer],
  pms => pms.assignmentsLoading
);

export const selectSubAssignmentsLoading = createSelector(
  [selectPmsReducer],
  pms => pms.subAssignmentsLoading
);

export const selectSystemHasUntriggeredJobs = createSelector(
  [selectCurrentSubAssignments, selectId],
  (currentSubAssignments, id) => {
    return currentSubAssignments
      .filter(c => id === c.parent_id)
      .some(
        assignment => assignment.maintenance_job_prototypes.some(({ job_count }) => !job_count) // if job_count = 0 there are untriggered jobs so we return true
      );
  }
);

export const selectPmsJobs = createSelector([selectPmsReducer], pms => pms.jobs);
export const selectPmsJobsLoading = createSelector([selectPmsReducer], pms => pms.jobsLoading);
export const selectCurrentPmsJob = createSelector([selectPmsReducer], pms => pms.currentJob);

export const selectCurrentPmsJobActiveTabs = createSelector([selectCurrentPmsJob], job =>
  _get(job, 'activeTabs', [])
);

export const selectVesselId = createSelector(
  [selectPmsReducer],
  pms => pms.currentAssignments[0]?.vessel_id || null
);
export const selectVesselSystemId = createSelector(
  [selectPmsReducer],
  pms => pms.currentAssignments[0]?.vessel_system_id || null
);

export const selectVesselSubSystemId = createSelector(
  [selectPmsReducer],
  pms => pms.currentSubAssignments[0]?.vessel_system_id || null
);

export const selectRunningHoursCaptainReportId = createSelector(
  selectselectedAssignment,
  assignment => assignment?.running_hours_report_id
);

export const selectPmsLibraryIsUsedInCategories = createSelector(
  [selectPmsReducer],
  pms => pms.pmsLibraryIsUsedIn === 'categories'
);

export const selectPmsLibraryIsUsedInRequisition = createSelector(
  [selectPmsReducer],
  pms => pms.pmsLibraryIsUsedIn === 'requisition'
);

export const selectPmsLibraryIsUsedInSetup = createSelector(
  [selectPmsReducer],
  pms => pms.pmsLibraryIsUsedIn === 'setup'
);

export const selectGroupsCollapsed = (state: RootState) => selectPmsReducer(state).groupsCollapsed;
