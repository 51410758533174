import React, { FC } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import FavoriteTableSearch from './FavoriteTableSearch';
import AuthCheck from 'components/permissions/AuthCheck';
import useRouter from 'use-react-router';

import { Link } from 'react-router-dom';
import { Col, Button } from 'reactstrap';
import { useAppSelector } from '@/store/hooks';
import { selectTableListInvalid } from '@/common/components/table/store/selector-ts';
import { parseQueryParams } from '@/ts-common/utils/urls';
import { PageAction } from './types';

import _isEqual from 'lodash/isEqual';
import dots from 'common/assets/svg/actions/three-dots.svg';
import comparison from 'assets/svg/sidebar/comparison.svg';

const Favorite = ({ label, actionsLength }: any) => {
  const { history } = useRouter();
  // const account = useSelector(state => state.account);
  const tableIsInvalid = useAppSelector(state => selectTableListInvalid(state, label));

  const tableSearch = useAppSelector(state => {
    const { searchId } = parseQueryParams(history.location.search);

    if (searchId) return state.tables.search;
    else return null;
  });

  return tableSearch &&
    // tableSearch.party_id === account.id &&
    tableSearch.item &&
    !tableIsInvalid ? (
    <FavoriteTableSearch
      favouriteType="item"
      className={`page-layout--table-action type-favorite ${actionsLength === 2 ? 'me-3' : ''}`}
      id={tableSearch.item.id}
      isFavorite={tableSearch.item.favourites_count > 0}
    ></FavoriteTableSearch>
  ) : null;
};

const PageActionButton = ({ action }: any) => {
  const Tag = action.path ? Link : Button;
  const props = action.path
    ? { to: action.path }
    : { color: action.color ? action.color : 'link', disabled: action.disabled };

  return (
    <Tag
      className={`page-layout--table-action d-inline-flex align-items-center justify-content-center ${
        action.className || 'me-3'
      } ${action.path ? `btn btn-${action.color ? action.color : 'link'}` : ''}`}
      onClick={action.onClick}
      data-cy={action.dataCy}
      {...props}
    >
      {action.text || ''}
    </Tag>
  );
};

type PageActionsProps = {
  actions?: PageAction[];
  label?: string;
  className?: string;
};

const PageActions: FC<PageActionsProps> = React.memo(
  function PageActions({ actions, label, className = '' }) {
    const ActionContent = ({ action, actionsLength }: any) => {
      switch (action.type) {
        case 'favorite':
          return <Favorite actionsLength={actionsLength} label={label} />;
        case 'actions':
          return (
            <Button
              disabled={action.disabled}
              data-cy={action.dataCy}
              color={'link'}
              className={`page-layout--table-action type-${action.type} d-inline-flex align-items-center justify-content-center`}
            >
              <SvgRender src={dots} style={{ height: 18, width: 18 }} />
            </Button>
          );
        case 'comparison':
          return (
            <PageActionButton
              action={{
                color: 'white',
                className: 'me-0 cps-12 cpe-12 border-0',
                text: (
                  <>
                    <SvgRender
                      src={comparison}
                      style={{ width: 16, height: 16 }}
                      className={'me-1'}
                    />
                    Comparison
                  </>
                ),
                ...action
              }}
            />
          );
        case 'search':
          return null;
        default:
          return action.customElement ? (
            action.customElement(action)
          ) : (
            <PageActionButton action={action} />
          );
      }
    };

    return actions && actions.length ? (
      <Col className={`d-flex align-items-center justify-content-end ${className}`}>
        {actions.map((action, index) => (
          <AuthCheck
            key={`action-check-${index}`}
            permissions={action.permissions}
            avoidCheck={!action.permissions}
          >
            <ActionContent
              key={action.type || index}
              actionsLength={(actions || []).length}
              action={action}
            />
          </AuthCheck>
        ))}
      </Col>
    ) : null;
  },
  (prevProps, nextProps) => _isEqual(prevProps, nextProps)
);

export default PageActions;
