import * as qs from 'qs';
import { QueryUrlParams } from '../types/table-query';
export const urlArrayLimit = 100;

export const stringifyObj = (obj: object) => qs.stringify(obj, { indices: true });

export const parseQueryParams = (search: string): QueryUrlParams => {
  if (search.includes('searchText=')) {
    // when searchText has symbols like '&' it creates bugs on our parsing

    // take the searchText correct value so we capture all the cases
    const regex = /searchText=([^&]+).*?(?=&sorting|&filters|&searchId|&visible|$)/;

    // deconstuct and take the searchText value
    const match = search.match(regex);

    const searchTextRaw = match ? match[0] : null;

    if (!searchTextRaw) return {};

    // clear the value from its tag and we keep it
    const searchTextValue = decodeURIComponent(searchTextRaw)
      ?.replace('&searchText=', '')
      .replace('searchText=', '');

    // modify the whole search url by deleting all the searchText part
    const modifiedSearch = search.replace(`${searchTextRaw}`, '');

    // parse the new modified search url
    const parsed = qs.parse(
      modifiedSearch.startsWith('?') ? modifiedSearch.substring(1) : modifiedSearch,
      { arrayLimit: urlArrayLimit }
    );

    return {
      ...parsed,
      // we add the searchText passing our new cleared text version
      searchText: searchTextValue
    };
  }
  return {
    ...qs.parse(search.startsWith('?') ? search.substring(1) : search, {
      arrayLimit: urlArrayLimit
    })
  };
};
