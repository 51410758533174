import { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import { get } from 'utils/api';

import { handleFileDownload } from 'common/utils/downloads';
import { selectNotHandledSucceededTasks } from 'store/tasks/selectors';
import { handlePendingSocketTasks, setTheTaskOuput } from 'store/tasks/slice';

import { download } from 'utils/api';

const useAsyncTasks = () => {
  const dispatch = useDispatch();
  const notHandledSucceededTasks = useSelector(selectNotHandledSucceededTasks);
  const onHandleTask = useCallback(
    async (uuid, type) => {
      dispatch(handlePendingSocketTasks(uuid));

      if (!uuid || !type) return;

      try {
        if (type === 'file') {
          await handleFileDownload(
            {
              url: `/system-tasks/${uuid}/result`
            },
            download,
            false
          );
        } else if (type === 'json') {
          const res = await get(`/system-tasks/${uuid}/result`);
          dispatch(setTheTaskOuput({ uuid, output: res?.data }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch]
  );

  const debounceOnHandleTask = useMemo(() => _debounce(onHandleTask, 800), [onHandleTask]);

  useEffect(() => {
    if (notHandledSucceededTasks?.length) {
      notHandledSucceededTasks.map(task =>
        debounceOnHandleTask(task.data?.job_uuid, task.data?.result?.type)
      );
    }
  }, [debounceOnHandleTask, notHandledSucceededTasks]);

  return null;
};

export default useAsyncTasks;
